import React from "react";
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import { imagesConst as IMAGECONST } from "../../../components/constants/imagesConst";


const CookiePolicy = () => {
  return (
    <div>
      <Header />
      <section className="azil-cookie-section-margin">
        <div className="azil-container-m">
          <div>
            <div className="gap-30" />
            <div className="text-center mx-auto">
              <div className="azil-cookie-main-title my-2">
                <span className="font-secondary">
                  {" "}
                  Discover Colors: Cookie Policy for{" "}
                </span>{" "}
                <a href="/">
                  <span className="font-primary">Colors.azillik.com </span>
                </a>
              </div>
              <div className="gap-60" />
            </div>
            <div className="azil-line" />
            <div className="mx-auto" style={{ maxWidth: "1000px" }}>
              <div className="azil-cookie-inner-margin">
                <div className="text-center mx-auto">
                  <div className="azil-cookie-var-title my-2">
                    <span className="font-secondary"> What Are</span>{" "}
                    <span className="font-primary">Cookies</span>
                  </div>
                  <div className="my-2 azil-cookie-text">
                    <p>
                      As is common practice with almost all professional
                      websites, this site uses cookies, which are tiny files
                      that are downloaded to your computer, to improve your
                      experience. This page describes what information they
                      gather, how we use it, and why we sometimes need to store
                      these cookies. We will also share how you can prevent
                      these cookies from being stored; however, this may
                      downgrade or 'break' certain elements of the site's
                      functionality. For more general information on cookies,
                      please read "What Are Cookies".
                    </p>
                  </div>
                </div>

                <div className="azil-cookie-inner-margin">
                  <div className="text-center">
                    <div className="azil-cookie-var-title my-2">
                      <span className="font-secondary"> How We Use </span>{" "}
                      <span className="font-primary">Cookies</span>
                      <span className="font-secondary"> ?</span>{" "}
                    </div>
                    <div className="my-2 azil-cookie-text">
                      <p>
                        We use cookies for a variety of reasons detailed below.
                        Unfortunately, in most cases, there are no industry
                        standard options for disabling cookies without
                        completely disabling the functionality and features they
                        add to this site. It is recommended that you leave on
                        all cookies if you are not sure whether you need them or
                        not, in case they are used to provide a service that you
                        use. Disabling Cookies
                      </p>
                    </div>
                  </div>
                </div>
                <div className="azil-cookie-inner-margin">
                  <div className="text-center">
                    <div className="azil-cookie-var-title my-2">
                      <span className="font-secondary">Disabling</span>{" "}
                      <span className="font-primary">Cookies</span>
                      <span className="font-secondary"> ?</span>{" "}
                    </div>
                    <div className="azil-cookie-text">
                      <p>
                        You can prevent the setting of cookies by adjusting the
                        settings on your browser (see your browser Help for how
                        to do this). Be aware that disabling cookies will affect
                        the functionality of this and many other websites that
                        you visit. Disabling cookies will usually result in also
                        disabling certain functionality and features of this
                        site. Therefore, it is recommended that you do not
                        disable cookies.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="azil-cookie-inner-margin">
                  <div className="text-center">
                    <div className="azil-cookie-var-title my-2">
                      <span className="font-secondary">The Cookies We</span>{" "}
                      <span className="font-primary">Set</span>
                      <span className="font-secondary"> ?</span>{" "}
                    </div>
                    <div className="my-2 azil-cookie-text">
                      <p>
                        Account related cookies: If you create an account with
                        us, then we will use cookies for the management of the
                        signup process and general administration. These cookies
                        will usually be deleted when you log out; however, in
                        some cases, they may remain afterward to remember your
                        site preferences when logged out.
                      </p>
                      <div className="azil-line my-3"></div>
                      <p>
                        Login related cookies: We use cookies when you are
                        logged in so that we can remember this fact. This
                        prevents you from having to log in every single time you
                        visit a new page. These cookies are typically removed or
                        cleared when you log out to ensure that you can only
                        access restricted features and areas when logged in.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="azil-cookie-inner-margin">
                  <div className="text-center">
                    <div className="azil-cookie-var-title my-2">
                      <span className="font-secondary">Third-Party</span>{" "}
                      <span className="font-primary">Cookies</span>
                      <span className="font-secondary"> ?</span>{" "}
                    </div>
                    <div className="my-2 azil-cookie-text">
                      <p>
                        In some special cases, we also use cookies provided by
                        trusted third parties. The following section details
                        which third-party cookies you might encounter through
                        this site:
                      </p>
                      <p>
                        This site uses Google Analytics, which is one of the
                        most widespread and trusted analytics solutions on the
                        web, to help us understand how you use the site and ways
                        that we can improve your experience. These cookies may
                        track things such as how long you spend on the site and
                        the pages that you visit so we can continue to produce
                        engaging content.
                      </p>
                      <div className="azil-line my-3"></div>
                      <p>
                        For more information on Google Analytics cookies, see
                        the official Google Analytics page.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="azil-cookie-inner-margin">
                  <div className="text-center">
                    <div className="azil-cookie-var-title my-2">
                      <span className="font-secondary">More</span>{" "}
                      <span className="font-primary">Information</span>
                      <span className="font-secondary"> ?</span>{" "}
                    </div>
                    <div className="my-2 azil-cookie-text">
                      <p>
                        Hopefully, that has clarified things for you, and as was
                        previously mentioned, if there is something that you
                        aren't sure whether you need or not, it's usually safer
                        to leave cookies enabled in case it does interact with
                        one of the features you use on our site.
                      </p>
                      <p className="mb-0">
                        However, if you are still looking for more information,
                        you can contact us through one of our preferred contact
                        methods:
                      </p>
                      <ul className="p-0 azil-list-style-none">
                        <li className="azil-list-style-blue">Email: contact@azillik.com</li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div id="color-type" style={{ paddingTop: "50px" }}>
                  <div className="azil-cookie-var-title text-center">
                    <div className="text-center">
                      <span className="font-secondary">Made in</span>{" "}
                            <img src={IMAGECONST.Indiaflag} alt="india"/>{" "}
                      <span className="font-primary">Bharat</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default CookiePolicy;
