/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import { Link, useHistory } from "react-router-dom";
import { constants as PATH } from "../../../components/constants/componentPath";
import { GetPortfolios } from "../../../services/ContactService";
import { Seo } from "../../../components/custom/Seo";
import { seoConstants as SEO_CONST } from "../../../components/constants/seoConst";
import AOS from "aos";
import "aos/dist/aos.css";

const Portfolio = () => {
  const history = useHistory();
  const [portfolioData, setPortfolioData] = useState([]);
  const [limit, setLimit] = useState(6);

  const getPortfolios = () => {
    let pagination = `?page=1&limit=${limit}`;
    GetPortfolios(pagination)
      .then((res) => {
        const data = res && res.data && res.data.data && res.data.data.data;
        setPortfolioData(data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getPortfolios();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit]);

  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);

  /* -------------------------------------------------------------------------- */
  /*                            Button ripple effect                            */
  /* -------------------------------------------------------------------------- */
  const handleHover = (e) => {
    const button = e.target;
    const rect = button.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    const rippleSpan = document.createElement('span');
    rippleSpan.classList.add('ripple-span');
    rippleSpan.style.top = `${y}px`;
    rippleSpan.style.left = `${x}px`;
    button.appendChild(rippleSpan);
  };

  const handleHoverEnd = (e) => {
    const button = e.target;
    const ripples = button.getElementsByClassName('ripple-span');
    while (ripples.length > 0) {
      button.removeChild(ripples[0]);
    }
  };

  return (
    <React.Fragment>
      <Seo
        title={SEO_CONST.PORTFOLIO_TITLE}
        description={""}
        keyword={SEO_CONST.PORTFOLIO_TITLE}
      />
      <Header />
      {/* OUR PORTFOLIOS */}
      <section className="azil-product-hero-section azil-section-padding azil-bg-hero">
        <div className="azil-home-container" data-aos="fade-up">
          <div className="text-center">
            <div className="my-2 font-primary font-600">
              OUR PORTFOLIOS
            </div>
            <div className="azil-primary-heading my-2">
              Get any creative project done with <span className="font-primary"> Azillik</span>
            </div>
            <div className="azil-description my-2">
              We provide various services to help our clients to fit their
              choices. So it will also refreshing our personality to, Our work
              shows our class with good experience WITH GOOD marks.
            </div>
          </div>
        </div>
      </section>
      {/* card */}
      <section className="azil-section-padding pb-0">
        <div className="azil-home-container" data-aos="fade-up">
          <div>
            <div className="active">
              <div className="row">
                {portfolioData &&
                  portfolioData.map((portfolio, index) => (
                    <>
                      <div
                        className="col-lg-4 col-md-6 col-sm-12 mb-4"
                        key={index}
                      >
                        <Link
                          to={{
                            pathname: PATH.CASE_STUDY,
                            state: {
                              portfolio: portfolio,
                            },
                          }}
                        >
                          <div className="portfolio-card">
                            <img
                              src={portfolio && portfolio.portfolio_image1}
                              draggable="false"
                            />
                          </div>
                          <div className="portfolio-card-body">
                            <h3 className="mt-4 mb-2 azil-small-heading">
                              {portfolio && portfolio.title}
                            </h3>
                            <p className="azil-description">
                              {portfolio && portfolio.description}
                            </p>
                            <div className="view-more-btn-slider">
                              <button className="vi-btn">
                                View More {" "}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="11"
                                  height="6"
                                  fill="none"
                                  viewBox="0 0 11 6"
                                  style={{rotate: "-90deg"}}
                                >
                                  <path
                                    fill="#6960eb"
                                    d="M5.061 5.53a.712.712 0 01-.496-.2L.255 1.16a.664.664 0 010-.961.719.719 0 01.993 0l3.813 3.69L8.874.2a.718.718 0 01.993 0 .664.664 0 010 .96l-4.31 4.172a.712.712 0 01-.496.199z"
                                  ></path>
                                </svg>
                              </button>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </>
                  ))}
              </div>
            </div>
            {portfolioData && portfolioData.length > 9 && (
              <div className="text-center">
                <button
                  className="azil-hero-button my-5"
                  onClick={() => setLimit(limit + 6)}
                >
                  View More
                </button>
              </div>
            )}
          </div>
        </div>
      </section>
      {/* let's talk */}
      <section>
        <div className="azil-home-container">
          <div className="row align-items-center">
            <div className="col-lg-7 col-md-6 col-sm-12 col-12 my-2">
              <div className="let-class">Let's</div>
              <div className="text-talk">Talk</div>
            </div>
            <div className="col-lg-5 col-md-6 col-sm-12 col-12 my-2">
              <div className="azil-description my-2">
                We're always looking for outstanding clients who are
                enthusiastic about their products and customers.
              </div>
              <button
                className="azil-hero-button my-2"
                onClick={() => {
                  history.push(PATH.CONTACT_US);
                }} onMouseEnter={handleHover} onMouseLeave={handleHoverEnd}
              >
                Contact Us
              </button>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </React.Fragment>
  );
};

export default Portfolio;