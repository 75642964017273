import React, { useEffect, useRef, useState } from "react";
import Footer from "../../Layout/Footer";
import Header from "../../Layout/Header";
import { imagesConst as IMAGECONST } from "../../../components/constants/imagesConst";
import { Link, useHistory } from "react-router-dom";
import { Collapse } from "reactstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { constants as PATH } from "../../../components/constants/componentPath";
import CustomContactUs from "../../../components/custom/CustomContactUs";
import {
  GetConfig,
  GetPillars,
  GetProductGrowths,
} from "../../../services/ContactService";
import { Seo } from "../../../components/custom/Seo";
import { seoConstants as SEO_CONST } from "../../../components/constants/seoConst";
import AOS from "aos";
import "aos/dist/aos.css";
import $ from "jquery";

const About = () => {
  const history = useHistory();
  const consultAzil = useRef();
  const [pillarData, setPillarData] = useState([]);
  const [productGrowthData, setProductGrowthData] = useState([]);
  const [activeFaq, setActiveFaq] = useState();
  const [configData, setConfigData] = useState({});

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getAllPillars = () => {
    let pagination = `?page=1&limit=5`;
    GetPillars(pagination)
      .then((res) => {
        const data = res && res.data && res.data.data && res.data.data.data;
        setPillarData(data);
      })
      .catch((err) => {});
  };

  const getProductGrowths = () => {
    let pagination = `?page=1&limit=5`;
    GetProductGrowths(pagination)
      .then((res) => {
        const data = res && res.data && res.data.data && res.data.data.data;
        setProductGrowthData(data);
      })
      .catch((err) => {});
  };
  /* -------------------------------------------------------------------------- */
  /*                      FAQ list close when click outside                     */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    getProductGrowths();

    document.body.addEventListener("click", handleBodyClick);
    return () => {
      document.body.removeEventListener("click", handleBodyClick);
    };
  }, []);
  const handleBodyClick = (event) => {
    if (!event.target.closest(".azil-faq-list")) {
      setActiveFaq(null);
    }
  };

  const getConfig = () => {
    GetConfig()
      .then((res) => {
        const data = res && res.data && res.data.data && res.data.data.data[0];
        setConfigData(data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getAllPillars();
    getProductGrowths();
    getConfig();
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);

  useEffect(() => {
    $.fn.jQuerySimpleCounter = function (options) {
      var settings = $.extend(
        {
          start: 0,
          end: 100,
          easing: "swing",
          duration: 400,
          complete: "",
        },
        options
      );

      var thisElement = $(this);

      $({ count: settings.start }).animate(
        { count: settings.end },
        {
          duration: settings.duration,
          easing: settings.easing,
          step: function () {
            var mathCount = Math.ceil(this.count);
            thisElement.text(mathCount);
          },
          complete: settings.complete,
        }
      );
    };

    if (Object.keys(configData).length > 0) {
      $("#number1").jQuerySimpleCounter({
        end: configData?.years_experience,
        duration: 3000,
      });
      $("#number2").jQuerySimpleCounter({
        end: configData?.successfull_rate,
        duration: 3000,
      });
      $("#number3").jQuerySimpleCounter({
        end: configData?.solution,
        duration: 3000,
      });
      $("#number4").jQuerySimpleCounter({
        end: configData?.clients,
        duration: 3000,
      });
    }
  }, [configData]);

  /* -------------------------------------------------------------------------- */
  /*                            Button ripple effect                            */
  /* -------------------------------------------------------------------------- */
  const handleHover = (e) => {
    const button = e.target;
    const rect = button.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    const rippleSpan = document.createElement('span');
    rippleSpan.classList.add('ripple-span');
    rippleSpan.style.top = `${y}px`;
    rippleSpan.style.left = `${x}px`;
    button.appendChild(rippleSpan);
  };

  const handleHoverEnd = (e) => {
    const button = e.target;
    const ripples = button.getElementsByClassName('ripple-span');
    while (ripples.length > 0) {
      button.removeChild(ripples[0]);
    }
  };

  return (
    <React.Fragment>
      <Seo
        title={SEO_CONST.ABOUT_TITLE}
        description={""}
        keyword={SEO_CONST.ABOUT_TITLE}
      />

      <Header />
      <section className="azil-hero-section azil-section-padding">
        <div className="azil-home-container" data-aos="fade-up">
          <div className="row align-items-center">
            <div className="col-lg-7 col-md-6 col-sm-12 col-12 my-2">
              <div className="azil-primary-heading my-2">
                We Provide <span className="font-primary">Quality Business</span> &{" "}
                <span className="font-primary">Smart Solution</span>{" "}
              </div>
              <div className="azil-description mt-4">
                To empower our customers to achieve their goals, by providing reliable and quality solutions with technology and innovation.
              </div>
              <button
                className="azil-hero-button my-3"
                onClick={() =>
                  consultAzil.current.scrollIntoView({ behavior: "smooth" })
                } onMouseEnter={handleHover} onMouseLeave={handleHoverEnd}
              >
                Consult Azillik
              </button>
            </div>
            <div className="col-lg-5 col-md-6 col-sm-12 col-12 my-2 text-right">
              <img
                src={IMAGECONST.About}
                alt="about"
                className="img-fluid azil-about-img"
                draggable="false"
                width={400} height={400}
              />
            </div>
          </div>
        </div>
      </section>

      {/* IT Solutions */}
      <section className="azil-section-padding">
        <div className="azil-home-container" data-aos="fade-up">
          <div className="text-center">
            <div className="azil-primary-heading">
              Your one-stop Address to{"  "}<span className="font-primary">IT Solutions</span>
            </div>
            <div className="azil-description">
              Our unbeatable expertise and experience as a website development
              company allow us to satisfy your
              <br /> needs in every area of web & mobile app development.
            </div>
          </div>
          <div className="row azil-section-padding">
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 my-2">
              <div className="d-flex">
                <div className="mx-2">
                  <img src={IMAGECONST.Circletick} alt="tick"/>
                </div>
                <div className="azil-description mx-2">
                  World-class Website and Mobile Application Development.
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 my-2">
              <div className="d-flex align-items-baseline">
                <div className="mx-2">
                  <img src={IMAGECONST.Circletick} alt="tick"/>
                </div>
                <div className="azil-description mx-2">
                  Cost-effective services with customer satisfaction.
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12 my-2">
              <div className="d-flex align-items-baseline">
                <div className="mx-2">
                  <img src={IMAGECONST.Circletick} alt="tick"/>
                </div>
                <div className="azil-description mx-2">
                  To deliver world-class products and services at competitive
                  rates.
                </div>
              </div>
            </div>
          </div>
          <div className="text-center">
            <button
              className="azil-hero-button my-2"
              onClick={() => history.push(PATH.WE_WORK)} onMouseEnter={handleHover} onMouseLeave={handleHoverEnd}
            >
              We Work on
            </button>
          </div>
        </div>
      </section>

      {/* Product Growth */}
      <section className="azil-section-3 azil-section-padding azil-bg-hero">
        <div className="azil-home-container" data-aos="fade-up">
          <div className="text-center">
            <div className="azil-primary-heading my-2">
              Secured
              <span className="font-primary"> Product Growth</span>
            </div>
            <div className="azil-description mb-5">
              We provide professional web development services that ensure a
              stable, measurable and secured marketing growth of your
              organization.
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6 col-sm-6 col-12 my-2">
              <img
                src={IMAGECONST.About2}
                alt="about"
                className="img-fluid azil-about-img"
                draggable="false"
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-12 my-2">
              <ul className="azil-about-faq-list azil-faq-list">
                {productGrowthData &&
                  productGrowthData.map((product, index) => (
                    <>
                      <li
                        className={
                          activeFaq === product.product_growth_id
                            ? "azil-the-active"
                            : ""
                        }
                        style={{textTransform: "capitalize", cursor: "pointer"}}
                        key={index}
                        onClick={() => {
                          setActiveFaq((prevActiveFaq) =>
                            prevActiveFaq === product.product_growth_id ? null : product.product_growth_id
                          );
                        }}
                      >
                        <Link
                          to="#"
                          className="font-secondary cursor-pointer"
                        >
                          <h4 className="azil-faq-heading">
                            {product && product.question}
                          </h4>
                        </Link>
                        <Collapse isOpen={activeFaq === product.product_growth_id}>
                          <p className="azil-read azil-faq-text pt-3">
                            {product && product.answer}
                          </p>
                        </Collapse>
                      </li>
                    </>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Achievements */}
      <section className="azil-section-padding">
        <div className="azil-home-container" data-aos="fade-up">
          <div className="text-center">
            <div className="azil-primary-heading my-2">
              Our<span className="font-primary"> Achievements </span>
            </div>
            <div className="azil-description my-2">
              We provide professional web development services that ensure a stable.
            </div>
          </div>
          <div className="row align-items-center text-center my-3">
            <div className="col-lg-3 col-md-3 col-sm-6 col-6 my-2">
              <div className="azil-achive-number">
                <span id="number1" className="font-secondary">{configData.years_experience}</span>+
              </div>
              <div className="azil-description my-2">
                Years Of Experience
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 col-6 my-2">
              <div className="azil-achive-number">
                <span id="number2">{configData.successfull_rate}</span>%
              </div>
              <div className="azil-description my-2">Successful Rate</div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 col-6 my-2">
              <div className="azil-achive-number">
                <span id="number3">{configData.solution}</span>+
              </div>
              <div className="azil-description my-2">Solution</div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 col-6 my-2">
              <div className="azil-achive-number">
                #<span id="number4">{configData.clients}</span>
              </div>
              <div className="azil-description my-2">Clients</div>
            </div>
          </div>
        </div>
      </section>

      {/* Vision */}
      <section className="azil-section-padding azil-bg-hero">
        <div className="azil-home-container" data-aos="fade-up">
          <div className="text-center">
            <div className="azil-primary-heading my-2">
              Our
              <span className="font-primary"> Vision</span>
            </div>
            <div className="azil-description my-2">
              To empower our customers to achieve their goals, by providing
              reliable and quality solutions with technology and innovation.
            </div>
          </div>

          <div className="row azil-section-padding pb-0 align-items-center">
            <div className="col-lg-7 col-md-6 col-sm-12 col-12 my-2 align-self-center">
              <div className="azil-mid-heading">
                Our
                <span className="font-primary"> Values</span>
              </div>
              <div className="azil-description line-height-30 my-2">
                <ul className="azil-list-style-none p-0">
                  <li>
                    {" "}
                    <span className="font-dark-grey font-700">Value to the customer: </span>We work together with our
                    client to add value in whatever we do to contribute to the
                    success of our client.
                  </li>
                  <li>
                    {" "}
                    <span className="font-dark-grey font-700">Repect the people: </span>We encourage people centric
                    approach, value people, encourage development and reward
                    their performance.
                  </li>
                  <li>
                    {" "}
                    <span className="font-dark-grey font-700">Will to win: </span>We exhibit a strong will to win in every
                    challenging task and in every aspect of work we do.
                  </li>
                  <li>
                    {" "}
                    <span className="font-dark-grey font-700">Quality: </span>We believe in the motto that “what we do –
                    we do well”. We work to provide outstanding services to our
                    customers with utmost importance given to quality.
                  </li>
                  <li>
                    {" "}
                    <span className="font-dark-grey font-700">Accountability: </span>We believe in fact that “If it’s to
                    be, it’s by me”. We take accountability to deliver our
                    commitments.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-5 col-md-6 col-sm-12 col-12 my-2 text-center">
              <img
                src={IMAGECONST.Abouthero}
                alt=""
                className="img-fluid azil-about-img"
                draggable="false"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Core Value */}
      <section className="azil-about-section-6 azil-section-padding">
        <div className="azil-home-container" data-aos="zoom-in">
          <div className="text-center">
            <div className="azil-primary-heading my-2">
              Core
              <span className="font-primary"> Value</span>
            </div>
            <div className="azil-description my-2">
              We provide professional web development services that ensure a stable.
            </div>
          </div>
          <div className="d-flex flex-wrap align-items-center justify-content-between my-3">
            <div className="azil-core-main-box my-2">
              <div>
                <div className="azil-core-img azil-core-clarity">
                  <img src={IMAGECONST.Clarity} alt="clarity"/>
                </div>
                <p className="azil-description my-2">Clarity</p>
              </div>
            </div>
            <div className="azil-core-main-box my-2">
              <div>
                <div className="azil-core-img azil-core-proficiency">
                  <img src={IMAGECONST.Proficiency} alt="Proficiency"/>
                </div>
                <p className="font-grey font-14 my-2">Proficiency</p>
              </div>
            </div>
            <div className="azil-core-main-box my-2">
              <div>
                <div className="azil-core-img azil-core-teamwork">
                  <img src={IMAGECONST.Teamwork} alt="Teamwork"/>
                </div>
                <p className="font-grey font-14 my-2">Teamwork</p>
              </div>
            </div>
            <div className="azil-core-main-box my-2">
              <div>
                <div className="azil-core-img azil-core-ideals">
                  <img src={IMAGECONST.Ideals} alt="Ideals"/>
                </div>
                <p className="font-grey font-14 my-2">Ideals</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Pillars */}
      <section>
        <div className="azil-home-container" data-aos="fade-up">
          <div className="text-center">
            <div className="azil-primary-heading my-2">
              Our
              <span className="font-primary"> Pillars</span>
            </div>
            <div className="azil-description my-2">
              Our team is our greatest value and asset, and our main goal is the success of our customers and our employees.
            </div>
          </div>

          <div className="azil-section-padding">
            <div>
              <Slider {...settings}>
                {pillarData &&
                  pillarData.map((pillar, index) => {
                    return (
                      <>
                        <div
                          className="row team-slider-1 d-flex"
                          key={index}
                        >
                          <div className="col-lg-3 col-md-6 col-sm-6 col-12 my-2">
                            <img
                              src={pillar && pillar.pillar_image}
                              alt="team-1"
                              className="img-fluid"
                              draggable="false"
                            />
                          </div>
                          <div className="col-lg-8 col-md-16 col-sm-6 col-12 my-2">
                            <div className="azil-mid-heading my-2">
                              {pillar && pillar.name}
                            </div>
                            <div className="font-dark-grey font-600 my-2">
                              {pillar && pillar.position}
                            </div>
                            <div className="azil-description my-2">
                              {pillar && pillar.description}
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </Slider>
            </div>
          </div>
        </div>
      </section>

      <section
        className="azil-home-section-10 azil-section-padding azil-bg-hero"
        ref={consultAzil}
        data-aos="fade-up"
      >
        <CustomContactUs />
      </section>
      <Footer />
    </React.Fragment>
  );
};

export default About;