/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import Footer from "../../Layout/Footer";
import Header from "../../Layout/Header";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { imagesConst as IMAGECONST } from "../../../components/constants/imagesConst";
import { GetLegals } from "../../../services/ContactService";

const Privacy = () => {
  const [contents, setContents] = useState("Terms of Service");
  const [services, setServices] = useState("it_service");
  const [legalData, setLegalData] = useState();

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: false,
    arrows: true,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <span className="azil-privacy slick-next">
        <img
          className={className}
          style={{ ...style, display: "block" }}
          onClick={onClick}
          src={IMAGECONST.Nextarrow}
          alt="next"
          height={50}
        />
      </span>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <span className="azil-privacy slick-prev">
        <img
          className={className}
          style={{ ...style, display: "block" }}
          onClick={onClick}
          src={IMAGECONST.Prevarrow}
          alt="prev"
          height={50}
        />
      </span>
    );
  }

  const getLegalAPI = () => {
    GetLegals(`?type=${services}&page=${contents}`)
      .then((res) => {
        const data = res && res.data && res.data.data && res.data.data.data;
        setLegalData(data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getLegalAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [services, contents]);

  return (
    <React.Fragment>
      <Header />
      <section className="azil-product-hero-section azil-privacy-section-padding azil-bg-hero">
        <div className="azil-home-container">
          <div className="text-center azil-section-title my-2">Legal</div>
          <div className="text-center azil-description mx-5 mt-4">
            This section presents you all legal-related documents regarding
            Azillik Company’s terms and conditions. We try our best to protect
            your rights. Something we missed?{" "}
            <span>
              <a href="mailto:info@azillik.com" className="font-primary azil-link-text"> Email us</a>
            </span>
          </div>
        </div>
      </section>

      <section className="azil-section-padding azil-privacy-top-margin">
        <div className="azil-home-container">
          <div className="azil-privacy-bg">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-12 my-2 azil-border-right">
                <div className="font-24 font-700 pb-4"> Contents</div>
                <div className="my-2">
                  <ul className="nav nav-tabs">
                    <li className="azil-privacy-tab">
                      <a
                        id="terms-tab"
                        data-toggle="tab"
                        href="#"
                        role="tab"
                        aria-controls="first"
                        aria-selected={
                          contents === "Terms of Service" ? "true" : "false"
                        }
                        onClick={() => setContents("Terms of Service")}
                        className={contents === "Terms of Service" ? "active" : ""}
                      >
                        Terms of Service
                      </a>
                    </li>
                    <li className="azil-privacy-tab">
                      <a
                        id="license-tab"
                        data-toggle="tab"
                        href="#"
                        role="tab"
                        aria-controls="second"
                        aria-selected={
                          contents === "License Agrement" ? "true" : "false"
                        }
                        onClick={() => setContents("License Agrement")}
                        className={
                          contents === "License Agrement" ? "active" : ""
                        }
                      >
                        License Agrement
                      </a>
                    </li>
                    <li className="azil-privacy-tab">
                      <a
                        id="business-tab"
                        data-toggle="tab"
                        href="#"
                        role="tab"
                        aria-controls="second"
                        aria-selected={
                          contents === "Business Authorization"
                            ? "true"
                            : "false"
                        }
                        onClick={() => setContents("Business Authorization")}
                        className={
                          contents === "Business Authorization" ? "active" : ""
                        }
                      >
                        Business Authorization
                      </a>
                    </li>
                    <li className="azil-privacy-tab">
                      <a
                        id="privacy-tab"
                        data-toggle="tab"
                        href="#"
                        role="tab"
                        aria-controls="second"
                        aria-selected={
                          contents === "Privacy Policy" ? "true" : "false"
                        }
                        onClick={() => setContents("Privacy Policy")}
                        className={
                          contents === "Privacy Policy" ? "active" : ""
                        }
                      >
                        Privacy Policy
                      </a>
                    </li>
                    <li className="azil-privacy-tab">
                      <a
                        id="copyright-tab"
                        data-toggle="tab"
                        href="#"
                        role="tab"
                        aria-controls="second"
                        aria-selected={
                          contents === "Copyright Information"
                            ? "true"
                            : "false"
                        }
                        onClick={() => setContents("Copyright Information")}
                        className={
                          contents === "Copyright Information" ? "active" : ""
                        }
                      >
                        Copyright Information
                      </a>
                    </li>
                    <li className="azil-privacy-tab">
                      <a
                        id="property-tab"
                        data-toggle="tab"
                        href="#"
                        role="tab"
                        aria-controls="second"
                        aria-selected={
                          contents === "Intellectual Property Rights"
                            ? "true"
                            : "false"
                        }
                        onClick={() =>
                          setContents("Intellectual Property Rights")
                        }
                        className={
                          contents === "Intellectual Property Rights"
                            ? "active"
                            : ""
                        }
                      >
                        Intellectual Property Rights
                      </a>
                    </li>
                    <li className="azil-privacy-tab">
                      <a
                        id="refund-tab"
                        data-toggle="tab"
                        href="#"
                        role="tab"
                        aria-controls="second"
                        aria-selected={
                          contents === "Refund Policy" ? "true" : "false"
                        }
                        onClick={() => setContents("Refund Policy")}
                        className={contents === "Refund Policy" ? "active" : ""}
                      >
                        Refund Policy
                      </a>
                    </li>
                    <li className="azil-privacy-tab">
                      <a
                        id="use-tab"
                        data-toggle="tab"
                        href="#"
                        role="tab"
                        aria-controls="second"
                        aria-selected={
                          contents === "How to Use" ? "true" : "false"
                        }
                        onClick={() => setContents("How to Use")}
                        className={contents === "How to Use" ? "active" : ""}
                      >
                        How to Use
                      </a>
                    </li>
                    <li className="azil-privacy-tab">
                      <a
                        id="faq-tab"
                        data-toggle="tab"
                        href="#"
                        role="tab"
                        aria-controls="second"
                        aria-selected={contents === "FAQ" ? "true" : "false"}
                        onClick={() => setContents("FAQ")}
                        className={contents === "FAQ" ? "active" : ""}
                      >
                        FAQ
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-9 col-md-9 col-sm-12 my-2">
                <div className="testimonial-slider">
                  <div>
                    <Slider {...settings}>
                      <div className="testimonial">
                        <a
                          id="itservice-tab"
                          data-toggle="tab"
                          href="#"
                          role="tab"
                          aria-controls="second"
                          aria-selected="false"
                          onClick={() => setServices("it_service")}
                          className={services === "it_service" ? "active" : ""}
                        >
                          IT services
                        </a>
                      </div>
                      <div className="testimonial">
                        <a
                          id="azillik-tab"
                          data-toggle="tab"
                          href="#"
                          role="tab"
                          aria-controls="second"
                          aria-selected="false"
                          onClick={() => setServices("discovery")}
                          className={services === "discovery" ? "active" : ""}
                        >
                          Azillik Discovery
                        </a>
                      </div>
                      <div className="testimonial">
                        <a
                          id="colorswatch-tab"
                          data-toggle="tab"
                          href="#"
                          role="tab"
                          aria-controls="first"
                          aria-selected="true"
                          onClick={() => setServices("color_swatch")}
                          className={
                            services === "color_swatch" ? "active" : ""
                          }
                        >
                          Color Swatch
                        </a>
                      </div>
                      <div className="testimonial">
                        <a
                          id="gradiant-tab"
                          data-toggle="tab"
                          href="#"
                          role="tab"
                          aria-controls="second"
                          aria-selected="false"
                          onClick={() => setServices("gradient_swatch")}
                          className={
                            services === "gradient_swatch" ? "active" : ""
                          }
                        >
                          Gradient Swatch
                        </a>
                      </div>
                      <div className="testimonial">
                        <a
                          id="uicolor--tab"
                          data-toggle="tab"
                          href="#"
                          role="tab"
                          aria-controls="second"
                          aria-selected="false"
                          onClick={() => setServices("ui_color_map")}
                          className={
                            services === "ui_color_map" ? "active" : ""
                          }
                        >
                          Ui Color Map
                        </a>
                      </div>
                      <div className="testimonial">
                        <a
                          id="mock-tab"
                          data-toggle="tab"
                          href="#"
                          role="tab"
                          aria-controls="second"
                          aria-selected="false"
                          onClick={() => setServices("mock_view")}
                          className={services === "mock_view" ? "active" : ""}
                        >
                          Mock View
                        </a>
                      </div>
                    </Slider>
                  </div>
                </div>

                <div className="azil-tab-content">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: legalData && legalData.page_content,
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </React.Fragment>
  );
};

export default Privacy;