import React, { useEffect, useRef, useState } from "react";
import Footer from "../../Layout/Footer";
import Header from "../../Layout/Header";
import { imagesConst as IMAGECONST } from "../../../components/constants/imagesConst";
import CustomContactUs from "../../../components/custom/CustomContactUs";
import { GetPillars } from "../../../services/ContactService";
import { Seo } from "../../../components/custom/Seo";
import { seoConstants as SEO_CONST } from "../../../components/constants/seoConst";
import AOS from "aos";
import "aos/dist/aos.css";

const OurTeam = () => {
  const consultAzil = useRef();
  const [pillarData, setPillarData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getAllPillars = () => {
    let pagination = `?page=1`;
    GetPillars(pagination)
      .then((res) => {
        const data = res && res.data && res.data.data && res.data.data.data;
        setPillarData(data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getAllPillars();
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);
  /* -------------------------------------------------------------------------- */
  /*                            Button ripple effect                            */
  /* -------------------------------------------------------------------------- */
  const handleHover = (e) => {
    const button = e.target;
    const rect = button.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    const rippleSpan = document.createElement('span');
    rippleSpan.classList.add('ripple-span');
    rippleSpan.style.top = `${y}px`;
    rippleSpan.style.left = `${x}px`;
    button.appendChild(rippleSpan);
  };

  const handleHoverEnd = (e) => {
    const button = e.target;
    const ripples = button.getElementsByClassName('ripple-span');
    while (ripples.length > 0) {
      button.removeChild(ripples[0]);
    }
  };

  return (
    <React.Fragment>
      <Seo
        title={SEO_CONST.OUR_TEAM_TITLE}
        description={""}
        keyword={SEO_CONST.OUR_TEAM_TITLE}
      />
      <Header />
      <section className="azil-team-hero-section azil-section-padding azil-bg-hero">
        <div className="azil-home-container" data-aos="fade-up">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6 col-sm-12 col-12 my-2">
              <div className="azil-primary-heading my-2">
                Our <span> Team</span>
              </div>
              <div className="azil-description mt-4">
                Leadership that fosters us for customer success
              </div>
              <button
                className="azil-hero-button my-3"
                onClick={() =>
                  consultAzil.current.scrollIntoView({ behavior: "smooth" })
                } onMouseEnter={handleHover} onMouseLeave={handleHoverEnd}
              >
                Consult Azillik
              </button>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-12 my-2 text-right">
              <img
                src={IMAGECONST.Herobanner}
                alt="banner"
                className="img-fluid"
                draggable="false" width={350} height={350}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="azil-section-padding">
        <div className="azil-home-container" data-aos="fade-up">
          <div className="text-center">
            <div className="azil-primary-heading my-2">
              Our <span className="font-primary">Pillars</span>
            </div>
          </div>
          <div className="team-slider pb-0 mb-0">
            <div className="row">
              {pillarData &&
                pillarData.map((pillar, index) => {
                  return (
                    <>
                      <div
                        className="col-lg-4 col-md-6 col-sm-12 col-12 my-2"
                        key={index}
                      >
                        <div className="azil-portfolio-box">
                          <div
                            className="team-slider-1 text-center"
                            id="our-team"
                          >
                            <div className="azil-img-scale-box">
                              <img
                                src={pillar && pillar.pillar_image}
                                alt="piller"
                                className="img-fluid azil-img-scale"
                                draggable="false"
                              />
                            </div>
                            <div className="azil-small-heading pt-4">
                              {pillar && pillar.name}
                            </div>
                            <div className="azil-description my-2">
                              {pillar && pillar.position}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
            </div>
          </div>
        </div>
      </section>
      <section className="azil-section-padding pt-0">
        <div className="container" data-aos="fade-up">
          <div className="text-justify">
            <div className="azil-blog-content">
              <span className="font-primary font-600">
                Our goal{" "}
              </span>
              is for the world to easily make its life easier by using
              technology. We have created a website, application, and software
              with user experience. We Select and create UI that can be used
              easily. And they can earn money using technology. We will do a
              100% accurate project. And if anyone has an idea in their mind and
              they want to do something, you can share it with azillik.
            </div>

            <br />
            <div className="azil-blog-content">
              At the beginning of our story, Divy Bhingradia, founder of Azillk
              company, Has been providing IT software development services for a
              long time. And he thought to create a platform in 2018 where
              designers, developers, artists, and freelancers could search for
              graphic resources with color and gradient combinations, as well as
              he started to make a UI Scheme view of what the application would
              look like using different colors. Then they decided to create a
              mock view of which application will look like on which device.
            </div>
            <br />
            <div className="azil-blog-content">
              But they still 2022 seemed to be missing something so they decided
              to give it a ready icon, 3D MODELS, Sound, Video template,
              Documentation template, and font as well as job placement. And he
              thinks about what people would do with all this. From which he
              makes a store for buying and selling ready-made software, apps,
              and web templates. And anyone can upload their content and earn
              money easily sitting at home. But it was not easy to make this
              worldwide so he needed a dedicated team which he built in a lot of
              struggle.
            </div>
            <br />
            <div className="azil-blog-content">
              <span className="font-primary font-600">
                Our vision{" "}
              </span>
              is to make work easier and faster for artists, software designers,
              and developers. and we made an easy way from learning a coding
              language to selling software. and we provide IT Services. And not
              only that, there is still a lot to do to provide good services to
              the world.
              <div className="azil-blog-content">
                Bhargav and Janvi supported his idea and thus Azillik company
                was formed.
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="azil-home-section-10 azil-section-padding azil-bg-hero"
        ref={consultAzil}
        data-aos="fade-up"
      >
        <CustomContactUs />
      </section>
      <Footer />
    </React.Fragment>
  );
};

export default OurTeam;
