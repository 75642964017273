import React, { useEffect, useState } from "react";
import Footer from "../../Layout/Footer";
import Header from "../../Layout/Header";
import { imagesConst as IMAGECONST } from "../../../components/constants/imagesConst";
import ReactHtmlParser from "react-html-parser";
import { GetHirings } from "../../../services/ContactService";
import { useHistory } from "react-router-dom";
import { constants as PATH } from "../../../components/constants/componentPath";

const JobDetails = (props) => {
  const { hiring } = props && props.location && props.location.state;
  const history = useHistory();
  const [hiringData, setHiringData] = useState([]);
  const [limit] = useState(5);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getHirings = () => {
    let pagination = `?page=1&limit=${limit}`;
    GetHirings(pagination)
      .then((res) => {
        const data = res && res.data && res.data.data && res.data.data.data;
        const result = data.filter(
          (item) => item.hiring_id !== hiring.hiring_id
        );
        setHiringData(result);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getHirings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit]);

  /* -------------------------------------------------------------------------- */
  /*                            Button ripple effect                            */
  /* -------------------------------------------------------------------------- */
  const handleHover = (e) => {
    const button = e.target;
    const rect = button.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    const rippleSpan = document.createElement('span');
    rippleSpan.classList.add('ripple-span');
    rippleSpan.style.top = `${y}px`;
    rippleSpan.style.left = `${x}px`;
    button.appendChild(rippleSpan);
  };

  const handleHoverEnd = (e) => {
    const button = e.target;
    const ripples = button.getElementsByClassName('ripple-span');
    while (ripples.length > 0) {
      button.removeChild(ripples[0]);
    }
  };

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  // Shuffle the hiringData array
  const shuffledData = shuffleArray(hiringData);

  return (
    <React.Fragment>
      <Header />
      <section className="azil-apply-hero-section azil-section-padding azil-bg-hero">
        <div className="azil-home-container">
          <div className="text-center">
            <img src={hiring.hiring_image} alt="hiring" className="azil-hiring-main-img" draggable={false} />
            <div className="azil-primary-heading my-2">
              {hiring.title.split(" ")[0]}
              <span className="font-primary"> {hiring.title.split(" ")[1]} </span>
            </div>
            <div className="azil-description my-2">
              {hiring.time === "part_time" ? "PART-TIME" : "FULL-TIME"} | (
              {hiring.location}) | {hiring.experience} EXPERIENCE
            </div>
          </div>
        </div>
      </section>
      <section className="azil-section-padding pt-0">
        <div className="azil-home-container">
          <div className="azil-contact-margin">
            <div className="row">
              <div className="col-lg-7 col-md-6 col-sm-12 col-12 my-2">
                <div className="azil-contact-form-bg azil-bg-hero-faq shadow-sm mt-5">
                  <div className="hiring">
                    <div className="azil-primary-heading mb-4">Details of <span className="font-primary">{hiring.description}</span></div>
                    <span className="azil-description">{ReactHtmlParser(hiring && hiring.hiring_content)}</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-6 col-sm-12 col-12 my-2">
                <div className="azil-bg-contact my-2 shadow-sm mt-5">
                  <div className="my-3">
                    <button
                      className="azil-hero-button align-items-center"
                      onClick={() => {
                        history.push({ pathname: PATH.APPLY_JOB, state: { hiring: hiring }, });
                      }} onMouseEnter={handleHover} onMouseLeave={handleHoverEnd}
                    >
                      Apply for this job
                    </button>
                  </div>
                  <div className="font-14 font-secondary font-600 mt-5 mb-3" style={{textTransform: "uppercase"}}>
                    Share This <span className="font-primary">With Someone</span>
                  </div>
                  <div className="azil-primary-content mt-2 mb-3">
                    <ul className="p-0 m-0 d-flex mt-3 azil-social">
                      <li className="azil-whatsapp">
                        <img src={IMAGECONST.Whatsapp} alt="whatsapp" draggable={false} width={18}/>
                      </li>
                      <li className="azil-linkedin">
                        <img src={IMAGECONST.Linkedin} alt="linkedin" draggable={false} width={18}/>
                      </li>
                      <li className="azil-facebook">
                        <img src={IMAGECONST.Facebook} alt="facebook" draggable={false} width={18}/>
                      </li>
                      <li className="azil-twitter">
                        <img src={IMAGECONST.Twitter} alt="twitter" draggable={false} width={18}/>
                      </li>
                    </ul>
                  </div>
                  <div className="font-14 font-secondary font-600 mt-5 mb-3">
                    MORE <span className="font-primary">OPENINGS</span>
                  </div>
                    <div className="row">
                      {shuffledData &&
                        shuffledData.map((hiring, index) => {
                          return (
                            <>
                              <div className="col-lg-12 col-md-12 col-sm-6 col-12 my-3" key={index} >
                                <div
                                  className="azil-hiring-box"
                                  onClick={() =>
                                    history.push({
                                      pathname: PATH.JOB_DETAILS,
                                      state: {
                                        hiring: hiring,
                                      },
                                    })
                                  }
                                >
                                  <div className="d-flex align-items-center">
                                    <div>
                                      <img
                                        src={hiring.hiring_image}
                                        alt="hiring"
                                        width={60}
                                        draggable={false}
                                        className="mr-4 azil-res-hiring-img"
                                      />
                                    </div>
                                    <div className="col-lg-8 col-md-8 col-sm-9 col-8 p-0">
                                      <div className="font-black font-16 font-600 mb-2">
                                        {hiring.title}
                                      </div>
                                      <div className="d-flex align-items-center justify-content-between flex-wrap">
                                        <div className="azil-description">
                                          <span className="font-dark-grey font-600">
                                            Exp: {" "}
                                          </span>
                                          {hiring.experience} Years
                                        </div>
                                        <div className="azil-description">
                                          <span className="font-dark-grey font-600">
                                            Positions: {" "}
                                          </span>
                                          {hiring.positions}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })}
                    </div>
                    <div className="font-primary font-500 cursor-pointer mt-3 azil-hiring-view-box"
                      onClick={() => history.push(PATH.HIRING)}
                    > <p>View all job openings</p> 
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        fill="none"
                        viewBox="0 0 18 18" className="mx-2"
                      >
                        <path
                          stroke="#6960eb"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.75"
                          d="M7.355 2.781h4.947V7.74M12.303 2.781L5.699 9.385M2 14.078a22.107 22.107 0 0014 0"
                        ></path>
                      </svg>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </React.Fragment>
  );
};

export default JobDetails;
