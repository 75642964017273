/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import Footer from "../../Layout/Footer";
import Header from "../../Layout/Header";
import Dropzone from "react-dropzone";
import { imagesConst as IMAGECONST } from "../../../components/constants/imagesConst";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { errorConst as ERROR } from "../../../components/constants/errorConst";
import { Card, Col, Progress, Row } from "reactstrap";
import { Link } from "react-router-dom";
import CustomInput from "../../../components/custom/CustomInput";
import "react-phone-input-2/lib/style.css";
import { ApplyJobAPI } from "../../../services/ContactService";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import { notificationMessageFunction } from "../../../components/constants/notificationConst";
import { Seo } from "../../../components/custom/Seo";
import { seoConstants as SEO_CONST } from "../../../components/constants/seoConst";
import CustomPhoneNumber from "../../../components/custom/CustomPhoneNumber/CustomPhoneNumber";

const ApplyJob = (props) => {
  const [images, setImages] = useState([]);
  const [percentage, setPercentage] = useState(0);
  const [gender, setGender] = useState("Gender");
  const [genderError, setGenderError] = useState("");
  const [isError, setIsError] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const { hiring } = props && props.location && props.location.state;

  const initialValues = {
    firstname: "",
    lastname: "",
    email: "",
    experience_in_year: "",
    age: "",
    birth_date: "",
  };

  const ApplyJobSchema = Yup.object().shape({
    firstname: Yup.string()
      .trim()
      .matches(ERROR.SPACE_REGEX, ERROR.SPACE)
      .required(ERROR.FIRST_NAME),
    lastname: Yup.string()
      .trim()
      .matches(ERROR.SPACE_REGEX, ERROR.SPACE)
      .required(ERROR.LAST_NAME),
    email: Yup.string().trim().email(ERROR.VALID_EMAIL).required(ERROR.EMAIL),
    experience_in_year: Yup.number()
      .required(ERROR.EXPIRENCE_IN_YEAR)
      .min(0, ERROR.VALID_NUM),
    age: Yup.number().required(ERROR.AGE).min(0, ERROR.VALID_NUM),
    birth_date: Yup.string()
      .trim()
      .matches(ERROR.SPACE_REGEX, ERROR.SPACE)
      .required(ERROR.BIRTH_DATE),
  });

  const handleSubmit = (values, { resetForm }) => {
    let isError = false;
    if (gender === "") {
      isError = true;
      setGenderError(ERROR.GENDER);
    } else {
      isError = false;
      setGenderError(false);
    }
    // if (phoneNumber === "" || phoneNumber === null) {
    //   isError = true;
    //   setIsError(true);
    // } else {
    //   isError = false;
    //   setIsError(false);
    // }

    if (images.length === 0) {
      isError = true;
    }

    if (images.length > 1) {
      isError = true;
    }

    if (!isError) {
      const data = new FormData();
      data.append("firstname", values.firstname);
      data.append("lastname", values.lastname);
      data.append("phone_number", values.phone_number);
      data.append("gender", gender.value);
      data.append("email", values.email);
      data.append("experience_year", values.experience_in_year);
      data.append("age", values.age);
      data.append("dob", values.birth_date);
      data.append("apply_job_image", images[0]);

      ApplyJobAPI(data)
        .then((res) => {
          NotificationManager.success("Apply job added successfully");
          resetForm();
          setImages([]);
        })
        .catch((err) => {
          const errorMessage =
            err && err.data && err.data.error && err.data.error.message;
          NotificationManager.error(notificationMessageFunction(errorMessage));
        });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleAcceptedFiles = (files) => {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setImages(files);
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const removeFile = (file) => {
    const newFiles = [...images];
    newFiles.splice(file, 1);
    setImages(newFiles);
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const items = [
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
    { label: "Other", value: "other" },
  ];

  const handleGenderChange = (selectedGender) => {
    setGender(selectedGender);
    setDropdownOpen(false);
    setGenderError("");
  };

  /* -------------------------------------------------------------------------- */
  /*                            Button ripple effect                            */
  /* -------------------------------------------------------------------------- */
  const handleHover = (e) => {
    const button = e.target;
    const rect = button.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    const rippleSpan = document.createElement('span');
    rippleSpan.classList.add('ripple-span');
    rippleSpan.style.top = `${y}px`;
    rippleSpan.style.left = `${x}px`;
    button.appendChild(rippleSpan);
  };

  const handleHoverEnd = (e) => {
    const button = e.target;
    const ripples = button.getElementsByClassName('ripple-span');
    while (ripples.length > 0) {
      button.removeChild(ripples[0]);
    }
  };

  return (
    <React.Fragment>
      <Seo
        title={SEO_CONST.APPLY_JOB_TITLE}
        description={""}
        keyword={SEO_CONST.APPLY_JOB_TITLE}
      />
      <Header />
      <section className="azil-apply-hero-section azil-section-padding azil-bg-hero">
        <div className="azil-home-container">
          <div className="text-center">
            <div className="azil-primary-heading my-2">
              {hiring.title.split(" ")[0]}
              <span className="font-primary"> {hiring.title.split(" ")[1]} </span>
            </div>
            <div className="azil-description my-2">
              {hiring.time === "part_time" ? "PART-TIME" : "FULL-TIME"} | (
              {hiring.location}) | {hiring.experience} EXPERIENCE
            </div>
          </div>
        </div>
      </section>

      <section className="apply-section-2 azil-section-padding">
        <div className="azil-home-container">
          <div className="azil-small-heading my-2">
            Apply for this job upload
            <span className="font-primary"> Resume </span>/<span className="font-primary"> CV </span>
            below
          </div>
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 mt-3">
              <Formik
                initialValues={initialValues}
                validationSchema={ApplyJobSchema}
                onSubmit={handleSubmit}
              >
                {({ errors, touched, values, handleChange }) => (
                  <Form>
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="upload my-2 upload-item">
                          <Dropzone
                            onDrop={(acceptedFiles) => {
                              handleAcceptedFiles(acceptedFiles);
                              const formData = new FormData();
                              for (const file of acceptedFiles)
                                formData.append("file", file);
                              const xhr = new XMLHttpRequest();
                              xhr.upload.onprogress = (event) => {
                                const percentage = parseInt(
                                  (event.loaded / event.total) * 100
                                );
                                setPercentage(percentage);
                              };
                              xhr.onreadystatechange = () => {
                                if (xhr.readyState !== 4) return;
                                if (xhr.status !== 200) {
                                  console.log("error");
                                }
                              };
                              xhr.open(
                                "POST",
                                "https://httpbin.org/post",
                                true
                              );
                              xhr.send(formData);
                            }}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div className="dropzone dz-clickable">
                                <div
                                  className="dz-message needsclick text-center"
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} />
                                  <div className="mb-3">
                                    <img
                                      src={IMAGECONST.Dropzone}
                                      alt="dropzone"
                                      style={{ height: 100 }}
                                      draggable="false"
                                    />
                                  </div>
                                  <h4>Drop files here or click to upload.</h4>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                        </div>
                        <div className="dropzone-previews mt-3 my-3" id="file-previews">
                          {images.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none dz-processing dz-image-preview dz-success dz-complete border-radius-12 boxshadow-full border-none"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        alt={f.name}
                                        src={f.preview}
                                        draggable="false"
                                      />
                                    </Col>
                                    <Col>
                                      <p className="font-dark-grey font-600 font-14">
                                        {f.name}
                                      </p>
                                      <p className="mb-0 font-grey font-12">{f.formattedSize}</p>
                                    </Col>
                                    <Col>
                                      <Progress color="primary" value={percentage} />
                                    </Col>
                                    <Col>
                                      <div className="azil-delete-button-box">
                                        <img
                                          src={IMAGECONST.Deleteicon}
                                          alt="delete"
                                          onClick={() => removeFile(i)}
                                          className="cursor-pointer"
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            );
                          })}
                        </div>
                        {images.length === 0 && (
                          <div
                            style={{ fontSize: 14 }}
                            className="text-left text-danger"
                          >
                            {"Please upload resume"}
                          </div>
                        )}
                        {images.length > 1 && (
                          <div
                            style={{ fontSize: 14 }}
                            className="text-left text-danger"
                          >
                            {"Please upload only one resume"}
                          </div>
                        )}
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        <div className="azil-input-group my-1">
                          <CustomInput
                            type="text"
                            values={values}
                            placeholder="First Name*"
                            name="firstname"
                            handleChange={handleChange}
                            touched={touched}
                            errors={errors}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        <div className="azil-input-group my-1">
                          <CustomInput
                            type="text"
                            values={values}
                            placeholder="Last Name*"
                            name="lastname"
                            handleChange={handleChange}
                            touched={touched}
                            errors={errors}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row align-items-center my-2">
                      <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        <div className="azil-job-dropdown">
                          <div className="azil-input-group cursor-pointer d-flex" onClick={() => setDropdownOpen(!dropdownOpen)}>
                            <div className="azil-gender-value-show cursor-pointer" name="gender" value={gender}>
                              <span>{gender}</span>
                              <span className="azil-gender-dropdown-arrow">
                                <svg 
                                  xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" 
                                  fill="none" 
                                  style={{rotate: dropdownOpen === true ? "180deg" : "0deg", transition: "0.4s ease" }}>
                                  <path 
                                    style={{stroke: dropdownOpen === true ? "#6960eb" : "#696969" }}
                                    d="M11.0234 1L6.90728 5.11616C6.42116 5.60227 5.62571 5.60227 5.1396 5.11616L1.02344 1" 
                                    strokeWidth="1.5" 
                                    strokeMiterlimit="10" 
                                    strokeLinecap="round" 
                                    strokeLinejoin="round"/>
                                </svg>
                              </span>
                            </div>
                            {dropdownOpen && (
                              <ul className="dropdown-job-menu azil-list-style-none p-2">
                                {items.map((item, index) => (
                                  <>
                                    <li key={index} onClick={() => handleGenderChange(item.value)}>
                                      <div className="azil-gender-selection"><span className="mx-2">{item.label}</span></div>
                                    </li>
                                  </>
                                ))}
                              </ul>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-6 col-sm-12 col-12">
                        <div className="azil-input-group my-1">
                          <CustomInput
                            type="email"
                            values={values}
                            placeholder="Email*"
                            name="email"
                            handleChange={handleChange}
                            touched={touched}
                            errors={errors}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row my-2">
                      <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        <div className="azil-input-group azil-phone-input-group my-1">
                        <CustomPhoneNumber
                          type="number"
                          value={values}
                          placeholder="Phone number*"
                          name="phone_number"
                          handleChange={handleChange}
                          touched={touched}
                          errors={errors}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        <div className="azil-input-group my-1">
                          <CustomInput
                            type="text"
                            values={values}
                            placeholder="Your Experience"
                            name="experience_in_year"
                            handleChange={handleChange}
                            touched={touched}
                            errors={errors}
                            min={0} Maxlength={2}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row my-2">
                      <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        <div className="azil-input-group my-1">
                          <CustomInput
                            type="number"
                            values={values}
                            placeholder="Enter Age"
                            name="age"
                            handleChange={handleChange}
                            touched={touched}
                            errors={errors}
                            min={0}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        <div className="azil-input-group my-1">
                          <CustomInput
                            type="date"
                            values={values}
                            placeholder="Enter Birthdate"
                            name="birth_date"
                            handleChange={handleChange}
                            touched={touched}
                            errors={errors}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="g-recaptcha"
                      data-sitekey="6LfK8wsjAAAAAN3xftPQZZid0zh1gGbGxm3_DLIS"
                    ></div>
                    <button className="azil-hero-button my-2" type="submit" onMouseEnter={handleHover} onMouseLeave={handleHoverEnd}>
                      Apply Now
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 my-2 azil-res-apply-job-img">
              <img
                src={IMAGECONST.Applyjob}
                alt="apply job"
                className="img-fluid azil-res-apply-job-img"
                draggable="false" width={400} height={400}
              />
            </div>
          </div>
        </div>
      </section>
      <NotificationContainer />
      <Footer />
    </React.Fragment>
  );
};

export default ApplyJob;
