import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CustomBlog from "../../../components/custom/customBlog";
import { PaginationFunction } from "../../../components/custom/Pagination";
import { GetBlogs } from "../../../services/ContactService";
import BlogHeader from "./BlogHeader";
import { Seo } from "../../../components/custom/Seo";
import { seoConstants as SEO_CONST } from "../../../components/constants/seoConst";

const BlogBrowseAll = (props) => {
  const { title } = props && props.location && props.location.state;
  const [tutorialBlog, setTutorialBlog] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [pageDataCount] = useState(9);

  const params = useParams();
  const getTutorialBlog = (activePage, pageDataCount) => {
    let pagination = `?page=${activePage}&limit=${pageDataCount}&tag=${params.id}`;
    GetBlogs(pagination)
      .then((res) => {
        const data = res && res.data && res.data.data && res.data.data.data;
        const totalCount =
          res && res.data && res.data.data && res.data.data.count;
        setTotalCount(totalCount);
        setTutorialBlog(data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getTutorialBlog(activePage, pageDataCount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage, pageDataCount]);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  return (
    <React.Fragment>
      <Seo
        title={SEO_CONST.BLOG_TITLE}
        description={""}
        keyword={SEO_CONST.BLOG_TITLE}
      />
      <BlogHeader />

      <div className="azil-tutorials azil-section-padding mx-5">
        <div className="d-flex align-items-center justify-content-between my-2">
          <div className="azil-blog-first">
            <div className="azil-primary-title my-2">{title}</div>
            <div className="azil-blog-inner-content my-2">
              Learn about different Iconscout tools and designing icons and
              illustrations.
            </div>
          </div>
        </div>
        <div className="row">
          {tutorialBlog &&
            tutorialBlog.map((blog, index) => {
              return (
                <React.Fragment key={index}>
                  <CustomBlog blog={blog} />
                </React.Fragment>
              );
            })}
        </div>
        <div className="azil-section-padding d-flex justify-content-center align-items-center">
          {tutorialBlog && tutorialBlog.length > 0 && (
            <PaginationFunction
              activePage={activePage}
              totalCount={totalCount}
              handlePageChange={handlePageChange}
              pageDataCount={pageDataCount}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default BlogBrowseAll;
