import React, { useEffect, useState } from "react";
import { imagesConst as IMAGECONST } from "../../../components/constants/imagesConst";
import { Link, useHistory } from "react-router-dom";
import { constants as PATH } from "../../../components/constants/componentPath";
import "./blog.css";
import BlogHeader from "./BlogHeader";
import { GetBlogs } from "../../../services/ContactService";
import moment from "moment";
import { Seo } from "../../../components/custom/Seo";
import { seoConstants as SEO_CONST } from "../../../components/constants/seoConst";
import Slider from "react-slick";
import Footer from "../../Layout/Footer";

const Blog = () => {
  const [blogData, setBlogData] = useState([]);
  const [firstBlog, setFirstBlog] = useState({});
  const [tutorialBlog, setTutorialBlog] = useState([]);
  const [designBlog, setDesignBlog] = useState([]);
  const history = useHistory();
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResultsCount, setSearchResultsCount] = useState(0);

  const sliderSettings = {
    infinite: true,
    dots: true,
    arrows: false,
    swipeToSlide: true,
    slidesToShow: 1,
    lazyLoad: true,
    slidesToScroll: 1,
    fade: true,
    autoplay: true,
    autoplaySpeed: 3000,
    draggable: false,
    cssEase: "cubic-bezier(0.7, 0, 0.3, 0.1)",
    touchThreshold: 100,
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getAllBlogs = () => {
    let pagination = `?page=1&limit=5`;
    GetBlogs(pagination)
      .then((res) => {
        const data = res?.data?.data?.data || [];
        setBlogData(data);
        setFilteredData(data);
      })
      .catch((err) => {
        console.error('Error fetching blogs:', err);
      });
  };

  const getTutorialBlog = () => {
    let pagination = `?page=1&limit=3&tag=tutorial`;
    GetBlogs(pagination)
      .then((res) => {
        const data = res && res.data && res.data.data && res.data.data.data;
        setTutorialBlog(data);
      })
      .catch((err) => {});
  };

  const getDesignBlog = () => {
    let pagination = `?page=1&limit=3&tag=design`;
    GetBlogs(pagination)
      .then((res) => {
        const data = res && res.data && res.data.data && res.data.data.data;
        setDesignBlog(data);
      })
      .catch((err) => {});
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
    const filtered = blogData.filter((blog) => {
      const authorFullName = (blog.admin_firstname || '') + ' ' + (blog.admin_lastname || '');
      const authorMatch = authorFullName.toLowerCase().includes(query.toLowerCase());
      const titleMatch = blog.title && blog.title.toLowerCase().includes(query.toLowerCase());
      const tagMatch = blog.tag && blog.tag.toLowerCase().includes(query.toLowerCase());
      return titleMatch || authorMatch || tagMatch;
    });
    setFilteredData(filtered);
    setSearchResultsCount(filtered.length);
    setSearchQuery(query);
  };
  const highlightText = (text) => {
    if (!searchQuery) return text;
    const regex = new RegExp(`(${searchQuery})`, 'gi');
    const parts = text.split(regex);
    return parts.map((part, index) => {
      if (regex.test(part)) {
        return <span key={index} style={{ color: '#958dff' }}>{part}</span>;
      } else {
        return part;
      }
    });
  };

  useEffect(() => {
    getAllBlogs();
    getTutorialBlog();
    getDesignBlog();
  }, []);
  
  /* -------------------------------------------------------------------------- */
  /*                            Button ripple effect                            */
  /* -------------------------------------------------------------------------- */
  const handleWhiteHover = (e) => {
    const button = e.target;
    const rect = button.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    const rippleSpan = document.createElement('span');
    rippleSpan.classList.add('ripple-span-white');
    rippleSpan.style.top = `${y}px`;
    rippleSpan.style.left = `${x}px`;
    button.appendChild(rippleSpan);
  };

  const handleWhiteHoverEnd = (e) => {
    const button = e.target;
    const ripples = button.getElementsByClassName('ripple-span-white');
    while (ripples.length > 0) {
      button.removeChild(ripples[0]);
    }
  }; 

  return (
    <React.Fragment>
      <Seo
        title={SEO_CONST.BLOG_TITLE}
        description={""}
        keyword={SEO_CONST.BLOG_TITLE}
      />
      <BlogHeader handleSearch={handleSearch}/>

      <div className="azil-blog-outer-container">
        <div className="text-center" style={{display: searchQuery === "" ? "none" : "block", marginBottom: "50px"}}>
          {searchResultsCount > 0 ? (
            <div>
              <p className="font-18 font-grey mb-2"><span className="font-secondary font-500">{searchResultsCount}</span> results found 👀: </p> 
              <p className="font-22 font-grey font-600 font-primary">"{searchQuery}"</p>
            </div>
          ) : (
            <div>
              <p className="font-18 font-grey mb-2"><span className="font-secondary font-500">0</span> results found 👀: </p> 
              <p className="font-22 font-grey font-600 font-primary">"{searchQuery}"</p>
            </div>
          )
        }
        </div>
        <div className="azil-empty-result">
          {searchQuery && searchResultsCount === 0 && <img src={IMAGECONST.Emptyresult} alt="No results found" width={300} draggable={false} />}
        </div>
        <div className="d-flex justify-content-center">
          {searchQuery && searchResultsCount === 0 &&
            <a href={PATH.BLOG} className="azil-hero-button azil-blog-return-home my-5" onMouseEnter={handleWhiteHover} onMouseLeave={handleWhiteHoverEnd}>
              <img src={IMAGECONST.Headerarrow} alt="home" style={{rotate: "90deg"}} width={14} />
            </a>
          }
        </div>
        <div>
          <section className="slider">
            <div className="azil-blog-slider-box" style={{display: searchQuery !== "" ? "none" : "block"}}>
              <Slider {...sliderSettings}>
                {filteredData.slice(0, 5).map((blog, index) => {
                    return (
                      <React.Fragment key={index}>
                        <div index={index} className="azil-blog-slider">
                          <div className="d-flex justify-content-evenly align-items-start azil-res-blog-wrap">
                            <div className="azil-blog-slider-img-container">
                              <Link to={{ pathname: PATH.BLOG_INNER, state: {blog: blog}}}>
                                <div className="azil-blog-slider-img-box">
                                  <img
                                    src={blog && blog.blog_image}
                                    alt="blog-img"
                                    draggable="false" className="azil-blog-slider-main-img"
                                  />
                                </div>
                              </Link>
                            </div>
                            <div className="azil-blog-slider-title-container">
                              <h5 className="py-2">
                                <Link to={{ pathname: PATH.BLOG_INNER, state: {blog: blog}}} className="azil-blog-category">
                                  {highlightText(blog && blog.tag)}
                                </Link>
                              </h5>
                              <h5 className="mt-2 azil-main-blog-title-space">
                                <Link to={{ pathname: PATH.BLOG_INNER, state: {blog: blog}}}>
                                  <span className="azil-mid-heading azil-res-blog-main-title">
                                    {highlightText(blog && blog.title && blog.title.length > 80 ? `${blog.title.slice(0, 80)}...` : blog.title)}
                                  </span>
                                </Link>
                              </h5>
                              <h6 className="font-12 font-grey my-2">
                                Published on{" "}
                                {moment(blog && blog.created_at)
                                  .startOf("second")
                                  .fromNow()}{" "}
                              </h6>
                              <Link to={{ pathname: PATH.BLOG_INNER, state: {blog: blog}}} className="my-1">
                                <span className="font-12 font-dark-grey">By</span>
                                <span className="font-12 font-600 font-dark-grey">
                                  {" "}
                                  {highlightText(blog?.admin_firstname ||
                                  blog?.admin_lastname
                                    ? `${
                                        blog.admin_firstname || ""
                                      } ${
                                        blog.admin_lastname || ""
                                      }`
                                    : "Azillik user")}
                                </span>{" "}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    )
                  })}
              </Slider>
            </div>
          </section>

          <div className="azil-blogs-card-container">
            <section>
              <div className="tab-content azil-blog-otherblog-section">
                <div className="tab-pane active">
                  <div className="azil-blog-grid">
                    {filteredData.map((blog, index) => {
                        return (
                          <div className="my-2" key={index}>
                            <div className="azil-vertical-blog-box">
                              <div className="azil-vertical-blog-img-box">
                                <Link to={{ pathname: PATH.BLOG_INNER, state: { blog: blog}}}>
                                  <img
                                    src={blog && blog.blog_image}
                                    alt="blogimage" draggable="false"
                                  />
                                </Link>
                              </div>
                              <div className="azil-vertical-blog-content-box">
                                <div>
                                  <h5 className="py-2">
                                    <Link to={{ pathname: PATH.BLOG_INNER, state: { blog: blog}}} className="font-600 font-12 font-primary" style={{textTransform: "uppercase"}}>
                                      {highlightText(blog && blog.tag)}
                                    </Link> 
                                  </h5>
                                  <h5 >
                                    <Link to={{ pathname: PATH.BLOG_INNER, state: { blog: blog}}} className="font-16 font-secondary font-600 my-2 azil-link-text" style={{textTransform: "capitalise"}}>
                                      {highlightText(blog && blog.title && blog.title.length > 100 ? `${blog.title.slice(0, 100)}...` : blog.title)}
                                    </Link>                              
                                  </h5>
                                </div>
                                <div>
                                  <div className="row font-400 mb-0">
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                      <Link to={{ pathname: PATH.BLOG_INNER, state: { blog: blog}}}>
                                        <span className="font-12 font-500 font-dark-grey">By</span>
                                        <span className="font-12 font-600 font-dark-grey">
                                          {" "}
                                          {highlightText(blog?.admin_firstname ||
                                          blog?.admin_lastname
                                            ? `${
                                                blog.admin_firstname || ""
                                              } ${
                                                blog.admin_lastname || ""
                                              }`
                                            : "Azillik user")}
                                        </span>{" "}
                                      </Link>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                      <span className="font-12 font-grey">
                                        {" "}
                                        {moment(
                                          blog && blog.created_at
                                        ).format("MMMM DD, YYYY")}{" "}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>

      <Footer />
    </React.Fragment>
  );
};

export default Blog;