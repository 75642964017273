import React, { useEffect, useState } from "react";
import Footer from "../../Layout/Footer";
import Header from "../../Layout/Header";
import { imagesConst as IMAGECONST } from "../../../components/constants/imagesConst";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { errorConst as ERROR } from "../../../components/constants/errorConst";
import CustomInput from "../../../components/custom/CustomInput";
import { Col, Row, Progress, Card } from "reactstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Dropzone from "react-dropzone";
import { useHistory } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import { GetTechnologies, WeWorkOnAPI } from "../../../services/ContactService";
import { notificationMessageFunction } from "../../../components/constants/notificationConst";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import { Seo } from "../../../components/custom/Seo";
import { seoConstants as SEO_CONST } from "../../../components/constants/seoConst";
import AOS from "aos";
import "aos/dist/aos.css";
import PhoneNumberInput from "../../../components/custom/PhoneNumberInput/PhoneNumberInput";
import CustomPhoneNumber from "../../../components/custom/CustomPhoneNumber/CustomPhoneNumber";

const WeWorkOn = () => {
  const [count, setCount] = useState(1);
  const [hiring, setHiring] = useState("part");
  const [workType, setWorkType] = useState("project");
  const [images, setImages] = useState([]);
  const [percentage, setPercentage] = useState(0);
  const [team, setTeam] = useState("1-5");
  const [workingHour, setWorkingHour] = useState("1-5");
  const [sweetAlert, setSweetAlert] = useState(false);
  const history = useHistory();
  const [techData, setTechData] = useState([]);
  const [techId, setTechId] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isError, setIsError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [search, setSearch] = useState("");
  const [limit] = useState(12);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getTechnologies = () => {
    let pagination = `?page=1&limit=${limit}`;
    if (search !== "") {
      pagination = `?page=1&limit=${limit}&search=${search}`;
    }
    GetTechnologies(pagination)
      .then((res) => {
        const data = res && res.data && res.data.data && res.data.data.data;
        setTechData(data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getTechnologies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, search]);

  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);

  const initialValues = {
    firstname: "",
    lastname: "",
    email: "",
    website: "",
    timeline: "",
    budget: "",
  };

  const ApplyJobSchema = Yup.object().shape({
    email: Yup.string().trim().email(ERROR.VALID_EMAIL).required(ERROR.EMAIL),
    firstname: Yup.string().trim().required(ERROR.FIRST_NAME),
    lastname: Yup.string().trim().required(ERROR.LAST_NAME),
  });

  const handleSubmit = (values, { resetForm }) => {
    let isError = false;
    // if (phoneNumber === "" || phoneNumber === null) {
    //   setPhoneError(true);
    //   isError = true;
    // } else {
    //   setPhoneError(false);
    //   isError = false;
    // }

    if (images.length > 1) {
      isError = true;
    }

    if (!isError) {
      const data = new FormData();
      data.append("firstname", values.firstname);
      data.append("lastname", values.lastname);
      data.append("phone_number", values.phone_number);
      data.append("email", values.email);
      data.append("skill", techId);
      data.append("hiring_needs", hiring);
      data.append("work_type", workType);
      data.append("website", values.website);
      data.append("developer_team", team);
      data.append("timeline", values.timeline);
      data.append("budget", values.budget);
      data.append("working", workingHour);
      data.append("documentation", images[0]);

      WeWorkOnAPI(data)
        .then((res) => {
          setSweetAlert(true);
          resetForm();
        })
        .catch((err) => {
          const errorMessage =
            err && err.data && err.data.error && err.data.error.message;
          NotificationManager.error(notificationMessageFunction(errorMessage));
        });
    }
    console.log("first", values.phone_number)
  };

  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setImages(files);
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const removeFile = (file) => {
    const newFiles = [...images];
    newFiles.splice(file, 1);
    setImages(newFiles);
  };

  /* -------------------------------------------------------------------------- */
  /*                        search result with highlight                        */
  /* -------------------------------------------------------------------------- */
  const handleSearch = (e) => {
    setSearch(e.target.value);
  };
  const highlightMatch = (title) => {
    if (!search) return title;
    const parts = title.split(new RegExp(`(${search})`, 'gi'));
    return parts.map((part, index) =>
      part.toLowerCase() === search.toLowerCase() ? (
        <strong key={index}>{part}</strong>
      ) : (
        part
      )
    );
  };
  /* -------------------------------------------------------------------------- */
  /*                            Button ripple effect                            */
  /* -------------------------------------------------------------------------- */
  const handleHover = (e) => {
    const button = e.target;
    const rect = button.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    const rippleSpan = document.createElement('span');
    rippleSpan.classList.add('ripple-span');
    rippleSpan.style.top = `${y}px`;
    rippleSpan.style.left = `${x}px`;
    button.appendChild(rippleSpan);
  };

  const handleHoverEnd = (e) => {
    const button = e.target;
    const ripples = button.getElementsByClassName('ripple-span');
    while (ripples.length > 0) {
      button.removeChild(ripples[0]);
    }
  };

  return (
    <React.Fragment>
      <Seo
        title={SEO_CONST.WE_WORK_TITLE}
        description={""}
        keyword={SEO_CONST.WE_WORK_TITLE}
      />
      <Header />
      <Formik
        initialValues={initialValues}
        validationSchema={ApplyJobSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, values, handleChange }) => (
          <Form>
            <section className="azil-apply-hero-section azil-section-padding azil-bg-hero">
              <div className="azil-home-container" data-aos="fade-up">
                <div className="text-center">
                  <div className="azil-primary-heading my-2">
                    {count === 1 && (
                      <>
                        Find <span className="font-primary"> Framework</span> / <span className="font-primary">Developers </span>
                        Team to work
                      </>
                    )}
                    {count === 2 && (
                      <>
                        Form for selected
                        <span className="font-primary"> Technology </span> & <span className="font-primary"> Platforms </span>
                        for working together
                      </>
                    )}
                  </div>
                </div>
                {count !== 3 && (
                  <div
                    style={{
                      float: "right",
                    }}
                    className="pt-3"
                  >
                    <h4>
                      {count > 1 && (
                        <span
                          className="badge badge-secondary mx-3"
                          style={{
                            padding: "8px 12px",
                          }}
                          onClick={() => setCount(count - 1)}
                        >
                          <img src={IMAGECONST.Headerarrow} alt="arrow" style={{rotate: "90deg"}} />
                        </span>
                      )}

                      <span
                        className="badge badge-secondary"
                        style={{
                          padding: "8px 10px",
                        }}
                      >
                        Step {count} / 3
                      </span>
                    </h4>
                  </div>
                )}
              </div>
              <div className="container text-center pt-5">
                <div
                  className="text-center"
                  style={{ display: count !== 1 && "none" }}
                >
                  <div className="azil-description my-2">
                    Select at least one skill
                  </div>                  
                </div>
                <div className="row" style={{ display: count !== 1 && "none" }}>
                  <div className="col-lg-2 col-md-3 col-sm-4 col-6 my-2 text-center">
                    <div
                      className="azil-wework-box"
                      onClick={() => setTechId("not secure")}
                      style={{
                        border:
                          techId === "not secure" ? "3px solid #6960eb" : "",
                      }}
                    >
                      <img
                        src={IMAGECONST.Notsure}
                        alt="not sure"
                        draggable="false" width={36} height={48}
                        className="azil-res-wework-img"
                      />
                      <div className="azil-description font-12">
                        Not sure, <br />need a device
                      </div>
                    </div>
                  </div>

                  {techData &&
                    techData.map((tech, index) => {
                      return (
                        <>
                          <div
                            className="col-lg-2 col-md-3 col-sm-4 col-6 my-2 text-center"
                            key={index}
                          >
                            <div
                              className="azil-wework-box"
                              onClick={() => setTechId(tech.technology_id)}
                              style={{
                                border:
                                  techId === tech.technology_id
                                    ? "3px solid #6960eb"
                                    : "",
                              }}
                            >
                              <div
                                className="text-center"
                              >
                                <img
                                  src={tech.image}
                                  alt="technology"
                                  className="img-fluid azil-res-wework-img"
                                  draggable="false"
                                  width={52}
                                  height={52}
                                />
                              </div>

                              <div className="azil-description my-2">
                                {highlightMatch(tech.title)}
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                </div>
                <div className="azil-job-searchbox">
                  <div className="azil-input-group azil-job-searchbar my-2">
                    <input
                      type="search"
                      placeholder="Enter other skills"
                      onChange={handleSearch}
                      value={search}
                      style={{background: "transparent"}}
                    />
                  </div>
                  <div className="azil-searchbox">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      fill="none"
                      viewBox="0 0 23 22"
                      >
                      <path
                        className="azil-search-svgstroke"
                        stroke="#7A7A7A"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M9.988 17a8 8 0 100-16 8 8 0 000 16zM21.99 21l-4.666-4.667"
                        style={{
                          stroke: search.trim() !== "" ? "#6960EB" : "#696969",
                        }}
                      ></path>
                    </svg>
                  </div>
                </div>

                <div style={{ display: count !== 1 && "none" }}>
                  <div className="text-left">
                    <button
                      className="azil-hero-button my-2"
                      onClick={() => (techId === "" ? true : setCount(2))} onMouseEnter={handleHover} onMouseLeave={handleHoverEnd}
                    >
                      Continue
                    </button>
                  </div>
                </div>

                {sweetAlert && (
                  <SweetAlert
                    title="Thanks!"
                    description=""
                    success
                    confirmBtnText="Ok"
                    confirmBtnBsStyle="success"
                    onConfirm={() => {
                      history.push("/");
                      window.location.reload(true);
                    }}
                    onCancel={() => setSweetAlert(false)}
                    style={{ width: "50%" }}
                  >
                    We'll be in touch with you.
                  </SweetAlert>
                )}
              </div>
            </section>
            <section className="azil-wework-herosection azil-bg-hero"></section>

            <section
              className="azil-contact-hero-section azil-section-padding azil-bg-hero"
              style={{ display: count !== 2 && "none" }}
            >
              <div className="azil-home-container" data-aos="fade-up">
                <div className="azil-contact-margin">
                  <div className="row" id="we-work">
                    <div className="col-lg-7 col-md-12 col-sm-12 col-12 my-2">
                      <div className="azil-contact-form-bg">
                        <div className="text-left col-lg-12 col-sm-12 col-12 my-2 p-0">
                          <div className="d-flex align-items-center flex-row hiring-need">
                            <div className="azil-description mr-3 pr-3">Hiring needs</div>
                            <div className="hiring-span">
                            <span
                                className={`badge mb-2 ${hiring === "project" ? "badge-primary" : "badge-secondary"} mx-3`}
                                onClick={() => setHiring("project")}
                              > Full project base
                              </span>
                              <span
                                className={`badge mb-2 ${hiring === "part" ? "badge-primary" : "badge-secondary"} mx-3`}
                                onClick={() => setHiring("part")}
                              > Part-time
                              </span>

                              <span
                                className={`badge mb-2 ${hiring === "full" ? "badge-primary" : "badge-secondary"} mx-3`}
                                onClick={() => setHiring("full")}
                              > Full-time
                              </span>
                              <span
                                className={`badge mb-2 ${hiring === "Freelancing" ? "badge-primary" : "badge-secondary"} mx-3`}
                                onClick={() => setHiring("Freelancing")}
                              > Freelancing
                              </span>
                            </div>
                          </div>
                        </div>

                        <div style={{display: hiring !== "project" && "none"}}>
                          <div className="d-flex flex-wrap">
                            <div className="text-left col-lg-6 col-md-6 col-sm-6 col-12 my-2 p-0">
                              <div className="d-flex align-items-center flex-row hiring-need">
                                <div className="azil-description mr-4 pr-4">
                                  Timeline
                                </div>
                                <div>
                                  <div className="hiring-div">
                                    <div className="d-flex azil-input-group mt-2">
                                      <CustomInput
                                        type="number"
                                        values={values}
                                        placeholder="Enter timeline"
                                        name="timeline"
                                        handleChange={handleChange}
                                        touched={touched}
                                        errors={errors}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="text-left col-lg-6 col-md-6 col-sm-6 col-12 my-2 p-0">
                              <div className="d-flex align-items-center flex-row hiring-need">
                                <div className="azil-description mr-4 pr-4">
                                  Budget
                                </div>
                                <div>
                                  <div className="hiring-div">
                                    <div className="d-flex azil-input-group mt-2">
                                      <CustomInput
                                        type="number"
                                        values={values}
                                        placeholder="Enter Rate in $(USD)"
                                        name="budget"
                                        handleChange={handleChange}
                                        touched={touched}
                                        errors={errors}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div style={{display: hiring !== "Freelancing" && "none"}}>
                          <div className="text-left col-lg-12 col-sm-12 col-12 my-2 p-0">
                            <div className="d-flex align-items-center flex-row hiring-need">
                              <div className="azil-description mr-4 pr-4">
                                Mon-Fri 8 hrs working
                              </div>
                              <div>
                                <div className="hiring-div">
                                  <span
                                    className={`badge ${workingHour === "1-10" ? "badge-primary" : "badge-secondary"} mx-3`}
                                    onClick={() => setWorkingHour("1-10")}
                                  > 1-10
                                  </span>

                                  <span
                                    className={`badge ${workingHour === "10-30" ? "badge-primary" : "badge-secondary"} mx-3`}
                                    onClick={() => setWorkingHour("10-30")}
                                  > 10-30
                                  </span>
                                  <span
                                    className={`badge ${workingHour === "30-50" ? "badge-primary" : "badge-secondary"} mx-3`}
                                    onClick={() => setWorkingHour("30-50")}
                                  >
                                    30-50
                                  </span>

                                  <div className="d-flex azil-input-group mt-2">
                                    <CustomInput
                                      type="number"
                                      values={values}
                                      placeholder="Enter Working Hours"
                                      name="working"
                                      handleChange={handleChange}
                                      touched={touched}
                                      errors={errors}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="text-left col-lg-12 col-sm-12 col-12 my-2 p-0">
                          <div className="d-flex align-items-center flex-row hiring-need">
                            <div className="azil-description mr-4 pr-4">Work type</div>
                            <div className="hiring-span">
                              <span
                                className={`badge mb-2 ${workType === "project" ? "badge-primary" : "badge-secondary"} mx-3`}
                                onClick={() => setWorkType("project")}
                              > By Project
                              </span>

                              <span
                                className={`badge mb-2 ${workType === "milestone" ? "badge-primary" : "badge-secondary"} mx-3`}
                                onClick={() => setWorkType("milestone")}
                              > By Milestone
                              </span>

                              <span
                                className={`badge mb-2 ${workType === "developer" ? "badge-primary" : "badge-secondary"} mx-3`}
                                onClick={() => setWorkType("developer")}
                              > By Developer Team
                              </span>
                            </div>
                          </div>
                        </div>

                        <div style={{display: workType !== "developer" && "none"}}>
                          <div className="text-left col-lg-12 col-sm-12 col-12 my-2 p-0">
                            <div className="d-flex align-items-center flex-row hiring-need">
                              <div className="azil-description mr-4 pr-4">
                                Required team for developement
                              </div>
                              <div>
                                <div className="hiring-div">
                                  <span
                                    className={`badge ${team === "1-10" ? "badge-primary" : "badge-secondary"} mx-3`}
                                    onClick={() => setTeam("1-10")}
                                  > 1-10
                                  </span>

                                  <span
                                    className={`badge ${team === "10-30" ? "badge-primary" : "badge-secondary"} mx-3`}
                                    onClick={() => setTeam("10-30")}
                                  > 10-30
                                  </span>
                                  <span
                                    className={`badge ${team === "30-50" ? "badge-primary" : "badge-secondary"} mx-3`}
                                    onClick={() => setTeam("30-50")}
                                  >
                                    30-50
                                  </span>

                                  <div className="d-flex azil-input-group mt-2">
                                    <CustomInput
                                      type="number"
                                      values={values}
                                      placeholder="Enter team"
                                      name="developer_team"
                                      handleChange={handleChange}
                                      touched={touched}
                                      errors={errors}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-lg-12 col-md-12 col-sm-12 col-12 my-2">
                            <div className="row">
                              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                <div className="azil-input-group my-1">
                                  <CustomInput
                                    type="text"
                                    values={values}
                                    placeholder="First Name*"
                                    name="firstname"
                                    handleChange={handleChange}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                <div className="azil-input-group my-1">
                                  <CustomInput
                                    type="text"
                                    values={values}
                                    placeholder="Last Name*"
                                    name="lastname"
                                    handleChange={handleChange}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                <div className="azil-input-group my-1">
                                  <CustomPhoneNumber
                                    type="number"
                                    value={values}
                                    placeholder="Phone number*"
                                    name="phone_number"
                                    handleChange={handleChange}
                                    touched={touched}
                                    errors={errors}
                                    // onChange={(e) => {
                                    //   setPhoneNumber(e);
                                    //   if (e === "") {
                                    //     setPhoneError(false);
                                    //   } else {
                                    //     setPhoneError(true);
                                    //   }
                                    // }}
                                    // name="phone_number"
                                  />
                                  {/* {isError === true && (
                                    <div
                                      style={{ fontSize: 14 }}
                                      className="text-left mt-1 text-danger"
                                    >
                                      {ERROR.WP_NUMBER}
                                    </div>
                                  )} */}
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                <div className="azil-input-group my-1">
                                  <CustomInput
                                    type="email"
                                    values={values}
                                    placeholder="Email Address*"
                                    name="email"
                                    handleChange={handleChange}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="azil-input-group my-1">
                                  <CustomInput
                                    type="text"
                                    values={values}
                                    placeholder="Company Website"
                                    name="website"
                                    handleChange={handleChange}
                                    touched={touched}
                                    errors={errors}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="upload my-2 upload-item">
                              <Dropzone
                                onDrop={(acceptedFiles) => {
                                  handleAcceptedFiles(acceptedFiles);
                                  const formData = new FormData();
                                  for (const file of acceptedFiles)
                                    formData.append("file", file);
                                  const xhr = new XMLHttpRequest();
                                  xhr.upload.onprogress = (event) => {
                                    const percentage = parseInt(
                                      (event.loaded / event.total) * 100
                                    );
                                    setPercentage(percentage);
                                  };
                                  xhr.onreadystatechange = () => {
                                    if (xhr.readyState !== 4) return;
                                    if (xhr.status !== 200) {
                                      console.log("error");
                                    }
                                  };
                                  xhr.open(
                                    "POST",
                                    "https://httpbin.org/post",
                                    true
                                  );
                                  xhr.send(formData);
                                }}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <div className="dropzone dz-clickable">
                                    <div
                                      className="dz-message needsclick text-center"
                                      {...getRootProps()}
                                    >
                                      <input {...getInputProps()} />
                                      <div className="mb-3">
                                        <img
                                          src={IMAGECONST.Dropzone}
                                          alt="dropzone"
                                          style={{ height: 100 }}
                                          draggable="false"
                                        />
                                      </div>
                                      <h4>
                                        Drop files here or click to upload.
                                      </h4>
                                    </div>
                                  </div>
                                )}
                              </Dropzone>
                            </div>
                            <div
                              className="dropzone-previews mt-3 my-3"
                              id="file-previews"
                            >
                              {images.map((f, i) => {
                                return (
                                  <Card
                                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                    key={i + "-file"}
                                    style={{
                                      borderRadius: "12px",
                                      border: "2px solid #e8e8e8",
                                    }}
                                  >
                                    <div className="p-2">
                                      <Row className="align-items-center">
                                        <Col className="col-auto">
                                          <img
                                            data-dz-thumbnail=""
                                            height="80"
                                            className="avatar-sm rounded bg-light"
                                            alt={f.name}
                                            src={f.preview}
                                            draggable="false"
                                          />
                                        </Col>
                                        <Col>
                                          <p className="font-dark-grey font-600 font-14">
                                            {f.name}
                                          </p>
                                          <p className="mb-0 font-grey font-12">{f.formattedSize}</p>
                                        </Col>
                                        <Col>
                                          <Progress color="primary" value={percentage} />
                                        </Col>
                                        <Col>
                                          <img
                                            src={IMAGECONST.Deleteicon}
                                            alt="delete"
                                            onClick={() => removeFile(i)}
                                            style={{
                                              cursor: "pointer",
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                    </div>
                                  </Card>
                                );
                              })}
                            </div>
                            {images.length > 1 && (
                              <div
                                style={{ fontSize: 14 }}
                                className="text-left mt-1 text-danger"
                              >
                                {"Please upload only one document"}
                              </div>
                            )}
                            <div
                              className="g-recaptcha"
                              data-sitekey="6LfK8wsjAAAAAN3xftPQZZid0zh1gGbGxm3_DLIS"
                            ></div>
                            <div className="text-left">
                              <button
                                className="azil-hero-button my-2"
                                type="submit" onMouseEnter={handleHover} onMouseLeave={handleHoverEnd}
                              >
                                Continue
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-5 col-md-12 col-sm-12 col-12 my-2">
                      <div className="azil-bg-contact azil-job-find-bg">
                        <img
                          src={IMAGECONST.Applyjob}
                          alt="apply job"
                          className="img-fluid azil-res-wework-imgbanner"
                          style={{
                            height: "680px"
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </Form>
        )}
      </Formik>
      <NotificationContainer />
      <Footer />
    </React.Fragment>
  );
};

export default WeWorkOn;
