export const seoConstants = {
  ABOUT_TITLE: "About | Azillik IT",
  APPLY_JOB_TITLE: "Apply Job | Azillik IT",
  BLOG_TITLE: "Blog | Azillik IT",
  CONTACT_TITLE: "Contact | Azillik IT",
  CURRENT_OPENING_TITLE: "Opening | Azillik IT",
  CUSTOM_SOFTWARE_TITLE: "Development | Azillik IT",
  FAQ_TITLE: "FAQs | Azillik IT",
  HIRING_TITLE: "Open Position | Azillik IT",
  HOME_TITLE: "Home | Azillik IT",
  HOW_WE_TITLE: "We Work | Azillik IT",
  OUR_TEAM_TITLE: "Team | Azillik IT",
  PORTFOLIO_TITLE: "Portfolio | Azillik IT",
  PRODUCT_TITLE: "Products | Azillik IT",
  WE_WORK_TITLE: "Work On | Azillik IT",
  ERROR_TITLE: "ERROR | Azillik IT",
};
