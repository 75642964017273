import React, { useEffect } from "react";
import Footer from "../../Layout/Footer";
import Header from "../../Layout/Header";
import Index from "./index";

import { Seo } from "../../../components/custom/Seo";
import { seoConstants as SEO_CONST } from "../../../components/constants/seoConst";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <Seo
        title={SEO_CONST.HOME_TITLE}
        description={""}
        keyword={SEO_CONST.HOME_TITLE}
      />
      <Header />
      <Index />
      <Footer />
    </React.Fragment>
  );
};

export default Home;
