import React, { useEffect, useState } from "react";
import Footer from "../../Layout/Footer";
import Header from "../../Layout/Header";
import { imagesConst as IMAGECONST } from "../../../components/constants/imagesConst";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { errorConst as ERROR } from "../../../components/constants/errorConst";
import CustomInput from "../../../components/custom/CustomInput";
import { Card, Col, Progress, Row } from "reactstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { constants as PATH } from "../../../components/constants/componentPath";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import { notificationMessageFunction } from "../../../components/constants/notificationConst";
import { ContactUs } from "../../../services/ContactService";
import { Seo } from "../../../components/custom/Seo";
import { seoConstants as SEO_CONST } from "../../../components/constants/seoConst";
import AOS from "aos";
import "aos/dist/aos.css";
import PhoneNumberInput from "../../../components/custom/PhoneNumberInput/PhoneNumberInput";

const ContactUsPage = () => {
  const [images, setImages] = useState([]);
  const [percentage, setPercentage] = useState(0);
  const [isError, setIsError] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [wpNumber, setWpNumber] = useState("");
  const [phoneError, setPhoneError] = useState(false);

  const initialValues = {
    firstname: "",
    lastname: "",
    email: "",
    message: "",
  };

  const ContactUsSchema = Yup.object().shape({
    firstname: Yup.string().trim().required(ERROR.FIRST_NAME),
    lastname: Yup.string().trim().required(ERROR.LAST_NAME),
    email: Yup.string().trim().email(ERROR.VALID_EMAIL).required(ERROR.EMAIL),
    message: Yup.string().trim().required(ERROR.MESSAGE),
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = (values, { resetForm }) => {
    let isError = false;
    if (wpNumber === "" || wpNumber === null) {
      setIsError(true);
      isError = true;
    } else {
      setIsError(false);
      isError = false;
    }
    if (phoneNumber === "" || phoneNumber === null) {
      setPhoneError(true);
      isError = true;
    } else {
      setPhoneError(false);
      isError = false;
    }

    if (images.length > 1) {
      isError = true;
    }

    if (!isError) {
      const data = new FormData();
      data.append("firstname", values.firstname);
      data.append("lastname", values.lastname);
      data.append("phone_number", phoneNumber.slice(2));
      data.append("wp_number", wpNumber.slice(2));
      data.append("email", values.email);
      data.append("message", values.message);
      data.append("contact_image", images[0]);

      ContactUs(data)
        .then((res) => {
          NotificationManager.success("Contact us added successfully");
          resetForm();
          setImages([]);
          window.location.reload(true);
        })
        .catch((err) => {
          const errorMessage =
            err && err.data && err.data.error && err.data.error.message;
          NotificationManager.error(notificationMessageFunction(errorMessage));
        });
    }
  };

  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setImages(files);
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const removeFile = (file) => {
    const newFiles = [...images];
    newFiles.splice(file, 1);
    setImages(newFiles);
  };

  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);

  /* -------------------------------------------------------------------------- */
  /*                            Button ripple effect                            */
  /* -------------------------------------------------------------------------- */
  const handleHover = (e) => {
    const button = e.target;
    const rect = button.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    const rippleSpan = document.createElement('span');
    rippleSpan.classList.add('ripple-span');
    rippleSpan.style.top = `${y}px`;
    rippleSpan.style.left = `${x}px`;
    button.appendChild(rippleSpan);
  };

  const handleHoverEnd = (e) => {
    const button = e.target;
    const ripples = button.getElementsByClassName('ripple-span');
    while (ripples.length > 0) {
      button.removeChild(ripples[0]);
    }
  };

  return (
    <React.Fragment>
      <Seo
        title={SEO_CONST.CONTACT_TITLE}
        description={""}
        keyword={SEO_CONST.CONTACT_TITLE}
      />
      <Header />
      <section className="azil-contact-hero-section azil-section-padding azil-bg-hero">
        <div className="azil-home-container" data-aos="fade-up">
          <div className="text-center  pt-0 azil-section-padding">
            <div className="azil-primary-heading my-2">
              Let's {"  "} <span className="font-primary">Consult </span> {"  "} With Us
            </div>
            <div className="azil-description my-2">
              Your idea with our expertise, and take your business to the
              pinnacle of success.{" "}
            </div>
            <div className="azil-description my-2">
              (Visit our{" "}
              <Link
                to={PATH.CURRENT_OPENING}
                className="font-primary azil-link-text"
                id="career"
              > {" "} career
              </Link>{" "}
              section for job and internship related queries.)
            </div>
            <div
              className="azil-primary-content my-2 font-red font-14"
            >
              (Visit our{" "}
              <Link to={PATH.WE_WORK} className="font-green azil-special-green-text">
                {" "}
                work on
              </Link>{" "}
              section for project discussion and connect from there.)
            </div>
          </div>
        </div>
      </section>
      <section className="azil-section-padding pt-0">
        <div className="azil-home-container" data-aos="fade-up">
          <div className="azil-contact-margin">
            <div className="row">
              <div className="col-lg-7 col-md-6 col-sm-12 col-12">
                <div className="azil-form-bg azil-bg-hero-faq shadow-sm mt-3">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={ContactUsSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ errors, touched, values, handleChange }) => (
                      <Form>
                        <Row>
                          <Col className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 my-2">
                            <div className="azil-input-group">
                              <CustomInput
                                type="text"
                                values={values}
                                placeholder="First Name*"
                                name="firstname"
                                handleChange={handleChange}
                                touched={touched}
                                errors={errors}
                              />
                            </div>
                          </Col>
                          <Col className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 my-2">
                            <div className="azil-input-group">
                              <CustomInput
                                type="text"
                                values={values}
                                placeholder="Last Name*"
                                name="lastname"
                                handleChange={handleChange}
                                touched={touched}
                                errors={errors}
                              />
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 my-2">
                            <div className="azil-input-group my-2">
                              <PhoneNumberInput
                                onChange={(e) => {
                                  setPhoneNumber(e);
                                  if (e === "") {
                                    setPhoneError(false);
                                  } else {
                                    setPhoneError(true);
                                  }
                                }}
                                name="phone_number"
                              />
                              {phoneError === true && (
                                <div
                                  style={{ fontSize: 14 }}
                                  className="text-left mt-1 text-danger"
                                >
                                  {ERROR.PHONE_NUMBER}
                                </div>
                              )}
                            </div>
                          </Col> 
                          <Col className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 my-2">
                            <div className="azil-input-group my-2">
                              <CustomInput
                                type="email"
                                values={values}
                                placeholder="Email Address*"
                                name="email"
                                handleChange={handleChange}
                                touched={touched}
                                errors={errors}
                              />
                            </div>
                          </Col>
                        </Row>
                        
                        <div className="azil-input-group my-2">
                          <textarea
                            type="text"
                            style={{ resize: "none" }}
                            placeholder="Message*"
                            name="message"
                            className={errors.message && "border-danger"}
                            value={values.message}
                            onChange={handleChange}
                          ></textarea>
                          {errors.message && touched.message && (
                            <div
                              style={{ fontSize: 14 }}
                              className="text-left text-danger"
                            >
                              {errors.message}
                            </div>
                          )}
                        </div>
                        <div className="upload my-2 upload-item">
                          <Dropzone
                            onDrop={(acceptedFiles) => {
                              handleAcceptedFiles(acceptedFiles);
                              const formData = new FormData();
                              for (const file of acceptedFiles)
                                formData.append("file", file);
                              const xhr = new XMLHttpRequest();
                              xhr.upload.onprogress = (event) => {
                                const percentage = parseInt(
                                  (event.loaded / event.total) * 100
                                );
                                setPercentage(percentage);
                              };
                              xhr.onreadystatechange = () => {
                                if (xhr.readyState !== 4) return;
                                if (xhr.status !== 200) {
                                  console.log("error");
                                }
                              };
                              xhr.open(
                                "POST",
                                "https://httpbin.org/post",
                                true
                              );
                              xhr.send(formData);
                            }}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div className="dropzone dz-clickable">
                                <div
                                  className="dz-message needsclick text-center"
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} />
                                  <div className="mb-3">
                                    <img
                                      src={IMAGECONST.Dropzone}
                                      alt="dropzone"
                                      style={{ height: 100 }}
                                      draggable="false"
                                    />
                                  </div>
                                  <h4>Drop files here or click to upload.</h4>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                        </div>
                        <div
                          className="dropzone-previews mt-3"
                          id="file-previews"
                        >
                          {images.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                                style={{
                                  borderRadius: "12px",
                                  border: "2px solid #e8e8e8",
                                }}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        alt={f.name}
                                        src={f.preview}
                                        draggable="false"
                                      />
                                    </Col>
                                    <Col>
                                      <Link
                                        to="#"
                                        className="text-muted font-weight-bold"
                                      >
                                        {f.name}
                                      </Link>
                                      <p className="mb-0 text-blue">
                                        {f.formattedSize}
                                      </p>
                                    </Col>
                                    <Col>
                                      <Progress
                                        color="primary"
                                        value={percentage}
                                      />
                                    </Col>
                                    <Col>
                                      <img
                                        src={IMAGECONST.Deleteicon}
                                        alt="delete"
                                        onClick={() => removeFile(i)}
                                        style={{ fontSize: 30, float: "right" }}
                                      />
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            );
                          })}
                        </div>
                        {images.length > 1 && (
                          <div
                            style={{ fontSize: 14 }}
                            className="text-left mt-1 text-danger"
                          >
                            {"Please upload only one image"}
                          </div>
                        )}
                        <div
                          className="g-recaptcha"
                          data-sitekey="6LfK8wsjAAAAAN3xftPQZZid0zh1gGbGxm3_DLIS"
                        ></div>
                        <div className="azil-contact-btn my-3">
                          <button
                            className="azil-hero-button align-items-center"
                            type="submit" onMouseEnter={handleHover} onMouseLeave={handleHoverEnd}
                          >
                            Submit
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
              <div className="col-lg-5 col-md-6 col-sm-12 col-12 my-2">
                <div className="azil-bg-contact my-2 shadow-sm">
                  <div className="font-dark-grey font-14 font-700 my-2">
                    <p> What's Next</p>
                  </div>
                  <div className="font-14 mt-4 font-primary font-600">
                    <p> 1. Initial Contact</p>
                  </div>
                  <div className="azil-description my-2 border-bottom">
                    Our experts will conduct a preliminary review of your
                    objectives and contact you with additional information about
                    the next course of action.
                  </div>
                  <div className="font-14 mt-4 font-primary font-600">
                    <p> 2. Project Analysis</p>
                  </div>
                  <div className="azil-description my-2 border-bottom">
                    We will gather all project requirements, establish your
                    business goals, and conduct market research.
                  </div>
                  <div className="font-14 mt-4 font-primary font-600">
                    <p> 3. Final Estimate</p>
                  </div>
                  <div className="azil-description my-2 border-bottom">
                    Our team will create a detailed structure for your project
                    along with the final estimate and an execution plan.
                  </div>
                </div>
                <div className="azil-bg-contact-2 my-2 shadow-sm">
                  <div className="font-22 font-700 font-white my-2">
                    Delivering Customer Success:
                  </div>
                  <div className="font-14 my-2 text-white">
                    The experts at Azillik utilize their proficiency in the
                    latest technologies to provide top-notch products to their
                    clients, ensuring business growth and success.
                  </div>
                  <ul className="font-14 text-white">
                    <li>750+ Projects Delivered</li>
                    <li>99% Project Delivery Success Rate</li>
                    <li>95% Customer Retention Rate</li>
                    <li>Awarded as the Best Outsourcing Company in the Region</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="azil-section-padding azil-bg-hero">
        <div className="azil-home-container" data-aos="fade-up">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-6 col-6 my-2">
              <div className="azil-mid-heading my-2">
                Our <span className="font-primary">Offices</span>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-6 my-2">
              <div className="azil-small-heading">
                  Surat {" "}
                <span className="font-primary">[HQ]</span>
              </div>
              <div className="azil-abt-content my-2">
                <Link
                  to="#"
                  onClick={() => {
                    const newWindow = window.open(
                      "https://maps.app.goo.gl/yFrSpYjK2dAnQbdt9",
                      "_blank",
                      "noopener,noreferrer"
                    );
                    if (newWindow) newWindow.opener = null;
                  }}
                >
                  <p className="font-grey font-14 address">
                    Unique Square, Causeway link road, Singanpor, Surat, Gujarat, {" "}
                    India-
                    <br /> 395004.
                  </p>
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-6 my-2">
              <div className="azil-small-heading">
                Londan
              </div>
              <div className="azil-abt-content my-2 azil-text-gray">
                <Link
                  to="#"
                  onClick={() => {
                    const newWindow = window.open(
                      "https://goo.gl/maps/eBeR82Y74VnRF1te9",
                      "_blank",
                      "noopener,noreferrer"
                    );
                    if (newWindow) newWindow.opener = null;
                  }}
                >
                  <p className="font-grey font-14 address">
                    Charles House Building, Bridge Road, Southa ll, <br />
                    London-UB2 4BD,
                    <br /> United Kingdom.
                  </p>
                </Link>
              </div>
            </div>
          </div>
          <div className="row azil-section-padding  px-0 pb-0">
            <div className="col-lg-4 col-md-4 col-sm-6 col-6 my-2">
              <div className="azil-mid-heading my-2">
                Contact <span className="font-primary">Details</span>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-6 my-2">
              <div className="azil-small-heading">
                Business & <span className="font-primary"> Projects </span>
              </div>
              <div className="d-flex align-itmes-center my-2">
                <div>
                  <img src={IMAGECONST.Contactmail} alt="mail" />
                </div>
                <a
                  className="azil-contact-item mx-2"
                  href="mailto:touch@azillik.com"
                >
                  touch@azillik.com
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-6 my-2 mx-auto my-0">
              <div className="azil-small-heading">
                Openings {" "}
                <span className="font-primary">[JOB]</span>
              </div>

              <div className="d-flex align-itmes-center my-2">
                <div>
                  <img src={IMAGECONST.Contactmail} alt="mail" />
                </div>
                <a
                  className="azil-contact-item mx-2"
                  href="mailto:life@azillik.com"
                >
                  life@azillik.com
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-6 my-2 mx-auto my-0"></div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-6 my-2 mx-auto my-0">
              <div className="azil-small-heading">
                Our All Web
                <span className="font-primary"> Complaints </span>
              </div>

              <div className="d-flex align-itmes-center my-2">
                <div>
                  <img src={IMAGECONST.Contactmail} alt="mail" />
                </div>  
                <a
                  className="azil-contact-item mx-2"
                  href="mailto:service@azillik.com"
                >
                  service@azillik.com
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-6 my-2 mx-auto my-0">
              <div className="azil-small-heading">
                Connect With
                <span className="font-primary"> HR </span>
              </div>

              <div className="d-flex align-itmes-center my-2">
                <div>
                  <img src={IMAGECONST.Contactmail} alt="mail" />
                </div>
                <a
                  className="azil-contact-item mx-2"
                  href="mailto:hr@azillik.com"
                >
                  hr@azillik.com
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <NotificationContainer />
      <Footer />
    </React.Fragment>
  );
};

export default ContactUsPage;