import React from "react";
import { Link } from "react-router-dom";
import { constants as PATH } from "../constants/componentPath";

const CustomBlog = ({ blog }) => {
  return (
    <React.Fragment>
      <div className="col-lg-3 col-md-6 col-sm-6 col-12">
        <div className="azil-vertical-blog-box my-4">
          <div className="azil-vertical-blog-img-box">
            <Link to={{pathname: PATH.BLOG_INNER, state: { blog: blog },}}>
              <img
                alt="blog img" loading="lazy" draggable="false"
                src={blog && blog.blog_image}
              />
            </Link>
          </div>
          <div className="azil-vertical-blog-content-box">
            <div className="my-2">
              <Link to={{pathname: PATH.BLOG_INNER, state: { blog: blog }}} className="azil-blog-category azil-link-text">{blog && blog.tag}</Link>
            </div>
            <div className="azil-main-blog-title-space">
              <Link to={{pathname: PATH.BLOG_INNER, state: { blog: blog }}}><span className="azil-mid-heading">{blog && blog.title}</span></Link>
            </div>
            <Link to={{pathname: PATH.BLOG_INNER, state: { blog: blog }}}>
              <p className="mb-2 azil-description azil-primary-hover">
                By{" "}
                {blog
                  ? blog.admin_firstname + " " + blog.admin_lastname
                  : "Azillik Discovery"}
              </p>
            </Link>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CustomBlog;
