import React from "react";
import { Helmet } from "react-helmet";

export function Seo(props) {
  return (
    <div className="application">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{props.title}</title>
        <meta name="og:description" content={props.description} />
        <meta name="og:keywords" content={props.keyword} />
        <meta
          name="image"
          property="og:image"
          itemProp="image"
          content={props.image}
        />
      </Helmet>
    </div>
  );
}
