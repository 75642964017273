import React from "react";
import { imagesConst as IMAGECONST } from "../../../components/constants/imagesConst";

const SocialBlog = ({ url, title, thumbnail, description, defaultText}) => {
  const encodedURL = encodeURIComponent(window.location.href);
  const encodedTitle = encodeURIComponent(title);
  const encodedDescription = encodeURIComponent(description);
  const encodedThumbnail = encodeURIComponent(thumbnail);
  const encodedDefaultText = encodeURIComponent(defaultText);
  const customText = "Explore this 📖👀...";

  // const facebookShareLink = `https://www.facebook.com/sharer/sharer.php?u=${encodedURL}&quote=${encodedDefaultText}`;
  // const twitterShareLink = `https://twitter.com/intent/tweet?url=${encodedURL}&text=${encodedDefaultText}`;
  // const whatsappShareLink = `https://wa.me/?text=${encodedTitle}%20-%20${encodedURL}?source=${encodedThumbnail}`;
  // const telegramShareLink = `https://t.me/share/url?url=${encodedURL}?text=${encodedDefaultText}?source=${encodedThumbnail}`;
  // const linkedinShareLink = `https://www.linkedin.com/shareArticle?url=${encodedURL}&title=${encodedTitle}&summary=${encodedDescription}&source=${encodedThumbnail}`;

  const facebookShareLink = `https://www.facebook.com/sharer/sharer.php?u=${encodedURL}&quote=${customText}${encodedDefaultText}`;
  const twitterShareLink = `https://twitter.com/intent/tweet?url=${encodedURL}&text=${customText}${encodedDefaultText}`;
  const whatsappShareLink = `https://wa.me/?text=${customText}${encodedTitle}%20-%20${encodedURL}`;
  const telegramShareLink = `https://t.me/share/url?url=${encodedURL}?text=${customText}${encodedDefaultText}`;
  const linkedinShareLink = `https://www.linkedin.com/shareArticle?url=${encodedURL}&title=${customText}${encodedTitle}`;

  /* -------------------------------------------------------------------------- */
  /*                                  copy link                                 */
  /* -------------------------------------------------------------------------- */
  const copyText = () => {
    const text = `${title} - ${url}`;
    navigator.clipboard.writeText(text)
      .then(() => {
        alert('Copied link to clipboard!');
      })
      .catch(err => {
        console.error('Failed to copy: ', err);
      });
  };
  
  return (
    <div>
      <div>
        <div className="d-flex align-items-center social-logo-container">
          <div className="azil-facebook azil-blog-social-icon" onClick={() => window.open(facebookShareLink, "_blank")}>
            <p target="_blank" rel="noopener noreferrer" >
              <img src={IMAGECONST.Facebook} alt="facebook" draggable={false} width={18} />
            </p>
          </div>

          <div className="azil-linkedin azil-blog-social-icon" onClick={() => window.open(linkedinShareLink, "_blank")}>
            <p target="_blank" rel="noopener noreferrer" >
              <img src={IMAGECONST.Linkedin} alt="linkedin" draggable={false} width={18} />
            </p>
          </div>

          <div className="azil-twitter azil-blog-social-icon" onClick={() => window.open(twitterShareLink, "_blank")}>
            <p target="_blank" rel="noopener noreferrer" >
              <img src={IMAGECONST.Twitter} alt="twitter" draggable={false} width={18} />
            </p>
          </div> 

          <div className="azil-telegram azil-blog-social-icon" onClick={() => window.open(telegramShareLink, "_blank")}>
            <p target="_blank" rel="noopener noreferrer" >
              <img src={IMAGECONST.Telegram} alt="telegram" draggable={false} width={18} />
            </p>
          </div>
          <div className="azil-whatsapp azil-blog-social-icon" onClick={() => window.open(whatsappShareLink, "_blank")}>
            <p target="_blank" rel="noopener noreferrer" >
              <img src={IMAGECONST.Whatsapp} alt="Whatsapp" draggable={false} width={18} />
            </p>
          </div>
          <div className="azil-copy azil-blog-social-icon" onClick={copyText}>
            <p className="font-white" target="_blank" rel="noopener noreferrer" >
              <img src={IMAGECONST.Copy} alt="copy" draggable={false} width={18} />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialBlog;