import React, { useEffect, useState } from "react";
import Footer from "../../Layout/Footer";
import Header from "../../Layout/Header";
import { imagesConst as IMAGECONST } from "../../../components/constants/imagesConst";
import { useHistory } from "react-router-dom";
import { constants as PATH } from "../../../components/constants/componentPath";
import { Seo } from "../../../components/custom/Seo";
import AOS from "aos";
import "aos/dist/aos.css";
import Slider from "react-slick";

const CaseStudy = (props) => {
  const { portfolio } = props && props.location && props.location.state;
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    speed: 500,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  /* -------------------------------------------------------------------------- */
  /*                            Button ripple effect                            */
  /* -------------------------------------------------------------------------- */
  const handleHover = (e) => {
    const button = e.target;
    const rect = button.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    const rippleSpan = document.createElement("span");
    rippleSpan.classList.add("ripple-span");
    rippleSpan.style.top = `${y}px`;
    rippleSpan.style.left = `${x}px`;
    button.appendChild(rippleSpan);
  };

  const handleHoverEnd = (e) => {
    const button = e.target;
    const ripples = button.getElementsByClassName("ripple-span");
    while (ripples.length > 0) {
      button.removeChild(ripples[0]);
    }
  };

  const [index, setIndex] = useState(0);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
  };
  const openFullscreen = (index) => {
    setIsFullscreen(true);
    setIndex(index);
  };
  const closeFullscreen = () => {
    setIsFullscreen(false);
  };

  return (
    <React.Fragment>
      <Seo
        title={portfolio.title + " | Azillik IT"}
        description={""}
        keyword={portfolio.title + " | Azillik IT"}
      />
      <Header />
      <section className="azil-casestudy-hero-section azil-section-padding azil-bg-hero">
        <div className="azil-home-container">
          <div className="text-center">
            <div className="azil-primary-heading my-2">
              <span className="font-primary">
                {portfolio && portfolio.title}
              </span>
            </div>
            <div className="azil-description my-2 w-50 mx-auto">
              {portfolio && portfolio.description}
            </div>
          </div>
        </div>
      </section>

      <section className="azil-casestudy-slider-container mx-auto">
        <img
          src={portfolio && portfolio.portfolio_image[selectedImageIndex]}
          alt="portfolio"
          className="azil-casestudy-mainimg"
          draggable="false"
        />
      </section>
      <section className="d-flex justify-content-center my-5">
        <div className="azil-casestudy-slider-container" style={{ maxWidth: "1024px" }}>
          <Slider {...settings}>
            {portfolio &&
              portfolio.portfolio_image.map((image, i) => {
                return (
                  <div key={i} className="azil-casestudy-slider-box" onClick={() => openFullscreen(i)}>
                    <img
                      src={image}
                      alt="portfolio"
                      draggable="false"
                      className="azil-casestudy-secondimg"
                      style={{ width: "100%", height: "auto" }}
                    />
                  </div>
                );
              })}
          </Slider>
          {isFullscreen && (
            <div>
              <div className="azil-fullscreen-image-container">
                <img src={portfolio.portfolio_image[index]} alt="portfolio" width="75%" height="75%" 
                  style={{borderRadius: "12px", boxShadow: "0 8px 15px #0000000d"}}/>
                <button onClick={closeFullscreen} className="azil-close-button azil-casestudy-closebtn">
                  <img src={IMAGECONST.close} alt="delete"/>
                </button>
              </div>
            </div>
          )}
        </div>
      </section>

      <section>
        {portfolio && portfolio.project_specification === true && (
          <>
            <div>
              <div className="azil-casestudy-slider-container mx-auto">
                <div className="align-items-center">
                  <div className="my-5">
                    <div className="my-2">
                      <p className="my-2 font-18 font-600 font-secondary">Technologies</p>
                      <div className="d-flex justify-content-start align-items-center">
                        <div className="azil-casestudy-tag">
                          <div className="azil-description">
                            {portfolio && portfolio.technology_used}
                          </div>
                        </div>
                        <div className="azil-casestudy-tag">
                          <div className="azil-description">
                            {portfolio && portfolio.frontend}
                          </div>
                        </div>
                        <div className="azil-casestudy-tag">
                          <div className="azil-description">
                            {portfolio && portfolio.backend}
                          </div>
                        </div>
                        <div className="azil-casestudy-tag">
                          <div className="azil-description">
                            {portfolio && portfolio.resource}
                          </div>
                        </div>
                        <div className="azil-casestudy-tag">
                          <div className="azil-description">
                            {portfolio && portfolio.duration}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-lg-6 col-md-6 col-sm-12 col-12 my-2">
                    <img
                      src={portfolio && portfolio.project_specification_image}
                      alt="specification"
                      className="img-fluid"
                      style={{height:"370px", borderRadius:"12px"}}
                      draggable="false"
                    />
                  </div> */}
                </div>
              </div>
            </div>
          </>
        )}
      </section>

      <section className="azil-casestudy-slider-container mx-auto">
        <p className="my-2 font-18 font-600 font-secondary">Features</p>
        {portfolio &&
          portfolio.case_study &&
          portfolio.case_study.map((caseStudy, index) => {
            return (
              <div>
                <div className="font-black font-14 font-600">
                  {caseStudy && caseStudy.sub_title.split(" ") ? (
                    <>
                      {caseStudy.sub_title.split(" ").length > 0 && (
                        <>
                          {caseStudy.sub_title.split(" ")[0]}
                          <span className="font-primary">
                            {" "}
                            {caseStudy.sub_title.split(" ")[1]}
                          </span>
                        </>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </div>
                <div className="azil-description my-2 text-justify hiring">
                  <ul>
                    <li>{caseStudy && caseStudy.sub_description}</li>
                  </ul>
                </div>
              </div>
            );
          })}
      </section>

      {/* <section className="azil-section-padding pb-0">
        <div data-aos="fade-up">
          {portfolio &&
            portfolio.case_study &&
            portfolio.case_study.map((caseStudy, index) => {
              console.log("=======> index", index);
              return (
                <>
                  {(index + 1) % 2 === 0 ? (
                    <div className="azil-case-2" key={index}>
                      <div className="azil-home-container">
                        <div className="row align-items-center">
                          <div className="col-lg-6 col-md-6 col-sm-12 col-12 my-2">
                            <img
                              // src={
                              //   portfolio &&
                              //   portfolio.portfolio_image[`${index}`]
                              // }
                              alt="portfolio"
                              style={{height:"370px", borderRadius:"12px"}}
                              className="img-fluid"
                              draggable="false"
                            />
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12 col-12 my-2">
                            <div className="azil-mid-heading my-2">
                              {caseStudy && caseStudy.sub_title.split(" ") ? (
                                <>
                                  {caseStudy.sub_title.split(" ").length >
                                    0 && (
                                    <>
                                      {caseStudy.sub_title.split(" ")[0]}
                                      <span className="font-primary">
                                        {" "}
                                        {caseStudy.sub_title.split(" ")[1]}
                                      </span>
                                    </>
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="azil-description my-2">
                              {caseStudy && caseStudy.sub_description}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="azil-case-1" key={index}>
                      <div className="azil-home-container">
                        <div className="row align-items-center">
                          <div className="col-lg-6 col-md-6 col-sm-12 col-12 my-2">
                            <div className="azil-mid-heading my-2">
                              {caseStudy && caseStudy.sub_title.split(" ") ? (
                                <>
                                  {caseStudy.sub_title.split(" ").length >
                                    0 && (
                                    <>
                                      {caseStudy.sub_title.split(" ")[0]}
                                      <span className="font-primary">
                                        {" "}
                                        {caseStudy.sub_title.split(" ")[1]}
                                      </span>
                                    </>
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="azil-description my-2">
                              {caseStudy && caseStudy.sub_description}
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12 col-12 my-2">
                            <img
                              src={
                                portfolio &&
                                portfolio.portfolio_image[`${index}`]
                              }
                              alt=""
                              className="img-fluid"
                              style={{height:"370px", borderRadius:"12px"}}
                              draggable="false"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              );
            })}

          
        </div>
      </section> */}

      <section className="azil-section-padding">
        <div className="azil-casestudy-slider-container mx-auto" data-aos="fade-up">
          <div className="row align-items-center">
            <div className="col-lg-7 col-md-6 col-sm-12 col-12 my-2">
              <div className="let-class let-class-casestudy">Let's</div>
              <div className="text-talk text-talk-casestudy">Talk</div>
            </div>
            <div className="col-lg-5 col-md-6 col-sm-12 col-12 my-2">
              <div className="azil-description my-2 text-justify">
                Discover exceptional clients passionate about their products and clientele with Azillik. Specializing in web, app, and game design and development, we're dedicated to bringing your vision to life. Join us in creating remarkable digital experiences that leave a lasting impression.
              </div>
              <button
                className="azil-hero-button my-2"
                onClick={() => {
                  history.push(PATH.CONTACT_US);
                }} onMouseEnter={handleHover} onMouseLeave={handleHoverEnd}
              >
                Contact Us
              </button>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </React.Fragment>
  );
};

export default CaseStudy;
