/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import Footer from "../../Layout/Footer";
import Header from "../../Layout/Header";
import { imagesConst as IMAGECONST } from "../../../components/constants/imagesConst";
import CustomBlog from "../../../components/custom/customBlog";
import { constants as PATH } from "../../../components/constants/componentPath";
import { useHistory } from "react-router-dom";
import CustomContactUs from "../../../components/custom/CustomContactUs";
import { GetBlogs } from "../../../services/ContactService";
import { Seo } from "../../../components/custom/Seo";
import { seoConstants as SEO_CONST } from "../../../components/constants/seoConst";
import AOS from "aos";
import "aos/dist/aos.css";

const HowWeWork = () => {
  const history = useHistory();
  const consultAzil = useRef();
  const [blogData, setBlogData] = useState([]);

  const getAllBlogs = () => {
    let pagination = `?page=1&limit=4 `;
    GetBlogs(pagination)
      .then((res) => {
        const data = res && res.data && res.data.data && res.data.data.data;
        setBlogData(data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getAllBlogs();
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);

  /* -------------------------------------------------------------------------- */
  /*                            Button ripple effect                            */
  /* -------------------------------------------------------------------------- */
  const handleHover = (e) => {
    const button = e.target;
    const rect = button.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    const rippleSpan = document.createElement('span');
    rippleSpan.classList.add('ripple-span');
    rippleSpan.style.top = `${y}px`;
    rippleSpan.style.left = `${x}px`;
    button.appendChild(rippleSpan);
  };

  const handleHoverEnd = (e) => {
    const button = e.target;
    const ripples = button.getElementsByClassName('ripple-span');
    while (ripples.length > 0) {
      button.removeChild(ripples[0]);
    }
  };

  const contractdata = [
    {
      contract_img: IMAGECONST.Team,
      imgalt: "team",
      contract_heading: "We Build Relationships",
      contract_description: "We believe in building and maintaining strong relationships with our clients. We have a great testimony of repetitive clients who are happy to recommend us."
    },
    {
      contract_img: IMAGECONST.Time,
      imgalt: "time",
      contract_heading: "Time and Material",
      contract_description: "You pay us only for the number of hours we spent on providing you with web and mobile app development services; not a penny more, not a penny less."
    },
    {
      contract_img: IMAGECONST.Price,
      imgalt: "price",
      contract_heading: "Fixed Price",
      contract_description: "Our charges are completely based on the web development services that we offer to you."
    },
    {
      contract_img: IMAGECONST.Team,
      imgalt: "team",
      contract_heading: "Dedicated Team",
      contract_description: "A dedicated team of web and app developers can help build your business from scratch or redefine its goals."
    },
  ]
  const approachdata = [
    {
      approach_img: IMAGECONST.Team,
      imgalt: "team",
      approach_heading: "Daily Scrum",
      approach_description: "We are the leading web & mobile development company. Thus, we keep updated with the latest technologies and have short meetings. We also review the work completed the previous day. That helps us to plan what work needs to be done in the next 24 hours. Thus, we stay updated with the current progress of the task and brainstorm on any hurdles."
    },
    {
      approach_img: IMAGECONST.Review,
      imgalt: "review",
      approach_heading: "Review",
      approach_description: "During the review meeting, the team shows what they have accomplished & demos the features developed. The R&D comes up with the newest technologies and produces ways to offer custom web app development services with ease. Not just confined to that, in the review process, we conduct software testing and quality assurance checks. The result is assessed with the determined goals."
    },
    {
      approach_img: IMAGECONST.Team,
      imgalt: "team",
      approach_heading: "Execution",
      approach_description: "After analyzing past, present and future projects, the team continues to execute their work with enhanced project lines. If there are any developments required, the website and app developers strive to deploy those with ease."
    },
  ]
  const lifecycledata = [
    {
      lifecycle_img: IMAGECONST.Understanding,
      imgalt: "Understanding",
      lifecycle_heading: "Understanding",
      lifecycle_description: "Being a professional development company, we analyze your project and suggest the best contract for its development. We make a framework for a new project and make a list of the things to be done."
    },
    {
      lifecycle_img: IMAGECONST.Project,
      imgalt: "review",
      lifecycle_heading: "Project Review",
      lifecycle_description: "We review the project to understand its current position to have better clarity. We plan the required and necessary changes in web and app development services that would make your experience worth it!"
    },
    {
      lifecycle_img: IMAGECONST.Research,
      imgalt: "Research",
      lifecycle_heading: "Research",
      lifecycle_description: "After reviewing the undertaken project, we conduct in-depth research to ensure that custom web app development services are equipped with the best and latest features. It uses the latest technologies to make it more attractive and user-friendly."
    },
    {
      lifecycle_img: IMAGECONST.Implement,
      imgalt: "Implementation",
      lifecycle_heading: "Implementation",
      lifecycle_description: "We work on accomplishing the set goals and objectives. We implement our plan to develop a unique and the best app or website for our client. Each of our development undergoes software quality and testing to ensure that we deliver the best services with an apt response."
    },
  ]

  return (
    <React.Fragment>
      <Seo
        title={SEO_CONST.HOW_WE_TITLE}
        description={""}
        keyword={SEO_CONST.HOW_WE_TITLE}
      />
      <Header />
      <section className="azil-hero-section azil-section-padding azil-bg-hero">
        <div className="azil-home-container" data-aos="fade-up">
          <div className="row align-items-center">
            <div className="col-lg-7 col-md-6 col-sm-12 col-12 my-2">
              <div className="azil-primary-heading my-2 w-75">
                We offer End-to-End{" "}
                <span className="font-primary"> Services to Clients </span>{" "}
              </div>
              <div className="azil-description w-75">
                Dedicated to excellence, we pride ourselves on being recognized as a premier website and application design + development company. Our commitment to quality and unwavering principles define every project we undertake.
              </div>
              <button
                className="azil-hero-button my-3"
                onClick={() => history.push(PATH.WE_WORK)} onMouseEnter={handleHover} onMouseLeave={handleHoverEnd}
              >
                We work on
              </button>
            </div>
            <div className="col-lg-5 col-md-6 col-sm-12 col-12 my-2 text-right">
              <img
                src={IMAGECONST.Howwe_banner}
                alt="banner"
                className="img-fluid azil-home-img"
                draggable="false"
              />
            </div>
          </div>
        </div>
      </section>

      {/* we offer */}
      <section className="azil-section-padding">
        <div className="azil-home-container" data-aos="fade-up">
          <div className="text-center">
            <div className="azil-primary-heading my-2">
              Types of contracts <span className="font-primary"> we offer</span>
            </div>
          </div>
          <div className="row pb-0">
            {contractdata.map((contract, i) => (
              <div className="col-lg-6 col-md-6 col-sm-12 col-12 my-2" key={i}>
                <div className="row">
                  <div className="col-lg-3 col-md-3 col-sm-3 col-3 my-2 text-center">
                    <img
                      src={contract.contract_img}
                      alt={contract.imgalt}
                      draggable="false"
                    />
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-9 my-2">
                    <div className="azil-small-heading my-2">
                      {contract.contract_heading}
                    </div>
                    <div className="azil-description my-2 text-justify">
                      {contract.contract_description}
                    </div>
                  </div>
                </div>
              </div>  
            ))}
          </div>
        </div>
      </section>

      {/* IT Services */}
      <section className="azil-section-padding azil-res-it-service-full">
        <div className="azil-home-container" data-aos="fade-up">
          <div className="azil-portfolio-box d-flex flex-wrap align-items-center justify-content-between hi">
            <div className="azil-consult-box-1">
              <div className="d-flex align-items-center">
                <div className="text-center mr-3">
                  <img
                    src={IMAGECONST.Howwework_Logo}
                    alt="logo"
                    style={{
                      height: "70px",
                    }}
                    draggable="false" className="azil-res-it-service-img"
                  />
                </div>
                <div className="pt-2">
                  <div className="azil-small-heading">
                    Azillik <span className="font-primary">IT Services</span>
                  </div>
                  <div className="azil-description">For IT Consultant (HR)</div>
                </div>
              </div>
            </div>
            <div className="azil-description">
              Don’t Wait Until It’s Too Late
            </div>
            <div>
              <button
                className="azil-hero-button azil-res-it-service"
                onClick={() =>
                  consultAzil.current.scrollIntoView({ behavior: "smooth" })
                } onMouseEnter={handleHover} onMouseLeave={handleHoverEnd}
              >
                Free Quote
              </button>
            </div>
          </div>
        </div>
      </section>

      <section className="azil-section-padding azil-res-it-service-mobile">
        <div className="azil-home-container" data-aos="fade-up">
          <div className="azil-portfolio-box">
            <div className="azil-consult-box-1">
              <div className="text-center">
                <img
                  src={IMAGECONST.Howwework_Logo}
                  alt="logo"
                  style={{
                    height: "70px",
                  }}
                  draggable="false" className="azil-res-it-service-img"
                />
              </div>
              <div className="pt-2 text-center">
                <div className="azil-small-heading">
                  Azillik <span className="font-primary">IT Services</span>
                </div>
                <div className="azil-description">For IT Consultant (HR)</div>
              </div>
            </div>
            <div className="azil-description text-center my-3">
              Don’t Wait Until It’s Too Late
            </div>
            <div className="text-center my-2">
              <button
                className="azil-hero-button"
                onClick={() =>
                  consultAzil.current.scrollIntoView({ behavior: "smooth" })
                } onMouseEnter={handleHover} onMouseLeave={handleHoverEnd}
              >
                Free Quote
              </button>
            </div>
          </div>
        </div>
      </section>

      {/* development */}
      <section className="azil-section-padding azil-bg-hero">
        <div className="azil-home-container" data-aos="fade-up">
          <div className="text-center">
            <div className="azil-primary-heading my-2">
              Agile approach to <span className="font-primary"> development</span>
            </div>
            <div className="azil-description my-2">
              We work on a much-focused approach to ensure that the custom app
              development services undertaken are delivered on time and as per
              your requirements.
            </div>
          </div>
          <div className="row">
            {approachdata.map((approach, i) => (
              <div className="col-lg-4 col-md-4 col-sm-12 col-12 my-2" key={i}>
                <div className="row">
                  <div className="col-lg-3 col-md-3 col-sm-3 col-3 my-2 text-center">
                    <img
                      src={approach.approach_img}
                      alt={approach.imgalt}
                      draggable="false"
                    />
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-9 my-2">
                    <div className="azil-small-heading my-2">
                      {approach.approach_heading}
                    </div>
                    <div className="azil-description my-2 text-justify">
                      {approach.approach_description}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Lifecycle */}
      <section className="azil-section-padding">
        <div className="azil-home-container" data-aos="fade-up">
          <div className="text-center">
            <div className="azil-primary-heading my-2">
              Software Development <span className="font-primary"> Lifecycle</span>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6 col-sm-12 col-12 my-2">
              <div className="azil-res-lifecycle-img">
                <img
                  src={IMAGECONST.Homeimg1}
                  alt="lifecycle"
                  className="img-fluid azil-home-img"
                  draggable="false"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-12 my-2">
              {lifecycledata.map((lifecycle, i) => (
                <div className="row" key={i}>
                  <div className="col-lg-3 col-md-3 col-sm-3 col-3 my-2 azil-lifecycle-main-box">
                    <div className="azil-lifecycle-img">
                      <img
                        src={lifecycle.lifecycle_img}
                        alt={lifecycle.imgalt}
                        draggable="false"
                      />
                    </div>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-9 my-2">
                    <div className="azil-small-heading my-2">
                      {lifecycle.lifecycle_heading}
                    </div>
                    <div className="azil-description my-2 text-justify">
                      {lifecycle.lifecycle_description}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* Our Launches */}
      <section className="azil-section-padding azil-bg-hero">
        <div className="azil-home-container" data-aos="fade-up">
          <div className="text-center">
            <div className="azil-primary-heading my-2">
              Reads about <span className="font-primary"> New Trends</span> & <span className="font-primary"> Our Launches</span>
            </div>
          </div>
          
          <div className="row">
            {blogData &&
              blogData.map((blog, index) => {
                return (
                  <React.Fragment key={index}>
                    <CustomBlog blog={blog} />
                  </React.Fragment>
                );
              })}
          </div>
          {blogData && blogData.length >= 4 && (
            <div className="text-center my-4 mr-2">
              <button
                className="azil-hero-button"
                onClick={() => {
                  history.push(PATH.BLOG);
                }}
              >
                View All
              </button>
            </div>
          )}
        </div>
      </section>

      <section
        className="azil-home-section-10 azil-section-padding azil-bg-hero"
        ref={consultAzil}
        data-aos="fade-up"
      >
        <CustomContactUs />
      </section>
      <Footer />
    </React.Fragment>
  );
};

export default HowWeWork;