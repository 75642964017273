/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { imagesConst as IMAGECONST } from "../../../components/constants/imagesConst";
import { constants as PATH } from "../../../components/constants/componentPath";

const BlogHeader = ({ handleSearch }) => {
  const history = useHistory();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [showSearch, setShowSearch] = useState(false);
  const [searchEntered, setSearchEntered] = useState(false);

  const handleChange = (e) => {
    setSearchQuery(e.target.value);
    // handleSearch(e.target.value);
  };
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
        handleSearch(searchQuery);
    }
  };
  useEffect(() => {
    if (!searchEntered) {
        return;
    }
    setSearchEntered(false);
  }, [searchQuery, searchEntered]);
  
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    const menu = document.querySelector(".menu");
    const menuSection = document.querySelector(".menu-section");
    const menuArrow = document.querySelector(".menu-mobile-arrow");
    const menuClosed = document.querySelector(".menu-mobile-close");
    const menuToggle = document.querySelector(".menu-mobile-toggle");
    const menuOverlay = document.querySelector(".overlay");
    let subMenu;


    
    // Show & Hide Toggle Menu Function
    function toggleMenu() {
      menu.classList.toggle("active");
      menuOverlay.classList.toggle("active");
      //   headerBottom.classList.toggle("d-none");
    }

    // Show the Mobile Side Menu Function
    function showSubMenu(hasChildren) {
      subMenu = hasChildren.querySelector(".menu-subs");
      subMenu.classList.add("active");
      subMenu.style.animation = "slideLeft 0.5s ease forwards";
      const menuTitle =
        hasChildren.querySelector("i").parentNode.childNodes[0].textContent;
      menu.querySelector(".menu-mobile-title").innerHTML = menuTitle;
      menu.querySelector(".menu-mobile-header").classList.add("active");
    }

    // Hide the Mobile Side Menu Function
    // function hideSubMenu() {
    //   subMenu.style.animation = "slideRight 0.5s ease forwards";
    //   setTimeout(() => {
    //     subMenu.classList.remove("active");
    //   }, 300);

    //   menu.querySelector(".menu-mobile-title").innerHTML = "";
    //   menu.querySelector(".menu-mobile-header").classList.remove("active");
    // }

    // Windows Screen Resizes Function
    // window.onresize = function () {
    //   if (this.innerWidth > 991) {
    //     if (menu.classList.contains("active")) {
    //       toggleMenu();
    //     }
    //   }
    // };
  }, []);

  const toggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  /* -------------------------------------------------------------------------- */
  /*                            Button ripple effect                            */
  /* -------------------------------------------------------------------------- */
  const handleWhiteHover = (e) => {
    const button = e.target;
    const rect = button.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    const rippleSpan = document.createElement('span');
    rippleSpan.classList.add('ripple-span-white');
    rippleSpan.style.top = `${y}px`;
    rippleSpan.style.left = `${x}px`;
    button.appendChild(rippleSpan);
  };

  const handleWhiteHoverEnd = (e) => {
    const button = e.target;
    const ripples = button.getElementsByClassName('ripple-span-white');
    while (ripples.length > 0) {
      button.removeChild(ripples[0]);
    }
  }; 

  return (
    <>
      <header className="blog-header">
        <div className="azil-custom-container">
          <div className="azil-header-container">
            <div className="azil-logo">
              <Link to={PATH.ROOT}>
                <img
                  src={IMAGECONST.Bloglogo}
                  alt="azillik blog logo"
                  className="img-fluid my-2"
                  draggable="false"
                  style={{ width: "200px" }}
                />
              </Link>
            </div>
            <div className="azil-blog-visit">
              <Link className="azil-description azil-primary-hover">
                Visit Now{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  fill="none"
                  viewBox="0 0 18 18"
                >
                  <path
                    stroke="#696969"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.75"
                    d="M7.355 2.781h4.947V7.74M12.303 2.781L5.699 9.385M2 14.078a22.107 22.107 0 0014 0"
                  ></path>
                </svg>
              </Link>
            </div>
          </div>
        </div>
        <div className="azil-line"></div>
      </header>

      <div className="blog-header-bottom my-3">
        <div className="px-5 d-flex justify-content-center align-items-center">
          <div className="d-flex align-items-center my-4 mx-sm-0 mx-auto ">
            <a href={PATH.BLOG} aria-current="page" className="mx-2 azil-description azil-primary-hover" >
              Home
            </a>
            <a href="#" aria-current="page" className="mx-2 azil-description azil-primary-hover" >
              Design Inspiration
            </a>
            <a href="#" aria-current="page" className="mx-2 azil-description azil-primary-hover" >
              News & Updates
            </a>
            <div className="mx-2 azil-description azil-primary-hover azil-blog-show cursor-pointer azil-hero-button" onClick={() => setDropdownOpen(!dropdownOpen)} ref={dropdownRef}
            onMouseEnter={handleWhiteHover} onMouseLeave={handleWhiteHoverEnd}>
              Categories {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
                className="azil-transition"
                style={{rotate: dropdownOpen === true ? "180deg" : "0deg"}}
              >
                <path
                  style={{stroke: dropdownOpen === true ? "#6960eb" : "#696969"}}
                  stroke="#292D32"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15.06 10.48L11.53 14 8 10.48"
                ></path>
              </svg>
            </div>
            <div className="azil-blog-searchicon" onClick={() => setShowSearch(!showSearch)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="#696969"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 17A8 8 0 109 1a8 8 0 000 16zM21.003 21.003l-4.667-4.667"
                ></path>
              </svg>
            </div>
            {dropdownOpen && 
              <ul className="azil-list-style-none dropdown-blog-menu">
                <li>Azillik Discovery IT Services</li>
                <li>Icons Villa</li>
                <li>Font Case</li>
                <li>3D Depot</li>
                <li>Color Swatch</li>
                <li>Gradient Swatch</li>
                <li>Ui Colors Map</li>
                <li>Mockview</li>
                <li>Code Learn</li>
                <li>Web Codes</li>
                <li>App Source</li>
                <li>Job Board</li>
                <li>Ads on Azillik</li>
              </ul>
            }
          </div>
        </div>
      </div>
      <div style={{display: showSearch === true ? "block" : "none"}}>
        <div className="azil-blog-search">
          <input type="search"
            placeholder="Enter other skills"
            value={searchQuery}
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            />
        </div>
      </div>
    </>
  );
};

export default BlogHeader;