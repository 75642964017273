// eslint-disable jsx-a11y/anchor-is-valid
import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { imagesConst as IMAGECONST } from "../../components/constants/imagesConst";
import { constants as PATH } from "../../components/constants/componentPath";

const Accordion = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [innerOpenIndex, setInnerOpenIndex] = useState(null);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      setInnerOpenIndex(null);
    }
  };

  const toggleInnerAccordion = (index) => {
    setInnerOpenIndex(innerOpenIndex === index ? null : index);
  };

  return (
    <div className="accordion">
      <div className="azil-header-accordion-heading" onClick={toggleAccordion}>
        <p className="font-16 font-secondary font-600">{title}</p>
        <img src={IMAGECONST.Headerarrow} alt="arrow" className={`azil-transition ${isOpen ? "rotate" : ""}`}/>
      </div>
      {isOpen &&
        React.Children.map(children, (child, index) => {
          return React.cloneElement(child, {
            isOpen: innerOpenIndex === index,
            toggleAccordion: () => toggleInnerAccordion(index)
          });
        })}
    </div>
  );
};

const AccordionContainer = ({ title, image, isOpen, toggleAccordion, children }) => {
  return (
    <div className="azil-accordion-container">
      <div className="d-flex justify-content-between align-items-center position-relative cursor-pointer" onClick={toggleAccordion}>
        <div className="d-flex align-items-center">
          {image && <img src={image} alt="data" className="azil-accordion-container-image" />}
          <p className="font-14 font-dark-grey font-500">{title}</p>
        </div>
        <div className={`accordion-toggle ${isOpen ? "open" : ""}`}>
          <span className="plus">+</span>
          <span className="minus">-</span>
        </div>
      </div>
      {isOpen && <div className="accordion-content"><span>{children}</span></div>}
    </div>
  );
};

const Header = () => {
  const history = useHistory();
  const [toggle] = useState(false);
  /* -------------------------------------------------------------------------- */
  /*                                   Sidebar                                  */
  /* -------------------------------------------------------------------------- */
  const [isNavOpen, setIsNavOpen] = useState(false);
  const sideMenuRef = useRef(null);

  const handleSidemenu = () => {
    setIsNavOpen(!isNavOpen);
  };
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (sideMenuRef.current && !sideMenuRef.current.contains(event.target)) {
        setIsNavOpen(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  /* -------------------------------------------------------------------------- */
  /*                            Button ripple effect                            */
  /* -------------------------------------------------------------------------- */
  const handleHover = (e) => {
    const button = e.target;
    const rect = button.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    const rippleSpan = document.createElement('span');
    rippleSpan.classList.add('ripple-span');
    rippleSpan.style.top = `${y}px`;
    rippleSpan.style.left = `${x}px`;
    button.appendChild(rippleSpan);
  };

  const handleHoverEnd = (e) => {
    const button = e.target;
    const ripples = button.getElementsByClassName('ripple-span');
    while (ripples.length > 0) {
      button.removeChild(ripples[0]);
    }
  };
  const handleWhiteHover = (e) => {
    const button = e.currentTarget;
    const rect = button.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    const rippleSpan = document.createElement('span');
    rippleSpan.classList.add('ripple-span-white');
    rippleSpan.style.top = `${y}px`;
    rippleSpan.style.left = `${x}px`;
    button.appendChild(rippleSpan);
  };

  const handleWhiteHoverEnd = (e) => {
    const button = e.currentTarget;
    const ripples = button.getElementsByClassName('ripple-span-white');
    while (ripples.length > 0) {
      button.removeChild(ripples[0]);
    }
  }; 
  /* -------------------------------------------------------------------------- */
  /*                                Submenu data                                */
  /* -------------------------------------------------------------------------- */
   
  const solutiondata = [
    {
      heading_img: IMAGECONST.Travelandtourism,
      heading_imgalt: "Travel",
      solution_heading: "Travel & Tourism",
      solution_content: [
        {
          arrowimg: IMAGECONST.Headerarrow,
          imgalt: "arrow",
          solutionpath: PATH.TAXI,
          solutiontitle: "Taxi Booking"
        },
        {
          arrowimg: IMAGECONST.Headerarrow,
          imgalt: "arrow",
          solutionpath: PATH.ESCOOTER,
          solutiontitle: "EScooter"
        },
        {
          arrowimg: IMAGECONST.Headerarrow,
          imgalt: "arrow",
          solutionpath: PATH.TRAVEL,
          solutiontitle: "Travel App"
        }
      ]
    },
    {
      heading_img: IMAGECONST.Socialnetworking,
      heading_imgalt: "Social",
      solution_heading: "Social Networking",
      solution_content: [
        {
          arrowimg: IMAGECONST.Headerarrow,
          imgalt: "arrow",
          solutionpath: PATH.DATING,
          solutiontitle: "Dating App"
        },
        {
          arrowimg: IMAGECONST.Headerarrow,
          imgalt: "arrow",
          solutionpath: PATH.LIVE_STREAM,
          solutiontitle: "Live Streaming App"
        },
        {
          arrowimg: IMAGECONST.Headerarrow,
          imgalt: "arrow",
          solutionpath: PATH.SOCIAL,
          solutiontitle: "Social Media"
        }
      ]
    },
    {
      heading_img: IMAGECONST.Deliverysolutions,
      heading_imgalt: "Delivery",
      solution_heading: "Delivery Solutions",
      solution_content: [
        {
          arrowimg: IMAGECONST.Headerarrow,
          imgalt: "arrow",
          solutionpath: PATH.FOOD_DELIVERY,
          solutiontitle: "Food Delivery"
        },
        {
          arrowimg: IMAGECONST.Headerarrow,
          imgalt: "arrow",
          solutionpath: PATH.GROCERY,
          solutiontitle: "Grocery Delivery"
        },
        {
          arrowimg: IMAGECONST.Headerarrow,
          imgalt: "arrow",
          solutionpath: PATH.ON_DEMAND,
          solutiontitle: "On-Demand App"
        }
      ]
    },
    {
      heading_img: IMAGECONST.Fintechsolutions,
      heading_imgalt: "Fintech",
      solution_heading: "Fintech & Real Estate",
      solution_content: [
        {
          arrowimg: IMAGECONST.Headerarrow,
          imgalt: "arrow",
          solutionpath: PATH.FINANCIAL,
          solutiontitle: "Financial"
        },
        {
          arrowimg: IMAGECONST.Headerarrow,
          imgalt: "arrow",
          solutionpath: PATH.EWALLET,
          solutiontitle: "EWallet App"
        },
        {
          arrowimg: IMAGECONST.Headerarrow,
          imgalt: "arrow",
          solutionpath: PATH.EWALLET,
          solutiontitle: "Real Estate"
        }
      ]
    },
    {
      heading_img: IMAGECONST.MediaAndEntertainment,
      heading_imgalt: "Media",
      solution_heading: "Media & Entertainment",
      solution_content: [
        {
          arrowimg: IMAGECONST.Headerarrow,
          imgalt: "arrow",
          solutionpath: PATH.NEWS,
          solutiontitle: "News"
        },
        {
          arrowimg: IMAGECONST.Headerarrow,
          imgalt: "arrow",
          solutionpath: PATH.SPORTS,
          solutiontitle: "Sports Betting"
        }
      ]
    },
    {
      heading_img: IMAGECONST.Healthcare,
      heading_imgalt: "HealthCare",
      solution_heading: "Health Care",
      solution_content: [
        {
          arrowimg: IMAGECONST.Headerarrow,
          imgalt: "arrow",
          solutionpath: PATH.HEALTHCARE,
          solutiontitle: "Healthcare App"
        },
        {
          arrowimg: IMAGECONST.Headerarrow,
          imgalt: "arrow",
          solutionpath: PATH.FITNESS,
          solutiontitle: "Fitness"
        }
      ]
    },
    {
      heading_img: IMAGECONST.RetailAndEcommerce,
      heading_imgalt: "Retail & ecommerce",
      solution_heading: "Retail & Ecommerce",
      solution_content: [
        {
          arrowimg: IMAGECONST.Headerarrow,
          imgalt: "arrow",
          solutionpath: PATH.MULTI_VENDOR,
          solutiontitle: "Multi Vendor CRM"
        },
        {
          arrowimg: IMAGECONST.Headerarrow,
          imgalt: "arrow",
          solutionpath: PATH.ECOMMERCE_APP,
          solutiontitle: "ECommerce App"
        }
      ]
    },
    {
      heading_img: IMAGECONST.Fullstack,
      heading_imgalt: "fullstack",
      solution_heading: "Fullstack Solutions",
      solution_content: [
        {
          arrowimg: IMAGECONST.Headerarrow,
          imgalt: "arrow",
          solutionpath: PATH.EDUCATION,
          solutiontitle: "Education"
        },
        {
          arrowimg: IMAGECONST.Headerarrow,
          imgalt: "arrow",
          solutionpath: PATH.FULL_STACK,
          solutiontitle: "Fullstack Solutions"
        }
      ]
    },
  ]
  const technologydata = [
    {
      heading_img: IMAGECONST.Webdevelopment,
      heading_imgalt: "web Development",
      techno_heading: "Web Development",
      techno_content: [
        {
          arrowimg: IMAGECONST.Mern,
          imgalt: "mern",
          technologypath: PATH.FRONTEND,
          technologytitle: "MERN Stack"
        },
        {
          arrowimg: IMAGECONST.Mean,
          imgalt: "mean",
          technologypath: PATH.FRONTEND,
          technologytitle: "MEAN Stack"
        },
        {
          arrowimg: IMAGECONST.React,
          imgalt: "react",
          technologypath: PATH.REACT,
          technologytitle: "React JS"
        },
        {
          arrowimg: IMAGECONST.Next,
          imgalt: "next",
          technologypath: PATH.REACT,
          technologytitle: "Next JS"
        },
        {
          arrowimg: IMAGECONST.Angular,
          imgalt: "angular",
          technologypath: PATH.ANGULAR,
          technologytitle: "Angular JS"
        },
        {
          arrowimg: IMAGECONST.Vue,
          imgalt: "vue",
          technologypath: PATH.ANGULAR,
          technologytitle: "Vue JS"
        },
        {
          arrowimg: IMAGECONST.Nuxt,
          imgalt: "nuxt",
          technologypath: PATH.ANGULAR,
          technologytitle: "Nuxt JS"
        },
        {
          arrowimg: IMAGECONST.Vite,
          imgalt: "vite",
          technologypath: PATH.ANGULAR,
          technologytitle: "Vite JS"
        },
        {
          arrowimg: IMAGECONST.Node,
          imgalt: "node",
          technologypath: PATH.NODE,
          technologytitle: "Node JS"
        },
        {
          arrowimg: IMAGECONST.Shopify,
          imgalt: "shopify",
          technologypath: PATH.NODE,
          technologytitle: "Shopify"
        },
        {
          arrowimg: IMAGECONST.Wordpress,
          imgalt: "wordpress",
          technologypath: PATH.WORDPRESS,
          technologytitle: "WordPress"
        },
        {
          arrowimg: IMAGECONST.Ruby,
          imgalt: "ruby",
          technologypath: PATH.NODE,
          technologytitle: "Ruby"
        },
        {
          arrowimg: IMAGECONST.Python,
          imgalt: "python",
          technologypath: PATH.PYTHON,
          technologytitle: "Python"
        },
        {
          arrowimg: IMAGECONST.Laravel,
          imgalt: "laravel",
          technologypath: PATH.LARAVEL,
          technologytitle: "Laravel"
        },
        {
          arrowimg: IMAGECONST.Asp,
          imgalt: "ASP.NET",
          technologypath: PATH.NET,
          technologytitle: "ASP.NET"
        },
        {
          arrowimg: IMAGECONST.Csharp,
          imgalt: "C#",
          technologypath: PATH.NET,
          technologytitle: "C# (C Sharp)"
        },
        {
          arrowimg: IMAGECONST.Java,
          imgalt: "java",
          technologypath: PATH.JAVA,
          technologytitle: "JAVA"
        },
        {
          arrowimg: IMAGECONST.Php,
          imgalt: "php",
          technologypath: PATH.PHP,
          technologytitle: "PHP"
        },
        {
          arrowimg: IMAGECONST.Codeigniter,
          imgalt: "Codeigniter",
          technologypath: PATH.PHP,
          technologytitle: "Codeigniter"
        },
        {
          arrowimg: IMAGECONST.Rails,
          imgalt: "ruby on rails",
          technologypath: PATH.ROR,
          technologytitle: "Ruby On Rails"
        },
      ]
    },
    {
      heading_img: IMAGECONST.Mobiledevelopment,
      heading_imgalt: "mobile apps",
      techno_heading: "Mobile Development",
      techno_content: [
        {
          arrowimg: IMAGECONST.Android,
          imgalt: "Android",
          technologypath: PATH.ANDROID,
          technologytitle: "Android"
        },
        {
          arrowimg: IMAGECONST.Ios,
          imgalt: "ios",
          technologypath: PATH.ANDROID,
          technologytitle: "iOS"
        },
        {
          arrowimg: IMAGECONST.Flutter,
          imgalt: "Flutter",
          technologypath: PATH.FLUTTER,
          technologytitle: "Flutter"
        },
        {
          arrowimg: IMAGECONST.Native,
          imgalt: "react Native",
          technologypath: PATH.REACT_NATIVE,
          technologytitle: "React Native"
        },
        {
          arrowimg: IMAGECONST.Ionic,
          imgalt: "Ionic",
          technologypath: PATH.REACT_NATIVE,
          technologytitle: "Ionic"
        }
      ]
    },
    {
      heading_img: IMAGECONST.Gamedevelopment,
      heading_imgalt: "Game development",
      techno_heading: "3D & Game Development",
      techno_content: [
        {
          arrowimg: IMAGECONST.TwoD_3D,
          imgalt: "2D/3D Design",
          technologypath: PATH.ANDROID,
          technologytitle: "2D/3D Design"
        },
        {
          arrowimg: IMAGECONST.Unity,
          imgalt: "Unity",
          technologypath: PATH.ANDROID,
          technologytitle: "Unity"
        },
        {
          arrowimg: IMAGECONST.Blender,
          imgalt: "Blender",
          technologypath: PATH.FLUTTER,
          technologytitle: "Blender"
        },
        {
          arrowimg: IMAGECONST.AutoCAD,
          imgalt: "auto-cad",
          technologypath: PATH.FLUTTER,
          technologytitle: "AutoCAD"
        },
        {
          arrowimg: IMAGECONST.Autodesk,
          imgalt: "Autodesk",
          technologypath: PATH.FLUTTER,
          technologytitle: "Autodesk"
        },
        {
          arrowimg: IMAGECONST.Sketchup,
          imgalt: "Sketchup",
          technologypath: PATH.FLUTTER,
          technologytitle: "SketchUp"
        },
        {
          arrowimg: IMAGECONST.ThreeDsmax,
          imgalt: "3d max",
          technologypath: PATH.FLUTTER,
          technologytitle: "3ds Max"
        },
        {
          arrowimg: IMAGECONST.Corona,
          imgalt: "Corona",
          technologypath: PATH.FLUTTER,
          technologytitle: "Corona"
        },
      ]
    },
    {
      heading_img: IMAGECONST.Trendytechnologies,
      heading_imgalt: "Trending tech",
      techno_heading: "Trending Technologies",
      techno_content: [
        {
          arrowimg: IMAGECONST.Blockchain,
          imgalt: "Blockchain",
          technologypath: PATH.ANDROID,
          technologytitle: "Block Chain"
        },
        {
          arrowimg: IMAGECONST.Chatgpt,
          imgalt: "Chatgpt",
          technologypath: PATH.ANDROID,
          technologytitle: "Chat GPT & AI"
        },
        {
          arrowimg: IMAGECONST.Crypto,
          imgalt: "Crypto",
          technologypath: PATH.FLUTTER,
          technologytitle: "Crypto Currency"
        },
        {
          arrowimg: IMAGECONST.Metaverce,
          imgalt: "Metaverce",
          technologypath: PATH.FLUTTER,
          technologytitle: "Metaverce"
        },
        {
          arrowimg: IMAGECONST.Cloudcompute,
          imgalt: "Cloud Computing",
          technologypath: PATH.FLUTTER,
          technologytitle: "Cloud Computing"
        },
        {
          arrowimg: IMAGECONST.Machinelearning,
          imgalt: "Machine learning",
          technologypath: PATH.FLUTTER,
          technologytitle: "Machine Learning"
        },
        {
          arrowimg: IMAGECONST.Iot,
          imgalt: "Iot",
          technologypath: PATH.FLUTTER,
          technologytitle: "Internet of Things(IOT)"
        },
        {
          arrowimg: IMAGECONST.Devops,
          imgalt: "Devops",
          technologypath: PATH.FLUTTER,
          technologytitle: "DevOps"
        },
        {
          arrowimg: IMAGECONST.Cybersecurity,
          imgalt: "Cyber security",
          technologypath: PATH.FLUTTER,
          technologytitle: "Cyber Security"
        },
        {
          arrowimg: IMAGECONST.Datascience,
          imgalt: "Data science",
          technologypath: PATH.FLUTTER,
          technologytitle: "Data Science"
        },
        {
          arrowimg: IMAGECONST.Data_analytics,
          imgalt: "Data Analytics",
          technologypath: PATH.FLUTTER,
          technologytitle: "Data Analytics"
        },
        {
          arrowimg: IMAGECONST.Robotics,
          imgalt: "Robotics",
          technologypath: PATH.FLUTTER,
          technologytitle: "Robotics"
        }
      ]
    },
    {
      heading_img: IMAGECONST.Systemdevelopment,
      heading_imgalt: "System Development",
      techno_heading: "System Development",
      techno_content: [
        {
          arrowimg: IMAGECONST.Python,
          imgalt: "Python",
          technologypath: PATH.ANDROID,
          technologytitle: "Python Software"
        },
        {
          arrowimg: IMAGECONST.Java,
          imgalt: "Java",
          technologypath: PATH.ANDROID,
          technologytitle: "Java Software"
        },
        {
          arrowimg: IMAGECONST.Electron,
          imgalt: "Electron",
          technologypath: PATH.FLUTTER,
          technologytitle: "Electron.JS"
        },
        {
          arrowimg: IMAGECONST.Vbnet,
          imgalt: "VB.NET",
          technologypath: PATH.FLUTTER,
          technologytitle: "VB.NET Software"
        }
      ]
    },
    {
      heading_img: IMAGECONST.Crmdevelopment,
      heading_imgalt: "crm development",
      techno_heading: "CRM Development",
      techno_content: [
        {
          arrowimg: IMAGECONST.Customdev,
          imgalt: "Custom software",
          technologypath: PATH.ANDROID,
          technologytitle: "Custom Software Development"
        },
        {
          arrowimg: IMAGECONST.Crm,
          imgalt: "Crm",
          technologypath: PATH.ANDROID,
          technologytitle: "CRM Development"
        },
        {
          arrowimg: IMAGECONST.Salesforce,
          imgalt: "Salesforce",
          technologypath: PATH.ANDROID,
          technologytitle: "Salesforce"
        },
        {
          arrowimg: IMAGECONST.Hubspot,
          imgalt: "Hubspot",
          technologypath: PATH.FLUTTER,
          technologytitle: "HubSpot"
        }
      ]
    },
  ]
  const servicedata = [
    {
      heading_img: IMAGECONST.Web_appdesign,
      heading_imgalt: "Web app design",
      service_heading: "Web & App Design",
      service_content: [
        {
          arrowimg: IMAGECONST.Ui_Ux,
          imgalt: "ui ux",
          servicepath: PATH.ANDROID,
          servicetitle: "UI/UX Design"
        },
        {
          arrowimg: IMAGECONST.Webdesign,
          imgalt: "web design",
          servicepath: PATH.CROSS_PLATFORM,
          servicetitle: "Web Design"
        },
        {
          arrowimg: IMAGECONST.Mobiledesign,
          imgalt: "Mobile App Design",
          servicepath: PATH.REACT_NATIVE,
          servicetitle: "Mobile App Design"
        },
        {
          arrowimg: IMAGECONST.Gamedesign,
          imgalt: "game design",
          servicepath: PATH.REACT_NATIVE,
          servicetitle: "Game Design"
        },
        {
          arrowimg: IMAGECONST.Graphicsdesign,
          imgalt: "Graphics design",
          servicepath: PATH.FLUTTER,
          servicetitle: "Graphics Design"
        },
        {
          arrowimg: IMAGECONST.Branddesign,
          imgalt: "Brand design",
          servicepath: PATH.FLUTTER,
          servicetitle: "Brand Design"
        }
      ]
    },
    {
      heading_img: IMAGECONST.Immersivesolutions,
      heading_imgalt: "Immersive",
      service_heading: "Immersive Solutions",
      service_content: [
        {
          arrowimg: IMAGECONST.Ar_view,
          imgalt: "ar",
          servicepath: PATH.ANDROID,
          servicetitle: "AR"
        },
        {
          arrowimg: IMAGECONST.Vr,
          imgalt: "vr",
          servicepath: PATH.ANDROID,
          servicetitle: "VR"
        },
        {
          arrowimg: IMAGECONST.Ml,
          imgalt: "ml",
          servicepath: PATH.FLUTTER,
          servicetitle: "AL/ML Services"
        },
        {
          arrowimg: IMAGECONST.Arvr,
          imgalt: "AR/VR",
          servicepath: PATH.FLUTTER,
          servicetitle: "AR/VR In Real Estate"
        }
      ]
    },
    {
      heading_img: IMAGECONST.Tools,
      heading_imgalt: "Creative Tools Compatibility",
      service_heading: "Tools Compatibility",
      service_content: [
        {
          arrowimg: IMAGECONST.Figma,
          imgalt: "Figma",
          servicepath: PATH.ANDROID,
          servicetitle: "Figma"
        },
        {
          arrowimg: IMAGECONST.Sketch,
          imgalt: "Sketch",
          servicepath: PATH.ANDROID,
          servicetitle: "Sketch"
        },
        {
          arrowimg: IMAGECONST.Photoshop,
          imgalt: "Photoshop",
          servicepath: PATH.ANDROID,
          servicetitle: "Adobe Photoshop"
        },
        {
          arrowimg: IMAGECONST.Illustrator,
          imgalt: "Illustrator",
          servicepath: PATH.ANDROID,
          servicetitle: "Adobe Illustrator"
        },
        {
          arrowimg: IMAGECONST.Xd,
          imgalt: "Xd",
          servicepath: PATH.FLUTTER,
          servicetitle: "Adobe XD"
        },
        {
          arrowimg: IMAGECONST.Indesign,
          imgalt: "Indesign",
          servicepath: PATH.FLUTTER,
          servicetitle: "InDesign"
        },
        {
          arrowimg: IMAGECONST.Aftereffect,
          imgalt: "Aftereffect",
          servicepath: PATH.FLUTTER,
          servicetitle: "Adobe After Effects"
        },
        {
          arrowimg: IMAGECONST.Premirepro,
          imgalt: "Premirepro",
          servicepath: PATH.FLUTTER,
          servicetitle: "Adobe Premire Pro"
        },
        {
          arrowimg: IMAGECONST.Character_animator,
          imgalt: "Character Animator",
          servicepath: PATH.FLUTTER,
          servicetitle: "Adobe Character Animator"
        },
        {
          arrowimg: IMAGECONST.Coraldraw,
          imgalt: "Coraldraw",
          servicepath: PATH.FLUTTER,
          servicetitle: "Coral Draw"
        },
        {
          arrowimg: IMAGECONST.Procreate,
          imgalt: "pro create",
          servicepath: PATH.FLUTTER,
          servicetitle: "Pro Create"
        },
        {
          arrowimg: IMAGECONST.Zeplin,
          imgalt: "Zeplin",
          servicepath: PATH.FLUTTER,
          servicetitle: "Zeplin"
        },
        {
          arrowimg: IMAGECONST.Invision,
          imgalt: "Invision",
          servicepath: PATH.FLUTTER,
          servicetitle: "InVision"
        },
        {
          arrowimg: IMAGECONST.Krita,
          imgalt: "krita",
          servicepath: PATH.FLUTTER,
          servicetitle: "Krita"
        }
      ]
    },
    {
      heading_img: IMAGECONST.Digitalmarketing,
      heading_imgalt: "Digital Marketing",
      service_heading: "Digital Marketing",
      service_content: [
        {
          arrowimg: IMAGECONST.Seo,
          imgalt: "Seo",
          servicepath: PATH.ANDROID,
          servicetitle: "SEO"
        },
        {
          arrowimg: IMAGECONST.Socialmarketing,
          imgalt: "Social marketing",
          servicepath: PATH.ANDROID,
          servicetitle: "Social Media Marketing"
        },
        {
          arrowimg: IMAGECONST.Googleads,
          imgalt: "Googleads",
          servicepath: PATH.FLUTTER,
          servicetitle: "Google Ads"
        },
        {
          arrowimg: IMAGECONST.Emailmarketing,
          imgalt: "Email marketing",
          servicepath: PATH.FLUTTER,
          servicetitle: "Email Marketing"
        },
        {
          arrowimg: IMAGECONST.Lead,
          imgalt: "Lead Generation",
          servicepath: PATH.FLUTTER,
          servicetitle: "Lead Generation"
        },
        {
          arrowimg: IMAGECONST.Blogmarketing,
          imgalt: "Blog marketing",
          servicepath: PATH.FLUTTER,
          servicetitle: "Blog Marketing"
        },
        {
          arrowimg: IMAGECONST.Keywordsearch,
          imgalt: "Keyword search",
          servicepath: PATH.FLUTTER,
          servicetitle: "Keyword Research"
        },
        {
          arrowimg: IMAGECONST.Linkbuild,
          imgalt: "Link build",
          servicepath: PATH.FLUTTER,
          servicetitle: "Link Building"
        }
      ]
    },
    {
      heading_img: IMAGECONST.Deployment,
      heading_imgalt: "Deployment",
      service_heading: "Deployment",
      service_content: [
        {
          arrowimg: IMAGECONST.Aws,
          imgalt: "Aws",
          servicepath: PATH.ANDROID,
          servicetitle: "AWS"
        },
        {
          arrowimg: IMAGECONST.Digitalocean,
          imgalt: "Digital ocean",
          servicepath: PATH.ANDROID,
          servicetitle: "Digital Ocean"
        },
        {
          arrowimg: IMAGECONST.Azure,
          imgalt: "Azure",
          servicepath: PATH.ANDROID,
          servicetitle: "Azure"
        },
        {
          arrowimg: IMAGECONST.Bluehost,
          imgalt: "Blue host",
          servicepath: PATH.FLUTTER,
          servicetitle: "Blue Host"
        },
        {
          arrowimg: IMAGECONST.Godaddy,
          imgalt: "Godaddy",
          servicepath: PATH.FLUTTER,
          servicetitle: "Godaddy"
        },
        {
          arrowimg: IMAGECONST.Hostinger,
          imgalt: "Hostinger",
          servicepath: PATH.FLUTTER,
          servicetitle: "Hostinger"
        },
        {
          arrowimg: IMAGECONST.Vercel,
          imgalt: "Vercel",
          servicepath: PATH.FLUTTER,
          servicetitle: "Vercel"
        },
        {
          arrowimg: IMAGECONST.Netlify,
          imgalt: "Netlify",
          servicepath: PATH.FLUTTER,
          servicetitle: "Netlify"
        }
      ]
    },
    {
      heading_img: IMAGECONST.Database,
      heading_imgalt: "Database",
      service_heading: "Database",
      service_content: [
        {
          arrowimg: IMAGECONST.Mongo,
          imgalt: "Mongo",
          servicepath: PATH.ANDROID,
          servicetitle: "MongoDB"
        },
        {
          arrowimg: IMAGECONST.PostgreSQL,
          imgalt: "PostgreSQL",
          servicepath: PATH.ANDROID,
          servicetitle: "PostgreSQL"
        },
        {
          arrowimg: IMAGECONST.Awsrds,
          imgalt: "Aws rds",
          servicepath: PATH.FLUTTER,
          servicetitle: "AWS RDS"
        },
        {
          arrowimg: IMAGECONST.Oracle,
          imgalt: "Oracle",
          servicepath: PATH.REACT_NATIVE,
          servicetitle: "Oracle"
        },
        {
          arrowimg: IMAGECONST.MySQL,
          imgalt: "MySQL",
          servicepath: PATH.REACT_NATIVE,
          servicetitle: "MySQL"
        },
        {
          arrowimg: IMAGECONST.MsSQL,
          imgalt: "MsSQL",
          servicepath: PATH.REACT_NATIVE,
          servicetitle: "Microsoft SQL Server"
        },
        {
          arrowimg: IMAGECONST.Cassandra,
          imgalt: "Apache Cassandra",
          servicepath: PATH.REACT_NATIVE,
          servicetitle: "Apache Cassandra"
        },
        {
          arrowimg: IMAGECONST.Elasticsearch,
          imgalt: "Elastic search",
          servicepath: PATH.REACT_NATIVE,
          servicetitle: "Elastic Search"
        },
        {
          arrowimg: IMAGECONST.Firebase,
          imgalt: "Firebase",
          servicepath: PATH.REACT_NATIVE,
          servicetitle: "Firebase"
        }
      ]
    },
    {
      heading_img: IMAGECONST.Qatesting,
      heading_imgalt: "QA Testing",
      service_heading: "QA Testing",
      service_content: [
        {
          arrowimg: IMAGECONST.Automationtest,
          imgalt: "Automation Testing",
          servicepath: PATH.ANDROID,
          servicetitle: "Automation Testing"
        },
        {
          arrowimg: IMAGECONST.manualtest,
          imgalt: "Manual Testing",
          servicepath: PATH.ANDROID,
          servicetitle: "Manual Testing"
        },
        {
          arrowimg: IMAGECONST.Unittest,
          imgalt: "Unit Testing",
          servicepath: PATH.FLUTTER,
          servicetitle: "Unit Testing"
        },
        {
          arrowimg: IMAGECONST.Webtest,
          imgalt: "Website Testing",
          servicepath: PATH.FLUTTER,
          servicetitle: "Web & App Testing"
        },
        {
          arrowimg: IMAGECONST.Gametest,
          imgalt: "Game Testing",
          servicepath: PATH.FLUTTER,
          servicetitle: "Game Testing"
        },
        {
          arrowimg: IMAGECONST.Uitest,
          imgalt: "Ui testing",
          servicepath: PATH.FLUTTER,
          servicetitle: "UI(Responsive) Testing"
        },
        {
          arrowimg: IMAGECONST.Endtoendtest,
          imgalt: "End To End Testing",
          servicepath: PATH.FLUTTER,
          servicetitle: "End-to-end Testing"
        },
        {
          arrowimg: IMAGECONST.Integrationtest,
          imgalt: "Integration test",
          servicepath: PATH.FLUTTER,
          servicetitle: "Integration Testing"
        },
        {
          arrowimg: IMAGECONST.Apitest,
          imgalt: "Api testing",
          servicepath: PATH.FLUTTER,
          servicetitle: "APIs Testing"
        },
        {
          arrowimg: IMAGECONST.Securitytest,
          imgalt: "Security testing",
          servicepath: PATH.FLUTTER,
          servicetitle: "Security Testing"
        },
        {
          arrowimg: IMAGECONST.Smocktest,
          imgalt: "Smock testing",
          servicepath: PATH.FLUTTER,
          servicetitle: "Smoke Testing"
        },
        {
          arrowimg: IMAGECONST.Systemtest,
          imgalt: "System testing",
          servicepath: PATH.FLUTTER,
          servicetitle: "System Testing"
        }
      ]
    },
  ]
  const companydata = [
    {
      heading_img: IMAGECONST.Whoweare,
      heading_imgalt: "who we are",
      company_heading: "Who We Are?",
      company_content: [
        {
          arrowimg: IMAGECONST.Headerarrow,
          imgalt: "arrow",
          companypath: PATH.ABOUT,
          companytitle: "About Us"
        },
        {
          arrowimg: IMAGECONST.Headerarrow,
          imgalt: "arrow",
          companypath: PATH.WE_WORK,
          companytitle: "We Work On"
        },
        {
          arrowimg: IMAGECONST.Headerarrow,
          imgalt: "arrow",
          companypath: PATH.HOW_WE_WORK,
          companytitle: "Process We Follow"
        }
      ]
    },
    {
      heading_img: IMAGECONST.Resources,
      heading_imgalt: "resources",
      company_heading: "Resources",
      company_content: [
        {
          arrowimg: IMAGECONST.Headerarrow,
          imgalt: "arrow",
          companypath: PATH.PORTFOLIO,
          companytitle: "Portfolio"
        },
        {
          arrowimg: IMAGECONST.Headerarrow,
          imgalt: "arrow",
          companypath: PATH.BLOG,
          companytitle: "Blogs"
        },
        {
          arrowimg: IMAGECONST.Headerarrow,
          imgalt: "arrow",
          companypath: PATH.FAQ,
          companytitle: "FAQs"
        }
      ]
    },
    {
      heading_img: IMAGECONST.Joinus,
      heading_imgalt: "join us",
      company_heading: "Join Us",
      company_content: [
        {
          arrowimg: IMAGECONST.Headerarrow,
          imgalt: "arrow",
          companypath: PATH.OUR_TEAM,
          companytitle: "Team Work"
        },
        {
          arrowimg: IMAGECONST.Headerarrow,
          imgalt: "arrow",
          companypath: PATH.CURRENT_OPENING,
          companytitle: "Current Openings"
        },
        {
          arrowimg: IMAGECONST.Headerarrow,
          imgalt: "arrow",
          companypath: PATH.HIRING,
          companytitle: "Life @ Azillik"
        }
      ]
    },
  ]
  const [activeTab, setActiveTab] = useState(technologydata[0].techno_heading);
  const [activeSolutionTab, setActiveSolutionTab] = useState(solutiondata[0].solution_heading);
  const [activeCompanyTab, setActiveCompanyTab] = useState(companydata[0].company_heading);
  const [activeServiceTab, setActiveServiceTab] = useState(servicedata[0].service_heading);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setActiveCompanyTab(tab);
    setActiveServiceTab(tab);
  };
  const solutionTabChange = (tab) => {
    setActiveSolutionTab(tab);
  }

  return (
    <>
      <section className="header">
        <header>
          <div className="azil-custom-container">
            <div className="azil-header-container">
              <div className="azil-logo">
                <Link to={PATH.ROOT}>
                  <img
                    src={IMAGECONST.Logo}
                    alt="azillik-it-logo"
                    className="img-fluid"
                    draggable="false"
                    style={{ width: "135px" }}
                  />
                </Link>
              </div>

              <div>
                <ul className="azil-header-menu">
                <span
                  style={{ fontSize: "15px" }}
                  className={`azil-icon ${isNavOpen ? 'close-rotate' : 'close-rotate-toggle'}`}
                  onClick={handleSidemenu}
                >
                  <img
                    src={IMAGECONST.Menuicon}
                    alt="menu"
                    draggable="false"
                    style={{ width: "25px"}}
                  />
                </span>
                  <li className="azil-submenu-list px-3">
                    <p className="font-dark-grey azil-primary-hover cursor-pointer">
                      <span>Solutions</span>
                    </p>
                    <div className="menu-subs menu-subs-solution">
                      <div>
                        <ul className="azil-list-style-none p-0">
                          {solutiondata.map((solution, i) => (
                            <li
                              key={i}
                              className={`azil-megamenu-nav azil-megamenu-li-hover ${activeSolutionTab === solution.solution_heading ? 'active' : ''}`}
                              data-href={solution.solution_heading}
                              onClick={() => solutionTabChange(solution.solution_heading)}
                              onMouseEnter={() => solutionTabChange(solution.solution_heading)}
                            >
                              <div className="menu-subs-heading-img">
                                <img
                                  src={solution.heading_img}
                                  alt={solution.heading_imgalt}
                                  draggable="false" width={34}
                                />
                              </div>
                              <div className="menu-subs-heading"><span>{solution.solution_heading}</span></div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </li>

                  <li className="azil-submenu-list px-3">
                    <p className="font-dark-grey azil-primary-hover cursor-pointer">
                      <span>Technologies</span>
                    </p>
                    <div className="menu-subs menu-subs-technology">
                      <div className="azil-megamenu-navside azil-megamenu-tech-navside">
                        <ul className="azil-megamenu-tabs azil-list-style-none">
                          {technologydata.map((technology, i) => (
                            <li
                              key={i}
                              className={`azil-megamenu-nav azil-megamenu-li-hover ${activeTab === technology.techno_heading ? 'active azil-megamenu-li-active' : ''}`}
                              data-href={technology.techno_heading}
                              onClick={() => handleTabChange(technology.techno_heading)}
                              onMouseEnter={() => handleTabChange(technology.techno_heading)}
                            >
                              <div className="menu-subs-heading-img">
                                <img
                                  src={technology.heading_img}
                                  alt={technology.heading_imgalt}
                                  draggable="false" width={34}
                                />
                              </div>
                              <div className="menu-subs-heading"><span>{technology.techno_heading}</span></div>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="d-flex azil-megamenu-tabside azil-megamenu-tech-tabside">
                        {technologydata.map((technology, i) => (
                          <div
                            key={i}
                            id={technology.techno_heading}
                            className={`azil-megamenu-tabcontent ${
                              activeTab === technology.techno_heading ? 'active' : ''
                            }`}
                          >
                            <div className="d-flex flex-wrap">
                              {technology.techno_content.map((i, index) => (
                                <div key={index}>
                                  <Link to={i.technologypath} className="azil-header-submenu-list azil-header-tech-list azil-submenu-ripple" 
                                   onMouseEnter={handleWhiteHover} onMouseLeave={handleWhiteHoverEnd}>
                                    <div className="menu-subs-heading-img">
                                      <img src={i.arrowimg} alt={i.imgalt} width={20}/>
                                    </div>
                                    <span className="font-grey azil-sub-menu-title" >{i.technologytitle}</span>
                                  </Link>
                                </div>
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </li>

                  <li className="azil-submenu-list px-3">
                    <p className="font-dark-grey azil-primary-hover cursor-pointer">
                      <span>Services</span>
                    </p>
                    <div className="menu-subs menu-subs-service">
                      <div className="azil-megamenu-navside">
                        <ul className="azil-megamenu-tabs azil-list-style-none">
                          {servicedata.map((service, i) => (
                            <li
                              key={i}
                              className={`azil-megamenu-nav azil-megamenu-li-hover ${activeServiceTab === service.service_heading ? 'active azil-megamenu-li-active' : ''}`}
                              data-href={service.service_heading}
                              onClick={() => handleTabChange(service.service_heading)}
                              onMouseEnter={() => handleTabChange(service.service_heading)}
                            >
                              <div className="menu-subs-heading-img">
                                <img
                                  src={service.heading_img}
                                  alt={service.heading_imgalt}
                                  draggable="false" width={34}
                                />
                              </div>
                              <div className="menu-subs-heading"><span>{service.service_heading}</span></div>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="d-flex azil-megamenu-tabside azil-megamenu-service-tabside">
                        {servicedata.map((service, i) => (
                          <div
                            key={i}
                            id={service.service_heading}
                            className={`azil-megamenu-tabcontent ${
                              activeServiceTab === service.service_heading ? 'active' : ''
                            }`}
                          >
                            <div className="d-flex flex-wrap">
                              {service.service_content.map((i, index) => (
                                <Link to={i.servicepath} className="azil-header-submenu-list azil-header-tech-list azil-submenu-ripple" key={index}
                                  onMouseEnter={handleWhiteHover} onMouseLeave={handleWhiteHoverEnd}>
                                  <div className="menu-subs-heading-img">
                                    <img src={i.arrowimg} alt={i.imgalt}/>
                                  </div>
                                  <span className="font-grey azil-sub-menu-title" >{i.servicetitle}</span>
                                </Link>
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </li>

                  <li className="azil-submenu-list px-3">
                    <p className="font-dark-grey azil-primary-hover cursor-pointer">
                      <span>Company</span>
                    </p>
                    <div className="menu-subs menu-subs-company">
                      <div className="azil-megamenu-navside azil-megamenu-company-navside">
                        <ul className="azil-megamenu-tabs azil-list-style-none">
                          {companydata.map((company, i) => (
                            <li
                              key={i}
                              className={`azil-megamenu-nav azil-megamenu-li-hover ${activeCompanyTab === company.company_heading ? 'active azil-megamenu-li-active' : ''}`}
                              data-href={company.company_heading}
                              onClick={() => handleTabChange(company.company_heading)}
                              onMouseEnter={() => handleTabChange(company.company_heading)}
                            >
                              <div className="menu-subs-heading-img">
                                <img
                                  src={company.heading_img}
                                  alt={company.heading_imgalt}
                                  draggable="false" width={34}
                                />
                              </div>
                              <div className="menu-subs-heading"><span>{company.company_heading}</span></div>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="d-flex azil-megamenu-tabside azil-megamenu-company-tabside">
                        {companydata.map((company, i) => (
                          <div
                            key={i}
                            id={company.company_heading}
                            className={`azil-megamenu-tabcontent ${
                              activeCompanyTab === company.company_heading ? 'active' : ''
                            }`}
                          >
                            <div>
                            {company.company_content.map((i, index) => (
                              <Link to={i.companypath} className="azil-header-submenu-list azil-submenu-ripple" key={index}
                               onMouseEnter={handleWhiteHover} onMouseLeave={handleWhiteHoverEnd}>
                                <div className="azil-submenu-arrow">
                                  <img src={i.arrowimg} alt={i.imgalt}/>
                                </div>
                                <span className="font-grey azil-sub-menu-title" >{i.companytitle}</span>
                              </Link>
                            ))}
                              </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </li>

                  <li className="mx-3">
                    <Link className="font-dark-grey azil-primary-hover" to={PATH.PRODUCT}>
                      <p>Products</p>
                    </Link>
                  </li>

                  <li>
                    <button
                      className="azil-hero-button"
                      onClick={() => history.push(PATH.CONTACT_US)} onMouseEnter={handleHover} onMouseLeave={handleHoverEnd}
                    > 
                      <span>Contact Us</span>
                    </button>
                  </li>
                </ul>
              </div>
            </div>

            <div className={`azil-sidenav ${isNavOpen ? 'sidebar-open' : ''}`} ref={sideMenuRef}>
              <div>
                <span className="azil-close-button azil-sidebar-closebtn" onClick={handleSidemenu}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    fill="none"
                    viewBox="0 0 18 18"
                    draggable="false"
                    className={isNavOpen ? "close-rotate" : "close-rotate-toggle"}
                  >
                    <path
                      stroke="#0C2955"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                      d="M5.145 12.857l7.714-7.714M12.859 12.857L5.145 5.143"
                    ></path>
                  </svg>
                </span>

                <div className="col-12 mt-3">
                  <div
                    className={
                      toggle
                        ? "mb-3 active p-0"
                        : "mb-3 p-0"
                    }
                  >
                    <Accordion title="Solutions">
                      {solutiondata.map((solution, i) => (
                        <AccordionContainer key={i} title={solution.solution_heading} image={solution.heading_img}>
                          {solution.solution_content.map((i, index)=> (
                            <div key={index} className="d-flex azil-accordion-description-box">
                              <div>
                              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" 
                                style={{rotate: "-90deg"}} className="azil-transition"
                              >
                                <path d="M13 7L9.70707 10.1496C9.31818 10.5216 8.68182 10.5216 8.29293 10.1496L5 7" stroke="#6960eb" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>

                              </div>
                              <p className="azil-description mx-2 azil-transition">{i.solutiontitle}</p>
                            </div>
                          ))}
                        </AccordionContainer>
                      ))}
                    </Accordion>

                    <Accordion title="Technologies">
                      {technologydata.map((technology, i) => (
                        <AccordionContainer key={i} title={technology.techno_heading} image={technology.heading_img}>
                          {technology.techno_content.map((i, index)=> (
                            <div key={index} className="d-flex azil-accordion-description-box">
                              <div>
                              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" 
                                style={{rotate: "-90deg"}} className="azil-transition"
                              >
                                <path d="M13 7L9.70707 10.1496C9.31818 10.5216 8.68182 10.5216 8.29293 10.1496L5 7" stroke="#6960eb" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                              </div>
                              <p className="azil-description mx-2 azil-transition">
                                <Link to={i.technologypath} className="" >{i.technologytitle}</Link>
                              </p>
                            </div>
                          ))}
                        </AccordionContainer>
                      ))}
                    </Accordion>

                    <Accordion title="Services">
                      {servicedata.map((service, i) => (
                        <AccordionContainer key={i} title={service.service_heading} image={service.heading_img}>
                          {service.service_content.map((i, index)=> (
                            <div key={index} className="d-flex azil-accordion-description-box">
                              <div>
                              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" 
                                style={{rotate: "-90deg"}} className="azil-transition"
                              >
                                <path d="M13 7L9.70707 10.1496C9.31818 10.5216 8.68182 10.5216 8.29293 10.1496L5 7" stroke="#6960eb" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                              </div>
                              <p className="azil-description mx-2 azil-transition">
                                <Link to={i.servicepath} className="p-0" >{i.servicetitle}</Link>
                              </p>
                            </div>
                          ))}
                        </AccordionContainer>
                      ))}
                    </Accordion>
                    
                    <Accordion title="Company">
                      {companydata.map((company, i) => (
                        <AccordionContainer key={i} title={company.company_heading} image={company.heading_img}>
                          {company.company_content.map((i, index)=> (
                            <div key={index} className="d-flex azil-accordion-description-box">
                              <div>
                              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" 
                                style={{rotate: "-90deg"}} className="azil-transition"
                              >
                                <path d="M13 7L9.70707 10.1496C9.31818 10.5216 8.68182 10.5216 8.29293 10.1496L5 7" stroke="#6960eb" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                              </div>
                              <p className="azil-description mx-2 azil-transition">
                                <Link to={i.companypath} className="p-0" >{i.companytitle}</Link>
                              </p>
                            </div>
                          ))}
                        </AccordionContainer>
                      ))}
                    </Accordion>
                    <Link to={PATH.PRODUCT} className="azil-header-accordion-heading">
                      <span className="font-16 font-600 font-secondary">
                        Products
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>       
          </div>
        </header>
        <div className="azil-line"></div>
      </section>
    </>
  );
};

export default Header;