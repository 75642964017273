import React, { useEffect, useState } from "react";
import Footer from "../../Layout/Footer";
import Header from "../../Layout/Header";
import { constants as PATH } from "../../../components/constants/componentPath";
import { useHistory } from "react-router-dom";

import { Seo } from "../../../components/custom/Seo";
import { seoConstants as SEO_CONST } from "../../../components/constants/seoConst";
import { GetHirings } from "../../../services/ContactService";
import AOS from "aos";
import "aos/dist/aos.css";

const Hiring = () => {
  const history = useHistory();
  const [hiringData, setHiringData] = useState([]);
  const [search, setSearch] = useState("");
  const [limit] = useState(12);

  const getHirings = () => {
    let pagination = `?page=1&limit=${limit}`;
    if (search !== "") {
      pagination = `?page=1&limit=${limit}&search=${search}`;
    }
    GetHirings(pagination)
      .then((res) => {
        const data = res && res.data && res.data.data && res.data.data.data;
        setHiringData(data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getHirings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, search]);

  /* -------------------------------------------------------------------------- */
  /*                        search result with highlight                        */
  /* -------------------------------------------------------------------------- */
  const handleSearch = (e) => {
    setSearch(e.target.value);
  };
  const highlightMatch = (title) => {
    if (!search) return title;
    const parts = title.split(new RegExp(`(${search})`, 'gi'));
    return parts.map((part, index) =>
      part.toLowerCase() === search.toLowerCase() ? (
        <strong key={index} style={{color: "#6960eb"}}>{part}</strong>
      ) : (
        part
      )
    );
  };

  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);

  return (
    <React.Fragment>
      <Seo
        title={SEO_CONST.HIRING_TITLE}
        description={""}
        keyword={SEO_CONST.HIRING_TITLE}
      />
      <Header />
      <section className="azil-apply-hero-section azil-section-padding azil-bg-hero">
        <div className="azil-home-container" data-aos="fade-up">
          <div className="text-center">
            <div className="azil-primary-heading my-2">
              Open <span className="font-primary"> Positions</span>
            </div>
          </div>
          <div className="col-12 p-0">
            <div className="azil-job-searchbox">
              <div className="azil-input-group azil-job-searchbar my-2">
                <input
                  type="search"
                  placeholder="Enter other skills"
                  onChange={handleSearch}
                  value={search}
                  style={{background: "transparent"}}
                />
              </div>
              <div className="azil-searchbox">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  fill="none"
                  viewBox="0 0 23 22"
                  >
                  <path
                    className="azil-search-svgstroke"
                    stroke="#7A7A7A"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9.988 17a8 8 0 100-16 8 8 0 000 16zM21.99 21l-4.666-4.667"
                    style={{
                      stroke: search.trim() !== "" ? "#6960EB" : "#696969",
                    }}
                  ></path>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="azil-section-padding">
        <div className="azil-home-container">
          <div className="row">
            {hiringData &&
              hiringData.map((hiring, index) => {
                return (
                  <>
                    <div
                      className="col-lg-4 col-md-6 col-sm-12 col-12 my-2"
                      key={index}
                    >
                      <div
                        className="azil-hiring-box"
                        onClick={() =>
                          history.push({
                            pathname: PATH.JOB_DETAILS,
                            state: {
                              hiring: hiring,
                            },
                          })
                        }
                      >
                        <div className="d-flex align-items-center">
                          <div>
                            <img
                              src={hiring.hiring_image}
                              alt="hiring"
                              width={60}
                              draggable={false}
                              className="mr-4"
                            />
                          </div>
                          <div className="col-lg-8 col-md-8 col-sm-8 col-8 p-0">
                            <div className="font-black font-16 font-600 mb-2">
                              {highlightMatch(hiring.title)}
                            </div>
                            <div className="d-flex align-items-center justify-content-between flex-wrap">
                              <div className="azil-description">
                                <span className="font-dark-grey font-600">
                                  Exp: {" "}
                                </span>
                                {hiring.experience} Years
                              </div>
                              <div className="azil-description">
                                <span className="font-dark-grey font-600">
                                  Positions: {" "}
                                </span>
                                {hiring.positions}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
          </div>
        </div>
      </section>

      <Footer />
    </React.Fragment>
  );
};

export default Hiring;
