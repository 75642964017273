/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from "react";
import Footer from "../../Layout/Footer";
import Header from "../../Layout/Header";
import { imagesConst as IMAGECONST } from "../../../components/constants/imagesConst";
import Dropzone from "react-dropzone";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { errorConst as ERROR } from "../../../components/constants/errorConst";
import CustomInput from "../../../components/custom/CustomInput";
import { useHistory } from "react-router-dom";
import { constants as PATH } from "../../../components/constants/componentPath";
import { Card, Col, Progress, Row } from "reactstrap";
import "react-phone-input-2/lib/style.css";
import {
  ApplyInternship,
  GetTechnologies,
} from "../../../services/ContactService";
import { notificationMessageFunction } from "../../../components/constants/notificationConst";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Seo } from "../../../components/custom/Seo";
import { seoConstants as SEO_CONST } from "../../../components/constants/seoConst";
import AOS from "aos";
import "aos/dist/aos.css";
import CustomPhoneNumber from "../../../components/custom/CustomPhoneNumber/CustomPhoneNumber";

const CurrentOpening = () => {
  const [images, setImages] = useState([]);
  const [percentage, setPercentage] = useState(0);
  const [isError, setIsError] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const consultAzil = useRef();

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 9,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 9,
        },
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 8,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
        },
      },
    ],
  };

  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setImages(files);
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const removeFile = (file) => {
    const newFiles = [...images];
    newFiles.splice(file, 1);
    setImages(newFiles);
  };

  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const SubscribeEmailSchema = Yup.object().shape({
    subscribe_email: Yup.string()
      .trim()
      .email(ERROR.VALID_EMAIL)
      .required(ERROR.EMAIL),
  });

  const handleSubmitSubscribeEmail = (values, { resetForm }) => {
    // if (wpNumber === "" || wpNumber === null) {
    //   setIsError(true);
    // }
    resetForm();
  };

  const initialValues = {
    firstname: "",
    lastname: "",
    job_title: "",
    email: "",
    message: "",
  };

  const IntenshipSchema = Yup.object().shape({
    firstname: Yup.string().trim().required(ERROR.FIRST_NAME),
    lastname: Yup.string().trim().required(ERROR.LAST_NAME),
    email: Yup.string().trim().email(ERROR.VALID_EMAIL).required(ERROR.EMAIL),
    message: Yup.string().trim().required(ERROR.MESSAGE),
    job_title: Yup.string().trim().required(ERROR.JOB_TITLE),
  });

  const handleSubmit = (values, { resetForm }) => {
    let isError = false;
    // if (phoneNumber === "" || phoneNumber === null) {
    //   setPhoneError(true);
    //   isError = true;
    // } else {
    //   setPhoneError(false);
    //   isError = false;
    // }

    if (images.length === 0) {
      isError = true;
    }

    if (images.length > 1) {
      isError = true;
    }

    if (!isError) {
      const data = new FormData();
      data.append("firstname", values.firstname);
      data.append("lastname", values.lastname);
      data.append("phone_number", values.phone_number);
      data.append("email", values.email);
      data.append("current_job_title", values.job_title);
      data.append("message", values.message);
      data.append("internship_image", images[0]);

      ApplyInternship(data)
        .then((res) => {
          NotificationManager.success("Intership data added successfully");
          resetForm();
          setImages([]);
          window.location.reload(true);
        })
        .catch((err) => {
          const errorMessage =
            err && err.data && err.data.error && err.data.error.message;
          NotificationManager.error(notificationMessageFunction(errorMessage));
        });
    }
  };

  const [techData, setTechData] = useState([]);
  const getAllTechnologies = () => {
    let pagination = `?page=1`;
    GetTechnologies(pagination)
      .then((res) => {
        const data = res && res.data && res.data.data && res.data.data.data;
        setTechData(data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getAllTechnologies();
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);

  /* -------------------------------------------------------------------------- */
  /*                            Button ripple effect                            */
  /* -------------------------------------------------------------------------- */
  const handleHover = (e) => {
    const button = e.target;
    const rect = button.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    const rippleSpan = document.createElement('span');
    rippleSpan.classList.add('ripple-span');
    rippleSpan.style.top = `${y}px`;
    rippleSpan.style.left = `${x}px`;
    button.appendChild(rippleSpan);
  };

  const handleHoverEnd = (e) => {
    const button = e.target;
    const ripples = button.getElementsByClassName('ripple-span');
    while (ripples.length > 0) {
      button.removeChild(ripples[0]);
    }
  };

  const careergrowthdata = [
    {
      careerimage: IMAGECONST.Current_opening1,
      imgalt: "work",
      careerheading: "5 day a Week + A Fun-filled Half Last Saturday of Every Month"
    },
    {
      careerimage: IMAGECONST.Current_opening2,
      imgalt: "time",
      careerheading: "Flexible Timings & Work From Home Facility"
    },
    {
      careerimage: IMAGECONST.Current_opening3,
      imgalt: "celebration",
      careerheading: "Cultural Celebration"
    },
    {
      careerimage: IMAGECONST.Current_opening4,
      imgalt: "picnic",
      careerheading: "Picnic & Sports Meet"
    },
    {
      careerimage: IMAGECONST.Current_opening5,
      imgalt: "leave",
      careerheading: "Leave Encashment"
    },
    {
      careerimage: IMAGECONST.Current_opening6,
      imgalt: "awards",
      careerheading: "Monthly Performance Awards"
    },
    {
      careerimage: IMAGECONST.Current_opening7,
      imgalt: "environment",
      careerheading: "Friendly and Energetic Work Environment"
    },
    {
      careerimage: IMAGECONST.Current_opening8,
      imgalt: "events",
      careerheading: "Trainee Session and Monthly Events"
    },
    {
      careerimage: IMAGECONST.Current_opening9,
      imgalt: "work life",
      careerheading: "Work Life Balance"
    },
  ]

  return (
    <React.Fragment>
      <Seo
        title={SEO_CONST.CURRENT_OPENING_TITLE}
        description={""}
        keyword={SEO_CONST.CURRENT_OPENING_TITLE}
      />
      <Header />
      <section className="azil-opening-hero-section section-padding azil-bg-hero" >
        <div className="azil-home-container" data-aos="fade-up">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6 col-sm-12 col-12 mt-2 mb-3">
              <div className="azil-primary-heading my-2">
                Careers Grow with
                <span className="font-primary"> Azillik</span>
              </div>
              <div className="azil-description mt-2 mb-3 font-width-mainsection">
                Do you want to work from the comfort of your home anywhere in India? Or from one of our offices in Surat?
              </div>
              <div className="azil-description mt-2 mb-3">
                If your answer to any of the above questions is yes, don’t wait to connect with us!
              </div>
              <div className="azil-description mt-2 mb-3">
                Email:{" "}
                <a
                  href="mailto:life@azillik.com"
                  className="font-primary font-14"
                >
                  life@azillik.com
                </a>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-12 my-2">
              <img
                src={IMAGECONST.Career}
                alt="career" className="img-fluid"
                style={{float: "right"}}
                draggable="false"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Your Career */}
      <section className="azil-section-padding">
        <div className="azil-home-container" data-aos="fade-up">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6 col-sm-12 col-12 my-2">
              <img
                src={IMAGECONST.Career1}
                alt="career"
                className="img-fluid"
                draggable="false"
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-12 my-2">
              <div className="azil-primary-heading mt-2 mb-3">
                It’s Time to Reshape
                <span className="font-primary"> Your Career</span>
              </div>
              <div className="azil-description line-height-30 mt-2 mb-3">
                Do you want to know the secret of our success? It’s the growth
                of our employees.
              </div>
              <div className="azil-description line-height-30 mt-2 mb-3">
                At Azillik, we value passion and determination. It’s not just
                about who you are today, but what you can be! So we don’t just
                focus on an individual’s skill and talent. Their potential and
                willingness to learn also matters.
              </div>
              <div className="azil-description line-height-30 mt-2 mb-3">
                Our team-building activities concentrate on bringing out the
                best in every member by further developing their skills.
              </div>
              <div className="azil-description line-height-30 mt-2 mb-3">
                Join our force of 350+ talented engineers
              </div>
              <div className="azil-description mt-2 mb-3">
                <a
                  className="font-primary azil-link-text"
                  onClick={() => consultAzil.current.scrollIntoView({ behavior: "smooth" })}
                >
                  <span style={{cursor: "pointer"}}>Submit the form</span>{" "}
                </a>{" "} if you do not find a suitable opening
              </div>
              
              <button
                className="azil-hero-button mt-2 mb-3"
                onClick={() => history.push(PATH.HIRING)} onMouseEnter={handleHover} onMouseLeave={handleHoverEnd}
              >
                Open Positions
              </button>
            </div>
          </div>
          <div className="text-center my-2">
            <div className="azil-small-heading mt-3"> Technologies We <span className="font-primary"> Work On</span> </div>
            <div className="mt-3">
              <Slider {...settings}>
                {techData.map((tech, i) => (
                  <div key={i} className="azil-tech-slider">
                    <img src={tech.image} alt="technology" width={50} height={100} className="cursor-pointer" draggable={false}/>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </section>

      {/* Reward” */}
      <section className="azil-section-padding azil-bg-hero">
        <div className="azil-home-container" data-aos="fade-up">
          <div className="text-center">
            <div className="azil-mid-heading">
              Subscribe to
              <span className="font-primary"> Get Notified </span>
              for Future Job Openings Via Email and WhatsApp.
            </div>

            <div className="azil-form-bg">
            <Formik
              initialValues={{ subscribe_email: "" }}
              validationSchema={SubscribeEmailSchema}
              onSubmit={handleSubmitSubscribeEmail}
            >
              {({ errors, touched, values, handleChange }) => (
                <Form>
                  <div className="row align-items center">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 my-2">
                      <div className="align-items-center my-3">
                        <div className="row align-items-center justify-content-center">
                          <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="azil-input-group azil-input-job-opening-group my-2">
                              <CustomInput
                                type="email"
                                placeholder="Enter Your Email Address"
                                name="subscribe_email"
                                values={values}
                                errors={errors}
                                touched={touched}
                                className="azil-open-input"
                                handleChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="azil-input-group azil-phone-input-group azil-input-job-opening-group my-2">
                              <CustomPhoneNumber
                                type="number"
                                value={values}
                                placeholder="Phone number*"
                                name="phone_number"
                                handleChange={handleChange}
                                touched={touched}
                                errors={errors}
                              />
                            </div>
                          </div>
                          <div className="azil-input-job-opening-group" style={{padding: "0px 15px"}}>
                            <button className="azil-hero-button azil-current-opening-notify-button" onMouseEnter={handleHover} onMouseLeave={handleHoverEnd}>
                              Subscribe
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
            </div>
          </div>
        </div>

          <div
            className="azil-home-container mt-5"
            data-aos="fade-up"
            ref={consultAzil}
          >
            <div className="text-center">
              <div className="azil-mid-heading my-2">
                No Suitable <span className="font-primary"> Opening</span> or Want to Apply for an
                <span className="font-primary"> Internship?</span> at Azillik
              </div>
              <div className="azil-description my-2 font-width">
                We provide paid internships of minimum 3 to 6 months. You will get best talent and experience from a unique work culture.
                Apart from that, you may also get to learn and work with our clients in some cases.
              </div>
            </div>
            <div className="azil-form-bg">
              <Formik
                initialValues={initialValues}
                validationSchema={IntenshipSchema}
                onSubmit={handleSubmit}
              >
                {({ errors, touched, values, handleChange }) => (
                  <Form>
                    <div className="row my-2">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                        <div className="azil-input-group my-1">
                          <CustomInput
                            type="text"
                            values={values}
                            placeholder="First Name*"
                            name="firstname"
                            handleChange={handleChange}
                            touched={touched}
                            errors={errors}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                        <div className="azil-input-group my-1">
                          <CustomInput
                            type="text"
                            values={values}
                            placeholder="Last Name*"
                            name="lastname"
                            handleChange={handleChange}
                            touched={touched}
                            errors={errors}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 my-1">
                        <div className="azil-input-group azil-phone-input-group my-2">
                          <CustomPhoneNumber
                            type="number"
                            value={values}
                            placeholder="Phone number*"
                            name="phone_number"
                            handleChange={handleChange}
                            touched={touched}
                            errors={errors}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 my-1">
                        <div className="azil-input-group my-2">
                          <CustomInput
                            type="email"
                            placeholder="Email Address*"
                            name="email"
                            values={values}
                            errors={errors}
                            touched={touched}
                            handleChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    
                    <div className="azil-input-group my-2">
                      <CustomInput
                        type="text"
                        placeholder="Current Job Title"
                        name="job_title"
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                      />
                    </div>
                    <div className="message my-2">
                      <div className="font-14 font-grey">
                        Cover Letter: (Tell us what is the best thing, that you
                        feel, about what you do)
                      </div>
                      <div className="azil-input-group my-2">
                        <textarea
                          type="text"
                          style={{ resize: "none" }}
                          placeholder="Message*"
                          name="message"
                          className={errors.message && "border-danger"}
                          value={values.message}
                          onChange={handleChange}
                        ></textarea>
                        {errors.message && touched.message && (
                          <div
                            style={{ fontSize: 14 }}
                            className="text-left text-danger"
                          >
                            {errors.message}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="upload my-2 upload-item">
                      <Dropzone
                        onDrop={(acceptedFiles) => {
                          handleAcceptedFiles(acceptedFiles);
                          const formData = new FormData();
                          for (const file of acceptedFiles)
                            formData.append("file", file);
                          const xhr = new XMLHttpRequest();
                          xhr.upload.onprogress = (event) => {
                            const percentage = parseInt(
                              (event.loaded / event.total) * 100
                            );
                            setPercentage(percentage);
                          };
                          xhr.onreadystatechange = () => {
                            if (xhr.readyState !== 4) return;
                            if (xhr.status !== 200) {
                              console.log("error");
                            }
                          };
                          xhr.open("POST", "https://httpbin.org/post", true);
                          xhr.send(formData);
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone dz-clickable">
                            <div
                              className="dz-message needsclick text-center"
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <div className="mb-3">
                                <img
                                  src={IMAGECONST.Dropzone}
                                  alt="dropzone"
                                  style={{ height: 100 }}
                                  draggable="false"
                                />
                              </div>
                              <h4 className="font-grey font-14">Drop files here or click to upload.</h4>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    </div>
                    <div className="dropzone-previews mt-3 my-3" id="file-previews">
                      {images.map((f, i) => {
                        return (
                          <Card
                            className="mt-1 mb-0 shadow-none dz-processing dz-image-preview dz-success dz-complete border-radius-12 boxshadow-full border-none"
                            key={i + "-file"}
                          >
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col className="col-auto">
                                  <img
                                    data-dz-thumbnail=""
                                    height="80"
                                    className="avatar-sm rounded bg-light"
                                    alt={f.name}
                                    src={f.preview}
                                    draggable="false"
                                  />
                                </Col>
                                <Col>
                                  <p className="font-dark-grey font-600 font-14">
                                    {f.name}
                                  </p>
                                  <p className="mb-0 font-grey font-12">{f.formattedSize}</p>
                                </Col>
                                <Col>
                                  <Progress color="primary" value={percentage} />
                                </Col>
                                <Col>
                                  <div className="azil-delete-button-box">
                                    <img
                                      src={IMAGECONST.Deleteicon}
                                      alt="delete"
                                      onClick={() => removeFile(i)}
                                      className="cursor-pointer"
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        );
                      })}
                    </div>
                    {images.length === 0 && (
                      <div
                        style={{ fontSize: 14 }}
                        className="text-left mt-1 text-danger"
                      >
                        {"Please upload resume"}
                      </div>
                    )}
                    {images.length > 1 && (
                      <div
                        style={{ fontSize: 14 }}
                        className="text-left mt-1 text-danger"
                      >
                        {"Please upload only one resume"}
                      </div>
                    )}

                    <div
                      className="g-recaptcha"
                      data-sitekey="6LfK8wsjAAAAAN3xftPQZZid0zh1gGbGxm3_DLIS"
                    ></div>
                    <div className="azil-contact-btn my-3">
                      <button className="azil-hero-button align-items-center">
                        Submit
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
      </section>

      {/* Career Growth */}
      <section className="azil-section-padding azil-bg-hero">
        <div className="azil-home-container" data-aos="zoom-in">
          <div className="text-center">
            <div className="azil-mid-heading mt-2 mb-3">
              What We Offer For Your Complete
              <span className="font-primary"> Career Growth</span>
            </div>
            <div className="azil-description mt-2 mb-3 font-width">
              Whether you are a fresher or an experienced individual, Azillik is
              the most recommended destination to shape your career the way you want.
            </div>
          </div>
          <div className="row">
            {careergrowthdata.map((careergrowth, i) => (
              <div className="col-lg-4 col-md-6 col-sm-12 col-12 mt-3 mb-2">
                <div>
                  <div className="azil-current-opening-box azil-box-border-hover">
                    <div className="azil-offer-img">
                      <img
                        src={careergrowth.careerimage}
                        alt={careergrowth.imgalt}
                        className="img-fluid"
                        draggable="false"
                      />
                    </div>
                    <div className="w-75 azil-description">
                      {careergrowth.careerheading}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <NotificationContainer />
      <Footer />
    </React.Fragment>
  );
};

export default CurrentOpening;