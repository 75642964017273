import api from "../api/api";
import { constants as API_CONST } from "../api/url";

export const ContactUs = (payData) => {
  return new Promise((resolve, reject) => {
    return api
      .post(API_CONST.CONTACT_US, payData)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const ApplyJobAPI = (payData) => {
  return new Promise((resolve, reject) => {
    return api
      .post(API_CONST.APPLY_JOB, payData)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const ApplyInternship = (payData) => {
  return new Promise((resolve, reject) => {
    return api
      .post(API_CONST.APPLY_INTERNSHIP, payData)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const WeWorkOnAPI = (payData) => {
  return new Promise((resolve, reject) => {
    return api
      .post(API_CONST.WE_WORK, payData)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetProducts = (pagination) => {
  return new Promise((resolve, reject) => {
    return api
      .get(API_CONST.PRODUCT + pagination)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetPillars = (pagination) => {
  return new Promise((resolve, reject) => {
    return api
      .get(API_CONST.PILLAR + pagination)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetHomePageData = (pagination) => {
  return new Promise((resolve, reject) => {
    return api
      .get(API_CONST.HOME_PAGE + pagination)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetFaqs = (pagination) => {
  return new Promise((resolve, reject) => {
    return api
      .get(API_CONST.FAQ + pagination)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetProductGrowths = (pagination) => {
  return new Promise((resolve, reject) => {
    return api
      .get(API_CONST.PRODUCT_GROWTH + pagination)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetConfig = () => {
  return new Promise((resolve, reject) => {
    return api
      .get(API_CONST.CONFIG)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetPortfolios = (pagination) => {
  return new Promise((resolve, reject) => {
    return api
      .get(API_CONST.PORTFOLIO + pagination)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetTechnologies = (pagination) => {
  return new Promise((resolve, reject) => {
    return api
      .get(API_CONST.TECHNOLOGY + pagination)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetBlogs = (pagination) => {
  return new Promise((resolve, reject) => {
    return api
      .get(API_CONST.BLOG + pagination)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetHirings = (pagination) => {
  return new Promise((resolve, reject) => {
    return api
      .get(API_CONST.HIRING + pagination)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetCustomSoftwares = (pagination) => {
  return new Promise((resolve, reject) => {
    return api
      .get(API_CONST.CUSTOM_SOFTWARE + pagination)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetLegals = (pagination) => {
  return new Promise((resolve, reject) => {
    return api
      .get(API_CONST.LEGAL + pagination)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
