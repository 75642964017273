import React from "react";
import { Link } from "react-router-dom";
import { imagesConst as IMAGE } from "../../components/constants/imagesConst";
import { constants as PATH } from "../../components/constants/componentPath";

import { Seo } from "../../components/custom/Seo";
import { seoConstants as SEO_CONST } from "../../components/constants/seoConst";

const PageNotFound = () => {
  return (
    <React.Fragment>
      <Seo
        title={SEO_CONST.ERROR_TITLE}
        description={""}
        keyword={SEO_CONST.ERROR_TITLE}
      />
      <section className="azil-404-section-2 azil-section-padding">
        <div className="azil-custom-container">
          <div className="text-center">
            <img
              src={IMAGE.IMG_404}
              alt=""
              className="img-fluid w-50"
              draggable="false"
            />
            <div className="azil-primary-content azil-section-padding azil-text-normal pb-0">
              <div className="azil-section-title  my-4">
                <b> 404 Not Found</b>
              </div>
              We can't find the page you're looking for.
              <br />
              <Link to={PATH.PRODUCT} className="azil-text-purple">
                Browse our items{" "}
              </Link>
              or head back to{" "}
              <Link to={PATH.ROOT} className="azil-text-purple">
                IT Services.
              </Link>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default PageNotFound;
