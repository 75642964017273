import React, { useEffect, useState, useRef } from "react";
import { Collapse } from "reactstrap";
import { Link } from "react-router-dom";
import Footer from "../../Layout/Footer";
import Header from "../../Layout/Header";
import CustomContactUs from "../../../components/custom/CustomContactUs";
import { imagesConst as IMAGECONST } from "../../../components/constants/imagesConst";
import { GetFaqs } from "../../../services/ContactService";

import { Seo } from "../../../components/custom/Seo";
import { seoConstants as SEO_CONST } from "../../../components/constants/seoConst";

const FAQ = () => {
  const consultAzil = useRef();
  const [faqData, setFaqData] = useState([]);
  const [activeFaq, setActiveFaq] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getFaqs = () => {
    let pagination = `?page=1`;
    GetFaqs(pagination)
      .then((res) => {
        const data = res && res.data && res.data.data && res.data.data.data;
        setFaqData(data);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    getFaqs();

    document.body.addEventListener("click", handleBodyClick);
    return () => {
      document.body.removeEventListener("click", handleBodyClick);
    };
  }, []);

  const handleBodyClick = (event) => {
    if (!event.target.closest(".azil-faq-list")) {
      setActiveFaq(null);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                            Button ripple effect                            */
  /* -------------------------------------------------------------------------- */
  const handleHover = (e) => {
    const button = e.target;
    const rect = button.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    const rippleSpan = document.createElement('span');
    rippleSpan.classList.add('ripple-span');
    rippleSpan.style.top = `${y}px`;
    rippleSpan.style.left = `${x}px`;
    button.appendChild(rippleSpan);
  };

  const handleHoverEnd = (e) => {
    const button = e.target;
    const ripples = button.getElementsByClassName('ripple-span');
    while (ripples.length > 0) {
      button.removeChild(ripples[0]);
    }
  };

  return (
    <React.Fragment>
      <Seo
        title={SEO_CONST.FAQ_TITLE}
        description={""}
        keyword={SEO_CONST.FAQ_TITLE}
      />
      <Header />
      <section className="azil-faq-hero-section azil-section-padding azil-bg-hero">
        <div className="azil-home-container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6 col-sm-12 col-12 my-2">
              <div className="azil-primary-heading my-2">
                Frequently Asked
                <span className="font-primary"> Questions</span>
              </div>
              <div className="azil-description mt-4">
                Get to know us better
              </div>
              <button
                className="azil-hero-button my-3"
                onClick={() =>
                  consultAzil.current.scrollIntoView({ behavior: "smooth" })
                } onMouseEnter={handleHover} onMouseLeave={handleHoverEnd}
              >
                Consult Azillik
              </button>
            </div>
            <div className="col-lg-5 col-md-6 col-sm-12 col-12 my-2 text-right">
              <img
                src={IMAGECONST.Faqs}
                alt="baner"
                className="img-fluid"
                draggable="false" width={350} height={350}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="azil-section-padding">
        <div className="azil-home-container">
          <ul className="azil-faq-list">
            {faqData &&
              faqData.map((faq, index) => {
                return (
                  <>
                    <li
                      className={activeFaq === faq.faq_id ? "azil-the-active" : ""}
                      key={index}
                      style={{textTransform: "capitalize", cursor: "pointer"}}
                      onClick={() => {
                        setActiveFaq((prevActiveFaq) =>
                          prevActiveFaq === faq.faq_id ? null : faq.faq_id
                        );
                      }}
                    >
                      <Link
                        to="#"
                        className="font-secondary cursor-pointer"
                      >
                        <h4 className="azil-faq-heading">
                          {faq && faq.question}
                        </h4>
                      </Link>
                      <Collapse isOpen={activeFaq === faq.faq_id}>
                        <p className="azil-read azil-faq-text pt-3 font-grey">
                          {faq && faq.answer}
                        </p>
                      </Collapse>
                    </li>
                  </>
                );
              })}
          </ul>
        </div>
      </section>
      <section
        className="azil-section-padding azil-bg-hero"
        ref={consultAzil}
      >
        <div className="azil-home-container">
          <CustomContactUs />
        </div>
      </section>
      <Footer />
    </React.Fragment>
  );
};

export default FAQ;
