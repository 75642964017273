export const notificationMessageFunction = (errorMessage) => {
  let notificationMessage = "Something went wrong";

  //eslint-disable-next-line
  notificationsArray.filter((notification) => {
    if (notification.code === errorMessage) {
      notificationMessage = notification.message;
      return notificationMessage;
    }
  });
  return notificationMessage;
};

const notificationsArray = [
  // ? Auth Notifications
  { code: "err_1", message: "First name is required" },
  { code: "err_2", message: "Last name is required" },
  { code: "err_3", message: "Please provide a valid secret" },
  { code: "err_4", message: "Phone Number is required" },
  { code: "err_5", message: "Country is required" },
  { code: "err_6", message: "Country code is required" },
  { code: "err_7", message: "Invalid email address" },
  {
    code: "err_8",
    message: "Password must be at least 8 characters in length",
  },
  { code: "err_9", message: "Passwords don't match" },
  { code: "err_10", message: "This email is already registered" },
  { code: "err_11", message: "User does not belong to any account" },
  {
    code: "err_12",
    message:
      "Your account E-mail address is not verified. We have send new verifiaction link to your email address. Please check mail box and verify your email",
  },
  { code: "err_13", message: "Invalid email or password" },
  { code: "err_14", message: "Your account is deleted" },
  { code: "err_15", message: "Your account is deactivated. Please Contact us" },
  { code: "err_16", message: "Please login with google account" },
  { code: "err_17", message: "Please login with facebook account" },
  { code: "err_18", message: "Password reset token is not valid" },
  { code: "err_19", message: "Old password does not match" },
  { code: "err_20", message: "Email already exists in pabbly" },

  //? Contact us api
  { code: "err_31", message: "Please enter whatsapp number" },
  { code: "err_32", message: "Please enter message" },
  { code: "err_33", message: "Please enter gender" },
  { code: "err_34", message: "Please enter experience year" },
  { code: "err_35", message: "Please enter age" },
  { code: "err_36", message: "Please enter date of birth" },
  { code: "err_37", message: "Please enter availability day" },
  { code: "err_38", message: "Please enter location" },
  { code: "err_39", message: "Please upload your resume" },
  { code: "err_40", message: "Please enter current job title" },
  { code: "err_41", message: "Please attach your project documentation" },
  { code: "err_42", message: "Please enter skill" },
  { code: "err_43", message: "Please enter hiring needs" },
  { code: "err_44", message: "Please enter work type" },

  { code: "err_500", message: "Something went wrong" },
];
