/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useEffect } from "react";
import { imagesConst as IMAGECONST } from "../../../components/constants/imagesConst";
import {
  TabContent,
  TabPane,
  Collapse,
  NavLink,
  NavItem,
  Nav,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import CustomBlog from "../../../components/custom/customBlog";
import { constants as PATH } from "../../../components/constants/componentPath";
import CustomContactUs from "../../../components/custom/CustomContactUs";
import Slider from "react-slick";
import logo from "../../../images/logo.svg";
import {
  GetBlogs,
  GetFaqs,
  GetHomePageData,
} from "../../../services/ContactService";
import AOS from "aos";
import "aos/dist/aos.css";
import "../../../css/style.css";
import { Modal } from "reactstrap";
import Typed from 'typed.js';
import {GetHirings} from "../../../services/ContactService"


const ImageGallery = ({ rotationPeriod = 5000 }) => {
  
  const images = [
  ];
  const options = ['Color', 'Gradient', 'Discovery',];
  const divinedata = [
    {
      data_img: IMAGECONST.Divine_colorbanner,
      data_icon: IMAGECONST.Coloricon,
      data_heading: "Color Swatch",
      data_tag: "New",
      data_path: "https://www.colors.azillik.com/",
      data_title: "Discover vibrant color palettes with Azillik! Unleash creativity with features like the Color Palette Generator and Image-to-Color Palette. Explore trendy swatches, master Color Wheels, and identify Shades effortlessly. Elevate your design game with user-friendly tools for stunning projects. Dive into endless possibilities of color inspirations!🎨✨"
    },
    {
      data_img: IMAGECONST.Divine_gradientbanner,
      data_icon: IMAGECONST.Gradienticon,
      data_heading: "Gradient Swatch",
      data_path: "https://www.gradient.azillik.com/",
      data_tag: "Beta",
      data_title: "Discover dynamic color gradients with Azillik! Unleash creativity using features like the Gradient Generator and Image-to-Gradient Converter. Explore trendy blends, master Gradient Wheels, and effortlessly blend hues. Elevate your design with user-friendly tools for captivating projects. Dive into limitless gradient inspirations!🌈✨"
    },
    {
      data_img: IMAGECONST.Divine_discoverybanner,
      data_icon: IMAGECONST.Discoveryicon,
      data_heading: "Discovery",
      data_tag: "Comming Soon",
      data_title: "Explore Azillik Discovery, a divine creative hub where imagination meets reality! Dive into our diverse collection of digital files, including Illustrations, UI Designs, and more. Elevate projects instantly with ready-to-use assets. Unleash creativity, captivate your audience effortlessly, and turn your vision into stunning reality!🚀✨"
    },
  ]
  
  const [activeOption, setActiveOption] = useState(0);
  const [lastTabChange, setLastTabChange] = useState(Date.now());
  const imageLoadTimes = useRef(Array(images.length).fill(null));

  useEffect(() => {
    const interval = setInterval(() => {
      const nextOption = (activeOption + 1) % options.length;
      setActiveOption(nextOption);
      setLastTabChange(Date.now());
      imageLoadTimes.current[nextOption] = performance.now();
    }, rotationPeriod);

    return () => clearInterval(interval);
  }, [activeOption, options.length, rotationPeriod]);

  useEffect(() => {
  }, [activeOption, lastTabChange]);

  const handleOptionClick = (index) => {
    setActiveOption(index);
    setLastTabChange(Date.now());
    imageLoadTimes.current[index] = performance.now();
  };

  return (
    <div>
      <div className="azil-divine-full">
      <div className="row">
        <div className="col-lg-6 col-md-6">
          <ul className="azil-rotate-list">
            {divinedata.map((divine, index) => (
              <li
                key={index}
                className={`cursor-pointer position-relative ${index === activeOption ? 'active azil-image-progress-bar azil-the-active' : ''}`}
                onClick={() => handleOptionClick(index)}
              >
                <div className="d-flex align-items-center">
                  <img src={divine.data_icon} alt={divine.data_heading} draggable={false}/>
                  <h4 className="azil-rotate-heading">{divine.data_heading}</h4>
                  <div className="azil-divine-tag" style={{display: index === activeOption ? "block" : "none"}}><span>{divine.data_tag}</span></div>
                </div>
                <Collapse isOpen={index === activeOption}>
                  <p className="azil-rotate-text mt-3 mb-3 text-justify" style={{paddingRight: "40px"}}>{divine.data_title}</p>
                  <div className="mt-2 azil-divine-visit-box">
                    <div className="azil-divine-visit-box-detail">
                      <a href={divine.data_path}><span className="azil-description">Visit now</span></a>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        fill="none"
                        viewBox="0 0 18 18" className="mx-2"
                      >
                        <path
                          stroke="#696969"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.75"
                          d="M7.355 2.781h4.947V7.74M12.303 2.781L5.699 9.385M2 14.078a22.107 22.107 0 0014 0"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </Collapse>
              </li>
            ))}
          </ul>
        </div>
        <div className="col-lg-6 col-md-6 align-items-center d-flex">
          {divinedata.map((divine, index) => (
            <div key={index} style={{ display: index === activeOption ? 'block' : 'none'}}>
              <img src={divine.data_img} alt={options[index]} className="azil-rotate-image" draggable={false}/>
            </div>
          ))}
        </div>
      </div>
      </div>

      <div className="azil-divine-responsive">
      <div className="row ">
        <div className="col-lg-6 col-md-6">
          <ul className="azil-rotate-list">
            {divinedata.map((divine, index) => (
              <li
                key={index}
                className={`cursor-pointer position-relative ${index === activeOption ? 'active azil-image-progress-bar azil-the-active' : ''}`}
                onClick={() => handleOptionClick(index)}
              >
                <div className="d-flex align-items-center">
                  <img src={divine.data_icon} alt={divine.data_heading} draggable={false}/>
                  <h4 className="azil-rotate-heading">{divine.data_heading}</h4>
                  <div className="azil-divine-tag" style={{display: index === activeOption ? "block" : "none"}}><span>{divine.data_tag}</span></div>
                </div>
                <Collapse isOpen={index === activeOption}>
                  <p className="azil-rotate-text mt-3">{divine.data_title}</p>
                  <div key={index} style={{ display: index === activeOption ? 'block' : 'none'}}>
                    <img src={divine.data_img} alt={options[index]} className="azil-rotate-image my-2" draggable={false}/>
                  </div>
                  <div className="mt-2">
                    <div className="azil-divine-visit-box-detail">
                      <a href={divine.data_path}><span className="azil-description">Visit now</span></a>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        fill="none"
                        viewBox="0 0 18 18" className="mx-2"
                      >
                        <path
                          stroke="#696969"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.75"
                          d="M7.355 2.781h4.947V7.74M12.303 2.781L5.699 9.385M2 14.078a22.107 22.107 0 0014 0"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </Collapse>
              </li>
            ))}
          </ul>
        </div>
      </div>
      </div>
    </div>
  );
};

const Index = () => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    speed: 500,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const [activeTab, setActiveTab] = useState("1");
  const [techActiveTab, setTechActiveTab] = useState("1");
  const [homePageData, setHomePageData] = useState([]);
  const [faqData, setFaqData] = useState([]);
  const [activeFaq, setActiveFaq] = useState();
  const [blogData, setBlogData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hiringData, setHiringData] = useState([]);
  const [search, setSearch] = useState("");
  const [limit] = useState(6);


  const history = useHistory();
  const consultAzil = useRef();

  const getHirings = () => {
    let pagination = `?page=1&limit=${limit}`;
    if (search !== "") {
      pagination = `?page=1&limit=${limit}&search=${search}`;
    }
    GetHirings(pagination)
      .then((res) => {
        const data = res && res.data && res.data.data && res.data.data.data;
        setHiringData(data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getHirings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, search]);

  const getHomePage = () => {
    let pagination = `?page=1`;
    GetHomePageData(pagination)
      .then((res) => {
        const data = res && res.data && res.data.data && res.data.data.data;
        setHomePageData(data);
      })
      .catch((err) => {});
  };

  const getFaqs = () => {
    let pagination = `?page=1&limit=5`;
    GetFaqs(pagination)
      .then((res) => {
        const data = res && res.data && res.data.data && res.data.data.data;
        setFaqData(data);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    getFaqs();

    document.body.addEventListener("click", handleBodyClick);
    return () => {
      document.body.removeEventListener("click", handleBodyClick);
    };
  }, []);

  const handleBodyClick = (event) => {
    if (!event.target.closest(".azil-faq-list")) {
      setActiveFaq(null);
    }
  };
  
  const getAllBlogs = () => {
    let pagination = `?page=1&limit=4 `;
    GetBlogs(pagination)
      .then((res) => {
        const data = res && res.data && res.data.data && res.data.data.data;
        setBlogData(data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getHomePage();
    getFaqs();
    getAllBlogs();
  }, []);

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  const techtoggle = (tab) => {
    if (techActiveTab !== tab) {
      setTechActiveTab(tab);
    }
  };

  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);


  useEffect(() => {
    setIsModalOpen(prevIsModalOpen => !prevIsModalOpen);
  }, []);  

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };


  const oursolutiondata = [
    {
      oursolution_img: IMAGECONST.Ar,
      imgalt: "ar-vr",
      oursolution_path: PATH.AR_VR,
      oursolution_heading: "AR & VR"
    },
    {
      oursolution_img: IMAGECONST.Communication,
      imgalt: "Communication",
      oursolution_path: PATH.SOCIAL,
      oursolution_heading: "Communication"
    },
    {
      oursolution_img: IMAGECONST.Education,
      imgalt: "Education",
      oursolution_path: PATH.EDUCATION,
      oursolution_heading: "Education"
    },
    {
      oursolution_img: IMAGECONST.Financial,
      imgalt: "Financial",
      oursolution_path: PATH.FINANCIAL,
      oursolution_heading: "Financial services"
    },
    {
      oursolution_img: IMAGECONST.Health,
      imgalt: "Healthcare",
      oursolution_path: PATH.HEALTHCARE,
      oursolution_heading: "healthcare"
    },
    {
      oursolution_img: IMAGECONST.Hospitality,
      imgalt: "hospitality",
      oursolution_path: PATH.NEWS,
      oursolution_heading: "hospitality"
    },
    {
      oursolution_img: IMAGECONST.IOT,
      imgalt: "IOT",
      oursolution_path: PATH.IOT,
      oursolution_heading: "iot"
    },
    {
      oursolution_img: IMAGECONST.Manufacturing,
      imgalt: "manufacturing",
      oursolution_path: PATH.MULTI_VENDOR,
      oursolution_heading: "manufacturing"
    },
    {
      oursolution_img: IMAGECONST.Media_Entertainment,
      imgalt: "media",
      oursolution_path: PATH.LIVE_STREAM,
      oursolution_heading: "media & entertainment"
    },
    {
      oursolution_img: IMAGECONST.Consumer,
      imgalt: "consumer",
      oursolution_path: PATH.ECOMMERCE_APP,
      oursolution_heading: "consumer-retail services"
    },
    {
      oursolution_img: IMAGECONST.Oil,
      imgalt: "oil & gas",
      oursolution_path: PATH.OIL_GAS,
      oursolution_heading: "oil & gas"
    },
    {
      oursolution_img: IMAGECONST.Utilities,
      imgalt: "full stack",
      oursolution_path: PATH.FULL_STACK,
      oursolution_heading: "full stack"
    },
  ]
  const ourvisiondata = [
    {
      ourvision_img: IMAGECONST.Ideas,
      imgalt: "ideas",
      ourvision_heading: "Ideas",
      ourvision_description: "Revolutionize your business with transformative ideas tailored to your unique needs. Explore how our innovative solutions drive growth and efficiency."
    },
    {
      ourvision_img: IMAGECONST.Enterprise_solution,
      imgalt: "enterprise",
      ourvision_heading: "Enterprise Solutions",
      ourvision_description: "Customized solutions aligned with client needs for optimal functionality and performance. Elevate your business with tailored enterprise solutions."
    },
    {
      ourvision_img: IMAGECONST.Synergy,
      imgalt: "synergy",
      ourvision_heading: "Synergy",
      ourvision_description: "Transforming enterprise software companies for sustainable business expansion and success. Partner with us for innovative IT solutions."
    },
    {
      ourvision_img: IMAGECONST.Building,
      imgalt: "building",
      ourvision_heading: "Building Trusted Partners",
      ourvision_description: "Forge strong client partnerships for transparent collaboration and superior outcomes. We prioritize close communication for mutual success."
    },
    {
      ourvision_img: IMAGECONST.Nimble,
      imgalt: "nimble",
      ourvision_heading: "Nimble Application",
      ourvision_description: "Our agile software development methodology ensures seamless integration and performance, making us your go-to for nimble solutions."
    },
    {
      ourvision_img: IMAGECONST.Empower,
      imgalt: "empower",
      ourvision_heading: "Empower Teams",
      ourvision_description: "Empower your team with a creative environment fostering innovative design. Elevate your IT projects with fresh ideas and forward-thinking solutions."
    }
  ]
  const toptechdata = [
    {
      toptech_tab: "1",
      toptech_content: [
        {toptech_image: IMAGECONST.Next, toptech_heading: "Next JS",},
        {toptech_image: IMAGECONST.React, toptech_heading: "React JS"},
        {toptech_image: IMAGECONST.Angular, toptech_heading: "Angular JS"},
        {toptech_image: IMAGECONST.Vue, toptech_heading: "Vue JS"},
        {toptech_image: IMAGECONST.Nuxt, toptech_heading: "Nuxt JS"},
        {toptech_image: IMAGECONST.Vite, toptech_heading: "Vite JS"},
        {toptech_image: IMAGECONST.Bootstrap, toptech_heading: "Bootstrap"},
        {toptech_image: IMAGECONST.Materialui, toptech_heading: "Material Ui"},
        {toptech_image: IMAGECONST.Elementui, toptech_heading: "Elemental-UI"},
        {toptech_image: IMAGECONST.Tailwind, toptech_heading: "Tailwindcss"},
        {toptech_image: IMAGECONST.Materializecss, toptech_heading: "Materializecss"},
        {toptech_image: IMAGECONST.Blueprint, toptech_heading: "Blueprint"},
        {toptech_image: IMAGECONST.Uikit, toptech_heading: "Uikit"},
      ]
    },
    {
      toptech_tab: "2",
      toptech_content: [
        {toptech_image: IMAGECONST.Node, toptech_heading: "Node JS",},
        {toptech_image: IMAGECONST.Nest, toptech_heading: "Nest JS"},
        {toptech_image: IMAGECONST.Laravel, toptech_heading: "Laravel"},
        {toptech_image: IMAGECONST.Codeigniter, toptech_heading: "Codeigniter"},
        {toptech_image: IMAGECONST.Php, toptech_heading: "PHP"},
        {toptech_image: IMAGECONST.Dotnet, toptech_heading: "Dotnet"},
        {toptech_image: IMAGECONST.Java, toptech_heading: "Java"},
        {toptech_image: IMAGECONST.Python, toptech_heading: "Python"},
        {toptech_image: IMAGECONST.Django, toptech_heading: "Django"},
        {toptech_image: IMAGECONST.Flask, toptech_heading: "Flask"},
        {toptech_image: IMAGECONST.Rails, toptech_heading: "Rails"},
        {toptech_image: IMAGECONST.Ruby, toptech_heading: "Ruby"},
      ]
    },
    {
      toptech_tab: "3",
      toptech_content: [
        {toptech_image: IMAGECONST.Ios, toptech_heading: "Apple",},
        {toptech_image: IMAGECONST.Android, toptech_heading: "Android"},
        {toptech_image: IMAGECONST.Flutter, toptech_heading: "Flutter"},
        {toptech_image: IMAGECONST.Native, toptech_heading: "React Native"},
        {toptech_image: IMAGECONST.Ionic, toptech_heading: "Ionic"},
      ]
    },
    {
      toptech_tab: "4",
      toptech_content: [
        {toptech_image: IMAGECONST.Aws, toptech_heading: "AWS",},
        {toptech_image: IMAGECONST.Cpanel, toptech_heading: "Cpanel"},
        {toptech_image: IMAGECONST.Digitalocean, toptech_heading: "Digital Ocean"},
        {toptech_image: IMAGECONST.Docker, toptech_heading: "Docker"},
        {toptech_image: IMAGECONST.Hostgator, toptech_heading: "Hostgator"},
        {toptech_image: IMAGECONST.Jenkins, toptech_heading: "Jenkins"},
        {toptech_image: IMAGECONST.Kubernetes, toptech_heading: "Kubernetes"},
      ]
    },
    {
      toptech_tab: "5",
      toptech_content: [
        {toptech_image: IMAGECONST.Mongo, toptech_heading: "MongoDb",},
        {toptech_image: IMAGECONST.MySQL, toptech_heading: "MySQL"},
        {toptech_image: IMAGECONST.PostgreSQL, toptech_heading: "PostgreSQL"},
        {toptech_image: IMAGECONST.Firebase, toptech_heading: "Firebase"},
        {toptech_image: IMAGECONST.Realm, toptech_heading: "Realm"},
        {toptech_image: IMAGECONST.Sqlite, toptech_heading: "SQLite"},
      ]
    },
    {
      toptech_tab: "6",
      toptech_content: [
        {toptech_image: IMAGECONST.Microsoftteam, toptech_heading: "Microsoft Teams",},
        {toptech_image: IMAGECONST.Jira, toptech_heading: "Jira"},
        {toptech_image: IMAGECONST.Asana, toptech_heading: "Asana"},
        {toptech_image: IMAGECONST.Slack, toptech_heading: "Slack"},
        {toptech_image: IMAGECONST.Monday, toptech_heading: "Monday"},
        {toptech_image: IMAGECONST.Pivotaltracker, toptech_heading: "Pivotal Tracker"},
        {toptech_image: IMAGECONST.Productboard, toptech_heading: "Productboard"},
        {toptech_image: IMAGECONST.Terraform, toptech_heading: "Terraform"},
        {toptech_image: IMAGECONST.Workplace, toptech_heading: "Workplace"},
      ]
    },
    {
      toptech_tab: "7",
      toptech_content: [
        {toptech_image: IMAGECONST.Joomla, toptech_heading: "Joomla",},
        {toptech_image: IMAGECONST.Wordpress, toptech_heading: "WordPress"},
        {toptech_image: IMAGECONST.Shopify, toptech_heading: "Shopify"},
        {toptech_image: IMAGECONST.Hubspot, toptech_heading: "HubSpot"},
        {toptech_image: IMAGECONST.Apostrophe, toptech_heading: "Apostrophe"},
        {toptech_image: IMAGECONST.Opencart, toptech_heading: "OpenCart"},
        {toptech_image: IMAGECONST.Builderio, toptech_heading: "Builder io"},
        {toptech_image: IMAGECONST.Datocms, toptech_heading: "DatoCMS"},
        {toptech_image: IMAGECONST.Drupal, toptech_heading: "Drupal"},
        {toptech_image: IMAGECONST.Ghost, toptech_heading: "Ghost"},
        {toptech_image: IMAGECONST.Modx, toptech_heading: "Modx"},
        {toptech_image: IMAGECONST.Payload, toptech_heading: "Payload"},
        {toptech_image: IMAGECONST.Storyblok, toptech_heading: "Storyblok"},
        {toptech_image: IMAGECONST.Typo3, toptech_heading: "TYPO3"},
        {toptech_image: IMAGECONST.Salesforce, toptech_heading: "Salesforce"},
      ]
    },
    {
      toptech_tab: "8",
      toptech_content: [
        {toptech_image: IMAGECONST.Paypal, toptech_heading: "PayPal",},
        {toptech_image: IMAGECONST.Stripe, toptech_heading: "stripe"},
        {toptech_image: IMAGECONST.Razorpay, toptech_heading: "razorpay"},
        {toptech_image: IMAGECONST.Square, toptech_heading: "square"},
        {toptech_image: IMAGECONST.Helcim, toptech_heading: "helcim"},
        {toptech_image: IMAGECONST.Payoneer, toptech_heading: "payoneer"},
        {toptech_image: IMAGECONST.Payu, toptech_heading: "payUmoney"},
        {toptech_image: IMAGECONST.Cashfree, toptech_heading: "cashfree"},
        {toptech_image: IMAGECONST.Cc_avenue, toptech_heading: "CC avenue"},
        {toptech_image: IMAGECONST.Oceanpayment, toptech_heading: "ocean payment"},
      ]
    },
  ]
  /* -------------------------------------------------------------------------- */
  /*                                 Text rotate                                */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {

    const options = {
      strings: [
        '<Link href="/palette/latest">Real Estate</Link>',
        '<Link href="/color-shades">Fintech Solutions</Link>',
        '<Link href="/variation">Healthcare</Link>',
        '<Link href="/image-to-colors">Retail &amp; Ecommerce</Link>',
        '<Link href="/shuffle-swatch">Travel &amp; Tourisum</Link>',
        '<Link href="/color-list">Social Networking</Link>',
        '<Link href="/swatch-trends">Delivery Solutions</Link>',
        '<Link href="/swatch-trends">Media &amp; Entertainment</Link>',
        '<Link href="/swatch-trends">Fitness</Link>',
        '<Link href="/swatch-trends">Education</Link>',
        '<Link href="/swatch-trends">Fullstack Solutions</Link>',
      ],
      typeSpeed: 100,
      backSpeed: 50,
      loop: true, 
      showCursor: false,
    };

    const typed = new Typed('.azil-section-title-rotate', options);

    return () => {
      typed.destroy();
    };
  }, []);

  /* -------------------------------------------------------------------------- */
  /*                            Button ripple effect                            */
  /* -------------------------------------------------------------------------- */
  const handleHover = (e) => {
    const button = e.target;
    const rect = button.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    const rippleSpan = document.createElement('span');
    rippleSpan.classList.add('ripple-span');
    rippleSpan.style.top = `${y}px`;
    rippleSpan.style.left = `${x}px`;
    button.appendChild(rippleSpan);
  };

  const handleHoverEnd = (e) => {
    const button = e.target;
    const ripples = button.getElementsByClassName('ripple-span');
    while (ripples.length > 0) {
      button.removeChild(ripples[0]);
    }
  };  

  const handleWhiteHover = (e) => {
    const button = e.target;
    const rect = button.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    const rippleSpan = document.createElement('span');
    rippleSpan.classList.add('ripple-span-white');
    rippleSpan.style.top = `${y}px`;
    rippleSpan.style.left = `${x}px`;
    button.appendChild(rippleSpan);
  };

  const handleWhiteHoverEnd = (e) => {
    const button = e.target;
    const ripples = button.getElementsByClassName('ripple-span-white');
    while (ripples.length > 0) {
      button.removeChild(ripples[0]);
    }
  }; 

  /* -------------------------------------------------------------------------- */
  /*                         Service we offer Modal data                        */
  /* -------------------------------------------------------------------------- */
  const [isModalOpenService, setIsModalOpenService] = useState(false);
  const [currentServiceContent, setCurrentServiceContent] = useState([]);
  const [currentServiceTitle, setCurrentServiceTitle] = useState("");
  const [isModalOpenTech, setIsModalOpenTech] = useState(false);
  const [currentTechContent, setCurrentTechContent] = useState([]);
  const [currentTechTitle, setCurrentTechTitle] = useState("");

  const toggleModalService = () => setIsModalOpenService(!isModalOpenService);
  const toggleModalTech = () => setIsModalOpenTech(!isModalOpenTech);

  const openModalServiceContent = (content, service) => {
    setCurrentServiceContent(content);
    setCurrentServiceTitle(service.service_heading);
    toggleModalService();
  };

  const openModalTechContent = (content, tech) => {
    setCurrentTechContent(content);
    setCurrentTechTitle(tech.tech_heading);
    toggleModalTech();
  };

  const services = [
    { service_heading: "Web & App Design", 
      content: [
        {
          service_img: IMAGECONST.Ui_Ux,
          service_path: PATH.ANDROID,
          service_title: "UI/UX Design"
        },
        {
          service_img: IMAGECONST.Webdesign,
          service_path: PATH.CROSS_PLATFORM,
          service_title: "Web Design"
        },
        {
          service_img: IMAGECONST.Mobiledesign,
          service_path: PATH.REACT_NATIVE,
          service_title: "Mobile App Design"
        },
        {
          service_img: IMAGECONST.Gamedesign,
          service_path: PATH.REACT_NATIVE,
          service_title: "Game Design"
        },
        {
          service_img: IMAGECONST.Graphicsdesign,
          service_path: PATH.FLUTTER,
          service_title: "Graphics Design"
        },
        {
          service_img: IMAGECONST.Branddesign,
          service_path: PATH.FLUTTER,
          service_title: "Brand Design"
        }
      ]
    },
    { service_heading: "Immersive Solutions",
      content: [
        {
          service_img: IMAGECONST.Ar_view,
          service_path: PATH.ANDROID,
          service_title: "AR"
        },
        {
          service_img: IMAGECONST.Vr,
          service_path: PATH.ANDROID,
          service_title: "VR"
        },
        {
          service_img: IMAGECONST.Ml,
          service_path: PATH.FLUTTER,
          service_title: "AL/ML Services"
        },
        {
          service_img: IMAGECONST.Arvr,
          service_path: PATH.FLUTTER,
          service_title: "AR/VR In Real Estate"
        }
      ] 
    },
    { service_heading: "Tools Compatibility", 
      content: [
        {
          service_img: IMAGECONST.Figma,
          service_path: PATH.ANDROID,
          service_title: "Figma"
        },
        {
          service_img: IMAGECONST.Sketch,
          service_path: PATH.ANDROID,
          service_title: "Sketch"
        },
        {
          service_img: IMAGECONST.Photoshop,
          service_path: PATH.ANDROID,
          service_title: "Adobe Photoshop"
        },
        {
          service_img: IMAGECONST.Illustrator,
          service_path: PATH.ANDROID,
          service_title: "Adobe Illustrator"
        },
        {
          service_img: IMAGECONST.Xd,
          service_path: PATH.FLUTTER,
          service_title: "Adobe XD"
        },
        {
          service_img: IMAGECONST.Indesign,
          service_path: PATH.FLUTTER,
          service_title: "InDesign"
        },
        {
          service_img: IMAGECONST.Aftereffect,
          service_path: PATH.FLUTTER,
          service_title: "Adobe After Effects"
        },
        {
          service_img: IMAGECONST.Premirepro,
          service_path: PATH.FLUTTER,
          service_title: "Adobe Premire Pro"
        },
        {
          service_img: IMAGECONST.Character_animator,
          service_path: PATH.FLUTTER,
          service_title: "Adobe Character Animator"
        },
        {
          service_img: IMAGECONST.Coraldraw,
          service_path: PATH.FLUTTER,
          service_title: "Coral Draw"
        },
        {
          service_img: IMAGECONST.Procreate,
          service_path: PATH.FLUTTER,
          service_title: "Pro Create"
        },
        {
          service_img: IMAGECONST.Zeplin,
          service_path: PATH.FLUTTER,
          service_title: "Zeplin"
        },
        {
          service_img: IMAGECONST.Invision,
          service_path: PATH.FLUTTER,
          service_title: "InVision"
        },
        {
          service_img: IMAGECONST.Krita,
          service_path: PATH.FLUTTER,
          service_title: "Krita"
        }
      ]
    },
    { service_heading: "Digital Marketing",
      content: [
        {
          service_img: IMAGECONST.Seo,
          service_path: PATH.ANDROID,
          service_title: "SEO"
        },
        {
          service_img: IMAGECONST.Socialmarketing,
          service_path: PATH.ANDROID,
          service_title: "Social Media Marketing"
        },
        {
          service_img: IMAGECONST.Googleads,
          service_path: PATH.FLUTTER,
          service_title: "Google Ads"
        },
        {
          service_img: IMAGECONST.Emailmarketing,
          service_path: PATH.FLUTTER,
          service_title: "Email Marketing"
        },
        {
          service_img: IMAGECONST.Lead,
          service_path: PATH.FLUTTER,
          service_title: "Lead Generation"
        },
        {
          service_img: IMAGECONST.Blogmarketing,
          service_path: PATH.FLUTTER,
          service_title: "Blog Marketing"
        },
        {
          service_img: IMAGECONST.Keywordsearch,
          service_path: PATH.FLUTTER,
          service_title: "Keyword Research"
        },
        {
          service_img: IMAGECONST.Linkbuild,
          service_path: PATH.FLUTTER,
          service_title: "Link Building"
        }
      ]
    },
    { service_heading: "Deployment Services", 
      content: [
        {
          service_img: IMAGECONST.Aws,
          service_path: PATH.ANDROID,
          service_title: "AWS"
        },
        {
          service_img: IMAGECONST.Digitalocean,
          service_path: PATH.ANDROID,
          service_title: "Digital Ocean"
        },
        {
          service_img: IMAGECONST.Azure,
          service_path: PATH.ANDROID,
          service_title: "Azure"
        },
        {
          service_img: IMAGECONST.Bluehost,
          service_path: PATH.FLUTTER,
          service_title: "Blue Host"
        },
        {
          service_img: IMAGECONST.Godaddy,
          service_path: PATH.FLUTTER,
          service_title: "Godaddy"
        },
        {
          service_img: IMAGECONST.Hostinger,
          service_path: PATH.FLUTTER,
          service_title: "Hostinger"
        },
        {
          service_img: IMAGECONST.Vercel,
          service_path: PATH.FLUTTER,
          service_title: "Vercel"
        },
        {
          service_img: IMAGECONST.Netlify,
          service_path: PATH.FLUTTER,
          service_title: "Netlify"
        }
      ] 
    },
    { service_heading: "Database Services",
      content: [
        {
          service_img: IMAGECONST.Mongo,
          service_path: PATH.ANDROID,
          service_title: "MongoDB"
        },
        {
          service_img: IMAGECONST.PostgreSQL,
          service_path: PATH.ANDROID,
          service_title: "PostgreSQL"
        },
        {
          service_img: IMAGECONST.Awsrds,
          service_path: PATH.FLUTTER,
          service_title: "AWS RDS"
        },
        {
          service_img: IMAGECONST.Oracle,
          service_path: PATH.REACT_NATIVE,
          service_title: "Oracle"
        },
        {
          service_img: IMAGECONST.MySQL,
          service_path: PATH.REACT_NATIVE,
          service_title: "MySQL"
        },
        {
          service_img: IMAGECONST.MsSQL,
          service_path: PATH.REACT_NATIVE,
          service_title: "Microsoft SQL Server"
        },
        {
          service_img: IMAGECONST.Cassandra,
          service_path: PATH.REACT_NATIVE,
          service_title: "Apache Cassandra"
        },
        {
          service_img: IMAGECONST.Elasticsearch,
          service_path: PATH.REACT_NATIVE,
          service_title: "Elastic Search"
        },
        {
          service_img: IMAGECONST.Firebase,
          service_path: PATH.REACT_NATIVE,
          service_title: "Firebase"
        }
      ] 
    },
    { service_heading: "QA Testing",
      content: [
        {
          service_img: IMAGECONST.Automationtest,
          service_path: PATH.ANDROID,
          service_title: "Automation Testing"
        },
        {
          service_img: IMAGECONST.manualtest,
          service_path: PATH.ANDROID,
          service_title: "Manual Testing"
        },
        {
          service_img: IMAGECONST.Unittest,
          service_path: PATH.FLUTTER,
          service_title: "Unit Testing"
        },
        {
          service_img: IMAGECONST.Webtest,
          service_path: PATH.FLUTTER,
          service_title: "Web & App Testing"
        },
        {
          service_img: IMAGECONST.Gametest,
          service_path: PATH.FLUTTER,
          service_title: "Game Testing"
        },
        {
          service_img: IMAGECONST.Uitest,
          service_path: PATH.FLUTTER,
          service_title: "UI(Responsive) Testing"
        },
        {
          service_img: IMAGECONST.Endtoendtest,
          service_path: PATH.FLUTTER,
          service_title: "End-to-end Testing"
        },
        {
          service_img: IMAGECONST.Integrationtest,
          service_path: PATH.FLUTTER,
          service_title: "Integration Testing"
        },
        {
          service_img: IMAGECONST.Apitest,
          service_path: PATH.FLUTTER,
          service_title: "APIs Testing"
        },
        {
          service_img: IMAGECONST.Securitytest,
          service_path: PATH.FLUTTER,
          service_title: "Security Testing"
        },
        {
          service_img: IMAGECONST.Smocktest,
          service_path: PATH.FLUTTER,
          service_title: "Smoke Testing"
        },
        {
          service_img: IMAGECONST.Systemtest,
          service_path: PATH.FLUTTER,
          service_title: "System Testing"
        }
      ]
    },
  ];
  const tech = [
    { tech_heading: "Web Development",
      content: [
        {
          tech_img: IMAGECONST.Mern,
          tech_path: PATH.FRONTEND,
          tech_title: "MERN Stack"
        },
        {
          tech_img: IMAGECONST.Mean,
          tech_path: PATH.FRONTEND,
          tech_title: "MEAN Stack"
        },
        {
          tech_img: IMAGECONST.React,
          tech_path: PATH.REACT,
          tech_title: "React Js"
        },
        {
          tech_img: IMAGECONST.Next,
          tech_path: PATH.REACT,
          tech_title: "Next Js"
        },
        {
          tech_img: IMAGECONST.Angular,
          tech_path: PATH.ANGULAR,
          tech_title: "Angular Js"
        },
        {
          tech_img: IMAGECONST.Vue,
          tech_path: PATH.ANGULAR,
          tech_title: "Vue Js"
        },
        {
          tech_img: IMAGECONST.Nuxt,
          tech_path: PATH.ANGULAR,
          tech_title: "Nuxt Js"
        },
        {
          tech_img: IMAGECONST.Vite,
          tech_path: PATH.ANGULAR,
          tech_title: "Vite Js"
        },
        {
          tech_img: IMAGECONST.Node,
          tech_path: PATH.NODE,
          tech_title: "Node Js"
        },
        {
          tech_img: IMAGECONST.Shopify,
          tech_path: PATH.NODE,
          tech_title: "Shopify"
        },
        {
          tech_img: IMAGECONST.Wordpress,
          tech_path: PATH.WORDPRESS,
          tech_title: "Wordpress"
        },
        {
          tech_img: IMAGECONST.Ruby,
          tech_path: PATH.NODE,
          tech_title: "Ruby"
        },
        {
          tech_img: IMAGECONST.Python,
          tech_path: PATH.PYTHON,
          tech_title: "Python"
        },
        {
          tech_img: IMAGECONST.Laravel,
          tech_path: PATH.LARAVEL,
          tech_title: "Laravel"
        },
        {
          tech_img: IMAGECONST.Asp,
          tech_path: PATH.NET,
          tech_title: "ASP.NET"
        },
        {
          tech_img: IMAGECONST.Csharp,
          tech_path: PATH.NET,
          tech_title: "C#"
        },
        {
          tech_img: IMAGECONST.Java,
          tech_path: PATH.JAVA,
          tech_title: "JAVA"
        },
        {
          tech_img: IMAGECONST.Php,
          tech_path: PATH.PHP,
          tech_title: "PHP"
        },
        {
          tech_img: IMAGECONST.Codeigniter,
          tech_path: PATH.PHP,
          tech_title: "Codeigniter"
        },
        {
          tech_img: IMAGECONST.Rails,
          tech_path: PATH.ROR,
          tech_title: "Ruby On Rails"
        },
      ] 
    },
    { tech_heading: "Mobile Development",
      content: [
        {
          tech_img: IMAGECONST.Android,
          tech_path: PATH.ANDROID,
          tech_title: "Android"
        },
        {
          tech_img: IMAGECONST.Ios,
          tech_path: PATH.ANDROID,
          tech_title: "iOS"
        },
        {
          tech_img: IMAGECONST.Flutter,
          tech_path: PATH.FLUTTER,
          tech_title: "Flutter"
        },
        {
          tech_img: IMAGECONST.Native,
          tech_pathypath: PATH.REACT_NATIVE,
          tech_title: "React Native"
        },
        {
          tech_img: IMAGECONST.Ionic,
          tech_path: PATH.REACT_NATIVE,
          tech_title: "Ionic"
        }
      ] 
    },
    { tech_heading: "3D & Game Development",
      content: [
        {
          tech_img: IMAGECONST.TwoD_3D,
          tech_path: PATH.ANDROID,
          tech_title: "2D/3D Design"
        },
        {
          tech_img: IMAGECONST.Unity,
          tech_path: PATH.ANDROID,
          tech_title: "Unity"
        },
        {
          tech_img: IMAGECONST.Blender,
          tech_path: PATH.FLUTTER,
          tech_title: "Blender"
        },
        {
          tech_img: IMAGECONST.AutoCAD,
          tech_path: PATH.FLUTTER,
          tech_title: "AutoCAD"
        },
        {
          tech_img: IMAGECONST.Autodesk,
          tech_path: PATH.FLUTTER,
          tech_title: "Auto desk"
        },
        {
          tech_img: IMAGECONST.Sketchup,
          tech_path: PATH.FLUTTER,
          tech_title: "SketchUp"
        },
        {
          tech_img: IMAGECONST.ThreeDsmax,
          tech_path: PATH.FLUTTER,
          tech_title: "3ds Max"
        },
        {
          tech_img: IMAGECONST.Corona,
          tech_path: PATH.FLUTTER,
          tech_title: "Corona"
        },
      ] 
    },
    { tech_heading: "Trending Technologies",
      content: [
        {
          tech_img: IMAGECONST.Blockchain,
          tech_path: PATH.ANDROID,
          tech_title: "Block Chain"
        },
        {
          tech_img: IMAGECONST.Chatgpt,
          tech_path: PATH.ANDROID,
          tech_title: "ChatGPT/AI"
        },
        {
          tech_img: IMAGECONST.Crypto,
          tech_path: PATH.FLUTTER,
          tech_title: "Crypto Currency"
        },
        {
          tech_img: IMAGECONST.Metaverce,
          tech_path: PATH.FLUTTER,
          tech_title: "Metaverce"
        },
        {
          tech_img: IMAGECONST.Cloudcompute,
          tech_path: PATH.FLUTTER,
          tech_title: "Cloud Computing"
        },
        {
          tech_img: IMAGECONST.Machinelearning,
          tech_path: PATH.FLUTTER,
          tech_title: "Machine Learning"
        },
        {
          tech_img: IMAGECONST.Iot,
          tech_path: PATH.FLUTTER,
          tech_title: "Internet of Things(IOT)"
        },
        {
          tech_img: IMAGECONST.Devops,
          tech_path: PATH.FLUTTER,
          tech_title: "DevOps"
        },
        {
          tech_img: IMAGECONST.Cybersecurity,
          tech_path: PATH.FLUTTER,
          tech_title: "Cyber Security"
        },
        {
          tech_img: IMAGECONST.Datascience,
          tech_path: PATH.FLUTTER,
          tech_title: "Data Science"
        },
        {
          tech_img: IMAGECONST.Data_analytics,
          tech_path: PATH.FLUTTER,
          tech_title: "Data Analytics"
        },
        {
          tech_img: IMAGECONST.Robotics,
          tech_path: PATH.FLUTTER,
          tech_title: "Robotics"
        }
      ] 
    },
    { tech_heading: "System Development",
      content: [
        {
          tech_img: IMAGECONST.Python,
          tech_path: PATH.ANDROID,
          tech_title: "Python Software"
        },
        {
          tech_img: IMAGECONST.Java,
          tech_path: PATH.ANDROID,
          tech_title: "Java Software"
        },
        {
          tech_img: IMAGECONST.Electron,
          tech_path: PATH.FLUTTER,
          tech_title: "Electron.JS"
        },
        {
          tech_img: IMAGECONST.Vbnet,
          tech_path: PATH.FLUTTER,
          tech_title: "VB.NET Software"
        }
      ] 
    },
    { tech_heading: "CRM Development",
      content: [
        {
          tech_img: IMAGECONST.Customdev,
          tech_path: PATH.ANDROID,
          tech_title: "Custom Software Development"
        },
        {
          tech_img: IMAGECONST.Crm,
          tech_path: PATH.ANDROID,
          tech_title: "CRM Development"
        },
        {
          tech_img: IMAGECONST.Salesforce,
          tech_path: PATH.ANDROID,
          tech_title: "Salesforce"
        },
        {
          tech_img: IMAGECONST.Hubspot,
          tech_path: PATH.FLUTTER,
          tech_title: "Hub Spot"
        }
      ] 
    },
  ]


  return (
    <React.Fragment>
      <section className="azil-hero-section azil-section-padding">
        <div className="azil-home-container mb-5" data-aos="fade-up">
          <div className="row align-items-center">
            <div className="col-xl-7 col-lg-12 col-md-12 my-2">
              <p className="my-2 font-red azil-list-style-red">Occasionally, certain pages may experience temporary issues and may not function perfectly.</p>
              <ul
                className="azil-list-style-none azil-list-style-horizontal mt-2 mb-3 pl-1"
                >
                <li
                  className="azil-list-style-blue azil-primary-hover cursor-pointer"
                  onClick={() => { history.push({pathname: PATH.UI_UX, state: { type: "UI/UX Design" },})}}
                >
                  UI/UX Design
                </li>
                <li
                  className="azil-list-style-blue azil-custom-green-hover cursor-pointer"
                  onClick={() => {history.push({pathname: PATH.WEB_APP, state: { type: "Web Application Development" },})}}
                >
                  Web Development
                </li>
                <li
                  className="azil-list-style-blue azil-custom-yellow-hover cursor-pointer"
                  onClick={() => {history.push({pathname: PATH.MOBILE_APP,state: { type: "Mobile Application Development" },})}}
                >
                  App Development
                </li>
                <li
                  className="azil-list-style-blue azil-custom-red-hover cursor-pointer"
                  onClick={() => {history.push({pathname: PATH.GAME_DEV,state: { type: "Game Development" },})}}
                >
                  Game Development
                </li>
              </ul>
              <div>
              <h1 className="azil-section-title mt-2 mb-3 azil-res-title-section">
                Best Way to Represent Your Business With <br className="azil-heading-down"/>
                <span className="azil-section-title-rotate"></span>
              </h1>
              </div>
              <div className="azil-primary-content mt-2 mb-3 font-width-mainsection">
                <span className="azil-description">
                  Discover exceptional website and technology solutions at unbeatable prices! Elevate productivity with cutting-edge technologies while ensuring top-tier security for your projects. 
                </span>
              </div>
              <button className="azil-hero-button my-3" onClick={()=> consultAzil.current.scrollIntoView({ behavior: 'smooth' })} onMouseEnter={handleHover} onMouseLeave={handleHoverEnd}>
                Let's Work Together!
              </button>
            </div>

            <div className="col-xl-5 col-lg-10 col-md-10 my-2 text-right azil-res-center-img">
              <img
                src={IMAGECONST.Herobanner}
                alt="hero-img"
                className="img-fluid azil-home-img"
                draggable="false"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Slider */}
      <section className="azil-home-container azil-home-slider" data-aos="fade-up">
        <div className="product-slider ">
          <div>
            <Slider {...settings}>
              {homePageData &&
                homePageData.length > 0 &&
                homePageData.map((home, i) => {
                  return (
                    <React.Fragment key={i}>
                      <div>
                        <a
                          href={home && home.slider_link}
                          target="_blank"
                          rel="noopener noreferrer" style={{cursor: "auto"}}
                        >
                          <div
                            className="product-slider-box cursor-pointer"
                            style={{ width: "300.24px" }}
                          >
                            <div className="product-slider-content-box">
                              <div className="product-logo my-2">
                                <img
                                  src={
                                    home && home.slider_logo
                                      ? home.slider_logo
                                      : logo
                                  }
                                  alt="Azillik-Discvoery"
                                  className="product-logo"
                                  draggable={false}
                                />
                              </div>
                              <div className="azil-product-slider-content mt-2 mb-3">
                                {home && home.title ? home.title : "-"}
                              </div>
                            </div>
                            <div className="product-bottom-image pt-4">
                              <img
                                style={{ width: "300px" }}
                                src={
                                  home &&
                                  home.slider_image &&
                                  home.slider_image
                                }
                                alt="discovery"
                                className="mx-auto w-93"
                                draggable={false}
                              />
                            </div>
                          </div>
                        </a>
                      </div>
                    </React.Fragment>
                  );
                })}
            </Slider>
          </div>
        </div>
      </section>

      {/* Verticals */}
      <section className="azil-home-section-2 azil-section-padding">
        <div className="azil-home-container">
          <div className="row align-items-center">
            <div
              className="col-lg-6 col-md-12 col-sm-12 col-12 my-2"
              data-aos="zoom-in"
            >
              <div className="azil-primary-heading mt-2 mb-3">
                Our <span className="font-primary"> Verticals</span>
              </div>
              <div className="azil-description line-height-30 my-2 pb-3 text-justify">
                Azillik IT Services specializes in creating visually appealing websites with user-friendly interfaces. Our custom web and mobile app development services are designed to attract leads and drive conversions effectively. Whether you need digital marketing, UI/UX design, or software development, we've got you covered. With our expertise, creativity, and passion, we tackle your challenges head-on. Count on us to be your one-stop solution for online growth and marketing. Let us help you stand out in the digital landscape and achieve your business goals seamlessly. Trust Azillik IT Services for comprehensive solutions tailored to your needs.
              </div>
              <div className="azil-line"></div>
              <div className="font-600 font-dark-grey font-14 mt-2 mb-3 pt-2">
                <p>
                  Embrace global vision with local impact! Our tailored solutions combine worldwide insight with local expertise, ensuring cost-effectiveness and optimal outcomes for your projects.
                </p>
              </div>
            </div>
            <div
              className="col-lg-6 col-md-12 col-sm-12 col-12 my-2 text-right azil-res-center-img"
              data-aos="zoom-in"
            >
              <img
                src={IMAGECONST.Homeimg1}
                alt="home-img"
                className="img-fluid azil-home-img"
                draggable="false"
              />
            </div>
          </div>
        </div>
      </section>

      {/* start */}
      <section>
        <div className="azil-work-bg py-2" data-aos="fade-up">
          <div className="azil-mid-heading azil-res-mid-heading text-white my-2">
            Let's Start an Awesome Project Now! Our Expertise in Multiple Frameworks for Maximum Flexibility and Efficiency.
          </div>
          <button
            className="azil-hero-button bg-white font-primary my-2"
            onClick={() => history.push(PATH.WE_WORK)} onMouseEnter={handleWhiteHover} onMouseLeave={handleWhiteHoverEnd}
          >
            We Work On
          </button>
        </div>
      </section>

      {/* Divine */}
      <section className="azil-section-padding azil-bg-hero-faq azil-divine-section-height">
        <div className="azil-home-container" data-aos="fade-up">
          <div className="my-2 d-flex align-items-center">
            <img src={IMAGECONST.Creativestar} alt="creative-star" width={70}/>
            <div className="mx-3">
              <div className="azil-primary-heading my-2">Divine {" "}<span className="font-primary">Creativity</span></div>
              <p className="azil-description mt-2 mb-3">Azillik becoming an all-in-one powerful platform for all the creative discovery, you need.</p>
            </div>
          </div>
          <ImageGallery />
        </div>
      </section>

      {/* start */}
      <section>
        <div className="azil-work-bg py-2" data-aos="fade-up">
          <div className="azil-mid-heading text-white my-2 azil-res-mid-heading">
            Discover Azillik's Divine Creative Product Range Now! Unlocking Possibilities with Versatile.
          </div>
          <button
            className="azil-hero-button bg-white font-primary my-2"
            onClick={() => history.push(PATH.PRODUCT)} onMouseEnter={handleWhiteHover} onMouseLeave={handleWhiteHoverEnd}
          >
            View Our Products
          </button>
        </div>
      </section>
      
      {/* Services We Offer */}
      <section className="azil-section-padding azil-bg-hero">
        <div className="azil-home-container" data-aos="fade-up">
          <div className="text-center">
            <div className="azil-primary-heading my-2">
              Services We <span className="font-primary"> Offer</span>
            </div>
          </div>
          <div className="row">
            <div className="col-12 my-2 azil-res-padding-remove">
              <Nav tabs className="justify-content-center service-we-offer">
                <NavItem className="azil-service-nav">
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={{ active: activeTab === "1" }}
                    onClick={() => toggle("1")}
                  >
                    <svg
                      width="25"
                      className="border-white mr-3 azil-res-service-svg"
                      height="25"
                      viewBox="0 0 36 38"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.6275 1.5L11.68 4.17755C11.715 5.59505 12.8875 6.75 14.305 6.75H19.59C21.0425 6.75 22.215 5.56 22.215 4.125V1.5"
                        stroke="#696969"
                        strokeWidth="2.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M25.75 27.75L22.25 31.25L25.75 34.75"
                        stroke="#696969"
                        strokeWidth="2.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M31 27.75L34.5 31.25L31 34.75"
                        stroke="#696969"
                        strokeWidth="2.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M18.75 36.5H10C3.875 36.5 1.25 33 1.25 27.75V10.25C1.25 5 3.875 1.5 10 1.5H24C30.125 1.5 32.75 5 32.75 10.25V22.5"
                        stroke="#696969"
                        strokeWidth="2.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M10 13.75H24"
                        stroke="#696969"
                        strokeWidth="2.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11.75 19H22.25"
                        stroke="#696969"
                        strokeWidth="2.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span className="font-16 azil-service-text">Services</span>
                  </NavLink>
                </NavItem>
                <NavItem className="azil-service-nav">
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={{ active: activeTab === "2" }}
                    onClick={() => toggle("2")}
                  >
                    <svg
                      width="25"
                      className="border-white mr-3 azil-res-service-svg"
                      height="25"
                      viewBox="0 0 38 38"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.0575 13.75C11.7725 14.6075 13.2425 15.9025 14.31 17.5125C14.9225 18.4225 14.9225 19.595 14.31 20.505C13.2425 22.0975 11.7725 23.3925 10.0575 24.25"
                        stroke="#696969"
                        strokeWidth="2.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M20.75 24.25H27.75"
                        stroke="#696969"
                        strokeWidth="2.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M13.75 36.5H24.25C33 36.5 36.5 33 36.5 24.25V13.75C36.5 5 33 1.5 24.25 1.5H13.75C5 1.5 1.5 5 1.5 13.75V24.25C1.5 33 5 36.5 13.75 36.5Z"
                        stroke="#696969"
                        strokeWidth="2.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span className="font-16 azil-service-text">Technologies</span>
                  </NavLink>
                </NavItem>
                <NavItem className="azil-service-nav">
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={{ active: activeTab === "3" }}
                    onClick={() => toggle("3")}
                  >
                    <svg
                      width="25"
                      className="border-white mr-3 azil-res-service-svg"
                      height="25"
                      viewBox="0 0 38 38"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.75 36.5H24.25C33 36.5 36.5 33 36.5 24.25V13.75C36.5 5 33 1.5 24.25 1.5H13.75C5 1.5 1.5 5 1.5 13.75V24.25C1.5 33 5 36.5 13.75 36.5Z"
                        stroke="#696969"
                        strokeWidth="2.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M25.125 30.375C27.05 30.375 28.625 28.8 28.625 26.875V11.125C28.625 9.2 27.05 7.625 25.125 7.625C23.2 7.625 21.625 9.2 21.625 11.125V26.875C21.625 28.8 23.1825 30.375 25.125 30.375Z"
                        stroke="#696969"
                        strokeWidth="2.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12.875 30.375C14.8 30.375 16.375 28.8 16.375 26.875V20.75C16.375 18.825 14.8 17.25 12.875 17.25C10.95 17.25 9.375 18.825 9.375 20.75V26.875C9.375 28.8 10.9325 30.375 12.875 30.375Z"
                        stroke="#696969"
                        strokeWidth="2.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span className="font-16 azil-service-text">Soultions</span>
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent activeTab={activeTab}>
                <TabPane tabId="1" className="container azil-tab-content-height">
                  <div className="text-center">
                    <div className="azil-description my-3 line-height-30">
                      <p className="mt-5">
                        We offer diverse services tailored to match our client's preferences, reflecting our expertise and commitment to excellence. Our work showcases our   professionalism and extensive experience, leaving a lasting impression. Elevate your online presence with our top-notch IT solutions.
                      </p>
                    </div>
                    <div className="azil-service-content">
                      {services.map((service, index) => (
                        <div key={index} className="azil-service-content-box" onClick={() => openModalServiceContent(service.content, service)}>
                          <div className="azil-list-style-blue mb-2">
                            <span className="azil-service-tab-title">{service.service_heading}</span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </TabPane>
                <TabPane tabId="2" className="container azil-tab-content-height">
                  <div className="text-center">
                    <div className="azil-description line-height-30 my-3">
                      <p className="mt-5">
                        Empower your business with cutting-edge technology solutions. From web and mobile development to CRM and system development, we offer expertise in trending tech to fulfill your requirements. Trust our dedicated teams for innovative solutions tailored to your needs.
                      </p>
                    </div>
                    <div className="azil-service-content">
                      {tech.map((tech, index) => (
                        <div key={index} className="azil-service-content-box" onClick={() => openModalTechContent(tech.content, tech)}>
                          <div className="azil-list-style-blue mb-2">
                            <span className="azil-service-tab-title">{tech.tech_heading}</span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </TabPane>
                <TabPane tabId="3" className="container azil-tab-content-height">
                  <div className="text-center">
                    <div className="azil-description line-height-30 my-3">
                      <p className="mt-5">
                        Experience global tailored IT solutions for your business needs! Our solutions offer cost-effective IT development for diverse industries. Elevate your business with our customized, forward-thinking approach. Let us empower your company with innovative technology solutions crafted just for you.
                      </p>
                    </div>
                    <div className="azil-service-content">
                      <div className="azil-service-content-box">
                        <div className="azil-list-style-blue mb-2" onClick={() => history.push({pathname: PATH.VIDEO_STREAM,state: { type: "Video Streaming App" },})}>
                          <span className="azil-service-tab-title">Travel & Tourisum</span>
                        </div>
                      </div>
                      <div className="azil-service-content-box">
                        <div className="azil-list-style-blue mb-2">
                          <span className="azil-service-tab-title">Social Networking</span>
                        </div>
                      </div>
                      <div className="azil-service-content-box">
                        <div className="azil-list-style-blue mb-2">
                          <span className="azil-service-tab-title">Delivery Solutions</span>
                        </div>
                      </div>
                      <div className="azil-service-content-box">
                        <div className="azil-list-style-blue mb-2">
                          <span className="azil-service-tab-title">Fintech & Real Estate</span>
                        </div>
                      </div>
                      <div className="azil-service-content-box">
                        <div className="azil-list-style-blue mb-2">
                          <span className="azil-service-tab-title">Media & Entertainment</span>
                        </div>
                      </div>
                      <div className="azil-service-content-box">
                        <div className="azil-list-style-blue mb-2">
                          <span className="azil-service-tab-title">Healthcare</span>
                        </div>
                      </div>
                      <div className="azil-service-content-box">
                        <div className="azil-list-style-blue mb-2">
                          <span className="azil-service-tab-title">Retail & Ecommerce</span>
                        </div>
                      </div>
                      <div className="azil-service-content-box">
                        <div className="azil-list-style-blue mb-2">
                          <span className="azil-service-tab-title">Fullstack Solutions</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPane>
              </TabContent>
            </div>
          </div>
        </div>
      </section>
    
      {/* Our Process */}
      <section className="azil-section-padding position-relative">
        <div className="lines">
          <div className="line" />
          <div className="line" />
          <div className="line" />
          <div className="line" />
        </div>
        <div className="azil-home-container" data-aos="fade-up" >

          <div className="text-center">
            <div className="azil-primary-heading my-2">
              Our <span className="font-primary"> Process</span>
            </div>
            <p className="azil-description font-width">Discover our streamlined process tailored to your needs. From initial research to final delivery, we ensure seamless execution for your IT projects.</p>
          </div>
          <div className="row azil-section-margin azil-home-our-process">
            <div className="col-lg-3 col-md-6 col-sm-6 col-12 my-2 azil-res-processbox-1">
              <div className="azil-process-box-1 azil-box-border-hover">
                <div className="azil-process-inner-box azil-process-inner-box-1 my-2">
                  <img src={IMAGECONST.Researchprocess} alt="research" width={42} height={42} />
                </div>
                <div className="azil-process-box-content my-2">
                  <div className="azil-soultion-box text-center">
                    <h4 className="font-18 font-secondary font-700 mb-3 line-height-30"> RESEARCH</h4>
                    <p className="azil-description mb-3 line-height-30">
                      Unleash our expert research team to solve IT challenges effectively. Partner with us today! 
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-12 my-2 azil-res-processbox-2">
              <div className="azil-process-box-2 azil-box-border-hover">
                <div className="azil-process-inner-box azil-process-inner-box-2 my-2">
                  <img src={IMAGECONST.Prototypeprocess} alt="prototype" />
                </div>
                <div className="azil-process-box-content my-2">
                  <div className="azil-soultion-box text-center">
                    <h4 className="font-18 font-secondary font-700 mb-3 line-height-30"> PROTOTYPE</h4>
                    <p className="azil-description mb-3 line-height-30">
                      Innovative IT solutions tailored to your needs, designed with lateral thinking. Welcome to Prototype.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-12 my-2 azil-res-processbox-3">
              <div className="azil-process-box-3 azil-box-border-hover">
                <div className="azil-process-inner-box azil-process-inner-box-3 my-2">
                  <img src={IMAGECONST.Delivery} alt="delivery" />
                </div>
                <div className="azil-process-box-content my-2">
                  <div className="azil-soultion-box text-center">
                    <h4 className="font-18 font-secondary font-700 mb-3 line-height-30"> DELIVERY</h4>
                    <p className="azil-description mb-3 line-height-30">
                      Efficient IT solutions for seamless product launches. Depend on our reliable delivery and support.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-12 my-2 azil-res-processbox-4">
              <div className="azil-process-box-4 azil-box-border-hover">
                <div className="azil-process-inner-box azil-process-inner-box-4 my-2">
                  <img src={IMAGECONST.Finalize} alt="finalize" />
                </div>
                <div className="azil-process-box-content my-2">
                  <div className="azil-soultion-box text-center">
                    <h4 className="font-18 font-secondary font-700 mb-3 line-height-30"> FINALIZE</h4>
                    <p className="azil-description mb-3 line-height-30">
                      Ready for seamless deployment! Trust our IT expertise for efficient solutions after successful testing.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center azil-readbtn-margin">
            <button
              className="azil-hero-button my-2"
              onClick={() => history.push(PATH.HOW_WE_WORK)} onMouseEnter={handleHover} onMouseLeave={handleHoverEnd}
            >
              Read More
            </button>
          </div>
        </div>
      </section>
      
      {/* Our Solutions */}
      <div className="gap-60"></div>
      <section className="azil-section-padding azil-bg-hero azil-section-margin mt-5 mb-0">
        <div className="azil-home-container" data-aos="zoom-in">
          <div className="text-center">
            <div className="azil-primary-heading mt-2 mb-3">
              Our <span className="font-primary"> Solutions</span>
            </div>
            <div className="azil-description mt-2 mb-3 font-width">
              <p>Elevate your business with cutting-edge technology solutions tailored to your needs. Boost productivity, enhance security, and stay ahead of the curve. Let us empower your digital journey.</p>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between text-center my-5" style={{flexWrap: "wrap"}}>
            {oursolutiondata.map((oursolution, i) => (
              <div className="azil-our-solution-box" onClick={() =>
                history.push({
                  pathname: oursolution.oursolution_path
                })
              }>
                <div>
                  <img src={oursolution.oursolution_img} alt={oursolution.imgalt}/>
                </div>
                <div className="azil-solution-text my-2" style={{textTransform: "uppercase"}}>
                  <span>{oursolution.oursolution_heading}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Innovative Direction */}
      <section className="azil-section-padding">
        <div className="azil-home-container" data-aos="fade-up">
          <div className="text-center">
            <div className="azil-primary-heading mt-2 mb-3">
              Innovative <span className="font-primary"> Direction</span>
            </div>
            <div className="azil-description mt-2 mb-3 font-width">
              Empowering your success through innovative technology solutions. Partner with us for reliable, quality-driven IT services tailored to your goals. Achieve excellence with our strategic vision and cutting-edge solutions.
            </div>
          </div>
          <div className="row">
            {ourvisiondata.map((ourvision, i) => (
              <div className="col-lg-4 col-md-6 col-sm-6 col-12 my-4">
                <div className="azil-vision-box text-center">
                  <div className="svg-icon my-2">
                    <img src={ourvision.ourvision_img} alt={ourvision.imgalt}/>
                  </div>
                  <div className="azil-vision-title">
                    <p>{ourvision.ourvision_heading}</p>
                  </div>
                  <div className="azil-description my-2 text-center px-3">
                    <span>{ourvision.ourvision_description}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Top-Notch Technology */}
      <section className="azil-section-padding azil-bg-hero">
        <div className="azil-home-container" data-aos="fade-up">
          <div className="text-center my-2">
            <div className="azil-primary-heading mt-2 mb-3">
              Top-Notch <span className="font-primary">Technology</span>
            </div>
            <p className="azil-description mt-2 mb-3 font-width">Top-Notch Technology delivers state-of-the-art solutions for seamless integration and enhanced digital experiences across various platforms and services.</p>
          </div>
          <div className="row">
            <div className="col-12 my-4">
              <Nav tabs className="justify-content-center azil-top-tech-navbar">
                <NavItem className="azil-top-tech-nav">
                  <NavLink onClick={() => techtoggle("1")}>
                    <div className="azil-res-top-tech-margin">
                      <span className={`azil-top-tech-heading ${techActiveTab === "1" ? 'toptech-active' : ''}`}>frontend</span>
                    </div>
                  </NavLink>
                </NavItem>
                <NavItem className="azil-top-tech-nav">
                  <NavLink onClick={() => techtoggle("2")}>
                    <div className="azil-top-tech position-relative azil-res-top-tech-margin">
                      <span className={`azil-top-tech-heading ${techActiveTab === "2" ? 'toptech-active' : ''}`}>backend</span>
                    </div>
                  </NavLink>
                </NavItem>
                <NavItem className="azil-top-tech-nav">
                  <NavLink onClick={() => techtoggle("3")}>
                    <div className="azil-top-tech position-relative azil-res-top-tech-margin">
                      <span className={`azil-top-tech-heading ${techActiveTab === "3" ? 'toptech-active' : ''}`}>mobile</span>
                    </div>
                  </NavLink>
                </NavItem>
                <NavItem className="azil-top-tech-nav">
                  <NavLink onClick={() => techtoggle("4")}>
                    <div className="azil-top-tech position-relative azil-res-top-tech-margin">
                      <span className={`azil-top-tech-heading ${techActiveTab === "4" ? 'toptech-active' : ''}`}>devops & cloud</span>
                    </div>
                  </NavLink>
                </NavItem>
                <NavItem className="azil-top-tech-nav">
                  <NavLink onClick={() => techtoggle("5")}>
                    <div className="azil-top-tech position-relative azil-res-top-tech-margin">
                      <span className={`azil-top-tech-heading ${techActiveTab === "5" ? 'toptech-active' : ''}`}>database</span>
                    </div>
                  </NavLink>
                </NavItem>
                <NavItem className="azil-top-tech-nav">
                  <NavLink onClick={() => techtoggle("6")}>
                    <div className="azil-top-tech position-relative azil-res-top-tech-margin">
                      <span className={`azil-top-tech-heading ${techActiveTab === "6" ? 'toptech-active' : ''}`}>project manager</span>
                    </div>
                  </NavLink>
                </NavItem>
                <NavItem className="azil-top-tech-nav">
                  <NavLink onClick={() => techtoggle("7")}>
                    <div className="azil-top-tech position-relative azil-res-top-tech-margin">
                      <span className={`azil-top-tech-heading ${techActiveTab === "7" ? 'toptech-active' : ''}`}>crm & cms</span>
                    </div>
                  </NavLink>
                </NavItem>
                <NavItem className="azil-top-tech-nav">
                  <NavLink onClick={() => techtoggle("8")}>
                    <div className="azil-top-tech position-relative azil-res-top-tech-margin">
                      <span className={`azil-top-tech-heading ${techActiveTab === "8" ? 'toptech-active' : ''}`}>payment gatways</span>
                    </div>
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent activeTab={techActiveTab}>
                {toptechdata.map((toptech, index) => (
                  <TabPane tabId={toptech.toptech_tab} key={index} className="container azil-res-padding-remove">
                    <div className="azil-toptech-tabbox azil-res-padding-remove">
                      {toptech.toptech_content.map((i,index) => (
                        <div key={index} className="azil-toptech-tab-content">
                          <div className="d-flex justify-content-center"><img src={i.toptech_image} alt={i.toptech_heading} width={55} height={55}/></div>
                          <p className="text-center font-14 my-2" style={{textTransform: "capitalize"}}>{i.toptech_heading}</p>
                        </div>
                      ))}
                    </div>
                    
                  </TabPane>
                ))}
              </TabContent>
            </div>
          </div>
        </div>
      </section>

      {/* Career */}
      <section className="azil-section-padding">
        <div className="azil-home-container" data-aos="fade-up">
          <div className="text-center mt-2 mb-4">
            <p className="azil-mid-heading mt-2 mb-3">Discover a New Chapter in Your <span className="font-primary">Career with Azillik</span></p>
            <p className="azil-description mt-2 mb-3 font-width"> Ready to leverage your expertise for thrilling opportunities? Whether you're transitioning from learning to leadership, seize  the opportunity to elevate your career. Explore and apply for your dream job today!
            </p>
          </div>
          <div className="row">
            {hiringData &&
              hiringData.map((hiring, index) => {
                return (
                  <>
                    <div
                      className="col-lg-4 col-md-6 col-sm-6 col-12 my-4"
                      key={index}
                    >
                      <div
                        className="azil-hiring-box"
                        onClick={() =>
                          history.push({
                            pathname: PATH.JOB_DETAILS,
                            state: {
                              hiring: hiring,
                            },
                          })
                        }
                      >
                        <div className="d-flex align-items-center">
                          <div>
                            <img
                              src={hiring.hiring_image}
                              alt="hiring"
                              width={60}
                              draggable={false}
                              className="mr-4"
                            />
                          </div>
                          <div className="col-lg-8 col-md-8 col-sm-8 col-8 p-0">
                            <div className="font-black font-16 font-600 mb-2">
                              {hiring.title}
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="azil-description">
                                <span className="font-dark-grey font-600">
                                  Exp: {" "}
                                </span>
                                {hiring.experience} Years
                              </div>
                              <div className="azil-description">
                                <span className="font-dark-grey font-600">
                                  Openings: {" "}
                                </span>
                                {hiring.positions}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
          </div>
          {hiringData.length >= 6 &&
            <div className="text-center my-4">
              <Link to={PATH.HIRING}><button className="azil-hero-button"  onMouseEnter={handleHover} onMouseLeave={handleHoverEnd}>View More</button></Link>
            </div>
          }
          
        </div>
      </section>

      {/* start */}
      <section>
        <div className="azil-work-bg py-2" data-aos="zoom-in">
          <div className="azil-mid-heading azil-res-mid-heading text-white my-2">
            Visit Our Official Site for Comprehensive Insights and Information
          </div>
          <button
            className="azil-hero-button bg-white my-2" onMouseEnter={handleWhiteHover} onMouseLeave={handleWhiteHoverEnd}
          >
            <a href="company.azillik.com" target="_blank"><span className="font-primary">Azillik Company</span></a>
          </button>
        </div>
      </section>

      {/* Faq */}
      <section className="azil-section-padding azil-bg-hero-faq">
        <div className="azil-home-container" data-aos="fade-up">
          <div className="text-center">
            <div className="azil-primary-heading my-2">
              Frequently Asked Questions <span className="font-primary"> (FAQs)</span>
            </div>
            <div className="azil-description my-2 font-width">
              Discover answers to commonly asked questions about our IT services, empowering you with quick insights. Streamlined solutions await you here.
            </div>
          </div>

          <ul className="azil-faq-list my-5">
            {faqData &&
              faqData.map((faq, index) => {
                return (
                  <>
                    <li
                      className={activeFaq === faq.faq_id ? "azil-the-active" : ""}
                      key={index}
                      style={{textTransform: "capitalize", cursor: "pointer"}}
                      onClick={() => {
                        setActiveFaq((prevActiveFaq) =>
                          prevActiveFaq === faq.faq_id ? null : faq.faq_id
                        );
                      }}
                    >
                      <Link
                        to="#"
                        className="font-secondary cursor-pointer"
                      >
                        <h4 className="azil-faq-heading">
                          {faq && faq.question}
                        </h4>
                      </Link>
                      <Collapse isOpen={activeFaq === faq.faq_id}>
                        <p className="azil-read azil-faq-text pt-3 font-grey">
                          {faq && faq.answer}
                        </p>
                      </Collapse>
                    </li>
                  </>
                );
              })}
          </ul>
        </div>
      </section>

      {/* blog */}
      <section className="azil-section-padding">
        <div className="azil-home-container" data-aos="fade-up">
          <div className="text-center">
            <div className="azil-primary-heading mt-2 mb-3">
              Read More about <span className="azil-font-gradient"> Azillik Company</span> & <span className="font-primary">Our Articles</span>
            </div>
            <p className="azil-description font-width mt-2 mb-3">Explore Azillik Company's blog for insightful articles covering various topics. Dive into our content to learn about our industry and more.</p>
          </div>

          <div className="row">
            {blogData &&
              blogData.map((blog, index) => {
                return (
                  <React.Fragment key={index}>
                    <CustomBlog blog={blog} />
                  </React.Fragment>
                );
              })}
          </div>
        </div>
        {blogData && blogData.length >= 4 && (
            <div className="text-center my-4 mr-2">
              <button
                className="azil-hero-button"
                onClick={() => {
                  history.push(PATH.BLOG);
                }} onMouseEnter={handleHover} onMouseLeave={handleHoverEnd}
              >
                View All
              </button>
            </div>
          )}
      </section>

      {/* Connect with us! */}
      <section
        className="azil-section-padding azil-bg-hero"
        ref={consultAzil}
        data-aos="fade-up"
      >
        <CustomContactUs />
      </section>


      <Modal
        className="azil-tab-modal border-radius-8"
        isOpen={isModalOpenService}
        toggle={toggleModalService}
        centered
      >
        <div style={{ padding: "10px 30px" }}>
          <div className="azil-close-button azil-modal-close-button cursor-pointer" onClick={() => setIsModalOpenService(!isModalOpenService)}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.9654 14.733L5.06592 4.8335" stroke="#0C2955" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M14.8435 4.94409L4.94401 14.8436" stroke="#0C2955" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <div className="pb-2 mt-1 mb-2">
            <p className="text-center font-secondary font-600">
              <span >{currentServiceTitle.split(' ')[0]}</span>
              <span className="font-primary">{" "}{currentServiceTitle.substr(currentServiceTitle.indexOf(' ') + 1)}</span>
            </p>
          </div>
          <div className="azil-line mb-2"></div>
            <div className="row">
              {currentServiceContent.map((contentItem, index) => (
                <Link to={contentItem.service_path} className="azil-header-submenu-list azil-res-maintab-list" key={index}>
                  <div className="menu-subs-heading-img"><img src={contentItem.service_img} alt={contentItem.service_heading} width={20}/></div>
                  <span className="azil-description azil-sub-menu-title azil-tab-modal-title">{contentItem.service_title}</span>                  
                </Link>
              ))}
            </div>
        </div>
      </Modal>

      <Modal
        className="azil-tab-modal border-radius-8"
        isOpen={isModalOpenTech}
        toggle={toggleModalTech}
        centered
      >
        <div style={{ padding: "10px 30px" }}>
          <div className="azil-close-button azil-modal-close-button cursor-pointer" onClick={() => setIsModalOpenTech(!isModalOpenTech)}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.9654 14.733L5.06592 4.8335" stroke="#0C2955" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M14.8435 4.94409L4.94401 14.8436" stroke="#0C2955" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <div className="pb-2 mt-1 mb-2">
            <p className="text-center font-secondary font-600">
              <span>{currentTechTitle.split(' ')[0]}</span>
              <span className="font-primary">{" "}{currentTechTitle.substr(currentTechTitle.indexOf(' ') + 1)}</span>
            </p>
          </div>
          <div className="azil-line mb-2"></div>
            <div className="row">
              {currentTechContent.map((contentItem, index) => (
                <Link to={contentItem.tech_path} className="azil-header-submenu-list" key={index}>
                  <div className="menu-subs-heading-img"><img src={contentItem.tech_img} alt={contentItem.tech_heading} width={20}/></div>
                  <span className="azil-description azil-sub-menu-title azil-tab-modal-title">{contentItem.tech_title}</span>
                </Link>
              ))}
            </div>
        </div>
      </Modal>

      <Modal
        className="azil-comming-soon"
        isOpen={isModalOpen}
        toggle={() => toggleModal()}
        centered
        style={{borderRadius: "8px"}}
      >
        <div style={{padding: "10px 30px"}}>
          <div className="azil-close-right-btn" onClick={() => setIsModalOpen(!isModalOpen)} style={{cursor: "pointer"}}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.9654 14.733L5.06592 4.8335" stroke="#0C2955" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M14.8435 4.94409L4.94401 14.8436" stroke="#0C2955" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <div>
            <p className="text-center" style={{fontSize: "20px", color: "#6960eb", fontWeight: "600"}}>Attention please</p>
          </div>
          <div className="azil-line"></div>
          <div
            className="azil-sub-title align-self-center text-center py-3"
          >
            <span className="azil-title-black"> We are currently undergoing <span style={{color: "#6960eb"}}>updates</span>. Occasionally, certain pages may experience temporary issues and may not function perfectly. </span>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default Index;