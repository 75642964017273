import React from "react";

function CustomInput({
  type,
  values,
  name,
  placeholder,
  errors,
  touched,
  handleChange,
  min,
  max,
  defaultValue,
  className,
}) {
  const isError = errors[name] && touched[name];

  return (
    <>
      <div className="azil-show-error">
        <input
          type={type}
          value={values[name]}
          name={name}
          placeholder={isError ? '' : placeholder}
          onChange={handleChange}
          autoComplete="off"
          defaultValue={defaultValue}
          style={{ border: isError ? "1px solid #ff3366" : "", background: isError ? "#fcd4ee30" : "", }}
        />

        {isError && (
          <label className="azil-show-error-message">
            {errors[name]}
          </label>
        )}
      </div>
    </>
  );  
}

export default CustomInput;
