export const DEV_URL = "https://api.azillik.com:8000/";
export const DEV_URL2 = "http://localhost:3000/";

// export const DEV_URL = "http://localhost:8000/";
// export const DEV_URL2 = "http://localhost:3000/";


// const ADMIN_PATH = "api/admin/";
const USER_PATH = "api/user/";

export const constants = {
  CONTACT_US: USER_PATH + "contact",
  APPLY_JOB: USER_PATH + "apply_job",
  APPLY_INTERNSHIP: USER_PATH + "apply_internship",
  WE_WORK: USER_PATH + "we_work",
  PRODUCT: USER_PATH + "product",
  PILLAR: USER_PATH + "pillar",
  HOME_PAGE: USER_PATH + "home_page",
  FAQ: USER_PATH + "faq",
  PRODUCT_GROWTH: USER_PATH + "product_growth",
  CONFIG: USER_PATH + "config",
  PORTFOLIO: USER_PATH + "portfolio",
  TECHNOLOGY: USER_PATH + "technology",
  BLOG: USER_PATH + "blog",
  HIRING: USER_PATH + "hiring",
  CUSTOM_SOFTWARE: USER_PATH + "custom_software",
  LEGAL: USER_PATH + "legal",
};

export const url = DEV_URL;

export const URL = DEV_URL;
