import React, { useEffect, useState } from "react";
import CustomBlog from "../../../components/custom/customBlog";
import { PaginationFunction } from "../../../components/custom/Pagination";
import { GetBlogs } from "../../../services/ContactService";
import BlogHeader from "./BlogHeader";
import { Seo } from "../../../components/custom/Seo";
import BlogFooter from "./BlogFooter";

const BlogAuthor = (props) => {
  const { blog } = props && props.location && props.location.state;
  const [authorBlog, setAuthorBlog] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [pageDataCount] = useState(9);

  const getTutorialBlog = (activePage, pageDataCount) => {
    let pagination = `?page=${activePage}&limit=${pageDataCount}&admin=${blog.admin_id}`;
    GetBlogs(pagination)
      .then((res) => {
        const data = res && res.data && res.data.data && res.data.data.data;
        const totalCount =
          res && res.data && res.data.data && res.data.data.count;
        setTotalCount(totalCount);
        setAuthorBlog(data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getTutorialBlog(activePage, pageDataCount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage, pageDataCount]);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  return (
    <React.Fragment>
      <Seo
        title={blog.admin_firstname + " | Author at Azillik IT"}
        description={""}
        keyword={blog.admin_firstname + " | Author at Azillik IT"}
      />
      <BlogHeader />
      <section className="azil-section-padding">
        <div className="text-center">
          <img
            src={blog && blog.admin_profile}
            className="shadow-lg rounded-circle"
            alt="author_image"
            height={100}
            width={100}
          />
          <h1 className="h1 mt-4">
            {blog && blog.admin_firstname + " " + blog.admin_lastname}
          </h1>
        </div>
      </section>
      <section className="mx-5">
        <div className="row">
          {authorBlog &&
            authorBlog.map((blog, index) => {
              return (
                <React.Fragment key={index}>
                  <CustomBlog blog={blog} />
                </React.Fragment>
              );
            })}
        </div>
        <div className="azil-section-padding d-flex justify-content-center align-items-center">
          {authorBlog && authorBlog.length > 0 && (
            <PaginationFunction
              activePage={activePage}
              totalCount={totalCount}
              handlePageChange={handlePageChange}
              pageDataCount={pageDataCount}
            />
          )}
        </div>
      </section>
      <BlogFooter />
    </React.Fragment>
  );
};

export default BlogAuthor;
