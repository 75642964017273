import React, { useState } from "react";
import { Link } from "react-router-dom";
import { constants as PATH } from "../../components/constants/componentPath";
import { imagesConst as IMAGECONST } from "../../components/constants/imagesConst";
import SocialMediaButton from "../../components/custom/SocialMediaButton/SocialMediaButton";
import DropDown from "../../images/dropdown_icon.svg";

const Footer = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [toggleNetwork, settoggleNetwork] = useState(false);
  const [togglePage, settogglePage] = useState(false);
  const [toggleLegal, settoggleLegal] = useState(false);
  const [toggleSupport, settoggleSupport] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const ournetwork = [
    { name: "Azillik Discovery", link: "#" },
    { name: "IT Services", link: "https://azillik.com/" },
    { name: "Icons Villa", link: "#" },
    { name: "Font Case", link: "#" },
    { name: "3D Depot", link: "#" },
    { name: "Color Swatch", link: "https://colors.azillik.com/" },
    { name: "Gradient Swatch", link: "#" },
    { name1: "Ui Colors Map", link: "#" },
    { name1: "Mockview", link: "#" },
    { name1: "Code Learn", link: "#" },
    { name1: "Web Codes", link: "#" },
    { name1: "Web Codes", link: "#" },
    { name1: "App Source", link: "#" },
    { name1: "Job Board", link: "#" },
  ];
  const legal = [
    { name: "Terms of services", link: PATH.PRIVACY },
    { name: "Privacy policy", link: PATH.PRIVACY },
    { name: "Cookie policy", link: PATH.COOKIE },
  ];
  const support = [
    { name: "FAQs", link: PATH.FAQ },
    { name: "Contact", link: PATH.CONTACT_US },
  ];
  const officialpage = [
    { name: "About us", link: PATH.ABOUT },
    { name: "We work on", link: PATH.WE_WORK },
    { name: "How we work", link: PATH.HOW_WE_WORK },
    { name: "Current openings", link: PATH.CURRENT_OPENING },
    { name: "Blogs", link: PATH.BLOG },
  ];

  return (
    <React.Fragment>
      <div className="azil-line" />
      <section className="azil-section-footer">
        <footer>
          <div className="azil-footer-container">
            <div className="row mb-5 desktop-footer">
              <div className="col-lg-3 col-sm-6 col-md-3 col-12">
                <div className="font-700 font-16 font-secondary my-2">
                  Our
                  <span className="font-primary"> Network</span>
                </div>
                <div>
                  {ournetwork.map((ournetwork, i) => (
                    <div key={i} className="azil-footer-item my-2">
                      <a href={ournetwork.link} target="_blank" className="font-dark-grey azil-link-text" rel="noreferrer">
                        <span>{ournetwork.name}</span>
                      </a>
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 col-md-3 col-12">
                <div style={{marginTop: "40px"}}></div>
                <div>
                  {ournetwork.map((ournetwork, i) => (
                    <div key={i} className="azil-footer-item my-2">
                      <a href={ournetwork.link} target="_blank" className="font-dark-grey azil-link-text" rel="noreferrer">
                        <span>{ournetwork.name1}</span>
                      </a>
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 col-md-3 col-12">
                <div className="font-700 font-16 font-secondary my-2">Legal</div>
                <div>
                  {legal.map((legal, i) => (
                    <div key={i} className="azil-footer-item my-2">
                      <Link to={{pathname: legal.link}} className="font-dark-grey azil-link-text">
                        <span>{legal.name}</span>
                      </Link>
                    </div>
                  ))}
                </div>

                <div className="font-700 font-16 font-secondary my-2">Support</div>
                <div>
                  {support.map((support, i) => (
                    <div key={i} className="azil-footer-item my-2">
                      <Link to={{pathname: support.link}} className="font-dark-grey azil-link-text">
                        <span>{support.name}</span>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>

              <div className="col-lg-3 col-sm-6 col-md-3 col-12">
                <div className="font-700 font-16 font-secondary my-2">
                  IT
                  <span className="font-primary"> Site</span>
                </div>
                <div>
                  {officialpage.map((it, i) => (
                    <div key={i} className="azil-footer-item my-2">
                      <Link to={{pathname: it.link}} className="font-dark-grey azil-link-text">
                        <span>{it.name}</span>
                      </Link>
                    </div>
                  ))}
                </div>
                <div className="font-700 font-16 font-secondary my-2">
                  Your
                  <span className="font-primary"> Grow</span>
                </div>
                <button className="azil-footer-ad-button container-red-button">
                  <img src={IMAGECONST.Advertise} alt="azillik" draggable={false} className="pr-2" />
                  Advertise with us
                </button>
              </div>
            </div>

            <div className="mobile-footer">
              <div>
                <div className="col-12">
                  <div
                    className={
                      toggleNetwork
                        ? "mobile-dropdown mb-3 active"
                        : "mobile-dropdown mb-3"
                    }
                  >
                    <div
                      className="select"
                      onClick={() => {
                        settoggleNetwork(!toggleNetwork);
                        settoggleSupport(false);
                        settoggleLegal(false);
                        settogglePage(false);
                      }}
                    >
                      <p className="font-16 font-secondary font-600 my-2">
                        Official <span className="font-primary"> Network</span>
                      </p>
                      <div>
                        <img
                          src={DropDown}
                          alt="arrow"
                          draggable="false" className="azil-mobile-footer-downarrow"
                        />
                      </div>
                    </div>
                    <ul
                      className="mobile-dropdown-menu"
                      style={{
                        display: toggleNetwork ? "block" : "none",
                      }}
                      data-aos="slide-up"
                    >
                      <li>
                        <div
                          className="d-flex align-items-center w-100"
                          onClick={toggleModal}
                        >
                          <div className="azil-footer-box-content">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="11"
                              height="6"
                              fill="none"
                              viewBox="0 0 11 6"
                              className="azil-mobile-footer-sidearrow"
                            >
                              <path
                                fill="#696969"
                                d="M5.061 5.53a.712.712 0 01-.496-.2L.255 1.16a.664.664 0 010-.961.719.719 0 01.993 0l3.813 3.69L8.874.2a.718.718 0 01.993 0 .664.664 0 010 .96l-4.31 4.172a.712.712 0 01-.496.199z"
                              ></path>
                            </svg>
                            <span> Azillik Discovery</span>
                          </div>
                        </div>

                        <div className="d-flex align-items-center w-100">
                          <Link href="http://it.azillik.com/" target="_blank">
                            <div className="azil-footer-box-content">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="11"
                                height="6"
                                fill="none"
                                viewBox="0 0 11 6"
                                className="azil-mobile-footer-sidearrow"
                              >
                                <path
                                  fill="#696969"
                                  d="M5.061 5.53a.712.712 0 01-.496-.2L.255 1.16a.664.664 0 010-.961.719.719 0 01.993 0l3.813 3.69L8.874.2a.718.718 0 01.993 0 .664.664 0 010 .96l-4.31 4.172a.712.712 0 01-.496.199z"
                                ></path>
                              </svg>
                              <span> IT Services</span>
                            </div>
                          </Link>
                        </div>

                        <div className="d-flex align-items-center w-100">
                          <Link href="https://www.colors.azillik.com/" target="_blank">
                            <div className="azil-footer-box-content">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="11"
                                height="6"
                                fill="none"
                                viewBox="0 0 11 6"
                                className="azil-mobile-footer-sidearrow"
                              >
                                <path
                                  fill="#696969"
                                  d="M5.061 5.53a.712.712 0 01-.496-.2L.255 1.16a.664.664 0 010-.961.719.719 0 01.993 0l3.813 3.69L8.874.2a.718.718 0 01.993 0 .664.664 0 010 .96l-4.31 4.172a.712.712 0 01-.496.199z"
                                ></path>
                              </svg>
                              <span> Color Swatch</span>
                            </div>
                          </Link>
                        </div>

                        <div className="d-flex align-items-center w-100">
                          <Link to="http://it.azillik.com/" target="_blank">
                            <div className="azil-footer-box-content">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="11"
                                height="6"
                                fill="none"
                                viewBox="0 0 11 6"
                                className="azil-mobile-footer-sidearrow"
                              >
                                <path
                                  fill="#696969"
                                  d="M5.061 5.53a.712.712 0 01-.496-.2L.255 1.16a.664.664 0 010-.961.719.719 0 01.993 0l3.813 3.69L8.874.2a.718.718 0 01.993 0 .664.664 0 010 .96l-4.31 4.172a.712.712 0 01-.496.199z"
                                ></path>
                              </svg>
                              <span> Gradient Swatch</span>
                            </div>
                          </Link>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div>
                <div className="col-12">
                  <div
                    className={
                      togglePage
                        ? "mobile-dropdown mb-3 active"
                        : "mobile-dropdown mb-3"
                    }
                  >
                    <div
                      className="select"
                      onClick={() => {
                        settogglePage(!togglePage);
                        settoggleNetwork(false);
                        settoggleSupport(false);
                        settoggleLegal(false);
                      }}
                    >
                      <span className="font-16 font-secondary font-600 my-2">
                        Official <span className="font-primary"> Page</span>
                      </span>
                      <div>
                        <img
                          src={DropDown}
                          alt="arrow"
                          draggable="false"
                        />
                      </div>
                    </div>
                    <ul
                      className="mobile-dropdown-menu"
                      style={{
                        display: togglePage ? "block" : "none",
                      }}
                      data-aos="slide-up"
                    >
                      <li>
                        <div
                          className="d-flex align-items-center w-100" onClick={toggleModal}
                        >
                          <div className="azil-footer-box-content">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="11"
                              height="6"
                              fill="none"
                              viewBox="0 0 11 6"
                              className="azil-mobile-footer-sidearrow"
                            >
                              <path
                                fill="#696969"
                                d="M5.061 5.53a.712.712 0 01-.496-.2L.255 1.16a.664.664 0 010-.961.719.719 0 01.993 0l3.813 3.69L8.874.2a.718.718 0 01.993 0 .664.664 0 010 .96l-4.31 4.172a.712.712 0 01-.496.199z"
                              ></path>
                            </svg>
                            <span> About us</span>
                          </div>
                        </div>
                        <div
                          className="d-flex align-items-center w-100" onClick={toggleModal}
                        >
                          <div className="azil-footer-box-content">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="11"
                              height="6"
                              fill="none"
                              viewBox="0 0 11 6"
                              className="azil-mobile-footer-sidearrow"
                            >
                              <path
                                fill="#696969"
                                d="M5.061 5.53a.712.712 0 01-.496-.2L.255 1.16a.664.664 0 010-.961.719.719 0 01.993 0l3.813 3.69L8.874.2a.718.718 0 01.993 0 .664.664 0 010 .96l-4.31 4.172a.712.712 0 01-.496.199z"
                              ></path>
                            </svg>
                            <span> We work on</span>
                          </div>
                        </div>
                        <div
                          className="d-flex align-items-center w-100" onClick={toggleModal}
                        >
                          <div className="azil-footer-box-content">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="11"
                              height="6"
                              fill="none"
                              viewBox="0 0 11 6"
                              className="azil-mobile-footer-sidearrow"
                            >
                              <path
                                fill="#696969"
                                d="M5.061 5.53a.712.712 0 01-.496-.2L.255 1.16a.664.664 0 010-.961.719.719 0 01.993 0l3.813 3.69L8.874.2a.718.718 0 01.993 0 .664.664 0 010 .96l-4.31 4.172a.712.712 0 01-.496.199z"
                              ></path>
                            </svg>
                            <span> How we work</span>
                          </div>
                        </div>
                        <div
                          className="d-flex align-items-center w-100" onClick={toggleModal}
                        >
                          <div className="azil-footer-box-content">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="11"
                              height="6"
                              fill="none"
                              viewBox="0 0 11 6"
                              className="azil-mobile-footer-sidearrow"
                            >
                              <path
                                fill="#696969"
                                d="M5.061 5.53a.712.712 0 01-.496-.2L.255 1.16a.664.664 0 010-.961.719.719 0 01.993 0l3.813 3.69L8.874.2a.718.718 0 01.993 0 .664.664 0 010 .96l-4.31 4.172a.712.712 0 01-.496.199z"
                              ></path>
                            </svg>
                            <span> Current openings</span>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div>
                <div className="col-12">
                  <div
                    className={
                      toggleLegal
                        ? "mobile-dropdown mb-3 active"
                        : "mobile-dropdown mb-3"
                    }
                  >
                    <div
                      className="select"
                      onClick={() => {
                        settoggleNetwork(false);
                        settoggleSupport(false);
                        settoggleLegal(!toggleLegal);
                        settogglePage(false);
                      }}
                    >
                      <span className="font-16 font-600 font-secondary my-2">Legal</span>
                      <div>
                        <img
                          src={DropDown}
                          alt="arrow"
                          draggable="false"
                        />
                      </div>
                    </div>
                    <ul
                      className="mobile-dropdown-menu"
                      style={{
                        display: toggleLegal ? "block" : "none",
                      }}
                      data-aos="slide-down"
                    >
                      <li>
                        <div className="d-flex align-items-center w-100" onClick={toggleModal} >
                          <div className="azil-footer-box-content">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="11"
                              height="6"
                              fill="none"
                              viewBox="0 0 11 6"
                              className="azil-mobile-footer-sidearrow"
                            >
                              <path
                                fill="#696969"
                                d="M5.061 5.53a.712.712 0 01-.496-.2L.255 1.16a.664.664 0 010-.961.719.719 0 01.993 0l3.813 3.69L8.874.2a.718.718 0 01.993 0 .664.664 0 010 .96l-4.31 4.172a.712.712 0 01-.496.199z"
                              ></path>
                            </svg>
                            <span> Terms of services</span>
                          </div>
                        </div>
                        <div className="d-flex align-items-center w-100" onClick={toggleModal} >
                          <div className="azil-footer-box-content">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="11"
                              height="6"
                              fill="none"
                              viewBox="0 0 11 6"
                              className="azil-mobile-footer-sidearrow"
                            >
                              <path
                                fill="#696969"
                                d="M5.061 5.53a.712.712 0 01-.496-.2L.255 1.16a.664.664 0 010-.961.719.719 0 01.993 0l3.813 3.69L8.874.2a.718.718 0 01.993 0 .664.664 0 010 .96l-4.31 4.172a.712.712 0 01-.496.199z"
                              ></path>
                            </svg>
                            <span> Privacy policy</span>
                          </div>
                        </div>
                        <div className="d-flex align-items-center w-100" onClick={toggleModal} >
                          <div className="azil-footer-box-content">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="11"
                              height="6"
                              fill="none"
                              viewBox="0 0 11 6"
                              className="azil-mobile-footer-sidearrow"
                            >
                              <path
                                fill="#696969"
                                d="M5.061 5.53a.712.712 0 01-.496-.2L.255 1.16a.664.664 0 010-.961.719.719 0 01.993 0l3.813 3.69L8.874.2a.718.718 0 01.993 0 .664.664 0 010 .96l-4.31 4.172a.712.712 0 01-.496.199z"
                              ></path>
                            </svg>
                            <span> Cookie policy</span>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div>
                <div className="col-12">
                  <div
                    className={
                      toggleSupport
                        ? "mobile-dropdown mb-3 active"
                        : "mobile-dropdown mb-3"
                    }
                  >
                    <div
                      className="select"
                      onClick={() => {
                        settoggleSupport(!toggleSupport);
                        settoggleNetwork(false);
                        settoggleLegal(false);
                        settogglePage(false);
                      }}
                    >
                      <span className="font-16 font-600 font-secondary my-2">Support</span>
                      <div>
                        <img
                          src={DropDown}
                          alt="arrow"
                          draggable="false"
                        />
                      </div>
                    </div>
                    <ul
                      className="mobile-dropdown-menu"
                      style={{
                        display: toggleSupport ? "block" : "none",
                      }}
                      data-aos="slide-down"
                    >
                      <li>
                        <div className="d-flex align-items-center w-100" onClick={toggleModal} >
                          <div className="azil-footer-box-content">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="11"
                              height="6"
                              fill="none"
                              viewBox="0 0 11 6"
                              className="azil-mobile-footer-sidearrow"
                            >
                              <path
                                fill="#696969"
                                d="M5.061 5.53a.712.712 0 01-.496-.2L.255 1.16a.664.664 0 010-.961.719.719 0 01.993 0l3.813 3.69L8.874.2a.718.718 0 01.993 0 .664.664 0 010 .96l-4.31 4.172a.712.712 0 01-.496.199z"
                              ></path>
                            </svg>
                            <span> FAQs</span>
                          </div>
                        </div>
                        <div className="d-flex align-items-center w-100" onClick={toggleModal} >
                          <div className="azil-footer-box-content">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="11"
                              height="6"
                              fill="none"
                              viewBox="0 0 11 6"
                              className="azil-mobile-footer-sidearrow"
                            >
                              <path
                                fill="#696969"
                                d="M5.061 5.53a.712.712 0 01-.496-.2L.255 1.16a.664.664 0 010-.961.719.719 0 01.993 0l3.813 3.69L8.874.2a.718.718 0 01.993 0 .664.664 0 010 .96l-4.31 4.172a.712.712 0 01-.496.199z"
                              ></path>
                            </svg>
                            <span> Contact</span>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="azil-line" />
            <div className="azil-home-container d-flex flex-wrap justify-content-between align-items-center azil-res-last-footer">
              <div className="d-flex flex-wrap align-items-center azil-res-social-footer">
                <div className="azil-footer-logo">
                  <Link
                    className="azil-text-normal px-0 azil-footer-logo"
                    to={PATH.ROOT}
                  >
                    <img
                      src={IMAGECONST.Footerlogo}
                      alt="azillik-it-service"
                      className="mr-3"
                      height="50" width="194"
                      draggable="false"
                    />
                  </Link>
                </div>
                <div className="azil-copyright font-14 font-grey">
                  Copyright © 2020-{new Date().getFullYear()} ❤️️ 
                  <Link to="#" className="font-600 font-primary"
                  >
                    {" "}
                    Azillik Company
                  </Link> {" "}S.L. All Rights Reserved.
                </div>
              </div>
              <div className="p-0 m-0">
                <div className="d-flex align-items-center justify-content-between p-0 azil-social">
                  <SocialMediaButton />
                </div>
              </div>
            </div>
          </div>
        </footer>
      </section>
    </React.Fragment>
  );
};

export default Footer;