import React from "react";
import "./css/bootstrap.min.css";
import "./css/header.css";
import "./css/intlTelInput.css";
import "./css/ionicon.min.css";
import "./css/reset.min.css";
import "./css/style.css";
import "./css/responsive.css";
// import "./css/style.min.css";
import Markup from "./markup/Markup";

function App() {
  return (
    <div className="App">
      <Markup />
    </div>
  );
}

export default App;
