import React from "react";

const CustomTextarea = ({
  type,
  values,
  name,
  placeholder,
  errors,
  touched,
  handleChange,
  defaultValue,
  className,
}) => {
  const isError = errors[name] && touched[name];

  return (
    <>
      <div className="azil-show-error">
        <textarea
          type={type}
          value={values[name]}
          name={name}
          placeholder={isError ? "" : placeholder}
          onChange={handleChange}
          autoComplete="off"
          defaultValue={defaultValue}
          style={{
            minHeight: "80px",
            maxHeight: "500px",
            textAlign: "justify",
            border: isError ? "1px solid #ff3366" : "",
            background: isError ? "#fcd4ee30" : "",
          }}
        ></textarea>

        {isError && (
          <label className="azil-show-error-message azil-show-error-textarea-message">
            {errors[name]}
          </label>
        )}
      </div>
    </>
  );
};

export default CustomTextarea;
