/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { imagesConst as IMAGECONST } from "../../../components/constants/imagesConst";
import BlogHeader from "./BlogHeader";
import { Link } from "react-router-dom";
import moment from "moment";
import ReactHtmlParser from "react-html-parser";
import { constants as PATH } from "../../../components/constants/componentPath";
import { Seo } from "../../../components/custom/Seo";
import BlogFooter from "./BlogFooter";
import BlogTest from "./BlogDataTest.json"
import SocialBlog from "./SocialBlog";

const BlogInnner = (props ) => {
  const { blog } =
    props &&
    props.history &&
    props.history.location &&
    props.history.location.state;
    
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    const [blogData, setBlogData] = useState(BlogTest);

    const [estimatedReadTime, setEstimatedReadTime] = useState(0);
    useEffect(() => {
      calculateReadTime();
    }, [blogData]);
  
    const calculateReadTime = () => {
      const wordsPerMinute = 200;
      const content = blogData.blog_content;
      const wordCount = content.split(/\s+/).length;
      const estimatedReadTime = Math.ceil(wordCount / wordsPerMinute);
  
      setEstimatedReadTime(estimatedReadTime);
    };

  return (
    <React.Fragment>
      <Seo
        title={blog.title + " - Azillik Blogs"}
        description={blog.description}
        keyword={blog.title + " - Azillik Blogs"}
      />
      <BlogHeader />
      {/* <section className="azil-blog-inner-hero-section azil-section-padding">
        <div className="container">
          <div className="text-center">
            <div className="azil-abt-content my-2 azil-text-purple text-uppercase">
              {blog && blog.tag}
            </div>
            <div className="col-lg-10 m-auto azil-primary-title">
              {blog && blog.title}
            </div>
            <div className="col-lg-10 m-auto azil-primary-content">
              {blog && blog.description}
            </div>
            <Link
              to={{
                pathname: PATH.BLOG + "/author/" + blog.admin_firstname,
                state: { blog: blog },
              }}
            >
              <div className="azil-author-img p-0">
                <img
                  src={
                    blog && blog.admin_profile
                      ? blog.admin_profile
                      : IMAGECONST.BLOG_INNER
                  }
                  alt=""
                  className="img-fluid"
                  draggable="false"
                />
              </div>
              <div className="azil-secondary-title azil-text-black pt-2">
                {" "}
                {blog &&
                  blog.admin_firstname + " " + blog &&
                  blog.admin_lastname}
              </div>
              <div className="azil-blog-inner-content my-2">
                Published on{" "}
                {moment(blog && blog.created_at).format("Do MMMM, YYYY")}
              </div>
            </Link>
          </div>
        </div>
      </section> */}

      {/* <section className="azil-blog-section-3">
        <img
          src={blog && blog.blog_image ? blog.blog_image : IMAGECONST.BLOG_INNER_1}
          alt=""
          className="img-fluid w-100 azil-blog-img"
          draggable="false"
        />
      </section> */}

      {/* <section className="azil-blog-inner section-2 azil-section-padding">
        <div className="azil-custom-container">
          <div className="azil-blog-inner-box">
            <div className="d-flex justify-content-between">
              <div className="azil-blog-flex-first shadow">
                {ReactHtmlParser(blog && blog.blog_content)}
              </div>
            </div>
          </div>
        </div>
      </section> */}


      <div className="azil-screen-container">
        <div className="azil-blog-inner-foreground">
          <div className="azil-blog-container">
            <div className="d-flex">
              <span className="azil-blog-tag-button">
                {blog && blog.tag}
              </span>
            </div>
            <h1 className="font-white font-700 azil-res-blog-inner-title">
              {blog && blog.title}
            </h1>
            <div className="azil-line my-2" style={{borderBottom: "1px solid white"}}></div>
            
            <div className="my-3 d-flex justify-content-between align-items-center flex-wrap">
              <Link to={{pathname: PATH.BLOG + "/author/" + blog.admin_firstname, state: { blog: blog }}} className="d-flex">
                <div className="azil-blog-img ">
                  <img
                      src={IMAGECONST.Bloginner}
                      alt="profile" className="img-fluid mr-3"
                      draggable="false" width={44} height={44}
                  />
                </div>
                <div>
                  <p>
                    <span className="font-14 font-white font-300">By</span>
                    <span className="font-14 font-white font-600">
                      {" "}
                      {blog?.admin_firstname ||
                      blog?.admin_lastname
                        ? `${
                            blog.admin_firstname || ""
                          } ${
                            blog.admin_lastname || ""
                          }`
                        : "Azillik user"}
                    </span>
                  </p>
                  <ul className="p-0 m-0 font-12 font-400 font-white d-flex align-items-center">
                    <li className="azil-list-style-none">{moment(blogData && blogData.created_at).format("MMMM DD, YYYY")}</li>
                    <li className="mx-4">{estimatedReadTime} minutes</li>
                  </ul>
                </div>
              </Link>

              {/* <div>
                <div className="d-flex align-items-center social-logo-container">
                  <div className="azil-facebook azil-blog-social-icon">
                    <a href="https://www.facebook.com/azillik.discovery01" target="_blank" rel="noopener noreferrer" onClick={() => window.open(facebookShareLink, '_blank')}>
                      <img src={IMAGECONST.Facebook} alt="facebook" draggable={false} />
                    </a>
                  </div>
                  <div className="azil-instagram azil-blog-social-icon">
                    <a href="https://www.instagram.com/azillik_discovery/" target="_blank" rel="noopener noreferrer" onClick={() => window.open(instagramShareLink, '_blank')}>
                      <img src={IMAGECONST.Instagram} alt="instagram" draggable={false} />
                    </a>
                  </div>
                  <div className="azil-linkedin azil-blog-social-icon">
                    <a href="https://www.linkedin.com/company/azillik/" target="_blank" rel="noopener noreferrer">
                      <img src={IMAGECONST.Linkedin} alt="linkedin"  draggable={false} />
                    </a>
                  </div>
                  <div className="azil-twitter azil-blog-social-icon">
                    <a href="https://twitter.com/azillik_" target="_blank" rel="noopener noreferrer" onClick={() => window.open(twitterShareLink, '_blank')}>
                      <img src={IMAGECONST.Twitter} alt="twitter"  draggable={false} />
                    </a>
                  </div>
                  <div className="azil-youtube azil-blog-social-icon">
                    <a href="https://www.youtube.com/channel/UCLbV2Ylfz3SPQ4SsmILAVVg" target="_blank" rel="noopener noreferrer">
                      <img src={IMAGECONST.Youtube} alt="youtube"  draggable={false} />
                    </a>
                  </div>
                  <div className="azil-telegram azil-blog-social-icon">
                    <a href="https://www.youtube.com/channel/UCLbV2Ylfz3SPQ4SsmILAVVg" target="_blank" rel="noopener noreferrer">
                      <img src={IMAGECONST.Telegram} alt="telegram"  draggable={false} />
                    </a>
                  </div>
                  <div className="azil-whatsapp azil-blog-social-icon">
                    <a href="https://www.youtube.com/channel/UCLbV2Ylfz3SPQ4SsmILAVVg" target="_blank" rel="noopener noreferrer">
                      <img src={IMAGECONST.Whatsapp} alt="Whatsapp"  draggable={false} />
                    </a>
                  </div>
                </div>
              </div> */}
              <SocialBlog url={window.location.href} title={blog.title} thumbnail={blog.blog_image} description={blog.blog_content} />   
            </div>
          </div>
        </div>

        <div className="azil-blog-container mt-5">
          <div>
            <img
              src={blog && blog.blog_image ? blog.blog_image : IMAGECONST.Bloginner1}
              alt="blog banner"
              className="img-fluid azil-blog-innerbanner"
              draggable="false"
            />
          </div>
          <div className="azil-blog-content">
            {ReactHtmlParser(blogData && blog.blog_content)}
          </div>
        </div>
      </div>

      <BlogFooter />
    </React.Fragment>
  );
};


export default BlogInnner;