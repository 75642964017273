import React, { useEffect, useRef, useState } from "react";
import Footer from "../../Layout/Footer";
import Header from "../../Layout/Header";
import { imagesConst as IMAGECONST } from "../../../components/constants/imagesConst";
import CustomBlog from "../../../components/custom/customBlog";
import CustomContactUs from "../../../components/custom/CustomContactUs";
import { Seo } from "../../../components/custom/Seo";
import { seoConstants as SEO_CONST } from "../../../components/constants/seoConst";
import { Col, Row } from "reactstrap";
import { GetCustomSoftwares } from "../../../services/ContactService";
import AOS from "aos";
import "aos/dist/aos.css";
import ReactHtmlParser from "react-html-parser";


const CustomSoftware = (props) => {
  const type =
    props &&
    props.location &&
    props.location.state &&
    props.location.state.type;

  const [customData, setCustomData] = useState([]);
  const consultAzil = useRef();

  const getAllCustomSoftwares = () => {
    const pagination = `?page=1`;
    GetCustomSoftwares(pagination)
      .then((res) => {
        const data = res && res.data && res.data.data && res.data.data.data;
        const result = data.filter((item) => item.category === type);
        setCustomData(result);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getAllCustomSoftwares();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);

  /* -------------------------------------------------------------------------- */
  /*                            Button ripple effect                            */
  /* -------------------------------------------------------------------------- */
  const handleHover = (e) => {
    const button = e.target;
    const rect = button.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    const rippleSpan = document.createElement('span');
    rippleSpan.classList.add('ripple-span');
    rippleSpan.style.top = `${y}px`;
    rippleSpan.style.left = `${x}px`;
    button.appendChild(rippleSpan);
  };

  const handleHoverEnd = (e) => {
    const button = e.target;
    const ripples = button.getElementsByClassName('ripple-span');
    while (ripples.length > 0) {
      button.removeChild(ripples[0]);
    }
  };

  return (
    <React.Fragment>
      <Seo
        title={SEO_CONST.CUSTOM_SOFTWARE_TITLE}
        description={""}
        keyword={SEO_CONST.CUSTOM_SOFTWARE_TITLE}
      />
      <Header />
      <section className="azil-service-section azil-section-padding azil-bg-hero">
        <div className="azil-home-container" data-aos="fade-up">
          <div className="row align-items-center">
            <div className="col-lg-7 col-md-6 col-sm-12 col-12 my-2">
              <div className="azil-section-title my-2">
                {customData[0] && customData[0].title ? (
                  <>
                    {customData[0].title.split(" ")[0]}
                    <span className="font-primary">
                      {" "}
                      {customData[0] && customData[0].title.split(" ")[1]}{" "}
                    </span>
                  </>
                ) : (
                  <>
                    Custom{" "}
                    <span className="font-primary">
                      {" "}
                      Software Development{" "}
                    </span>{" "}
                    Company{" "}
                  </>
                )}
              </div>
              <div className="azil-description mt-4">
                {customData[0] && customData[0].description ? (
                  <>{customData[0] && customData[0].description}</>
                ) : (
                  <>
                    A software that is tailor-made and meets every standard you
                    want to meet can now be a reality with us.
                  </>
                )}
              </div>
              <button
                className="azil-hero-button my-3"
                onClick={() =>
                  consultAzil.current.scrollIntoView({ behavior: "smooth" }) 
                }
                onMouseEnter={handleHover} onMouseLeave={handleHoverEnd}
              >
                Consult Azillik
              </button>
            </div>
            <div className="col-lg-5 col-md-6 col-sm-12 col-12 my-2 text-right">
              <img
                src={IMAGECONST.Customimage1}
                alt="banner"
                className="img-fluid"
                draggable="false"
                width={350} height={350}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="azil-service-section-2 azil-section-padding">
        <div className="azil-home-container" data-aos="fade-up">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-6 col-sm-12 col-12 my-2 text-right">
              <img
                src={IMAGECONST.Customimage2}
                alt="company"
                className="img-fluid"
                draggable="false"
              />
            </div>
            <div className="col-lg-7 col-md-6 col-sm-12 col-12 my-2">
              <div className="azil-primary-heading my-2">
                Be In<span className="font-primary"> Good Company </span>
              </div>
              <div className="azil-description mt-4">
                Whether it is for enterprise businesses, SMBs, or even
                large-scale projects for MNCs, Azillik is equipped to provide
                comprehensive and custom software development services.
              </div>
              <div className="d-flex align-items-center mt-4">
                <div className="azil-good-cmp-img">
                  <img
                    src={IMAGECONST.Checkmark}
                    alt="checkmark"
                    draggable="false"
                  />
                </div>
                <div className="azil-good-cmp-content mx-3">
                  <div className="azil-description">
                    <span className="font-700 font-secondary"> 95%</span> Customer Retention Rate
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center my-2">
                <div className="azil-good-cmp-img">
                  <img
                    src={IMAGECONST.Checkmark}
                    alt="checkmark"
                    draggable="false"
                  />
                </div>
                <div className="azil-good-cmp-content mx-3">
                  <div className="azil-description">
                  <span className="font-700 font-secondary"> 150</span> Active World Class Clients
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center my-2">
                <div className="azil-good-cmp-img">
                  <img
                    src={IMAGECONST.Checkmark}
                    alt="checkmark"
                    draggable="false"
                  />
                </div>
                <div className="azil-good-cmp-content mx-3">
                  <div className="azil-description">
                    <span className="font-700 font-secondary"> NDA</span> Agreement
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

{/* <div className="azil-service-section-3 azil-section-padding ml-3">

</div> */}
      <section className="azil-service-section-3 azil-section-padding ml-3 azil-bg-hero">
        <div className="azil-home-container" data-aos="fade-up">
          <div className="row">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-6 col-sm-12 col-12 my-2">
                <div className="azil-primary-heading my-2">
                  3 Reasons<span className="font-primary"> To Choose Azillik </span>
                </div>
                <div className="azil-description mt-4">
                  When you think about software development, it’s not just the
                  coding and development, it’s about the entire process. At
                  Azillik not only do we keep the features, functionality, UI &
                  UX in mind, but we ensure that it is a scalable, flexible, and
                  absolutely secure software solution.
                </div>
                <div className="d-flex align-items-center mt-4">
                  <div className="azil-good-cmp-img">
                    <img
                      src={IMAGECONST.Checkmark}
                      alt="checkmark"
                      draggable="false"
                    />
                  </div>
                  <div className="azil-good-cmp-content mx-3">
                    <div className="azil-description">
                      Experts with experience of <span className="font-700 font-secondary"> 1 to 10+ years</span>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center my-2">
                  <div className="azil-good-cmp-img">
                    <img
                      src={IMAGECONST.Checkmark}
                      alt="checkmark"
                      draggable="false"
                    />
                  </div>
                  <div className="azil-good-cmp-content mx-3">
                    <div className="azil-description">
                      Smooth Resource Transitions
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center my-2">
                  <div className="azil-good-cmp-img">
                    <img
                      src={IMAGECONST.Checkmark}
                      alt="checkmark"
                      className="img-fluid"
                      draggable="false"
                    />
                  </div>
                  <div className="azil-good-cmp-content mx-3">
                    <div className="azil-description">
                      On boarding in less than <span className="font-700 font-secondary"> 72 hours</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-12 my-2">
                <img
                  src={IMAGECONST.Customimage3}
                  alt="choose"
                  className="img-fluid"
                  draggable="false"
                  width={350} height={350} style={{float: "right"}}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="azil-service-section-4 azil-section-padding azil-bg-hero">
        <div className="azil-home-container" data-aos="fade-up">
          <div className="text-center">
            <div className="azil-primary-heading my-2">
              {customData[0] && customData[0].section_4 ? (
                <>
                  Introduction Of {" "}
                  <span className="font-primary">
                    {customData[0].section_4.split(" ")[0]}{" "}
                    {customData[0] &&
                      customData[0].section_4.split(" ")[1]}{" "}
                  </span>
                  {customData[0] && customData[0].section_4.split(" ")[2]}{" "}
                </>
              ) : (
                <>
                  Custom <span className="font-primary"> Software Development</span> Solutions
                </>
              )}
            </div>
          </div>
          <div className="row align-items-center">
            
          </div>
          <div className="row">
            {/* <div className="col-lg-6 col-md-6 col-sm-12 col-12 my-2">
              <div className="azil-description my-4">
                With over decade long experience in the field of providing
                custom software development services and catering to clients
                panning all over the world, there is a responsibility to do our
                best every single time.
              </div>
              <div className="azil-description my-4">
                Our custom software development primarily focuses on “What does
                the client want?” and for that we are even prepared to upgrade
                ourselves from time to time with the latest technology stack,
                hire the best resources and strive to fulfill the delivery of
                the project in hand.
              </div>
              <div className="azil-description my-4">
                We have a structured approach and client-friendly policies
                making the best-in-class custom software development services
                affordable and a reality. Bring your ideas to us and we are sure
                we can put our vast business experience and market-insight to
                good use for your custom software development.
              </div>
            </div> */}
            <div className="col-lg-6 col-md-6 col-sm-12 col-12 my-2">
              <div className="azil-description mt-4">
              {customData[0] && customData[0].section_5 ? (
                  <>
                  {customData[0].section_5.split(" ")[0]}
                  <span className="azil-text-purple">
                    {" "}
                    {customData[0] &&
                      customData[0].section_5.split(" ")[1]}{" "}
                  </span>
                  {customData[0] && customData[0].section_5.split(" ")[2]}{" "}
                </>
                ) : (
                  <>
                    A software that is tailor-made and meets every standard you
                    want to meet can now be a reality with us.
                  </>
                )}
            </div>
            <div className="col-6">
                {customData[0] && customData[0].blog_content ? (
                  <><div> {ReactHtmlParser(customData[0] && customData[0].blog_content)}</div></>
                ) : (
                  <>
                    A software that is tailor-made and meets every standard you
                    want to meet can now be a reality with us.
                  </>
                )}
            </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-12 my-2">
              <img
                src={IMAGECONST.Customimage4}
                alt="solution"
                className="img-fluid"
                draggable="false"
                width={350} height={350} style={{float: "right"}}
              />
            </div>
          </div>
        </div>
      </section>

      {/* <section className="azil-service-section-5 azil-section-padding">
        <div className="azil-custom-container" data-aos="fade-up">
          <div className="text-center">
            <div className="azil-primary-title my-2">
              {customData[0] && customData[0].section_5 ? (
                <>
                  {customData[0].section_5.split(" ")[0]}
                  <span className="azil-text-purple">
                    {" "}
                    {customData[0] &&
                      customData[0].section_5.split(" ")[1]}{" "}
                  </span>
                  {customData[0] && customData[0].section_5.split(" ")[2]}{" "}
                </>
              ) : (
                <>
                  Most Effective
                  <span> Software Development Outsourcing </span>
                  Services
                </>
              )}
            </div>
            <div className="azil-primary-content azil-text-gray my-2">
              There are two main models to work with:{" "}
            </div>
            <div className="d-flex align-items-center justify-content-center py-4">
              <Row>
                <Col xl={6} md={6} lg={6} sm={12}>
                  <div className="service-detail-box-1 my-4">
                    <div className="azil-primary-content azil-text-bold py-3 px-4">
                      Staff Augmention
                    </div>
                    <div className="azil-primary-content my-4 azil-text-gray px-4">
                      Best for Agile methodology
                    </div>
                    <div className="azil-primary-content my-4 azil-text-gray px-4">
                      Large talent pool to choose from
                    </div>
                    <div className="azil-primary-content my-4 azil-text-gray px-4">
                      System evolves with time
                    </div>
                    <div className="azil-primary-content my-4 azil-text-gray px-4">
                      Deadlines are on scrums
                    </div>
                    <div className="azil-primary-content my-4 azil-text-gray px-4">
                      Best flexibility during development
                    </div>
                  </div>
                </Col>
                <Col xl={6} md={6} lg={6} sm={12}>
                  <div className="service-detail-box-2 my-4">
                    <div className="azil-primary-content azil-text-bold py-3 sd-2">
                      Project-Based Model
                    </div>
                    <div className="azil-primary-content my-4 azil-text-gray px-4">
                      Best for Agile methodology
                    </div>
                    <div className="azil-primary-content my-4 azil-text-gray px-4">
                      Large talent pool to choose from
                    </div>
                    <div className="azil-primary-content my-4 azil-text-gray px-4">
                      System evolves with time
                    </div>
                    <div className="azil-primary-content my-4 azil-text-gray px-4">
                      Deadlines are on scrums
                    </div>
                    <div className="azil-primary-content my-4 azil-text-gray px-4">
                      Best flexibility during development
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </section> */}

      {/* {customData[0] &&
      customData[0].techData &&
      customData[0].techData.length > 0 ? (
        <>
          <section className="azil-service-section-6 azil-section-padding azil-bg-hero">
            <div className="azil-custom-container" data-aos="fade-up">
              <div className="text-center">
                <div className="azil-primary-title my-2">
                  Technology <span>Stack</span>{" "}
                </div>
              </div>
              <div className="row">
                {customData[0] &&
                  customData[0].techData &&
                  customData[0].techData.length > 0 &&
                  customData[0].techData.map((tech, i) => {
                    return (
                      <>
                        <div
                          className="col-lg-4 col-md-6 col-sm-6 col-12 my-3"
                          key={i}
                        >
                          <div className="service-detail-box-1 h-100 p-3">
                            <div
                              className="azil-text-bold py-4"
                              style={{
                                lineHeight: "24px",
                                fontSize: "18px",
                                color: "#0c2955",
                              }}
                            >
                              <img
                                src={tech && tech.tech_image}
                                alt=""
                                className="img-fluid pr-3"
                                draggable="false"
                                style={{
                                  height: 47,
                                  width: 102,
                                }}
                              />
                              {tech && tech.title}
                            </div>
                            <div
                              className="d-flex"
                              style={{ justifyContent: "space-around" }}
                            >
                              <Row>
                                {tech &&
                                  tech.tech_tools.length > 0 &&
                                  tech.tech_tools.map((techTool, index) => {
                                    return (
                                      <>
                                        {index % 2 === 0 ? (
                                          <Col
                                            lg={5}
                                            md={5}
                                            sm={5}
                                            className="mx-2"
                                          >
                                            <div
                                              className="azil-abt-content azil-text-gray py-2"
                                              key={index}
                                            >
                                              <img
                                                src={IMAGE.TECH_STACK_CIRCLE}
                                                alt=""
                                                className="img-fluid pr-3"
                                                draggable="false"
                                                style={{ height: 10 }}
                                              />
                                              {techTool.name}
                                            </div>
                                          </Col>
                                        ) : (
                                          <Col
                                            lg={5}
                                            md={5}
                                            sm={5}
                                            className="mx-2"
                                          >
                                            <div
                                              className="azil-abt-content azil-text-gray py-2"
                                              key={index}
                                            >
                                              <img
                                                src={IMAGE.TECH_STACK_CIRCLE}
                                                alt=""
                                                className="img-fluid pr-3"
                                                draggable="false"
                                                style={{ height: 10 }}
                                              />
                                              {techTool.name}
                                            </div>
                                          </Col>
                                        )}
                                      </>
                                    );
                                  })}
                              </Row>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
          </section>
        </>
      ) : null} */}

      {/* {customData[0] &&
      customData[0].services &&
      customData[0].services.length > 0 ? (
        <>
          <section className="azil-service-section-7 azil-section-padding">
            <div className="azil-custom-container" data-aos="fade-up">
              <div className="text-center">
                <div className="azil-primary-title my-2">
                  {customData[0] && customData[0].section_7 ? (
                    <>
                      {customData[0].section_7.split(" ")[0]}
                      <span className="azil-text-purple">
                        {" "}
                        {customData[0] &&
                          customData[0].section_7.split(" ")[1]}{" "}
                      </span>
                      {customData[0] && customData[0].section_7.split(" ")[2]}{" "}
                    </>
                  ) : (
                    <>
                      Custom <span>Software Development</span> Services
                    </>
                  )}
                </div>
              </div>
              <div className="row">
                {customData[0] &&
                  customData[0].services &&
                  customData[0].services.length > 0 &&
                  customData[0].services.map((service, i) => {
                    return (
                      <div className="col-lg-4 col-md-6 col-12 my-4" key={i}>
                        <div className="azil-vision-box text-left">
                          <div className="svg-icon my-2">
                            <img
                              src={
                                customData[0] && customData[0].custom_image[i]
                              }
                              alt=""
                              className="img-fluid"
                              draggable="false"
                            />
                          </div>
                          <div className="azil-vision-title mt-4 text-left">
                            {" "}
                            <b>{service.sub_title}</b>
                          </div>
                          <div className="azil-primary-content mt-2 azil-text-gray text-left">
                            {service.sub_description}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </section>
        </>
      ) : null} */}

      <section className="azil-service-section-8 azil-section-padding">
        <div className="azil-home-container" data-aos="fade-up">
          <div className="text-center">
            <div className="azil-primary-heading my-2">
              Reads about <span className="font-primary"> New Trends </span> &{" "}
              <span className="font-primary"> Our Launches </span>
            </div>
          </div>
        </div>
        <CustomBlog />
      </section>
      <section
        className="azil-service-section-9 azil-section-padding"
        ref={consultAzil}
        data-aos="fade-up"
      >
        <CustomContactUs />
      </section>
      <Footer />
    </React.Fragment>
  );
};

export default CustomSoftware;
