export const errorConst = {
  ALPHA_REGEX: /^[0-9\b]+$/,
  SPACE_REGEX: /^\S+$/,
  NAME: "Please enter name",
  PHONE_NUMBER: "Please enter phone number",
  EMAIL: "Email Address*",
  MESSAGE: "Please enter message",
  SPACE: "Spaces are not allow",
  VALID_EMAIL: " ",
  DIGIT_ONLY: "Please enter number only",
  MIN_PHONE_NO: "Phone number must be at least 8 characters",
  MAX_PHONE_NO: "Phone number must be at most 10 characters",
  WP_NUMBER: "Please enter whatsapp number",
  MIN_WP_NO: "Whatsapp number must be at least 8 characters",
  MAX_WP_NO: "Whatsapp number must be at most 10 characters",
  JOB_TITLE: "Please enter job title",
  FIRST_NAME: "Please enter first name",
  LAST_NAME: "Please enter last name",
  EXPIRENCE_IN_YEAR: "Please enter experience in years",
  AGE: "Please enter age",
  BIRTH_DATE: "Please enter birth date",
  AVAILABILITY_DAYS: "Please enter availibility days",
  CURRENT_LOCATION: "Please enter current location",
  GENDER: "Please select gender",
  FEATURED: "Please select Featured",
  VALID_NUM: "Please enter valid number",
};
