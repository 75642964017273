import React from "react";
import { Link } from "react-router-dom";
import { imagesConst as IMAGECONST } from "../../../components/constants/imagesConst";
import { constants as PATH } from "../../../components/constants/componentPath";
import SocialMediaButton from "../../../components/custom/SocialMediaButton/SocialMediaButton";

const BlogFooter = () => {
  return (
    <section>
      <footer>
        <div className="azil-line" />
        <div className="azil-footer-container d-flex flex-wrap justify-content-between align-items-center azil-res-last-footer p-0">
          <div className="d-flex flex-wrap align-items-center azil-res-social-footer">
            <div className="azil-footer-logo">
              <Link
                className="azil-text-normal px-0 azil-footer-logo"
                to={PATH.ROOT}
              >
                <img
                  src={IMAGECONST.Footerlogo}
                  alt="azillik-it-service"
                  className="mr-3"
                  height="50" width="194"
                  draggable="false"
                />
              </Link>
            </div>
            <div className="azil-copyright font-14 font-grey">
              Copyright © 2020-{new Date().getFullYear()} ❤️️ 
              <Link to="#" className="font-600 font-primary"
              >
                {" "}
                Azillik Company
              </Link> {" "}S.L. All Rights Reserved.
            </div>
          </div>
          <div className="p-0 m-0 azil-social-responsive">
            <div className="d-flex align-items-center justify-content-between p-0 azil-social">
              <SocialMediaButton />
            </div>
          </div>
        </div>
      </footer>
    </section>
  );
};

export default BlogFooter;