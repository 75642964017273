import React, { Component } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { constants as PATH } from "../components/constants/componentPath";
import About from "./Pages/About/About";
import ApplyJob from "./Pages/ApplyJob/ApplyJob";
import Blog from "./Pages/Blog/Blog";
import BlogAuthor from "./Pages/Blog/BlogAuthor";
import BlogBrowseAll from "./Pages/Blog/BlogBrowseAll";
import BlogInnner from "./Pages/Blog/BlogInnner";
import CaseStudy from "./Pages/CaseStudy/CaseStudy";
import ContactUsPage from "./Pages/ContactUs/ContactUs";
import CurrentOpening from "./Pages/CurrentOpenings/CurrentOpening";
import CustomSoftware from "./Pages/CustomSoftware/CustomSoftware";
import FAQ from "./Pages/FAQ/FAQ";
import Hiring from "./Pages/Hiring/Hiring";
import JobDetails from "./Pages/Hiring/JobDetails";
import Home from "./Pages/Home/Home";
import HowWeWork from "./Pages/How_we_work/HowWeWork";
import OurTeam from "./Pages/OurTeam/OurTeam";
import PageNotFound from "./Pages/PageNotFound";
import Portfolio from "./Pages/Portfolio/Portfolio";
import Privacy from "./Pages/Privacy/Privacy";
import Product from "./Pages/Product/Product";
import WeWorkOn from "./Pages/WeWorkOn/WeWorkOn";
import CookiePolicy from "./Pages/CookiePolicy/CookiePolicy";

class Markup extends Component {
  render() {
    return (
      <BrowserRouter basename="/">
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path={PATH.ROOT} exact component={Home} />
          <Route path={PATH.ABOUT} exact component={About} />
          <Route path={PATH.HOW_WE_WORK} exact component={HowWeWork} />
          <Route path={PATH.PORTFOLIO} exact component={Portfolio} />
          <Route path={PATH.BLOG} exact component={Blog} />
          <Route path={PATH.CONTACT_US} exact component={ContactUsPage} />
          <Route path={PATH.BLOG_INNER} exact component={BlogInnner} />
          <Route path={PATH.WE_WORK} exact component={WeWorkOn} />
          <Route path={PATH.CURRENT_OPENING} exact component={CurrentOpening} />
          <Route path={PATH.APPLY_JOB} exact component={ApplyJob} />
          <Route path={PATH.CASE_STUDY} exact component={CaseStudy} />
          <Route path={PATH.CUSTOM_SOFTWARE} exact component={CustomSoftware} />
          <Route path={PATH.FAQ} exact component={FAQ} />
          <Route path={PATH.HIRING} exact component={Hiring} />
          <Route path={PATH.OUR_TEAM} exact component={OurTeam} />
          <Route path={PATH.PRODUCT} exact component={Product} />
          <Route path={PATH.JOB_DETAILS} exact component={JobDetails} />
          <Route path={PATH.PRIVACY} exact component={Privacy} />
          <Route path={PATH.COOKIE} exact component={CookiePolicy} />
          <Route
            path={PATH.BLOG + "/tag/:id"}
            exact
            component={BlogBrowseAll}
          />
          <Route
            path={PATH.BLOG + "/author/:id"}
            exact
            component={BlogAuthor}
          />
          <Route path={PATH.PAGE_NOT_FOUND} component={PageNotFound} />
          <Route
            path={PATH.TAXI}
            exact
            render={() => (
              <CustomSoftware
                location={{
                  state: {
                    type: "Taxi Booking",
                  },
                }}
              />
            )}
          />
          <Route
            path={PATH.ESCOOTER}
            exact
            render={() => (
              <CustomSoftware
                location={{
                  state: { type: "EScooter" },
                }}
              />
            )}
          />
          <Route
            path={PATH.TRAVEL}
            exact
            render={() => (
              <CustomSoftware
                location={{
                  state: { type: "Travel App" },
                }}
              />
            )}
          />
          <Route
            path={PATH.NEWS}
            exact
            render={() => (
              <CustomSoftware
                location={{
                  state: { type: "News" },
                }}
              />
            )}
          />
          <Route
            path={PATH.SPORTS}
            exact
            render={() => (
              <CustomSoftware
                location={{
                  state: { type: "Sports Betting" },
                }}
              />
            )}
          />
          <Route
            path={PATH.DATING}
            exact
            render={() => (
              <CustomSoftware
                location={{
                  state: { type: "Dating App" },
                }}
              />
            )}
          />
          <Route
            path={PATH.MUSIC_STREAM}
            exact
            render={() => (
              <CustomSoftware
                location={{
                  state: {
                    type: "Music Streaming App",
                  },
                }}
              />
            )}
          />
          <Route
            path={PATH.VIDEO_STREAM}
            exact
            render={() => (
              <CustomSoftware
                location={{
                  state: {
                    type: "Video Streaming App",
                  },
                }}
              />
            )}
          />
          <Route
            path={PATH.SOCIAL}
            exact
            render={() => (
              <CustomSoftware
                location={{
                  state: {
                    type: "Social Media",
                  },
                }}
              />
            )}
          />
          <Route
            path={PATH.TELEMEDICINE}
            exact
            render={() => (
              <CustomSoftware
                location={{
                  state: {
                    type: "Telemedicine",
                  },
                }}
              />
            )}
          />
          <Route
            path={PATH.HEALTHCARE}
            exact
            render={() => (
              <CustomSoftware
                location={{
                  state: {
                    type: "Healthcare App",
                  },
                }}
              />
            )}
          />
          <Route
            path={PATH.FITNESS}
            exact
            render={() => (
              <CustomSoftware
                location={{
                  state: {
                    type: "Fitness",
                  },
                }}
              />
            )}
          />
          <Route
            path={PATH.FOOD_DELIVERY}
            exact
            render={() => (
              <CustomSoftware
                location={{
                  state: {
                    type: "Food Delivery",
                  },
                }}
              />
            )}
          />
          <Route
            path={PATH.GROCERY}
            exact
            render={() => (
              <CustomSoftware
                location={{
                  state: {
                    type: "Grocery Delivery",
                  },
                }}
              />
            )}
          />
          <Route
            path={PATH.ON_DEMAND}
            exact
            render={() => (
              <CustomSoftware
                location={{
                  state: {
                    type: "On-Demand App",
                  },
                }}
              />
            )}
          />
          <Route
            path={PATH.ECOMMERCE}
            exact
            render={() => (
              <CustomSoftware
                location={{
                  state: {
                    type: "ECommerce",
                  },
                }}
              />
            )}
          />
          <Route
            path={PATH.ECOMMERCE_APP}
            exact
            render={() => (
              <CustomSoftware
                location={{
                  state: {
                    type: "ECommerce App",
                  },
                }}
              />
            )}
          />
          <Route
            path={PATH.FINANCIAL}
            exact
            render={() => (
              <CustomSoftware
                location={{
                  state: {
                    type: "Financial",
                  },
                }}
              />
            )}
          />
          <Route
            path={PATH.FINTECH}
            exact
            render={() => (
              <CustomSoftware
                location={{
                  state: {
                    type: "Fintech",
                  },
                }}
              />
            )}
          />
          <Route
            path={PATH.EWALLET}
            exact
            render={() => (
              <CustomSoftware
                location={{
                  state: {
                    type: "EWallet App",
                  },
                }}
              />
            )}
          />
          <Route
            path={PATH.LOGISTICS}
            exact
            render={() => (
              <CustomSoftware
                location={{
                  state: {
                    type: "Logistics App",
                  },
                }}
              />
            )}
          />
          <Route
            path={PATH.EMBEDDED}
            exact
            render={() => (
              <CustomSoftware
                location={{
                  state: {
                    type: "Embedded Solutions",
                  },
                }}
              />
            )}
          />
          <Route
            path={PATH.FRONTEND}
            exact
            render={() => (
              <CustomSoftware
                location={{
                  state: {
                    type: "Frontend-JS",
                  },
                }}
              />
            )}
          />
          <Route
            path={PATH.REACT}
            exact
            render={() => (
              <CustomSoftware
                location={{
                  state: {
                    type: "React Js",
                  },
                }}
              />
            )}
          />
          <Route
            path={PATH.ANGULAR}
            exact
            render={() => (
              <CustomSoftware
                location={{
                  state: {
                    type: "Angular Js",
                  },
                }}
              />
            )}
          />
          <Route
            path={PATH.NODE}
            exact
            render={() => (
              <CustomSoftware
                location={{
                  state: {
                    type: "Node Js",
                  },
                }}
              />
            )}
          />
          <Route
            path={PATH.JAVA}
            exact
            render={() => (
              <CustomSoftware
                location={{
                  state: {
                    type: "JAVA",
                  },
                }}
              />
            )}
          />
          <Route
            path={PATH.NET}
            exact
            render={() => (
              <CustomSoftware
                location={{
                  state: {
                    type: ".NET",
                  },
                }}
              />
            )}
          />
          <Route
            path={PATH.PHP}
            exact
            render={() => (
              <CustomSoftware
                location={{
                  state: {
                    type: "PHP",
                  },
                }}
              />
            )}
          />
          <Route
            path={PATH.MAGENTO}
            exact
            render={() => (
              <CustomSoftware
                location={{
                  state: {
                    type: "Magento",
                  },
                }}
              />
            )}
          />
          <Route
            path={PATH.LARAVEL}
            exact
            render={() => (
              <CustomSoftware
                location={{
                  state: {
                    type: "Laravel",
                  },
                }}
              />
            )}
          />
          <Route
            path={PATH.WORDPRESS}
            exact
            render={() => (
              <CustomSoftware
                location={{
                  state: {
                    type: "Wordpress",
                  },
                }}
              />
            )}
          />
          <Route
            path={PATH.PYTHON}
            exact
            render={() => (
              <CustomSoftware
                location={{
                  state: {
                    type: "Python",
                  },
                }}
              />
            )}
          />
          <Route
            path={PATH.ROR}
            exact
            render={() => (
              <CustomSoftware
                location={{
                  state: {
                    type: "ROR",
                  },
                }}
              />
            )}
          />
          <Route
            path={PATH.ANDROID}
            exact
            render={() => (
              <CustomSoftware
                location={{
                  state: {
                    type: "Android",
                  },
                }}
              />
            )}
          />
          <Route
            path={PATH.CROSS_PLATFORM}
            exact
            render={() => (
              <CustomSoftware
                location={{
                  state: {
                    type: "Cross Platform",
                  },
                }}
              />
            )}
          />
          <Route
            path={PATH.REACT_NATIVE}
            exact
            render={() => (
              <CustomSoftware
                location={{
                  state: {
                    type: "React Native",
                  },
                }}
              />
            )}
          />
          <Route
            path={PATH.FLUTTER}
            exact
            render={() => (
              <CustomSoftware
                location={{
                  state: {
                    type: "Flutter",
                  },
                }}
              />
            )}
          />
          <Route
            path={PATH.SHARE_POINT}
            exact
            render={() => (
              <CustomSoftware
                location={{
                  state: {
                    type: "Sharepoint",
                  },
                }}
              />
            )}
          />
          <Route
            path={PATH.SALES_FORCE}
            exact
            render={() => (
              <CustomSoftware
                location={{
                  state: {
                    type: "Salessforce",
                  },
                }}
              />
            )}
          />
          <Route
            path={PATH.WEB_APP}
            exact
            render={() => (
              <CustomSoftware
                location={{
                  state: { type: "Web Application Development" },
                }}
              />
            )}
          />
          <Route
            path={PATH.MOBILE_APP}
            exact
            render={() => (
              <CustomSoftware
                location={{
                  state: { type: "Mobile Application Development" },
                }}
              />
            )}
          />
          <Route
            path={PATH.STAFF}
            exact
            render={() => (
              <CustomSoftware
                location={{
                  state: { type: "Staff Augmentation" },
                }}
              />
            )}
          />
          <Route
            path={PATH.TESTING}
            exact
            render={() => (
              <CustomSoftware
                location={{
                  state: { type: "Testing & QA" },
                }}
              />
            )}
          />
          <Route
            path={PATH.CLOUD}
            exact
            render={() => (
              <CustomSoftware
                location={{
                  state: { type: "Cloud" },
                }}
              />
            )}
          />
          <Route
            path={PATH.DEVOPS}
            exact
            render={() => (
              <CustomSoftware
                location={{
                  state: { type: "Devops" },
                }}
              />
            )}
          />
          <Route
            path={PATH.BOTS_DEV}
            exact
            render={() => (
              <CustomSoftware
                location={{
                  state: { type: "Bots Development" },
                }}
              />
            )}
          />
          <Route
            path={PATH.MACHINE}
            exact
            render={() => (
              <CustomSoftware
                location={{
                  state: { type: "Machine Learning" },
                }}
              />
            )}
          />
          <Route
            path={PATH.AR_VR}
            exact
            render={() => (
              <CustomSoftware
                location={{
                  state: { type: "AR / VR" },
                }}
              />
            )}
          />
          <Route
            path={PATH.UI_UX}
            exact
            render={() => (
              <CustomSoftware
                location={{
                  state: {
                    type: "UI/UX Design",
                  },
                }}
              />
            )}
          />
          <Route
            path={PATH.GAME_DEV}
            exact
            render={() => (
              <CustomSoftware
                location={{
                  state: {
                    type: "Game Development",
                  },
                }}
              />
            )}
          />
          <Route
            path={PATH.BACKEND}
            exact
            render={() => (
              <CustomSoftware
                location={{
                  state: {
                    type: "Backend",
                  },
                }}
              />
            )}
          />
          <Route
            path={PATH.EDUCATION}
            exact
            render={() => (
              <CustomSoftware
                location={{
                  state: {
                    type: "Education",
                  },
                }}
              />
            )}
          />
          <Route
            path={PATH.IOT}
            exact
            render={() => (
              <CustomSoftware
                location={{
                  state: {
                    type: "IOT",
                  },
                }}
              />
            )}
          />
          <Route
            path={PATH.MULTI_VENDOR}
            exact
            render={() => (
              <CustomSoftware
                location={{
                  state: {
                    type: "Multi Vendor CRM",
                  },
                }}
              />
            )}
          />
          <Route
            path={PATH.FULL_STACK}
            exact
            render={() => (
              <CustomSoftware
                location={{
                  state: {
                    type: "Fullstack Solutions",
                  },
                }}
              />
            )}
          />
          <Route
            path={PATH.LIVE_STREAM}
            exact
            render={() => (
              <CustomSoftware
                location={{
                  state: {
                    type: "Live Streaming",
                  },
                }}
              />
            )}
          />
          <Route
            path={PATH.OIL_GAS}
            exact
            render={() => (
              <CustomSoftware
                location={{
                  state: {
                    type: "Oil & Gas",
                  },
                }}
              />
            )}
          />
          <Redirect to={PATH.PAGE_NOT_FOUND} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default Markup;
