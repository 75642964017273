import React, { useState } from "react";
import { imagesConst as IMAGECONST } from "../constants/imagesConst";
import Dropzone from "react-dropzone";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { errorConst as ERROR } from "../constants/errorConst";
import CustomInput from "./CustomInput";
import { Card, Col, Row, Progress } from "reactstrap";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { ContactUs } from "../../services/ContactService";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import { notificationMessageFunction } from "../constants/notificationConst";
import PhoneNumberInput from "./PhoneNumberInput/PhoneNumberInput";
import CustomTextarea from "./CustomTextarea";
import { fireEvent } from "@testing-library/react";
import CustomPhoneNumber from "./CustomPhoneNumber/CustomPhoneNumber";
// import Uploadimg from "../../images/Upload Ico.svg"

const   CustomContactUs = () => {
  const [images, setImages] = useState([]);
  const [percentage, setPercentage] = useState(0);
  const [isError, setIsError] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneError, setPhoneError] = useState(false);

  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setImages(files);
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const removeFile = (file) => {
    const newFiles = [...images];
    newFiles.splice(file, 1);
    setImages(newFiles);
  };

  const initialValues = {
    firstname: "",
    lastname: "",
    email: "",
    message: "",
  };

  const ContactUsSchema = Yup.object().shape({
    firstname: Yup.string().trim().required(ERROR.FIRST_NAME),
    lastname: Yup.string().trim().required(ERROR.LAST_NAME),
    email: Yup.string().trim().email(ERROR.VALID_EMAIL).required(ERROR.EMAIL),
    message: Yup.string().trim().required(ERROR.MESSAGE),
  });

  const handleSubmit = (values, { resetForm }) => {
    let isError = false;
    // if (phoneNumber === "" || phoneNumber === null) {
    //   setPhoneError(true);
    //   isError = true;
    // } else {

    //   setPhoneError(false);
    //   isError = false;
    // }
    // if (phoneNumber === "" || phoneNumber === null) {
    //   setPhoneError(true);
    //   isError = true;
    // } else {
    //   setPhoneError(false);
    //   isError = false;
    // }

    // if (images.length > 1) {
    //   isError = true;
    // }


    if (!isError) {
      const data = new FormData();
      data.append("firstname", values.firstname);
      data.append("lastname", values.lastname);
      // data.append("phone_number", phoneNumber.slice(2));
      data.append("phone_number", values.phone_number);
      // data.append("phone_number", values.phoneNumber);
      data.append("email", values.email);
      data.append("message", values.message);
      data.append("contact_image", images[0]);
      ContactUs(data)
        .then((res) => {
          NotificationManager.success("Contact us added successfully");
          resetForm();
          setImages([]);
          window.location.reload(true);
        })
        .catch((err) => {
          const errorMessage =
            err && err.data && err.data.error && err.data.error.message;
          NotificationManager.error(notificationMessageFunction(errorMessage));
        });
      }
  };

  /* -------------------------------------------------------------------------- */
  /*                            Button ripple effect                            */
  /* -------------------------------------------------------------------------- */
  const handleHover = (e) => {
    const button = e.target;
    const rect = button.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    const rippleSpan = document.createElement('span');
    rippleSpan.classList.add('ripple-span');
    rippleSpan.style.top = `${y}px`;
    rippleSpan.style.left = `${x}px`;
    button.appendChild(rippleSpan);
  };

  const handleHoverEnd = (e) => {
    const button = e.target;
    const ripples = button.getElementsByClassName('ripple-span');
    while (ripples.length > 0) {
      button.removeChild(ripples[0]);
    }
  };

  return (
    <div className="azil-home-container">
      <div className="text-center">
        <div className="azil-primary-heading my-2">
          Connect <span className="font-primary"> with us! </span>{" "}
        </div>
      </div>
      <div className="azil-form-bg">
        <Formik
          initialValues={initialValues}
          validationSchema={ContactUsSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, values, handleChange }) => (
            <Form>
              <div className="row my-2">
                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                  <div className="azil-input-group my-1">
                    <CustomInput
                      type="text"
                      values={values}
                      placeholder="First Name*"
                      name="firstname"
                      handleChange={handleChange}
                      touched={touched}
                      errors={errors}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                  <div className="azil-input-group my-1">
                    <CustomInput
                      type="text"
                      values={values}
                      placeholder="Last Name*"
                      name="lastname"
                      handleChange={handleChange}
                      touched={touched}
                      errors={errors}
                    />
                  </div>
                </div>
              </div>
              <div className="row my-2">
                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                  {/* <div className="azil-input-group my-2">
                    <PhoneNumberInput
                    values={phoneNumber}
                      onChange={(e) => {
                        setPhoneNumber(e);
                        if (e === "") {
                          setPhoneError(false);
                        } else {
                          setPhoneError(true);
                        }
                      }}
                      name="phone_number"
                    />
                    {isError === true && (
                      <div className="azil-show-error-message azil-show-error-message"
                      >
                        {ERROR.WP_NUMBER}
                      </div>
                    )}
                  </div> */}
                  <div className="azil-input-group azil-phone-input-group my-2">
                    <CustomPhoneNumber 
                      type="number"
                      value={values}
                      placeholder="Phone number*"
                      name="phone_number"
                      handleChange={handleChange}
                      touched={touched}
                      errors={errors}
                      // onChange={(e) => {
                      //   setPhoneNumber(e);
                      //   if (e !== "") {
                      //     setIsError(false);
                      //   } else {
                      //     setIsError(true);
                      //   }
                      // }}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                  <div className="azil-input-group my-2">
                    <CustomInput
                      type="email"
                      values={values}
                      placeholder="Email Address*"
                      name="email"
                      handleChange={handleChange}
                      touched={touched}
                      errors={errors}
                    />
                  </div>
                </div>
              </div>
              <div className="row my-2">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="azil-input-group my-2 position-relative">
                    <CustomTextarea 
                      type="text"
                      values={values}
                      placeholder="Message*"
                      name="message"
                      handleChange={handleChange}
                      touched={touched}
                      errors={errors}
                    />
                  </div>
                </div>
              </div>
              <div className="upload my-2 upload-item">
                <Dropzone
                  onDrop={(acceptedFiles) => {
                    handleAcceptedFiles(acceptedFiles);
                    const formData = new FormData();
                    for (const file of acceptedFiles)
                      formData.append("file", file);
                    const xhr = new XMLHttpRequest();
                    xhr.upload.onprogress = (event) => {
                      const percentage = parseInt(
                        (event.loaded / event.total) * 100
                      );
                      setPercentage(percentage);
                    };
                    xhr.onreadystatechange = () => {
                      if (xhr.readyState !== 4) return;
                      if (xhr.status !== 200) {
                        console.log("error");
                      }
                    };
                    xhr.open("POST", "https://httpbin.org/post", true);
                    xhr.send(formData);
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div className="dropzone dz-clickable">
                      <div
                        className="dz-message needsclick text-center"
                        {...getRootProps()}
                      >
                        <input {...getInputProps()} />
                        <div className="mb-3">
                          <img
                            src={IMAGECONST.Dropzone}
                            alt="upload"
                            style={{ height: 100 }}
                            draggable="false"
                          />
                        </div>
                        <h4 className="font-grey font-14">
                          Drop files here or click to upload.
                        </h4>
                      </div>
                    </div>
                  )}
                </Dropzone>
              </div>
              <div className="dropzone-previews mt-3 my-3" id="file-previews">
                {images.map((f, i) => {
                  return (
                    <Card
                      className="mt-1 mb-0 shadow-none dz-processing dz-image-preview dz-success dz-complete border-radius-12 boxshadow-full border-none"
                      key={i + "-file"}
                    >
                      <div className="p-2">
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            <img
                              data-dz-thumbnail=""
                              height="80"
                              className="avatar-sm rounded bg-light"
                              alt={f.name}
                              src={f.preview}
                              draggable="false"
                            />
                          </Col>
                          <Col>
                            <p className="font-dark-grey font-600 font-14">
                              {f.name}
                            </p>
                            <p className="mb-0 font-grey font-12">{f.formattedSize}</p>
                          </Col>
                          <Col>
                            <Progress color="primary" value={percentage} />
                          </Col>
                          <Col>
                            <div className="azil-delete-button-box">
                              <img
                                src={IMAGECONST.Deleteicon}
                                alt="delete"
                                onClick={() => removeFile(i)}
                                className="cursor-pointer"
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  );
                })}
              </div>
              {images.length > 1 && (
                <div
                  style={{ fontSize: 14 }}
                  className="text-left mt-1 text-danger"
                >
                  {"Please upload only one image"}
                </div>
              )}
              <div
                className="g-recaptcha"
                data-sitekey="6LfK8wsjAAAAAN3xftPQZZid0zh1gGbGxm3_DLIS"
              ></div>
              <div className="azil-contact-btn my-3">
                <button
                  className="azil-hero-button align-items-center" type="submit"
                  onSubmit={(values) => handleSubmit(values)} onMouseEnter={handleHover} onMouseLeave={handleHoverEnd} 
                >
                  Send Message
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <NotificationContainer />
    </div>
  );
};

export default CustomContactUs;
