export const constants = {
  ROOT: "/",
  ABOUT: "/about",
  HOW_WE_WORK: "/how-we-work",
  PORTFOLIO: "/portfolio",
  BLOG: "/blog",
  CONTACT_US: "/contact",
  BLOG_INNER: "/blog-details",
  WE_WORK: "/we-work",
  CURRENT_OPENING: "/current-opening",
  APPLY_JOB: "/apply-job",
  CASE_STUDY: "/case-study",
  CUSTOM_SOFTWARE: "/custom-software",
  FAQ: "/faq",
  HIRING: "/hiring",
  OUR_TEAM: "/our-team",
  PRODUCT: "/product",
  PAGE_NOT_FOUND: "/404",
  JOB_DETAILS: "/job-details",
  TAXI: "/taxi-booking",
  ESCOOTER: "/escooter",
  TRAVEL: "/travel-app",
  NEWS: "/news",
  SPORTS: "/sports-betting",
  DATING: "/dating-app",
  MUSIC_STREAM: "/music-streaming-app",
  VIDEO_STREAM: "/video-streaming-app",
  SOCIAL: "/social-media",
  TELEMEDICINE: "/telemedicine",
  HEALTHCARE: "/healthcare-app",
  FITNESS: "/fitness",
  FOOD_DELIVERY: "/food-delivery",
  GROCERY: "/grocery-delivery",
  ON_DEMAND: "/on-demand",
  ECOMMERCE: "/ecommerce",
  ECOMMERCE_APP: "/ecommerce-app",
  FINANCIAL: "/financial",
  FINTECH: "/fintech",
  EWALLET: "/ewallet-app",
  LOGISTICS: "/logistics-app",
  FRONTEND: "/frontend-js",
  REACT: "/react-js",
  ANGULAR: "/angular-js",
  NODE: "/node-js",
  JAVA: "/java",
  NET: "/net",
  PHP: "/php",
  MAGENTO: "/magento",
  LARAVEL: "/laravel",
  WORDPRESS: "/wordpress",
  PYTHON: "/python",
  ROR: "/ror",
  ANDROID: "/android",
  CROSS_PLATFORM: "/cross-platform",
  REACT_NATIVE: "/react-native",
  FLUTTER: "/flutter",
  SHARE_POINT: "/sharepoint",
  SALES_FORCE: "/salesforce",
  WEB_APP: "/web-app",
  MOBILE_APP: "/mobile-app",
  STAFF: "/staff-augmentation",
  TESTING: "/testing",
  CLOUD: "/cloud",
  DEVOPS: "/devops",
  BOTS_DEV: "/bots-development",
  MACHINE: "/machine-learning",
  AR_VR: "/ar-vr",
  UI_UX: "/ui-ux",
  GAME_DEV: "/game-development",
  BACKEND: "/backend-development",
  EDUCATION: "/education",
  IOT: "/iot",
  MULTI_VENDOR: "/multi-vendor-crm",
  FULL_STACK: "/fullstack",
  LIVE_STREAM: "/live-streaming",
  OIL_GAS: "/oil-gas",
  PRIVACY: "/privacy",
  COOKIE: "/cookie",
  EMBEDDED: "/embedded",
};
