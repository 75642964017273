import React from "react";
import { imagesConst as IMAGECONST } from "../../constants/imagesConst";

const SocialMediaButton = () => {
  return (
    <div className="social-logo">
      <div className="d-flex align-items-center social-logo-container">
        <div className="azil-facebook">
          <a href="https://www.facebook.com/Azillik.company" target="_blank" rel="noopener noreferrer">
            <img src={IMAGECONST.Facebook} alt="facebook" draggable={false} width={18}/>
          </a>
        </div>
        <div className="azil-instagram">
          <a href="https://www.instagram.com/azillik.company" target="_blank" rel="noopener noreferrer">
            <img src={IMAGECONST.Instagram} alt="instagram" draggable={false} width={18}/>
          </a>
        </div>
        <div className="azil-linkedin">
          <a href="https://www.linkedin.com/company/azillik/" target="_blank" rel="noopener noreferrer">
            <img src={IMAGECONST.Linkedin} alt="linkedin"  draggable={false} width={18}/>
          </a>
        </div>
        <div className="azil-twitter">
          <a href="https://twitter.com/azillik_" target="_blank" rel="noopener noreferrer">
            <img src={IMAGECONST.Twitter} alt="twitter"  draggable={false} width={18}/>
          </a>
        </div>
        <div className="azil-youtube">
          <a href="https://www.youtube.com/channel/UCLbV2Ylfz3SPQ4SsmILAVVg" target="_blank" rel="noopener noreferrer">
            <img src={IMAGECONST.Youtube} alt="youtube"  draggable={false} width={18}/>
          </a>
        </div>
      </div>
    </div>
  );
};

export default SocialMediaButton;