import React, { useEffect, useRef, useState } from "react";
import "../PhoneNumberInput/PhoneNumberInput.css";

const CustomPhoneNumber = ({
  defaultCountryCode = "+91",
  defaultFlagImage = "india.svg",
  defaultCountry = "India",
  type,
  values,
  name,
  placeholder,
  errors,
  touched,
  handleChange,
  min,
  max,
  defaultValue,
  className,
}) => {
  const countriesname = [
    {
      id: 1,
      name: "Afghanistan",
      image: "afghanistan.svg",
      flag: "afghanistan",
      prefix: 93,
      calldigit: 9,
    },
    {
      id: 2,
      name: "Albania",
      image: "albania.svg",
      flag: "albania",
      prefix: 355,
      calldigit: 9,
    },
    {
      id: 3,
      name: "Algeria",
      image: "algeria.svg",
      flag: "algeria",
      prefix: 213,
      calldigit: 9,
    },
    {
      id: 4,
      name: "Andorra",
      image: "andorra.svg",
      flag: "andorra",
      prefix: 376,
      calldigit: 9,
    },
    {
      id: 5,
      name: "Angola",
      image: "angola.svg",
      flag: "angola",
      prefix: 244,
      calldigit: 9,
    },
    {
      id: 6,
      name: "Antigua and Barbuda",
      image: "antigua_and_barbuda.svg",
      flag: "antigua and barbuda",
      prefix: 1268,
      calldigit: 10,
    },
    {
      id: 7,
      name: "Argentina",
      image: "argentina.svg",
      flag: "argentina",
      prefix: 54,
      calldigit: 10,
    },
    {
      id: 8,
      name: "Armenia",
      image: "armenia.svg",
      flag: "armenia",
      prefix: 374,
      calldigit: 8,
    },
    {
      id: 9,
      name: "Aruba",
      image: "aruba.svg",
      flag: "aruba",
      prefix: 297,
      calldigit: 7,
    },
    {
      id: 10,
      name: "Artsakh",
      image: "artsakh.svg",
      flag: "artsakh",
      prefix: 374,
      calldigit: 8,
    },
    {
      id: 11,
      name: "Australia",
      image: "australia.svg",
      flag: "australia",
      prefix: 61,
      calldigit: 10,
    },
    {
      id: 12,
      name: "Austria",
      image: "austria.svg",
      flag: "austria",
      prefix: 43,
      calldigit: 11,
    },
    {
      id: 13,
      name: "Azerbaijan",
      image: "azerbaijan.svg",
      flag: "azerbaijan",
      prefix: 994,
      calldigit: 9,
    },
    {
      id: 14,
      name: "Bahamas",
      image: "bahamas.svg",
      flag: "bahamas",
      prefix: 1242,
      calldigit: 10,
    },
    {
      id: 15,
      name: "Bahrain",
      image: "bahrain.svg",
      flag: "bahrain",
      prefix: 973,
      calldigit: 8,
    },
    {
      id: 16,
      name: "Bangladesh",
      image: "bangladesh.svg",
      flag: "bangladesh",
      prefix: 880,
      calldigit: 10,
    },
    {
      id: 17,
      name: "Barbados",
      image: "barbados.svg",
      flag: "barbados",
      prefix: 1246,
      calldigit: 10,
    },
    {
      id: 18,
      name: "Belarus",
      image: "belarus.svg",
      flag: "belarus",
      prefix: 375,
      calldigit: 9,
    },
    {
      id: 19,
      name: "Belgium",
      image: "belgium.svg",
      flag: "belgium",
      prefix: 32,
      calldigit: 9,
    },
    {
      id: 20,
      name: "Belize",
      image: "belize.svg",
      flag: "belize",
      prefix: 501,
      calldigit: 7,
    },
    {
      id: 21,
      name: "Benin",
      image: "benin.svg",
      flag: "benin",
      prefix: 229,
      calldigit: 9,
    },
    {
      id: 22,
      name: "Bhutan",
      image: "bhutan.svg",
      flag: "bhutan",
      prefix: 975,
      calldigit: 8,
    },
    {
      id: 23,
      name: "Bolivia",
      image: "bolivia.svg",
      flag: "bolivia",
      prefix: 591,
      calldigit: 8,
    },
    {
      id: 24,
      name: "Bosnia and Herzegovina",
      image: "bosnia_and_herzegovina.svg",
      flag: "bosnia and herzegovina",
      prefix: 387,
      calldigit: 8,
    },
    {
      id: 25,
      name: "Botswana",
      image: "botswana.svg",
      flag: "botswana",
      prefix: 267,
      calldigit: 8,
    },
    {
      id: 26,
      name: "Brazil",
      image: "brazil.svg",
      flag: "brazil",
      prefix: 55,
      calldigit: 11,
    },
    {
      id: 27,
      name: "British Indian Ocean Territory",
      image: "british-indian-ocean-territory.svg",
      flag: "british indian ocean territory",
      prefix: 246,
      calldigit: 7,
    },
    {
      id: 28,
      name: "Brunei",
      image: "brunei.svg",
      flag: "brunei",
      prefix: 673,
      calldigit: 7,
    },
    {
      id: 29,
      name: "Bulgaria",
      image: "bulgaria.svg",
      flag: "bulgaria",
      prefix: 359,
      calldigit: 9,
    },
    {
      id: 30,
      name: "Burkina Faso",
      image: "burkina_faso.svg",
      flag: "burkina faso",
      prefix: 226,
      calldigit: 8,
    },
    {
      id: 31,
      name: "Burundi",
      image: "burundi.svg",
      flag: "burundi",
      prefix: 257,
      calldigit: 8,
    },
    {
      id: 32,
      name: "Cape Verde",
      image: "cape_verde.svg",
      flag: "cape verde",
      prefix: 238,
      calldigit: 7,
    },
    {
      id: 33,
      name: "Cambodia",
      image: "cambodia.svg",
      flag: "cambodia",
      prefix: 855,
      calldigit: 9,
    },
    {
      id: 34,
      name: "Cameroon",
      image: "cameroon.svg",
      flag: "cameroon",
      prefix: 237,
      calldigit: 9,
    },
    {
      id: 35,
      name: "Canada",
      image: "canada.svg",
      flag: "canada",
      prefix: 1,
      calldigit: 10,
    },
    {
      id: 36,
      name: "Caribbean Netherlands",
      image: "caribbean_netherlands.svg",
      flag: "caribbean netherlands",
      prefix: 599,
      calldigit: 9,
    },
    {
      id: 37,
      name: "Central African Republic",
      image: "central_african_republic.svg",
      flag: "central african republic",
      prefix: 236,
      calldigit: 8,
    },
    {
      id: 38,
      name: "Chad",
      image: "chad.svg",
      flag: "chad",
      prefix: 235,
      calldigit: 8,
    },
    {
      id: 39,
      name: "Chile",
      image: "chile.svg",
      flag: "chile",
      prefix: 56,
      calldigit: 9,
    },
    {
      id: 40,
      name: "China",
      image: "people's_republic_of_china.svg",
      flag: "china",
      prefix: 86,
      calldigit: 13,
    },
    {
      id: 41,
      name: "Colombia",
      image: "colombia.svg",
      flag: "colombia",
      prefix: 57,
      calldigit: 10,
    },
    {
      id: 42,
      name: "Comoros",
      image: "comoros.svg",
      flag: "comoros",
      prefix: 269,
      calldigit: 7,
    },
    {
      id: 43,
      name: "Cook island",
      image: "cook_Islands.svg",
      flag: "cook island",
      prefix: 682,
      calldigit: 5,
    },
    {
      id: 44,
      name: "Congo, Democratic Re...",
      image: "democratic_republic_of_the_congo.svg",
      flag: "congo, democratic Re...",
      prefix: 243,
      calldigit: 9,
    },
    {
      id: 45,
      name: "Congo, Republic of the",
      image: "republic_of_the_congo.svg",
      flag: "congo, republic of the",
      prefix: 242,
      calldigit: 9,
    },
    {
      id: 46,
      name: "Costa Rica",
      image: "costa_rica.svg",
      flag: "costa rica",
      prefix: 506,
      calldigit: 8,
    },
    {
      id: 47,
      name: "Cote d'Ivoire",
      image: "cote_d_Ivoire.svg",
      flag: "cote d'Ivoire",
      prefix: 225,
      calldigit: 8,
    },
    {
      id: 48,
      name: "Croatia",
      image: "croatia.svg",
      flag: "croatia",
      prefix: 385,
      calldigit: 9,
    },
    {
      id: 49,
      name: "Cuba",
      image: "cuba.svg",
      flag: "cuba",
      prefix: 53,
      calldigit: 8,
    },
    {
      id: 50,
      name: "Curacao",
      image: "curacao.svg",
      flag: "curacao",
      prefix: 599,
      calldigit: 8,
    },
    {
      id: 51,
      name: "Cyprus",
      image: "cyprus.svg",
      flag: "cyprus",
      prefix: 357,
      calldigit: 8,
    },
    {
      id: 52,
      name: "Czech Republic",
      image: "czech_republic.svg",
      flag: "czech republic",
      prefix: 420,
      calldigit: 9,
    },
    {
      id: 53,
      name: "Denmark",
      image: "denmark.svg",
      flag: "denmark",
      prefix: 45,
      calldigit: 8,
    },
    {
      id: 54,
      name: "Djibouti",
      image: "djibouti.svg",
      flag: "djibouti",
      prefix: 253,
      calldigit: 6,
    },
    {
      id: 55,
      name: "Dominica",
      image: "dominica.svg",
      flag: "dominica",
      prefix: 1767,
      calldigit: 10,
    },
    {
      id: 56,
      name: "Dominican Republic",
      image: "dominican_republic.svg",
      flag: "dominican republic",
      prefix: 1,
      calldigit: 10,
    },
    {
      id: 57,
      name: "East Timor (Timor-Leste)",
      image: "east_timor.svg",
      flag: "east timor",
      prefix: 670,
      calldigit: 8,
    },
    {
      id: 58,
      name: "Ecuador",
      image: "ecuador.svg",
      flag: "ecuador",
      prefix: 593,
      calldigit: 9,
    },
    {
      id: 59,
      name: "Egypt",
      image: "egypt.svg",
      flag: "egypt",
      prefix: 20,
      calldigit: 10,
    },
    {
      id: 60,
      name: "El Salvador",
      image: "el_salvador.svg",
      flag: "el salvador",
      prefix: 503,
      calldigit: 8,
    },
    {
      id: 61,
      name: "Equatorial Guinea",
      image: "equatorial_guinea.svg",
      flag: "equatorial guinea",
      prefix: 240,
      calldigit: 9,
    },
    {
      id: 62,
      name: "Eritrea",
      image: "eritrea.svg",
      flag: "eritrea",
      prefix: 291,
      calldigit: 7,
    },
    {
      id: 63,
      name: "Estonia",
      image: "estonia.svg",
      flag: "estonia",
      prefix: 372,
      calldigit: 8,
    },
    {
      id: 64,
      name: "Eswatini",
      image: "eswatini.svg",
      flag: "eswatini",
      prefix: 268,
      calldigit: 8,
    },
    {
      id: 65,
      name: "Ethiopia",
      image: "ethiopia.svg",
      flag: "ethiopia",
      prefix: 251,
      calldigit: 9,
    },
    {
      id: 66,
      name: "Fiji",
      image: "fiji.svg",
      flag: "fiji",
      prefix: 679,
      calldigit: 7,
    },
    {
      id: 67,
      name: "Finland",
      image: "finland.svg",
      flag: "finland",
      prefix: 358,
      calldigit: 12,
    },
    {
      id: 68,
      name: "France",
      image: "france.svg",
      flag: "france",
      prefix: 33,
      calldigit: 9,
    },
    {
      id: 69,
      name: "French Guiana",
      image: "french_guiana_1.svg",
      flag: "french guiana",
      prefix: 594,
      calldigit: 9,
    },
    {
      id: 70,
      name: "French Polynesia",
      image: "french-polynesia.svg",
      flag: "french polynesia",
      prefix: 689,
      calldigit: 6,
    },
    {
      id: 71,
      name: "Gabon",
      image: "gabon.svg",
      flag: "gabon",
      prefix: 241,
      calldigit: 7,
    },
    {
      id: 72,
      name: "Gambia",
      image: "gambia.svg",
      flag: "gambia",
      prefix: 220,
      calldigit: 7,
    },
    {
      id: 73,
      name: "Georgia",
      image: "georgia.svg",
      flag: "georgia",
      prefix: 995,
      calldigit: 9,
    },
    {
      id: 74,
      name: "Germany",
      image: "germany.svg",
      flag: "germany",
      prefix: 49,
      calldigit: 11,
    },
    {
      id: 75,
      name: "Ghana",
      image: "ghana.svg",
      flag: "ghana",
      prefix: 233,
      calldigit: 9,
    },
    {
      id: 76,
      name: "Greece",
      image: "greece.svg",
      flag: "greece",
      prefix: 30,
      calldigit: 10,
    },
    {
      id: 77,
      name: "Green land",
      image: "green_land.svg",
      flag: "green land",
      prefix: 299,
      calldigit: 6,
    },
    {
      id: 78,
      name: "Grenada",
      image: "grenada.svg",
      flag: "grenada",
      prefix: 1473,
      calldigit: 10,
    },
    {
      id: 79,
      name: "Guadeloupe",
      image: "guadeloupe.svg",
      flag: "guadeloupe",
      prefix: 590,
      calldigit: 9,
    },
    {
      id: 80,
      name: "Guam",
      image: "guam.svg",
      flag: "guam",
      prefix: 1671,
      calldigit: 10,
    },
    {
      id: 81,
      name: "Guatemala",
      image: "guatemala.svg",
      flag: "guatemala",
      prefix: 502,
      calldigit: 8,
    },
    {
      id: 82,
      name: "Guinea",
      image: "guinea.svg",
      flag: "guinea",
      prefix: 224,
      calldigit: 8,
    },
    {
      id: 83,
      name: "Guinea-Bissau",
      image: "guinea-bissau.svg",
      flag: "guinea bissau",
      prefix: 245,
      calldigit: 7,
    },
    {
      id: 84,
      name: "Guyana",
      image: "guyana.svg",
      flag: "guyana",
      prefix: 592,
      calldigit: 7,
    },
    {
      id: 85,
      name: "Haiti",
      image: "haiti.svg",
      flag: "haiti",
      prefix: 509,
      calldigit: 8,
    },
    {
      id: 86,
      name: "Honduras",
      image: "honduras.svg",
      flag: "honduras",
      prefix: 504,
      calldigit: 8,
    },
    {
      id: 87,
      name: "Hong Kong",
      image: "hong-kong.svg",
      flag: "hong kong",
      prefix: 852,
      calldigit: 8,
    },
    {
      id: 88,
      name: "Hungary",
      image: "hungary.svg",
      flag: "hungary",
      prefix: 36,
      calldigit: 9,
    },
    {
      id: 89,
      name: "Iceland",
      image: "iceland.svg",
      flag: "iceland",
      prefix: 354,
      calldigit: 7,
    },
    {
      id: 90,
      name: "India",
      image: "india.svg",
      flag: "india",
      prefix: 91,
      calldigit: 10,
    },
    {
      id: 91,
      name: "Indonesia",
      image: "indonesia.svg",
      flag: "indonesia",
      prefix: 62,
      calldigit: 12,
    },
    {
      id: 92,
      name: "Iran",
      image: "iran.svg",
      flag: "iran",
      prefix: 98,
      calldigit: 10,
    },
    {
      id: 93,
      name: "Iraq",
      image: "iraq.svg",
      flag: "iraq",
      prefix: 964,
      calldigit: 10,
    },
    {
      id: 94,
      name: "Ireland",
      image: "ireland.svg",
      flag: "ireland",
      prefix: 353,
      calldigit: "",
    },
    {
      id: 95,
      name: "Israel",
      image: "israel.svg",
      flag: "israel",
      prefix: 972,
      calldigit: 9,
    },
    {
      id: 96,
      name: "Italy",
      image: "italy.svg",
      flag: "italy",
      prefix: 39,
      calldigit: 11,
    },
    {
      id: 97,
      name: "Jamaica",
      image: "jamaica.svg",
      flag: "jamaica",
      prefix: 1876,
      calldigit: 10,
    },
    {
      id: 98,
      name: "Japan",
      image: "japan.svg",
      flag: "japan",
      prefix: 81,
      calldigit: 10,
    },
    {
      id: 99,
      name: "Jordan",
      image: "jordan.svg",
      flag: "jordan",
      prefix: 962,
      calldigit: 9,
    },
    {
      id: 100,
      name: "Kazakhstan",
      image: "kazakhstan.svg",
      flag: "kazakhstan",
      prefix: 7,
      calldigit: 10,
    },
    {
      id: 101,
      name: "Kenya",
      image: "kenya.svg",
      flag: "kenya",
      prefix: 254,
      calldigit: 10,
    },
    {
      id: 102,
      name: "Kiribati",
      image: "kiribati.svg",
      flag: "kiribati",
      prefix: 686,
      calldigit: 8,
    },
    {
      id: 103,
      name: "Kosovo",
      image: "kosovo.svg",
      flag: "kosovo",
      prefix: 383,
      calldigit: 8,
    },
    {
      id: 104,
      name: "Kuwait",
      image: "kuwait.svg",
      flag: "kuwait",
      prefix: 965,
      calldigit: 8,
    },
    {
      id: 105,
      name: "Kyrgyzstan",
      image: "kyrgyzstan.svg",
      flag: "kyrgyzstan",
      prefix: 996,
      calldigit: 9,
    },
    {
      id: 106,
      name: "Laos",
      image: "laos.svg",
      flag: "laos",
      prefix: 856,
      calldigit: 10,
    },
    {
      id: 107,
      name: "Latvia",
      image: "latvia.svg",
      flag: "latvia",
      prefix: 371,
      calldigit: 8,
    },
    {
      id: 108,
      name: "Lebanon",
      image: "lebanon.svg",
      flag: "lebanon",
      prefix: 961,
      calldigit: 8,
    },
    {
      id: 109,
      name: "Lesotho",
      image: "lesotho.svg",
      flag: "lesotho",
      prefix: 266,
      calldigit: 8,
    },
    {
      id: 110,
      name: "Liberia",
      image: "liberia.svg",
      flag: "liberia",
      prefix: 231,
      calldigit: 8,
    },
    {
      id: 111,
      name: "Libya",
      image: "libya.svg",
      flag: "libya",
      prefix: 218,
      calldigit: 10,
    },
    {
      id: 112,
      name: "Liechtenstein",
      image: "liechtenstein.svg",
      flag: "liechtenstein",
      prefix: 423,
      calldigit: 7,
    },
    {
      id: 113,
      name: "Lithuania",
      image: "lithuania.svg",
      flag: "lithuania",
      prefix: 370,
      calldigit: 8,
    },
    {
      id: 114,
      name: "Luxembourg",
      image: "luxembourg.svg",
      flag: "luxembourg",
      prefix: 352,
      calldigit: 9,
    },
    {
      id: 115,
      name: "Macau",
      image: "macau.svg",
      flag: "macau",
      prefix: 853,
      calldigit: 8,
    },
    {
      id: 116,
      name: "Macedonia",
      image: "north_macedonia.svg",
      flag: "north macedonia",
      prefix: 389,
      calldigit: 8,
    },
    {
      id: 117,
      name: "Madagascar",
      image: "madagascar.svg",
      flag: "madagascar",
      prefix: 261,
      calldigit: 10,
    },
    {
      id: 118,
      name: "Malawi",
      image: "malawi.svg",
      flag: "malawi",
      prefix: 265,
      calldigit: 9,
    },
    {
      id: 119,
      name: "Malaysia",
      image: "malaysia.svg",
      flag: "malaysia",
      prefix: 60,
      calldigit: 8,
    },
    {
      id: 120,
      name: "Maldives",
      image: "maldives.svg",
      flag: "maldives",
      prefix: 960,
      calldigit: 7,
    },
    {
      id: 121,
      name: "Mali",
      image: "mali.svg",
      flag: "mali",
      prefix: 223,
      calldigit: 8,
    },
    {
      id: 122,
      name: "Malta",
      image: "malta.svg",
      flag: "malta",
      prefix: 356,
      calldigit: 8,
    },
    {
      id: 123,
      name: "Marshall Islands",
      image: "marshall_islands.svg",
      flag: "marshall islands",
      prefix: 692,
      calldigit: 7,
    },
    {
      id: 124,
      name: "Martinique",
      image: "martinique.svg",
      flag: "martinique",
      prefix: 596,
      calldigit: 9,
    },
    {
      id: 125,
      name: "Mauritania",
      image: "mauritania.svg",
      flag: "mauritania",
      prefix: 222,
      calldigit: 8,
    },
    {
      id: 126,
      name: "Mauritius",
      image: "mauritius.svg",
      flag: "mauritius",
      prefix: 230,
      calldigit: 8,
    },
    {
      id: 127,
      name: "Mexico",
      image: "mexico.svg",
      flag: "mexico",
      prefix: 52,
      calldigit: 10,
    },
    {
      id: 128,
      name: "Micronesia",
      image: "federated_states_of_micronesia.svg",
      flag: "micronesia",
      prefix: 691,
      calldigit: 7,
    },
    {
      id: 129,
      name: "Moldova",
      image: "moldova.svg",
      flag: "moldova",
      prefix: 373,
      calldigit: 8,
    },
    {
      id: 130,
      name: "Monaco",
      image: "monaco.svg",
      flag: "monaco",
      prefix: 377,
      calldigit: 8,
    },
    {
      id: 131,
      name: "Mongolia",
      image: "mongolia.svg",
      flag: "mongolia",
      prefix: 976,
      calldigit: 8,
    },
    {
      id: 132,
      name: "Montenegro",
      image: "montenegro.svg",
      flag: "montenegro",
      prefix: 382,
      calldigit: 8,
    },
    {
      id: 133,
      name: "Morocco",
      image: "morocco.svg",
      flag: "morocco",
      prefix: 212,
      calldigit: 9,
    },
    {
      id: 134,
      name: "Mozambique",
      image: "mozambique.svg",
      flag: "mozambique",
      prefix: 258,
      calldigit: 12,
    },
    {
      id: 135,
      name: "Myanmar (Burma)",
      image: "myanmar.svg",
      flag: "myanmar",
      prefix: 95,
      calldigit: 10,
    },
    {
      id: 136,
      name: "Namibia",
      image: "namibia.svg",
      flag: "namibia",
      prefix: 264,
      calldigit: 10,
    },
    {
      id: 137,
      name: "Nauru",
      image: "nauru.svg",
      flag: "nauru",
      prefix: 674,
      calldigit: 7,
    },
    {
      id: 138,
      name: "Nepal",
      image: "nepal.svg",
      flag: "nepal",
      prefix: 977,
      calldigit: 10,
    },
    {
      id: 139,
      name: "Netherlands",
      image: "netherlands.svg",
      flag: "netherlands",
      prefix: 31,
      calldigit: 9,
    },
    {
      id: 140,
      name: "New Caledonia",
      image: "new_caledonia.svg",
      flag: "new caledonia",
      prefix: 687,
      calldigit: 6,
    },
    {
      id: 141,
      name: "New Zealand",
      image: "new_zealand.svg",
      flag: "new zealand",
      prefix: 64,
      calldigit: 10,
    },
    {
      id: 142,
      name: "Nicaragua",
      image: "nicaragua.svg",
      flag: "nicaragua",
      prefix: 505,
      calldigit: 8,
    },
    {
      id: 143,
      name: "Niger",
      image: "niger.svg",
      flag: "niger",
      prefix: 227,
      calldigit: 8,
    },
    {
      id: 144,
      name: "Nigeria",
      image: "nigeria.svg",
      flag: "nigeria",
      prefix: 234,
      calldigit: 8,
    },
    {
      id: 145,
      name: "North Korea",
      image: "north_korea.svg",
      flag: "north korea",
      prefix: 850,
      calldigit: 11,
    },
    {
      id: 146,
      name: "Norway",
      image: "norway.svg",
      flag: "norway",
      prefix: 47,
      calldigit: 8,
    },
    {
      id: 147,
      name: "Oman",
      image: "oman.svg",
      flag: "oman",
      prefix: 968,
      calldigit: 8,
    },
    {
      id: 148,
      name: "Pakistan",
      image: "pakistan.svg",
      flag: "pakistan",
      prefix: 92,
      calldigit: 10,
    },
    {
      id: 149,
      name: "Palau",
      image: "palau.svg",
      flag: "palau",
      prefix: 680,
      calldigit: 7,
    },
    {
      id: 150,
      name: "Palestine",
      image: "palestine.svg",
      flag: "palestine",
      prefix: 970,
      calldigit: 9,
    },
    {
      id: 151,
      name: "Panama",
      image: "panama.svg",
      flag: "panama",
      prefix: 507,
      calldigit: 8,
    },
    {
      id: 152,
      name: "Papua New Guinea",
      image: "papua_new_guinea.svg",
      flag: "papua new guinea",
      prefix: 675,
      calldigit: 8,
    },
    {
      id: 153,
      name: "Paraguay",
      image: "paraguay.svg",
      flag: "paraguay",
      prefix: 595,
      calldigit: 9,
    },
    {
      id: 154,
      name: "Peru",
      image: "peru.svg",
      flag: "peru",
      prefix: 51,
      calldigit: 9,
    },
    {
      id: 155,
      name: "Philippines",
      image: "philippines.svg",
      flag: "philippines",
      prefix: 63,
      calldigit: 10,
    },
    {
      id: 156,
      name: "Poland",
      image: "poland.svg",
      flag: "poland",
      prefix: 48,
      calldigit: 9,
    },
    {
      id: 157,
      name: "Portugal",
      image: "portugal.svg",
      flag: "portugal",
      prefix: 351,
      calldigit: 9,
    },
    {
      id: 158,
      name: "Puerto Rico",
      image: "puerto_rico.svg",
      flag: "puerto rico",
      prefix: 1,
      calldigit: 10,
    },
    {
      id: 159,
      name: "Qatar",
      image: "qatar.svg",
      flag: "qatar",
      prefix: 974,
      calldigit: 8,
    },
    {
      id: 160,
      name: "Republic of Abkhazia",
      image: "republic_of_abkhazia.svg",
      flag: "abkhazia",
      prefix: 840,
      calldigit: 12,
    },
    {
      id: 161,
      name: "Reunion",
      image: "reunion.svg",
      flag: "reunion",
      prefix: 262,
      calldigit: 9,
    },
    {
      id: 162,
      name: "Romania",
      image: "romania.svg",
      flag: "romania",
      prefix: 40,
      calldigit: 10,
    },
    {
      id: 163,
      name: "Russia",
      image: "russia.svg",
      flag: "russia",
      prefix: 7,
      calldigit: 10,
    },
    {
      id: 164,
      name: "Rwanda",
      image: "rwanda.svg",
      flag: "rwanda",
      prefix: 250,
      calldigit: 9,
    },
    {
      id: 165,
      name: "Sahrawi arab democratic republic",
      image: "sahrawi_arab_democratic_republic.svg",
      flag: "sahrawi arab democratic republic",
      prefix: 212,
      calldigit: 10,
    },
    {
      id: 166,
      name: "Saint Kitts and Nevis",
      image: "saint_kitts_and_nevis.svg",
      flag: "saint kitts and nevis",
      prefix: 1869,
      calldigit: 10,
    },
    {
      id: 167,
      name: "Saint Lucia",
      image: "saint_lucia.svg",
      flag: "saint lucia",
      prefix: 1758,
      calldigit: 10,
    },
    {
      id: 168,
      name: "Saint Vincent and the Grenadines",
      image: "saint_vincent_and_the_grenadines.svg",
      flag: "saint vincent and the grenadines",
      prefix: 1784,
      calldigit: 10,
    },
    {
      id: 169,
      name: "Samoa",
      image: "samoa.svg",
      flag: "samoa",
      prefix: 685,
      calldigit: 5,
    },
    {
      id: 170,
      name: "San Marino",
      image: "san_marino.svg",
      flag: "san marino",
      prefix: 378,
      calldigit: 10,
    },
    {
      id: 171,
      name: "Sao Tome and Principe",
      image: "sao_tome_and_principe.svg",
      flag: "sao tome and principe",
      prefix: 239,
      calldigit: 7,
    },
    {
      id: 172,
      name: "Saudi Arabia",
      image: "saudi_arabia.svg",
      flag: "saudi arabia",
      prefix: 966,
      calldigit: 9,
    },
    {
      id: 173,
      name: "Senegal",
      image: "senegal.svg",
      flag: "senegal",
      prefix: 221,
      calldigit: 9,
    },
    {
      id: 174,
      name: "Serbia",
      image: "serbia.svg",
      flag: "serbia",
      prefix: 381,
      calldigit: 9,
    },
    {
      id: 175,
      name: "Seychelles",
      image: "seychelles.svg",
      flag: "seychelles",
      prefix: 248,
      calldigit: 7,
    },
    {
      id: 176,
      name: "Sierra Leone",
      image: "sierra_leone.svg",
      flag: "sierra leone",
      prefix: 232,
      calldigit: 8,
    },
    {
      id: 177,
      name: "Singapore",
      image: "singapore.svg",
      flag: "singapore",
      prefix: 65,
      calldigit: 8,
    },
    {
      id: 178,
      name: "Slovakia",
      image: "slovakia.svg",
      flag: "slovakia",
      prefix: 421,
      calldigit: 9,
    },
    {
      id: 179,
      name: "Slovenia",
      image: "slovenia.svg",
      flag: "slovenia",
      prefix: 386,
      calldigit: 8,
    },
    {
      id: 180,
      name: "Solomon Islands",
      image: "solomon_islands.svg",
      flag: "solomon islands",
      prefix: 677,
      calldigit: 7,
    },
    {
      id: 181,
      name: "Somalia",
      image: "somalia.svg",
      flag: "somalia",
      prefix: 252,
      calldigit: 8,
    },
    {
      id: 182,
      name: "Somaliland",
      image: "somaliland.svg",
      flag: "somaliland",
      prefix: 252,
      calldigit: 8,
    },
    {
      id: 183,
      name: "South Africa",
      image: "south_africa.svg",
      flag: "south africa",
      prefix: 27,
      calldigit: 9,
    },
    {
      id: 184,
      name: "South Korea",
      image: "south_korea.svg",
      flag: "south korea",
      prefix: 82,
      calldigit: 10,
    },
    {
      id: 185,
      name: "South ossetia",
      image: "south_ossetia.svg",
      flag: "south ossetia",
      prefix: 7,
      calldigit: 10,
    },
    {
      id: 186,
      name: "South Sudan",
      image: "south_sudan.svg",
      flag: "south sudan",
      prefix: 211,
      calldigit: 9,
    },
    {
      id: 187,
      name: "Spain",
      image: "spain.svg",
      flag: "spain",
      prefix: 34,
      calldigit: 9,
    },
    {
      id: 188,
      name: "Sri Lanka",
      image: "sri_lanka.svg",
      flag: "sri lanka",
      prefix: 94,
      calldigit: 9,
    },
    {
      id: 189,
      name: "Sudan",
      image: "sudan.svg",
      flag: "sudan",
      prefix: 249,
      calldigit: 9,
    },
    {
      id: 190,
      name: "Suriname",
      image: "suriname.svg",
      flag: "suriname",
      prefix: 597,
      calldigit: 7,
    },
    {
      id: 191,
      name: "Sweden",
      image: "sweden.svg",
      flag: "sweden",
      prefix: 46,
      calldigit: 10,
    },
    {
      id: 192,
      name: "Switzerland",
      image: "switzerland.svg",
      flag: "switzerland",
      prefix: 41,
      calldigit: 9,
    },
    {
      id: 193,
      name: "Syria",
      image: "syria.svg",
      flag: "syria",
      prefix: 963,
      calldigit: 9,
    },
    {
      id: 194,
      name: "Taiwan",
      image: "taiwan.svg",
      flag: "taiwan",
      prefix: 886,
      calldigit: 9,
    },
    {
      id: 195,
      name: "Tajikistan",
      image: "tajikistan.svg",
      flag: "tajikistan",
      prefix: 992,
      calldigit: 9,
    },
    {
      id: 196,
      name: "Tanzania",
      image: "tanzania.svg",
      flag: "tanzania",
      prefix: 255,
      calldigit: 9,
    },
    {
      id: 197,
      name: "Thailand",
      image: "thailand.svg",
      flag: "thailand",
      prefix: 66,
      calldigit: 9,
    },
    {
      id: 198,
      name: "Togo",
      image: "togo.svg",
      flag: "togo",
      prefix: 228,
      calldigit: 8,
    },
    {
      id: 199,
      name: "Tonga",
      image: "tonga.svg",
      flag: "tonga",
      prefix: 676,
      calldigit: 7,
    },
    {
      id: 200,
      name: "Trinidad and Tobago",
      image: "trinidad_and_tobago.svg",
      flag: "trinidad and tobago",
      prefix: 1868,
      calldigit: 10,
    },
    {
      id: 201,
      name: "Tunisia",
      image: "tunisia.svg",
      flag: "tunisia",
      prefix: 216,
      calldigit: 8,
    },
    {
      id: 202,
      name: "Turkey",
      image: "turkey.svg",
      flag: "turkey",
      prefix: 90,
      calldigit: 10,
    },
    {
      id: 203,
      name: "Turkmenistan",
      image: "turkmenistan.svg",
      flag: "turkmenistan",
      prefix: 993,
      calldigit: 8,
    },
    {
      id: 204,
      name: "Tuvalu",
      image: "tuvalu.svg",
      flag: "tuvalu",
      prefix: 688,
      calldigit: 6,
    },
    {
      id: 205,
      name: "Uganda",
      image: "uganda.svg",
      flag: "uganda",
      prefix: 256,
      calldigit: 9,
    },
    {
      id: 206,
      name: "Ukraine",
      image: "ukraine.svg",
      flag: "ukraine",
      prefix: 380,
      calldigit: 9,
    },
    {
      id: 207,
      name: "United Arab Emirates",
      image: "united_arab_emirates.svg",
      flag: "united arab emirates",
      prefix: 971,
      calldigit: 9,
    },
    {
      id: 208,
      name: "United Kingdom",
      image: "united_kingdom.svg",
      flag: "united kingdom",
      prefix: 44,
      calldigit: 10,
    },
    {
      id: 209,
      name: "United States",
      image: "united_states.svg",
      flag: "united_states",
      prefix: 1,
      calldigit: 10,
    },
    {
      id: 210,
      name: "Uruguay",
      image: "uruguay.svg",
      flag: "uruguay",
      prefix: 598,
      calldigit: 8,
    },
    {
      id: 211,
      name: "Uzbekistan",
      image: "uzbekistan.svg",
      flag: "uzbekistan",
      prefix: 998,
      calldigit: 8,
    },
    {
      id: 212,
      name: "Vanuatu",
      image: "vanuatu.svg",
      flag: "vanuatu",
      prefix: 678,
      calldigit: 7,
    },
    {
      id: 213,
      name: "Vatican City",
      image: "vatican_city.svg",
      flag: "vatican city",
      prefix: 379,
      calldigit: 10,
    },
    {
      id: 214,
      name: "Venezuela",
      image: "venezuela.svg",
      flag: "venezuela",
      prefix: 58,
      calldigit: 7,
    },
    {
      id: 215,
      name: "Vietnam",
      image: "vietnam.svg",
      flag: "vietnam",
      prefix: 84,
      calldigit: 9,
    },
    {
      id: 216,
      name: "Yemen",
      image: "yemen.svg",
      flag: "yemen",
      prefix: 967,
      calldigit: 9,
    },
    {
      id: 217,
      name: "Zambia",
      image: "zambia.svg",
      flag: "zambia",
      prefix: 260,
      calldigit: 9,
    },
    {
      id: 218,
      name: "Zimbabwe",
      image: "zimbabwe.svg",
      flag: "zimbabwe",
      prefix: 263,
      calldigit: 9,
    },
  ];
  const isError = errors[name] && touched[name];
  const [selectedPrefix, setSelectedPrefix] = useState(defaultCountryCode);
  const [selectedFlag, setSelectedFlag] = useState(defaultFlagImage);
  const [selectedCountry, setSelectedCountry] = useState(defaultCountry);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [countrySearch, setCountrySearch] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const dropdownRef = useRef(null);

  const selectCountry = (prefix, flag, image, name) => {
    setSelectedPrefix(`+${prefix}`);
    setSelectedFlag(image);
    setSelectedCountry(name);
    setDropdownOpen(false);
    setCountrySearch("");
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const toggleDropdown = () => {
    setDropdownOpen((prevState) => !prevState);
  };

  const filteredCountries = countriesname.filter((country) =>
    country.name.toLowerCase().includes(countrySearch.toLowerCase())
  );

  const handleInputChange = (event) => {
    setCountrySearch(event.target.value);

    /* -------------------------------------------------------------------------- */
    /*                      for search color change when type                     */
    /* -------------------------------------------------------------------------- */
    const inputValue = event.target.value;
    setSearchValue(inputValue);
    };

  return (
    <>
      <div className="phone-input-container" ref={dropdownRef}>
        <div className={`pn-select ${isDropdownOpen ? "pn-select--open" : ""}`}>
          <button
            className="pn-selected-prefix d-flex text-left"
            aria-label="Select phonenumber prefix"
            onClick={toggleDropdown}
            tabIndex={1}
            style={{ backgroundColor: "var(--form-bar-f-5-f-5-f-5, #F5F5F5)" }}
          >
            <img
              className="pn-selected-prefix__flag align-item-center"
              src={`https://azillik-it.s3.ap-south-1.amazonaws.com/Flags/${selectedFlag}`}
              alt="flag"
            />

            {/* trim function for country name  */}
            <span className="align-self-center" style={{ width: "90px" }}>
              ({selectedPrefix}){" "}
              {selectedCountry.length > 4
                ? selectedCountry.slice(0, 4) + ".."
                : selectedCountry}
            </span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="7"
              fill="none"
              viewBox="0 0 12 7"
              style={{ margin: "0px 10px 0px 0px", transition: "0.4s" }}
              className={` ${isDropdownOpen ? "azil-open-arrow" : " "}`}
            >
              <path
                stroke="#696969"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="1.5"
                d="M11 1L6.884 5.116a1.254 1.254 0 01-1.768 0L1 1"
                className={` ${isDropdownOpen ? "azil-open-arrow1" : " "}`}
              ></path>
            </svg>
          </button>

          <div className="position-relative" style={{width: "100%"}}>
            <input
              className="pn-input"
              type={type}
              value={values}
              name={name}
              placeholder={isError ? "" : (
                selectedCountry
                  ? `Enter ${selectedCountry} calldigits (max ${
                      countriesname.find((c) => c.name === selectedCountry)
                        ?.calldigit
                    } digits)`
                  : "Select country first"
              )}    
              onChange={handleChange}
              autoComplete="off"
              min="7"
              maxLength="13"
              defaultValue={defaultValue}
              style={{
                border: isError ? "1px solid #ff3366" : "",
                background: isError ? "#fcd4ee30" : "",
              }}
            />
            {isError && (
            <label className="azil-show-error-message">{errors[name]}</label>
            )}
          </div>
          <div className="pn-dropdown">
            <div className="pn-search mt-2 text-center align-item-center">
              <div className="azil-country-search-button">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  fill="none"
                  viewBox="0 0 23 22"
                >
                  <path
                    stroke="#7A7A7A"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9.988 17a8 8 0 100-16 8 8 0 000 16zM21.99 21l-4.666-4.667"
                    style={{
                      stroke: searchValue.trim() !== "" ? "#6960EB" : "#696969",
                    }}
                  ></path>
                </svg>
              </div>
              <input
                placeholder="Search for countries "
                className="pn-search__input search align-self-center"
                type="search"
                value={searchValue}
                onChange={handleInputChange}
                autoComplete="nope"
                name="search"
              />
            </div>

            <ul className="pn-list list p-0">
              {filteredCountries.length > 0 ? (
                filteredCountries.map((country) => (
                  <li
                    key={country.flag}
                    className={`pn-list-item ${
                      country.flag === selectedFlag
                        ? "pn-list-item--selected"
                        : ""
                    }`}
                    tabIndex={0}
                    role="button"
                    aria-pressed="false"
                    onClick={() =>
                      selectCountry(
                        country.prefix,
                        country.flag,
                        country.image,
                        country.name
                      )
                    }
                    onKeyDown={(e) => {
                      if (
                        e.key === "Enter" ||
                        e.keyCode === 13 ||
                        e.key === "Spacebar" ||
                        e.key === " " ||
                        e.keyCode === 32
                      ) {
                        e.preventDefault();
                        selectCountry(
                          country.prefix,
                          country.flag,
                          country.image,
                          country.name
                        );
                      }
                    }}
                  >
                    <img
                      className="pn-list-item__flag pr-2"
                      src={`https://azillik-it.s3.ap-south-1.amazonaws.com/Flags/${country.image}`}
                      alt={country.flag}
                    />

                    <span className="pn-list-item__country">
                      {country.name} ( +{country.prefix})
                    </span>
                    <span className="pn-list-item__prefix"></span>
                  </li>
                ))
              ) : (
                <div className="pn-list-item pn-list-item--no-results">
                  No results found
                </div>
              )}
            </ul>
          </div>
        </div>    
      </div>
    </>
  );
};

export default CustomPhoneNumber;
