/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import Footer from "../../Layout/Footer";
import Header from "../../Layout/Header";
import { imagesConst as IMAGECONST } from "../../../components/constants/imagesConst";
import { GetProducts } from "../../../services/ContactService";
import { Seo } from "../../../components/custom/Seo";
import { seoConstants as SEO_CONST } from "../../../components/constants/seoConst";
import AOS from "aos";
import "aos/dist/aos.css";

const Product = () => {
  const [productData, setProductData] = useState([]);
  const [limit, setLimit] = useState(5);

  const getProductData = () => {
    let pagination = `?page=1&limit=${limit}`;
    GetProducts(pagination)
      .then((res) => {
        const data = res && res.data && res.data.data && res.data.data.data;
        setProductData(data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getProductData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);

  /* -------------------------------------------------------------------------- */
  /*                            Button ripple effect                            */
  /* -------------------------------------------------------------------------- */
  const handleHover = (e) => {
    const button = e.target;
    const rect = button.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    const rippleSpan = document.createElement('span');
    rippleSpan.classList.add('ripple-span');
    rippleSpan.style.top = `${y}px`;
    rippleSpan.style.left = `${x}px`;
    button.appendChild(rippleSpan);
  };

  const handleHoverEnd = (e) => {
    const button = e.target;
    const ripples = button.getElementsByClassName('ripple-span');
    while (ripples.length > 0) {
      button.removeChild(ripples[0]);
    }
  };

  return (
    <React.Fragment>
      <Seo
        title={SEO_CONST.PRODUCT_TITLE}
        description={""}
        keyword={SEO_CONST.PRODUCT_TITLE}
      />
      <Header />
      <section className="azil-product-hero-section azil-section-padding azil-bg-hero">
        <div className="azil-home-container" data-aos="fade-up">
          <div className="text-center">
            <div className="my-2 font-primary font-600">
              OUR PRODUCTS
            </div>
            <div className="azil-primary-heading my-2">
              Get any creative project done with <span className="font-primary"> Azillik</span>
            </div>
            <div className="azil-description my-2">
              Bring your ideas to life, no matter your skill level
            </div>
          </div>
        </div>
      </section>
      <section className="azil-product-section-2 azil-section-padding">
        <div className="azil-home-container" data-aos="fade-up">
          <div className="row">
            {productData &&
              productData.map((product, index) => {
                return (
                  <>
                    {product && product.position === "horizontal" ? (
                      <div
                        className="azil-product-box m-3"
                        style={{
                          background: product && product.background_color,
                        }}
                        key={index}
                      >
                        <div className="row align-items-center">
                          <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div
                              className="azil-product-download-content"
                              style={{
                                background:
                                  product && product.sub_title_backgroung_color,
                                color: product && product.sub_title_color,
                              }}
                            >
                              {product.sub_title}
                            </div>
                            <img
                              src={
                                product && product.product_logo
                                  ? product.product_logo
                                  : IMAGECONST.Logo
                              }
                              alt=""
                              className="img-fluid mt-3"
                              draggable="false"
                            />
                            <div className="azil-small-heading my-2">
                              {product.title}
                            </div>
                            <div className="azil-description">
                              {product.description}
                            </div>
                            <a href={product.product_link} target="_blank">
                              <div
                                className="azil-small-heading py-3"
                                style={{
                                  color: product && product.product_link_color,
                                }}
                              >
                                {product.product_link_name} {" "}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="none"
                                  viewBox="0 0 11 6"
                                  style={{rotate: "-90deg"}}
                                >
                                  <path
                                    style={{color: product && product.product_link_color}}
                                    fill="#696969"
                                    d="M5.061 5.53a.712.712 0 01-.496-.2L.255 1.16a.664.664 0 010-.961.719.719 0 01.993 0l3.813 3.69L8.874.2a.718.718 0 01.993 0 .664.664 0 010 .96l-4.31 4.172a.712.712 0 01-.496.199z"
                                  ></path>
                                </svg>
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12 col-12 text-center">
                            <img
                              src={product.product_image}
                              alt="product img"
                              className="img-fluid"
                              draggable="false"
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div
                          className="col-lg-6 col-md-6 col-sm-12 col-12 my-3"
                          key={index}
                        >
                          <div
                            className="azil-product-box m-3"
                            style={{
                              background: product && product.background_color,
                            }}
                          >
                            <div
                              className="azil-product-download-content"
                              style={{
                                background:
                                  product && product.sub_title_backgroung_color,
                                color: product && product.sub_title_color,
                              }}
                            >
                              {product.sub_title}
                            </div>
                            <img
                              src={IMAGECONST.Logo}
                              alt="logo"
                              className="img-fluid mt-3"
                              draggable="false"
                            />
                            <div className="azil-small-heading my-2">
                              {product.title}
                            </div>
                            <div className="azil-description">
                              {product.description}
                            </div>
                            <a href={product.product_link} target="_blank">
                              <div
                                className="azil-small-heading py-3"
                                style={{
                                  color: product && product.product_link_color,
                                }}
                              >
                                {product.product_link_name} {" "}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="none"
                                  viewBox="0 0 11 6"
                                  style={{rotate: "-90deg"}}
                                >
                                  <path
                                    style={{color: product && product.product_link_color}}
                                    fill="#696969"
                                    d="M5.061 5.53a.712.712 0 01-.496-.2L.255 1.16a.664.664 0 010-.961.719.719 0 01.993 0l3.813 3.69L8.874.2a.718.718 0 01.993 0 .664.664 0 010 .96l-4.31 4.172a.712.712 0 01-.496.199z"
                                  ></path>
                                </svg>
                              </div>
                            </a>
                            <img
                              src={product.product_image}
                              alt="product img"
                              className="img-fluid"
                              draggable="false"
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </>
                );
              })}
          </div>
          {productData && productData.length > 5 && (
            <div className="text-right my-4 mr-2">
              <button
                className="azil-hero-button"
                onClick={() => setLimit(limit + 5)} onMouseEnter={handleHover} onMouseLeave={handleHoverEnd}
              >
                View All
              </button>
            </div>
          )}
        </div>
      </section>
      <Footer />
    </React.Fragment>
  );
};

export default Product;
